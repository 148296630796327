import { useMemo, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import classNames from "classnames";

import SleepLog from "../../../types/SleepLog";
import SleepLogSettings from "../../../types/SleepLogSettings";
import AnalyticsUtility from "../../../utils/analytics-utils";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import { isFilteredOut } from "../../../utils/SleepLogFilterUtils";
import SleepPerDayChart from "../charts/sleep/SleepPerDayChart";
import SleepPerDayLineChart from "../charts/sleep/SleepPerDayLineChart";
import FeelingTagCountChart from "../charts/taglike/FeelingTagCountChart";
import UserTagCountChart from "../charts/taglike/UserTagCountChart";
import SleepMedCountChart from "../charts/medication/SleepMedCountChart";
import SleepDurationDoughnutChart from "../charts/sleep/SleepDurationDoughnutChart";
import SleepLogUtils from "../../../utils/SleepLogUtils";
import RatingDoughnutChart from "../charts/rating/RatingDoughnutChart";
import RatingPerDayChart from "../charts/rating/RatingPerDayChart";
import SleepCard from "../cards/SleepCard";
import RatingCard from "../cards/RatingCard";
import SleepWakeCard from "../cards/SleepWakeCard";
import ChartCard from "../cards/ChartCard";
import SleepLogViewFactory from "../../../types/SleepLogViewFactory";
import SimpleTooltip from "../../sleep-log-view/common/SimpleTooltip";
import SleepWakePerDayChart from "../charts/sleep/SleepWakePerDayChart";
import NumAwakeningsPerDayChart from "../charts/sleep/NumAwakeningsPerDayChart";
import SleepTimeBreakdownChart from "../charts/sleep/SleepTimeBreakdownChart";
import ErrorBoundary from "../../common/ErrorBoundary";
import SleepStagesUtils from "../../../utils/SleepStagesUtils";

import styles from "./styles/sleep-log-dashboard.module.css";

interface Props {
    sleepLogs: SleepLog[];
    sleepLogSettings: SleepLogSettings | undefined;
}

export default function SleepLogDashboard(props: Props) {
    const {sleepLogSettings} = props;
    const [sleepStats, setSleepStats] = useState([] as any[]);

    const [numDays, setNumDays] = useState(28);

    const allSleepLogs = useMemo(() => {
        let thresholdDate = DateTimeUtils.calculateThresholdDate(34);
        const filter = { minDate: thresholdDate};

        return props.sleepLogs.filter(sleepLog => !isFilteredOut(sleepLog, filter));
    }, [props.sleepLogs]);

    const lastSleepLogs = useMemo(() => {
        let thresholdDate = DateTimeUtils.calculateThresholdDate(numDays);
        const filter = { minDate: thresholdDate};

        return props.sleepLogs.filter(sleepLog => !isFilteredOut(sleepLog, filter));
    }, [props.sleepLogs, numDays]); 

    const lastSleepLogsView = useMemo(() => {
        return SleepLogViewFactory.createViews(lastSleepLogs);
    }, [lastSleepLogs]);

    const allSleepLogsView = useMemo(() => {
        return SleepLogViewFactory.createViews(allSleepLogs);
    }, [allSleepLogs]);    

    const [showSleepSevenDayAverage, setShowSleepSevenDayAverage] = useState(false);
    const [showSleepColors, setShowSleepColors] = useState(false);
    const [showSleepDoughnutChart, setShowSleepDoughnutChart] = useState(false);

    const [showRatingSevenDayAverage, setShowRatingSevenDayAverage] = useState(false);
    const [showRatingColors, setShowRatingColors] = useState(true); 
    const [showRatingDoughnutChart, setShowRatingDoughnutChart] = useState(false);

    const [showSleepTimeBreakdown, setShowSleepTimeBreakdown] = useState(false);
    

    useEffect(() => {
        let thresholdInDays = [7, numDays];

        let stats: Array<Record<string, any>> = [];

        thresholdInDays.forEach(threshold => {
            let thresholdDate = DateTimeUtils.calculateThresholdDate(threshold);

            const filter = { minDate: thresholdDate};

            const filteredLogs = allSleepLogsView.filter(sleepLog => !isFilteredOut(sleepLog.baseSleepLog, filter));

            const sleepDurations = filteredLogs.filter(sleepLog => sleepLog.minutesAsleep !== undefined)
                .map(sleepLog => sleepLog.minutesAsleep as number);

            let averageSleep = Math.round(AnalyticsUtility.calculateAverage(sleepDurations));
            let [minSleep, maxSleep] = AnalyticsUtility.calculateMinMax(sleepDurations);
            let standardDeviation = Math.round(AnalyticsUtility.calculateStandardDeviation(sleepDurations, averageSleep));

            const ratings = filteredLogs.filter(sleepLog => sleepLog.baseSleepLog.rating !== undefined)
                .map(sleepLog => sleepLog.baseSleepLog.rating as number);

            const averageRating = AnalyticsUtility.calculateAverage(ratings);

            stats.push({threshold, averageSleep, min: minSleep, max: maxSleep, standardDeviation, averageRating});
        });

        setSleepStats(stats);
    }, [allSleepLogsView, numDays]);

    const averageSleepTime = useMemo(() => {
        let thresholdInDays = [7, numDays];
        return thresholdInDays.map(threshold => {
            let thresholdDate = DateTimeUtils.calculateThresholdDate(threshold);

            const filter = { minDate: thresholdDate};
            const filteredLogs = allSleepLogsView.filter(sleepLog => !isFilteredOut(sleepLog.baseSleepLog, filter))
                .filter(sleepLog => sleepLog.mainSleep);

            const sleepTimesInMins = filteredLogs.map(log => {
                const mainSleepEpisode = log.mainSleep!;
                let { sleeptime } = SleepStagesUtils.getSleepAndWakeupTime(mainSleepEpisode);
                sleeptime = sleeptime.setZone(mainSleepEpisode.timezone ?? "utc");
                return (sleeptime.hour * 60) + sleeptime.minute;
            });

            const wakeTimesInMins = filteredLogs.map(log => {
                const mainSleepEpisode = log.mainSleep!;
                let { waketime } = SleepStagesUtils.getSleepAndWakeupTime(mainSleepEpisode);
                waketime = waketime.setZone(mainSleepEpisode.timezone ?? "utc");
                return (waketime.hour * 60) + waketime.minute;
            });            

            return sleepTimesInMins.length > 0 && wakeTimesInMins.length > 0 ?
                {
                    sleepTime: SleepLogUtils.averageTime(sleepTimesInMins),
                    wakeTime: SleepLogUtils.averageTime(wakeTimesInMins)
                }
                : undefined;
        });

    }, [allSleepLogsView, numDays]);

    const hasSleepMed = useMemo(() => {
        let hasSleepMed = false;

        allSleepLogs.forEach(sleepLog => {
            if (sleepLog.medications && sleepLog.medications.length > 0) {
                hasSleepMed = true;
            }         
        });

        return hasSleepMed;
    }, [allSleepLogs]);

    const hasOtherMed = useMemo(() => {
        let hasOtherMed = false;

        allSleepLogs.forEach(sleepLog => {
            if (sleepLog.otherMedications && sleepLog.otherMedications.length > 0) {
                hasOtherMed = true;
            }                    
        });

        return hasOtherMed;
    }, [allSleepLogs]); 
    
    function getStat(i: number): any {
        return sleepStats.length > i ? sleepStats[i] : undefined;
    }

    const titleStyle = 'text-center text-muted-light fs-5';

    return (
        <div>
            <h4 className="pt-4 pb-3 fs-3 fw-semibold text-gray-600">Last 7 days</h4>
            <Row className="justify-content-center gx-4 gy-3">
                <Col xs="6" lg="3">
                    <SleepCard sleepInMins={getStat(0)?.averageSleep} comparison={getStat(1)?.averageSleep}/>
                </Col>
                <Col xs="6" lg="3">
                    <RatingCard rating={getStat(0)?.averageRating} comparison={getStat(1)?.averageRating} />
                </Col>
                <Col xs="6" lg="3">
                 <SleepWakeCard title="Sleep time" timeInMins={averageSleepTime[0]?.sleepTime} />
                </Col>
                <Col xs="6" lg="3">
                 <SleepWakeCard title="Wake time" timeInMins={averageSleepTime[0]?.wakeTime} />
                </Col>                             
            </Row>
            <h4 className="pt-4 pb-3 fs-3 fw-semibold text-gray-600">
                <Row className="g-0">
                <Col xs="auto">Last</Col>
                <Col xs="auto">
                    <select 
                        className={classNames("form-select d-inline-block py-0 fs-3 text-gray-600", styles.title)}
                        aria-label="Default select example"
                        value={numDays.toString()}
                        onChange={e => setNumDays(Number(e.target.value))}
                    >
                        <option value="3">3</option>                         
                        <option value="7">7</option>                    
                        <option value="14">14</option>
                        <option value="21">21</option>
                        <option value="28">28</option>
                    </select>                
                </Col>
                <Col xs="auto">days</Col>
                </Row>
            </h4>
            <Row className="justify-content-center gx-4 gy-3">
                <Col xs="6" lg="3">
                    <SleepCard sleepInMins={getStat(1)?.averageSleep} comparison={undefined}/>
                </Col>
                <Col xs="6" lg="3">
                    <RatingCard rating={getStat(1)?.averageRating} comparison={undefined} />
                </Col>
                <Col xs="6" lg="3">
                 <SleepWakeCard title="Sleep time" timeInMins={averageSleepTime[1]?.sleepTime} />
                </Col>
                <Col xs="6" lg="3">
                 <SleepWakeCard title="Wake time" timeInMins={averageSleepTime[1]?.wakeTime} />
                </Col>                                                                     
            </Row>             

            <Row className="justify-content-center mt-1 g-4">

                <Col lg="6">
                    <ChartCard title='Hours Slept'>
                        <ErrorBoundary>
                            {showSleepDoughnutChart ?
                                <div className="px-3">
                                    <SleepDurationDoughnutChart sleepLogs={lastSleepLogsView} />
                                </div>
                                :
                                <SleepPerDayChart
                                    sleepLogs={[showSleepSevenDayAverage ? allSleepLogsView : lastSleepLogsView]}
                                    aggregationPeriod={showSleepSevenDayAverage ? 'week' : 'day'}
                                    goalSleepInMins={sleepLogSettings?.targetSleepDuration}
                                    showLines={true}
                                    showLegend={false}
                                    colorRows={showSleepColors}
                                />        
                            }
                            <div className="d-flex justify-content-between mt-1">
                                <div>
                                    <button type="button"
                                        className="btn btn-link link-dark py-0"
                                        onClick={() => setShowSleepDoughnutChart(!showSleepDoughnutChart)}
                                    >                            
                                        <i className={showSleepDoughnutChart ? "bi bi-bar-chart" : "bi bi-pie-chart"}>
                                        </i>
                                    </button>                    
                                </div>
                                <button type="button"
                                    className="btn btn-sm btn-link link-secondary py-0"
                                    onClick={(e) => setShowSleepSevenDayAverage(!showSleepSevenDayAverage)}
                                >
                                    {showSleepSevenDayAverage ? 'Show daily' : 'Show weekly'}
                                </button>
                                <button type="button"
                                    className="btn btn-link link-dark py-0"
                                    onClick={(e) => setShowSleepColors(!showSleepColors)}
                                >
                                    <SimpleTooltip message="Color code the chart by sleep time.">
                                        <i className={showSleepColors ? 'bi bi-palette-fill' : 'bi bi-palette'}></i>
                                    </SimpleTooltip>
                                </button>                            
                            </div>
                        </ErrorBoundary>                      
                </ChartCard>
                </Col>

                <Col lg="6">
                    <ChartCard title='Rating'>
                        <ErrorBoundary>
                            {showRatingDoughnutChart ?
                            <div className="px-3">
                                <RatingDoughnutChart sleepLogs={lastSleepLogsView} />
                            </div>    
                            :
                            <RatingPerDayChart 
                                sleepLogGroups={[showRatingSevenDayAverage ? allSleepLogsView : lastSleepLogsView]}
                                aggregationPeriod={showRatingSevenDayAverage ? 'week' : 'day'}
                                type="bar"
                                showLines={true}
                                useColorCoding={showRatingColors}
                            />
                            }
                            <div className="d-flex justify-content-between mt-1">
                                <div>
                                    <i
                                        className={showRatingDoughnutChart ? "bi bi-bar-chart" : "bi bi-pie-chart"}
                                        onClick={() => setShowRatingDoughnutChart(!showRatingDoughnutChart)}
                                    >
                                    </i>                            
                                </div>
                                <button type="button"
                                    className="btn btn-sm btn-link link-secondary py-0"
                                    onClick={(e) => setShowRatingSevenDayAverage(!showRatingSevenDayAverage)}
                                >
                                    {showRatingSevenDayAverage ? 'Show daily' : 'Show weekly'}
                                </button>
                                <button type="button"
                                    className="btn btn-link link-dark py-0"
                                    onClick={(e) => setShowRatingColors(!showRatingColors)}
                                >
                                    <SimpleTooltip message="Color code the chart by rating.">
                                        <i className={showRatingColors ? 'bi bi-palette-fill' : 'bi bi-palette'}></i>
                                    </SimpleTooltip>
                                </button>                            
                            </div>
                        </ErrorBoundary>                           
                    </ChartCard>
                </Col>

            </Row>

            <Row className="justify-content-center mt-1 g-4">
                <Col lg="6">
                    <div style={{height: "100%"}}>
                        <ChartCard title="Sleep and Wakeup Times">
                            <ErrorBoundary>
                                {showSleepTimeBreakdown ?
                                    <SleepTimeBreakdownChart sleepLogs={lastSleepLogsView} />
                                    :
                                    <SleepWakePerDayChart
                                        sleepLogs={[lastSleepLogsView]} 
                                        aggregation="day"
                                        targetSleepTime={sleepLogSettings?.targetBedTime}
                                        targetWakeTime={sleepLogSettings?.targetWakeTime}
                                    />
                                }
                                <div className="d-flex mt-2">
                                    <div>
                                        <button type="button"
                                            className="btn btn-link link-dark p-0"
                                            onClick={() => setShowSleepTimeBreakdown(state => !state)}
                                        >                            
                                            <i className={classNames('bi', {'bi-bar-chart-line': !showSleepTimeBreakdown, 'bi-bar-chart-steps': showSleepTimeBreakdown})}>
                                            </i>
                                        </button>                    
                                    </div>                          
                                </div>
                            </ErrorBoundary>
                        </ChartCard>
                    </div>
                </Col>
                <Col lg="6">
                    <div style={{height: "100%"}}>
                        <ChartCard title="Middle of Night Awakenings">
                            <ErrorBoundary>
                                <NumAwakeningsPerDayChart
                                    sleepLogs={[lastSleepLogsView]}
                                    thresholdInMins={5}
                                    showThresholdInput={true}
                                />
                            </ErrorBoundary>
                        </ChartCard>
                    </div>
                </Col>                         
            </Row>                

            <Row className="justify-content-center mt-1 g-4">
                <Col lg="6">
                    <div style={{height: "100%"}}>
                    <ChartCard title="How I've Been Feeling">
                        <FeelingTagCountChart sleepLogs={lastSleepLogs} limit={5} showTitle={false} />
                    </ChartCard>
                    </div>    
                </Col>
                <Col lg="6">
                    <div style={{height: "100%"}}>
                    <ChartCard title="Tags">
                        <UserTagCountChart sleepLogs={lastSleepLogs} limit={5} showTitle={false} />
                    </ChartCard>
                    </div>    
                </Col>                                
            </Row>                    

            <Row className="justify-content-center mt-1 g-4">
                {hasSleepMed &&
                    <Col lg="6">
                        <div style={{height: "100%"}}>
                        <ChartCard title="Sleep Medications">
                            <SleepMedCountChart sleepLogs={lastSleepLogsView} useSleepMedications={true} limit={5} />
                        </ChartCard>
                        </div>    
                    </Col>                
                }
                {hasOtherMed &&
                    <Col lg="6">
                        <div style={{height: "100%"}}>
                        <ChartCard title="Other Medications">
                            <SleepMedCountChart sleepLogs={lastSleepLogsView} useSleepMedications={false} limit={5} />
                        </ChartCard>
                        </div>
                    </Col>                
                }                
            </Row>
        </div>
    );
}