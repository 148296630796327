import React from "react";
import { Row, Col } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import SleepLog from "../../../../types/SleepLog";
import ISleepLogView from "../../../../types/ISleepLogView";
import { prettyFormatTimeDurationInHours } from "../../../../utils/FormatUtility";
import { ChartOptions } from "chart.js";
import { ChartStyles } from "../constants/chart-styles";

interface Props {
  sleepLogs: ISleepLogView[];
}

export default function RatingPerMedicationChart(props: Props) {
  const { sleepLogs } = props;
  
  function calculateRatingAverages(sleepLogs: ISleepLogView[]) {

    const sleepMedicationData: any = {
      labels: [],
      datasets: [
        {
          label: ["Average Rating"],
          data: [],
          fill: false,
          backgroundColor: 'rgba(255, 214, 102, .7)',
          borderWidth: 0,
          maxBarThickness: 100
        }           
      ],
    };

    let ratingTotal: {[key: string]: number} = {};
    let medicationUseCount: {[key: string]: number} = {};

    sleepLogs.forEach(log => {
      if (log.rating !== undefined) {
        if (log.baseSleepLog.medications && log.baseSleepLog.medications.length > 0) {
          log.baseSleepLog.medications.forEach(m => {

            if (log.rating !== undefined) {
              if (!medicationUseCount[m.name]) {
                ratingTotal[m.name] = 0;
                medicationUseCount[m.name] = 0;
              }

              medicationUseCount[m.name]++;
              ratingTotal[m.name] += log.rating;
            }
          });
        }
        else
        {
          if (!medicationUseCount["No medication"]) {
            ratingTotal["No medication"] = 0;
            medicationUseCount["No medication"] = 0;
          }

          medicationUseCount["No medication"]++;
          ratingTotal["No medication"] += log.rating;
        }
      }
    });

    let averages = Object.keys(medicationUseCount).map((k) => {
      return {name: k, averageRating: ratingTotal[k] / medicationUseCount[k]};
    });

    averages.sort((lhs, rhs) => {
      return rhs.averageRating - lhs.averageRating;      
    });

    const truncate = (name: string) => {
      if (name.length > 15) {
        return name.slice(0, 15) + "..";
      }
      else {
        return name;
      }
    }

    averages.forEach((e) => {

      let rating = e.averageRating.toFixed(2);

      sleepMedicationData.datasets[0].data.push(rating);
      sleepMedicationData.labels.push(truncate(e.name));
    })

    return sleepMedicationData;
  }

  const sleepMedicationData = calculateRatingAverages(sleepLogs);

  const options: ChartOptions<"bar"> = {
    plugins: {
      legend: {
        display: false
      },       
    },
    scales: {
      x: {
        grid: {
            color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          font: {
            ...ChartStyles.axisFont
          }
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          ...ChartStyles.gridYAxis
        },
        ticks: {
            stepSize: 1,
            font: {
                ...ChartStyles.axisFont
            }
        },
      }
    },
  };   

  return (
    <Bar data={sleepMedicationData} options={options} />   
  );


}