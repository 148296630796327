import { ChartOptions } from "chart.js";
import React, { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDeferred from 'chartjs-plugin-deferred';

import ISleepLogView from "../../../../types/ISleepLogView";
import { ChartStyles } from "../constants/chart-styles";
import { useImmer } from "use-immer";
import { Aggregation } from "../../../../types/types";
import TimeAggregationButtonGroup from "../../../library/TimeAggregationButtonGroup";
import _ from "lodash";
import AnalyticsUtils from "../../../../utils/analytics-utils";

interface Props {
  sleepLogs: ISleepLogView[][];
  thresholdInMins?: number;
  showThresholdInput?: boolean;
  enableZoom?: boolean;
}

export default function NumAwakeningsPerDayChart({
    sleepLogs: sleepLogGroups,
    thresholdInMins,
    showThresholdInput,
    enableZoom
}: Props) {

    // in minutes
    const [awakeTimeThresholdInput, setAwakeTimeThresholdInput] = useState(thresholdInMins ?? "5");
    const awakeTimeThreshold = useMemo(() => Number(awakeTimeThresholdInput), [awakeTimeThresholdInput]);

    const [timePeriod, setTimePeriod] = useImmer<Aggregation>("day");

    const averageAwakenings = useMemo(() => {
        if (sleepLogGroups.length === 0) {
            return undefined;
        }

        const awakeningCounts = sleepLogGroups[0].map(sleepLog => sleepLog.calculateAwakeningCount(awakeTimeThreshold))
            .filter(count => count != null) as number[];

        if (awakeningCounts.length === 0) {
            return undefined;
        }

        const sum = awakeningCounts.reduce((prev, cur) => prev + cur, 0);
        return sum / awakeningCounts.length;

    }, [sleepLogGroups[0], sleepLogGroups[1], awakeTimeThreshold])    
    
    const options: ChartOptions<"bar"> = useMemo(() => {
        const unit = (() => {
            switch (timePeriod) {
                case 'day':
                    return 'day'
                case 'week':
                    return 'week';
                case 'month':
                    return 'month';
                default: 
                    return 'day'
            }
        })();

        return {
            plugins: {
                annotation: {
                    annotations: {
                        averageAwakenings: {
                            type: 'line',
                            borderColor: getPrimaryColor(.25),
                            borderWidth: 2,
                            scaleID: 'y',
                            value: averageAwakenings,
                        } 
                    }
                },
                deferred: {
                },      
                title: {
                    display: false
                },
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                      label: function(context) {
                        return `${(context.raw as number).toFixed(1)}`;
                      }          
                    }
                },
                zoom: {
                    pan: {
                    enabled: enableZoom,
                    mode: 'x',
                    },        
                    zoom: {
                    wheel: {
                        enabled: enableZoom,
                    },
                    pinch: {
                        enabled: enableZoom
                    },
                    mode: 'x',
                    }
                },
            },    
            scales: {
            x: {
                type: 'time',
                time: {
                    unit: unit,
                    displayFormats: {
                        day: "M/d",
                        week: "M/d'-'",
                        month: "M/d'-'"
                    },
                    tooltipFormat: "MM/dd/y" + (unit === "day" ? '' : '-')
                },   
                adapters: {
                    date: {
                        zone: "UTC"
                    }
                },                  
                ticks: {
                display: true,
                font: {
                    size: 10,
                    family: "Segoe UI"
                }
                },
                grid: {
                display: false,
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                    font: {
                    size: 10
                    }
                },
                grid: {
                    ...ChartStyles.gridYAxis,
                }
            }        
            },
        };
    }, [enableZoom, timePeriod, averageAwakenings]);

    const data = useMemo(() => {
        if (timePeriod === "day") {
            return getPerDayData(sleepLogGroups, awakeTimeThreshold);
        }
        else if (timePeriod === "week" || timePeriod === "month") {
            return getAggregatedData(sleepLogGroups, timePeriod, awakeTimeThreshold);
        }

    }, [sleepLogGroups[0], sleepLogGroups[1], timePeriod, awakeTimeThreshold]);

    const aggregations: Aggregation[] = useMemo(() => ["day", "week", "month"], []);

    return (
        <div>
            <Bar
                data={data}
                options={options}
                plugins={[ChartDeferred]}
                width={null as any}
                height={null as any} 
            />

            <div className="mt-2 text-center">
                <TimeAggregationButtonGroup
                    aggregations={aggregations}
                    idPrefix="num-awakenings"
                    selected={timePeriod}
                    onSelect={(selected) => setTimePeriod(selected)} 
                />
            </div>
                
            {(showThresholdInput ?? true) &&
                <div className="mt-4 text-xs">
                <span>Awake for at least&nbsp;&nbsp;</span>
                <input type="number"
                    className="form-control d-inline-block mx-1 px-1 py-0 d-inline-block text-xs"
                    style={{width:"2.75rem", borderWidth: "0px 0px 1px 0px", borderRadius: "0"}}
                    size={3}
                    value={awakeTimeThreshold}
                    min="1"
                    max="120"
                    onChange={(e) => setAwakeTimeThresholdInput(e.target.value)}>
                </input>
                <span> minutes</span>
                </div>
            }
            
            <div className="mt-2">
                <span className="text-xs">Average awakenings: {averageAwakenings?.toFixed(1)}</span>
            </div>
        </div>   
    );
}

const fileredOutOpacity = .075;
const primaryColor = "239, 68, 68";

function getPrimaryColor(opacity: number) {
    return `rgba(${primaryColor}, ${opacity})`;
}

function getPerDayData(sleepLogGroups: ISleepLogView[][], awakeTimeThreshold: number) {
    const data: any = {
        labels: [],
        datasets: [
            {
            label: [''],
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 0,
            maxBarThickness: 100,
            minBarLength: 4
            },         
        ],
    };

    type Metadata = { 
        numAwakenings?: number;
        filtered: boolean;
    };

    const datesMap = Object.fromEntries(
        sleepLogGroups.flatMap((sleepLogs, groupIndex) => 
            sleepLogs.map((sleepLog: ISleepLogView): [string, Metadata] => ([
                sleepLog.date.asString,
                {
                    numAwakenings: sleepLog.calculateAwakeningCount(awakeTimeThreshold),
                    filtered: groupIndex === 0                    
                }
            ]))
        )
    );

    data.labels = Object.keys(datesMap).sort();

    for (const dateLabel of data.labels) {
        let values = datesMap[dateLabel];
        const dataset = data.datasets[0];

        dataset.data.push(values.numAwakenings ?? 0);
        dataset.backgroundColor.push(getPrimaryColor(values.filtered ? .75 : fileredOutOpacity));
        dataset.borderColor.push(getPrimaryColor(values.filtered ? 1 : fileredOutOpacity));              
    }
    
    return data;    
}

function getAggregatedData(sleepLogGroups: ISleepLogView[][], period: 'week' | 'month', awakeTimeThreshold: number) {
    const data: any = {
        labels: [],
        datasets: [
            {
                label: [""],
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 0,
                maxBarThickness: 100
            },                 
        ],
    };

    const dataset = data.datasets[0];

    if (sleepLogGroups.length === 0) {
        return data;
    }

    let totalLogs = sleepLogGroups.map(logs => logs.length).reduce((prev, cur) => prev + cur, 0);

    if (totalLogs === 0) {
        return data;
    }

    let start = "9999-12-31";
    let end = "";

    for (const sleepLogs of sleepLogGroups) {
        if (sleepLogs.length > 0) {
            const firstDate = sleepLogs[0].date.asString;
            const lastDate = sleepLogs[sleepLogs.length - 1].date.asString;

            start = firstDate < start ? firstDate : start;
            end = lastDate > end ? lastDate : end;
        }
    };

    const dateToDataMap = Object.fromEntries(sleepLogGroups[0].map(sleepLog => [
        sleepLog.date.asString,
        sleepLog.mainSleep ? sleepLog.calculateAwakeningCount(awakeTimeThreshold) : undefined
    ]));

    const dateRange = AnalyticsUtils.fillDateRange(start, end);
    const values: (number | undefined)[] = dateRange.map(date => dateToDataMap[date]);

    const approximatePeriodLength = (() => {
        switch (period) {
            case 'week':
                return 7;
            case 'month':
                return 30;
            default:
                return Number.MAX_SAFE_INTEGER;
        }
    })();

    const { dates, values: aggregatedValues } = AnalyticsUtils.aggregation(
        dateRange,
        values,
        period,
        1
    );

    for (const [date, value] of _.zip(dates, aggregatedValues)) {
        const formattedDate = date;
        data.labels.push(formattedDate);
        dataset.data.push(value as number);

        dataset.backgroundColor.push(getPrimaryColor(.75));
        dataset.borderColor.push(getPrimaryColor(1));
    }

    return data;    
}