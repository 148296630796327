import { AppState } from "../../types/AppState";
import { HttpException } from "../../types/Exceptions";
import HttpErrorBody from "../../types/HttpErrorResponse";

export class HttpService {
    constructor(
        protected appState: AppState,
        protected username: string) {
    }

    public onUserChanged(username: string) {
        this.username = username;
    }

    public onAppStateChanged(appState: AppState) {
        this.appState = appState;
    }

    protected get baseAccountPath() {
        return `${HttpService.basePath}/accounts/${this.username}`;
    }

    protected get commonHeaders() {
        return {
            "x-session-id": this.appState.sessionId
        }
    }

    protected static throwHttpError(res: Response, body: any): never {
        const error = body as HttpErrorBody;
        throw new HttpException(res.status, error.error, error.msg);        
    }

    protected static readonly basePath = "/api/v1";    
}