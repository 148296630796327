import classNames from "classnames";
import { Dropdown } from "react-bootstrap";

import styles from "../styles/sleep-log-filter.module.css";

import { Field, Operator, SleepLogFilter, ApplyTo } from "../../../types/SleepLogFilter";

interface Props {
    // Inputs
    filter: SleepLogFilter;
    fields: string[];
    operators: Operator[];

    showApplyDifferentDay: boolean;

    // Actions
    onFieldChange: (field: Field) => void;
    onOperatorChange: (op: Operator) => void;
    onEnabledChange: (enabled: boolean) => void;
    onInputChange: (index: number, value: string) => void;
    onApplyToChange: (applyTo: ApplyTo) => void;
    onDelete: () => void;    
}

export const SleepLogFilterComponent = (props: Props) => {
    return (
        <>
            <div className="d-none d-lg-block">
                <SleepLogFilterLargeWidth {...props} />
            </div>
            <div className="d-lg-none">
                <SleepLogFilterSmallWidth {...props} />
            </div>
        </>
    );
};

const SleepLogFilterLargeWidth = ({
    filter,
    fields,
    operators,
    showApplyDifferentDay,
    onFieldChange,
    onOperatorChange,
    onEnabledChange,
    onInputChange,
    onApplyToChange,
    onDelete
}: Props) => {
    return (
        <div className={classNames("input-group input-group-sm px-5", { "opacity-50": !filter.enabled })}>

            <div className="form-check align-self-center">
                <input type="checkbox"
                    className="form-check-input"
                    checked={filter.enabled}
                    onChange={e => onEnabledChange(e.target.checked)}
                />
            </div>

            <select
                className="form-select"
                value={filter.field}
                autoFocus
                onChange={(e) => onFieldChange(e.target.value as Field)}
            >
                { fields.map((field, i) => 
                    <option key={i} value={field}>{field}</option>
                )}
            </select>

            <select 
                className="form-select"
                value={filter.operator}
                onChange={(e) => onOperatorChange(e.target.value as Operator)}
            >
                { operators.map((op, i) =>
                    <option key={i} value={op}>{op}</option>
                )}
            </select>

            <FilterInput
                filter={filter} 
                onChange={(i: number, value: string) => onInputChange(i, value)}
            />

            { showApplyDifferentDay && 
                <ApplyFilterDropdown 
                    applyTo={filter.applyTo}
                    showBorder={true}
                    onChange={applyTo => onApplyToChange(applyTo)} 
                />
            }

            <div>
                <button 
                    type="button"
                    className={classNames("btn btn-sm bg-white", styles.filterButton)}
                    style={{
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                        WebkitTextStrokeWidth: ".5px"
                    }}
                    onClick={() => onDelete()}
                >
                    <i className="bi bi-x-lg text-secondary"></i>
                </button>
            </div>

        </div>
    );
}

const SleepLogFilterSmallWidth = ({
    filter,
    fields,
    operators,
    onFieldChange,
    onOperatorChange,
    onEnabledChange,
    onInputChange,
    onApplyToChange,
    onDelete
}: Props) => {

    return (
        <div className={classNames("card p-2 border-0 d-flex flex-column tw-shadow ring-1 ring-gray-200", { "opacity-50": !filter.enabled })}>
            <div className="d-flex justify-content-end">

                <div className="form-check align-self-end text-xxs">
                    <input type="checkbox"
                        className="form-check-input"
                        checked={filter.enabled}
                        onChange={e => onEnabledChange(e.target.checked)}
                    />
                </div>


                <ApplyFilterDropdown 
                    applyTo={filter.applyTo}
                    showBorder={false}
                    onChange={applyTo => onApplyToChange(applyTo)} 
                />

                <div>
                    <button 
                        type="button"
                        className="btn btn-sm"
                        onClick={() => onDelete()}
                    >
                        <i className="bi bi-x-lg"></i>
                    </button>
                </div>
                </div>
            <div className="mt-1 p-2 d-flex flex-column" style={{gap: ".75rem"}}>
                <div className="input-group input-group-sm">
                    <select
                        className="form-select"
                        value={filter.field}
                        autoFocus
                        onChange={(e) => onFieldChange(e.target.value as Field)}
                    >
                        { fields.map((field, i) => 
                            <option key={i} value={field}>{field}</option>
                        )}
                    </select>
                    <select 
                        className="form-select"
                        value={filter.operator}
                        onChange={(e) => onOperatorChange(e.target.value as Operator)}
                    >
                        { operators.map((op, i) =>
                            <option key={i} value={op}>{op}</option>
                        )}
                    </select>
                </div>

                <FilterInput
                    filter={filter} 
                    onChange={(i: number, value: string) => onInputChange(i, value)}
                />
            </div>
        </div>
    );
}

interface FilterInputProps {
    filter: SleepLogFilter;
    onChange: (index: number, value: string) => any;
}

const FilterInput = ({ filter, onChange }: FilterInputProps) => {
    switch (filter.type) {
        case 'awakening threshold':
            return (
                <>
                    <input type="text"
                        className="form-control form-control-sm"
                        placeholder=""
                        value={filter.parameters[0]}
                        onChange={(e) => onChange(0, e.target.value)}
                    />
                    <input type="text"
                        className="form-control form-control-sm"
                        placeholder="Awakening is at least X minutes"
                        value={filter.parameters[1]}
                        onChange={(e) => onChange(1, e.target.value)}
                    />
                </>
            );

        case 'date':
            switch (filter.operator) {
                case 'between':
                case 'not between':
                    return (
                        <>
                            <input type="date"
                                className="form-control form-control-sm"
                                value={filter.parameters[0]}
                                onChange={(e) => onChange(0, e.target.value)}
                            >
                            </input>
                            <input type="date"
                                className="form-control form-control-sm"
                                value={filter.parameters[1]}
                                onChange={(e) => onChange(1, e.target.value)}
                            >
                            </input>
                        </>
                    );

                default:
                    break;
            }
            break;

            case 'medication taken':
                return (
                    <>
                        <input type="time"
                            className="form-control form-control-sm"
                            value={filter.parameters[0]}
                            onChange={(e) => onChange(0, e.target.value)}
                        >
                        </input>
                        <input type="time"
                            className="form-control form-control-sm"
                            value={filter.parameters[1]}
                            onChange={(e) => onChange(1, e.target.value)}
                        >
                        </input>
                        <input type="text"
                            className="form-control form-control-sm"
                            placeholder="Medication"
                            value={filter.parameters[2]}
                            onChange={(e) => onChange(2, e.target.value)}
                        />
                    </>
                );

        default:
            break;
    }

    switch (filter.operator) {
        case "has none":
        case 'has any':
        case 'is null':
            return (
                <>
                </>
            );
        case "between":
            if (filter.type === 'time') {
                return (
                    <>
                        <input type="time"
                            className="form-control form-control-sm"
                            value={filter.parameters[0]}
                            onChange={(e) => onChange(0, e.target.value)}
                        >
                        </input>
                        <input type="time"
                            className="form-control form-control-sm"
                            value={filter.parameters[1]}
                            onChange={(e) => onChange(1, e.target.value)}
                        >
                        </input>
                    </>
                );
            }
            else {
                return <></>;
            }
         
        case 'greater than or equal to':
        case 'greater than':
        case 'less than or equal to':
        case 'less than':
        // @ts-expect-error
        case 'equals':
            if (filter.type === 'medication') {
                return (
                    <>
                        <input type="text"
                            className="form-control form-control-sm"
                            placeholder="dose"
                            value={filter.parameters[0]}
                            onChange={(e) => onChange(0, e.target.value)}
                        />
                        <input type="text"
                            className="form-control form-control-sm"
                            placeholder="medication"
                            value={filter.parameters[1]}
                            onChange={(e) => onChange(1, e.target.value)}
                        />
                    </>
                );
            }
        default:
            return (
                <input type="text"
                    className="form-control form-control-sm"
                    value={filter.parameters[0]}
                    onChange={(e) => onChange(0, e.target.value)}
                />
            );     
    }
}

interface ApplyToProps {
    applyTo: ApplyTo;
    showBorder: boolean;
    onChange: (applyTo: ApplyTo) => void;
}

const ApplyFilterDropdown = ({ applyTo, showBorder, onChange }: ApplyToProps) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="" size="sm" className={classNames("bg-white rounded-0", { [styles.filterButton]: showBorder })}>

                <i className="bi bi-calendar3"></i>
                { applyTo !== "day of" &&
                    <span className="position-absolute start-50 top-100 translate-middle opacity-50 badge rounded-pill bg-primary">
                        <i className={classNames(
                            "bi",
                            { ["bi-arrow-left"]: applyTo === "day before" || applyTo === "2 days before"},
                            { ["bi-arrow-right"]: applyTo === "day after" }
                        )}
                        >
                        </i>
                    </span>
                }

            </Dropdown.Toggle>

            <Dropdown.Menu className="shadow" renderOnMount={true} popperConfig={{ strategy: "fixed"}}>

                <div className="px-3 text-sm">

                    <div className="text-center fs-6">Apply filter on</div>
                    <div className="mt-2"></div>

                    <div className="form-check">
                        <input type="radio"
                            className="form-check-input"
                            checked={applyTo === "day of"}
                            onChange={e => onChange("day of")}>
                        </input>
                        <label className="form-check-label">
                            Day of
                        </label>
                    </div>

                    <div className="form-check">
                        <input type="radio"
                            className="form-check-input"
                            checked={applyTo === "day before"}
                            onChange={e => onChange("day before")} 
                            >
                            </input>
                        <label className="form-check-label">
                            Day before
                            </label>
                    </div>

                    <div className="form-check">
                        <input type="radio"
                            className="form-check-input"
                            checked={applyTo === "2 days before"}
                            onChange={e => onChange("2 days before")} 
                            >
                            </input>
                        <label className="form-check-label">
                            2 days before
                        </label>
                    </div>                    

                    <div className="form-check">
                        <input type="radio"
                            className="form-check-input"
                            checked={applyTo === "day after"}
                            onChange={e => onChange("day after")}                                         
                        >
                        </input>
                        <label className="form-check-label">
                            Day after
                        </label>
                    </div>
         
                </div>

            </Dropdown.Menu>
        </Dropdown>     
    );
}