import { useMemo } from "react";
import { WithingsAdapter } from "../../adapters/WithingsAdapter";
import { WithingsData } from "../../types/SleepLog";
import { WithingsSleepEpisode, WithingsSleepStage } from "../../types/WithingsData";
import SleepStagesCard from "./SleepStagesCard";

interface Props {
    episode: WithingsSleepEpisode;
    sleepStages: WithingsSleepStage[];
}

export default function WithingsSleepStagesCard({ episode, sleepStages }: Props) {

    const convertedEpisode = useMemo(() => {
        return  new WithingsAdapter([episode], [sleepStages]).getSleepSummary().episodes[0]
    }, [sleepStages]);

    return (
        <SleepStagesCard episode={convertedEpisode} />
    );
}