import { Nullable } from "../../types/Nullable";
import { SimpleSleepLogFilterSet, SleepLogFilter } from "../../types/SleepLogFilter";
import SleepLog from "../../types/SleepLog";
import SleepLogSettings, { CustomMetric } from "../../types/SleepLogSettings";
import { HttpService } from "./HttpService";
import { DateTime } from "luxon";

type AdvancedSleepLogFilter = { startDate: string, endDate: string, filters: SleepLogFilter[] };

export default class SleepLogHttpService extends HttpService {

    public async getSleepLogs(
        simpleFilter?: SimpleSleepLogFilterSet,
        advancedFilter?: AdvancedSleepLogFilter,
        limit?: number,
        scanForward?: boolean,
        exclusiveStartId?: string,
        useEventualConsistency?: boolean,
    ) {
        let query = `scan-forward=${scanForward}&limit=${limit}`;

        if (exclusiveStartId) {
            query += `&exclusive-start-id=${exclusiveStartId}`;
        }

        if (simpleFilter) {
            query += `&filter=${JSON.stringify(simpleFilter)}`;
        }
        else if (advancedFilter) {
            query += `&advancedFilter=${JSON.stringify(advancedFilter)}`;                
        }

        const res = await fetch(`${this.baseAccountPath}/sleep-logs?${query}`, {
            headers: {
                ...this.commonHeaders,
            }
        });

        const body = await res.json();
        
        if (res.status !== 200) {
            HttpService.throwHttpError(res, body);                
        }
        
        return body as { logs: SleepLog[], lastEvaluatedId?: string };
    }

    public async createSleepLog(
        date: string,
        notes?: string,
        customValues?: CustomMetric[],
        primaryConnectedAccount?: string
    ) {
        const sleepLogToCreate = {
            date: date,
            notes: notes,
            customMetrics: customValues,
            primaryConnectedAccount: primaryConnectedAccount
        };

        const res = await fetch(`${this.baseAccountPath}/sleep-logs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...this.commonHeaders,               
            },
            body: JSON.stringify(sleepLogToCreate)
        });

        const body = await res.json();

        if (res.status !== 201) {
            HttpService.throwHttpError(res, body);    
        }

        return body as SleepLog;
    }

    public async deleteSleepLog(id: string) {
        const res = await fetch(`${this.baseAccountPath}/sleep-log/${id}`, {
            method: 'DELETE',
            headers: {
                ...this.commonHeaders,
            }
        });

        if (res.status !== 200) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);    
        }
    }

    public async updateSleepLog(
        id: string,
        update: Nullable<Omit<SleepLog, "id" | "date">>,
        lastModifiedTimeCondition?: string,
    ) {
        let headers: any = {
            'Content-Type': 'application/json',
            ...this.commonHeaders,
        };

        if (lastModifiedTimeCondition !== undefined) {
            headers["x-if-match"] = lastModifiedTimeCondition;
        }

        const res = await fetch(`${this.baseAccountPath}/sleep-log/${id}`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(update)
        });

        const body = await res.json();
        
        if (res.status !== 200) {
            HttpService.throwHttpError(res, body);    
        }

        return body as { id: string, lastModifiedTime: string };        
    }

    public async getSleepLogSettings() {
        const res = await fetch(`${this.baseAccountPath}/settings/sleep-log-settings`, {
            method: 'GET',
            headers: {
                ...this.commonHeaders,
            },
        });
    
        const body = await res.json();

        if (res.status !== 200) {
            HttpService.throwHttpError(res, body);    
        }      

        return body as SleepLogSettings;
    }

    public async putSleepLogSettings(settings: SleepLogSettings) {
        const res = await fetch(`${this.baseAccountPath}/settings/sleep-log-settings`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...this.commonHeaders,
            },
            body: JSON.stringify(settings),            
        });

        if (res.status !== 201) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);    
        }
    }

    public async patchSleepLogSettings(partialSettings: SleepLogSettings) {
        const res = await fetch(`${this.baseAccountPath}/settings/sleep-log-settings`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/merge-patch+json',
                ...this.commonHeaders,
            },
            body: JSON.stringify(partialSettings),            
        });

        const body = await res.json();

        if (res.status !== 200) {
            HttpService.throwHttpError(res, body);    
        }

        return body as SleepLogSettings;
    }    

    public async getTagSuggestions(from: DateTime, to: DateTime) {
        const res = await fetch(`${this.baseAccountPath}/sleep-logs/suggestions?from=${from.toISODate()}&to=${to.toISODate()}`, {
            method: 'GET',
            headers: {
                ...this.commonHeaders,
            },            
        });

        const body = await res.json();

        if (res.status !== 200) {
            HttpService.throwHttpError(res, body);    
        }      

        return body as { [key: string]: [string, number][] };        
    }

    public async syncSleepLog(id: string) {
        const res = await fetch(`${this.baseAccountPath}/connectedAccounts/sync/dest/sleep-logs/${id}`, {
            method: 'POST',
            headers: {
                ...this.commonHeaders,
            },
        });
        
        const body = await res.json();

        if (res.status !== 200) {
            HttpService.throwHttpError(res, body);    
        }
        
        return body as SleepLog;
    }
}