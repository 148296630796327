import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import ISleepLogView from "../../../../types/ISleepLogView";
import SleepLog from "../../../../types/SleepLog";
import AnalyticsHelper from "../../../../utils/analytics-utils";
import SimpleTooltip from "../../../sleep-log-view/common/SimpleTooltip";
import ChangeArrow from "../../common/ChangeArrow";

interface Props {
    sleepLogsState: ISleepLogView[];
    filteredSleepLogs: ISleepLogView[];
    unfilteredSleepLogs: ISleepLogView[];
}

interface SleepStats {
    min: number;
    max: number;
    avg: number;
    stdDev: number;
    absoluteDev: number;
}

const emptyStats: SleepStats = {
    min: 0,
    max: 0,
    avg: 0,
    stdDev: 0,
    absoluteDev: 0
}

export default function RatingSummaryChart(props: Props) {

    const {sleepLogsState, filteredSleepLogs, unfilteredSleepLogs} = props;

    const [ratingStats, setSleepStats] = useState<SleepStats[]>([emptyStats]);

    function computeSleepStats(sleepLogs: ISleepLogView[]): SleepStats {
        const ratings = sleepLogs.filter(sleepLog => {return sleepLog.baseSleepLog.rating !== undefined;})
            .map(sleepLog => sleepLog.rating as number);

        let avg = AnalyticsHelper.calculateAverage(ratings);
        let [min, max] = AnalyticsHelper.calculateMinMax(ratings);
        let stdDev = AnalyticsHelper.calculateStandardDeviation(ratings, avg);
        let absoluteDev = AnalyticsHelper.calculateAbsoluteDeviation(ratings, avg);
        
        return {
            min: min,
            max: max,
            avg: avg,
            stdDev: stdDev,
            absoluteDev: absoluteDev
        }
    }

    useEffect(() => {
        if (filteredSleepLogs.length > 0) {
            const stats = computeSleepStats(sleepLogsState);
            const filteredStats = computeSleepStats(filteredSleepLogs);

            setSleepStats([stats, filteredStats]); 
        }
        else {
            const stats = computeSleepStats(sleepLogsState);
            setSleepStats([stats]);
        } 
    }, [sleepLogsState, filteredSleepLogs, unfilteredSleepLogs]);

    return (
        <table className="table table-sm text-center">
            <thead className="text-secondary">
                <tr>
                    <td>Min</td>
                    <td><strong>Average</strong></td>                 
                    <td>Max</td>
                </tr>
            </thead>
            <tbody>
                {ratingStats.map((stat, i) =>
                    <>
                    <tr key={i} style={{backgroundColor: "white"}}>                     
                        <td>
                            <Rating rating={stat.min} baseLine={ratingStats.length > 1 ? ratingStats[0].min : undefined} />
                        </td>
                        <td>
                            <SimpleTooltip message={`Std. deviation: ${stat.stdDev.toFixed(2)} mins, Absolute deviation: ${stat.absoluteDev.toFixed(2)}`} position="bottom">
                                <div>
                                    <Rating rating={stat.avg} baseLine={ratingStats.length > 1 ? ratingStats[0].avg : undefined} />
                                </div>
                            </SimpleTooltip>

                        </td>
                        <td>
                            <Rating rating={stat.max} baseLine={ratingStats.length > 1 ? ratingStats[0].max : undefined}/>
                        </td>                     
                    </tr>
                    { i === 0 && ratingStats.length > 1 &&
                        <tr className="table-light fs-6">
                            <td></td>
                            <td align="center">
                                Filtered
                            </td>
                            <td></td>
                        </tr>
                    }
                    </>                 
                )}

            </tbody>
        </table>
    );
}

function Rating(props: {rating: number, baseLine: number | undefined}) {
    const {rating, baseLine} = props;

    return (
        <span>
            <ChangeArrow val={rating} baseLine={baseLine} /> 
            <span className="fs-5">
                {rating.toFixed(2)}
            </span>            
        </span>
    );    
}