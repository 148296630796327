import React from "react";
import { Row, Col } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import SleepLog from "../../../../types/SleepLog";
import ISleepLogView from "../../../../types/ISleepLogView";
import { prettyFormatTimeDurationInHours } from "../../../../utils/FormatUtility";
import { ChartOptions } from "chart.js";
import { ChartStyles } from "../constants/chart-styles";

interface Props {
  sleepLogs: ISleepLogView[];
}

export default function SleepPerMedicationChart(props: Props) {
  const { sleepLogs } = props;
  
  function calculateMedicationSleepAverages(sleepLogs: ISleepLogView[]) {

    const sleepMedicationData: any = {
      labels: [],
      datasets: [
        {
          label: ["Hours slept by medication"],
          data: [],
          fill: false,
          backgroundColor: 'rgba(61, 139, 253, .7)',
          borderWidth: 0,
          maxBarThickness: 100
        }           
      ],
    };

    let medicationSleepTotal: {[key: string]: number} = {};
    let medicationUseCount: {[key: string]: number} = {};

    sleepLogs.forEach(log => {
      if (log.minutesAsleep !== undefined) {
        if (log.baseSleepLog.medications && log.baseSleepLog.medications.length > 0) {
          log.baseSleepLog.medications.forEach(m => {

            if (log.minutesAsleep !== undefined) {
              if (!medicationUseCount[m.name]) {
                medicationSleepTotal[m.name] = 0;
                medicationUseCount[m.name] = 0;
              }

              medicationUseCount[m.name]++;
              medicationSleepTotal[m.name] += log.minutesAsleep;
            }
          });
        }
        else
        {
          if (!medicationUseCount["No medication"]) {
            medicationSleepTotal["No medication"] = 0;
            medicationUseCount["No medication"] = 0;
          }

          medicationUseCount["No medication"]++;
          medicationSleepTotal["No medication"] += log.minutesAsleep;
        }
      }
    });

    let averages = Object.keys(medicationUseCount).map((k) => {
      return {name: k, averageInMins: medicationSleepTotal[k] / medicationUseCount[k]};
    });

    averages.sort((lhs, rhs) => {
      return rhs.averageInMins - lhs.averageInMins;      
    });

    const truncate = (name: string) => {
      if (name.length > 15) {
        return name.slice(0, 15) + "..";
      }
      else {
        return name;
      }
    }

    averages.forEach((e) => {

      let sleepInHours = Number((e.averageInMins / 60).toFixed(3));

      sleepMedicationData.datasets[0].data.push(sleepInHours);
      sleepMedicationData.labels.push(truncate(e.name));
    })

    return sleepMedicationData;
  }

  const sleepMedicationData = calculateMedicationSleepAverages(sleepLogs);

  const options: ChartOptions<"bar"> = {
    plugins: {
      legend: {
        display: false
      },      
      title: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return prettyFormatTimeDurationInHours((context.raw as number) ?? 0);
          }          
        }
      }
    },    
    scales: {
      x: {
          grid: {
              color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            font: {
              ...ChartStyles.axisFont
            }
          }
      },
      y: {
        beginAtZero: false,
        ticks: {
          stepSize: 1,
          font: {
            ...ChartStyles.axisFont
          }
        },
        grid: {
          ...ChartStyles.gridYAxis
        }
      }
    },   
  };
  
  /*

      tooltips: {
      callbacks: {
          label: function(tooltipItem: any, data: any) {
            return prettyFormatTimeDurationInHours(tooltipItem.yLabel);
          }
      }           
    },

  */

  return (
    <Bar data={sleepMedicationData} options={options} />   
  );


}