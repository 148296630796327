import { produce } from "immer";
import SleepLogHttpService from "../lib/http/SleepLogHttpService";
import { DateTime } from "luxon";
import Store from "../shared/store/store";

// suggestions[type] => [tag, weight][]
// tags are sorted by weight.
type State = { [key: string]: [string, number][] };

export type TagType = 'tags' | 'feelings' | 'sleepMeds' | 'otherMeds' | 'events'; 

const TAG_NAME = 0;
const WEIGHT = 1;

export class TagSuggestionsService extends Store<State> {
    constructor(
        private username: string | undefined,
        private sleepLogHttpService: SleepLogHttpService)
    {
        super({});
    }

    async load(): Promise<void> {
        if (!this.username) {
            return;
        }

        try {
            const from = DateTime.now().minus({ years: 1.5 });
            const to = DateTime.now();
            const suggestions = await this.sleepLogHttpService.getTagSuggestions(from, to);
            this.state = produce(suggestions, () => {});
            this.notifySubscribers();
        }
        catch (e) {
            //ignore
        }
    }

    add(type: TagType, tagName: string, weight: number): void {
        this.state = produce(this.state, suggestionsState => {
            if (!(type in suggestionsState)) {
                return;
            }

            const suggestions = suggestionsState[type];

            const tag = suggestions.find((t) => t[TAG_NAME] === tagName);
            if (tag) {
                tag[WEIGHT] += weight;
            }
            else {
                suggestions.push([tagName, weight]);
            }

            suggestions.sort((lhs, rhs) => rhs[WEIGHT] - lhs[WEIGHT]);
            }
        );

        this.notifySubscribers();
    }

    filter(type: TagType, phrase: string, limit: number, minWeight?: number): string[] | undefined {

        if (!(type in this.state)) {
            return undefined;
        }

        let tags = this.state[type]
            .filter(tag => tag[WEIGHT] >= (minWeight ?? 0))
            .map(tag => tag[TAG_NAME]);

        if (phrase.length === 0) {
            return tags.slice(0, limit);
        }

        // Prefix matches
        let suggestions = tags.filter(t => t.toLowerCase().startsWith(phrase.toLowerCase())).slice(0, limit);

        if (suggestions.length < limit) {
            // Substring matches
            let substringMatches = tags.filter(t => !suggestions.includes(t) && t.toLowerCase().includes(phrase.toLowerCase())).slice(0, limit - suggestions.length);

            suggestions = suggestions.concat(substringMatches);
        }

        return suggestions;
    }
}