import React, { useState, useRef } from "react";
import { Row, Col, Button, OverlayTrigger, Popover, PopoverBody, Overlay } from "react-bootstrap";
import { TagSuggestionsService, TagType } from "../../services/TagSuggestionsService";
import { Medication } from "../../types/SleepLog";

interface SelectDrugComponentProps {
    type: TagType;
    drugs: Medication[];
    addDrug: (drugName: string) => boolean;
    updateDrug: (oldValue: string, newValue: string, index: number) => any;
    deleteDrug: (index: number, drugName: string) => any;
    updateDose: (dose: string, index: number, drugName: string) => any;
    updateTakenAtTime: (index: number, takenAt: string) => any;
    tagSuggestionsService: TagSuggestionsService;
    tagColor: string;
    editable: boolean;
}

interface TagState {
    input: string;
    showSuggestions: boolean;
    suggestions: string[] | undefined;
    pos: number;
}

export default function SelectDrugComponent({
    type,
    drugs,
    addDrug,
    deleteDrug,
    updateDose,
    tagSuggestionsService,
    updateTakenAtTime,
    tagColor,
    editable
}: SelectDrugComponentProps) {

    const [tagState, setTagState] = useState(() => {
        let initialState: TagState = {
            input: "",
            showSuggestions: false,
            suggestions: undefined,
            pos: -1
        };
        return initialState;
    });

    const triggerRef = useRef(null);

    // todo: don't use any for event type.
    function handleKeyPress(event: any) {
        if (event.key === "Enter") {
            return;
        }
        else if (event.key === "ArrowDown") {
            event.preventDefault();

            if (tagState.suggestions === undefined) {
                return;
            }

            if (!tagState.showSuggestions || tagState.suggestions.length === 0) {
                return;
            }

            let newPos = (tagState.pos + 1) % tagState.suggestions.length;
            setTagState(state => ({...state, pos: newPos}));            
        }
        else if (event.key === "ArrowUp") {
            event.preventDefault();

            if (tagState.suggestions === undefined) {
                return;
            }

            if (!tagState.showSuggestions || tagState.suggestions.length === 0) {
                return;
            }

            let newPos = Math.max(tagState.pos - 1, -1);         

            setTagState(state => ({ ...state, pos: newPos}));
        } 
    }

    function revealSuggestions() {
        const suggestions = matchingSuggestions(tagState.input);
        setTagState(state => ({...state, showSuggestions: true, suggestions: suggestions, pos: -1}));
    }
    
    function chooseDrugSuggestion(drugName: string) {
        const success = addDrug(drugName);
        if (!success) { return; }

        setTagState(state =>({...state, input: ""}));
    }

    function handleChange(event: any) {
        let inputValue = event.target.value;

        const suggestions = matchingSuggestions(inputValue) ;
        setTagState(state => ({...state, input: inputValue, suggestions: suggestions, pos: -1}));
    }

    const matchingSuggestions = (input: string) => {
        const limit = 20;
        return tagSuggestionsService.filter(type, input, limit);
    };

    function hideSuggestions() {
        setTagState(state => ({...state, showSuggestions: false, suggestions:[], pos: -1}));
      }

    function getSuggestionDecoration(index: number) {
        let classNames = "list-group-item";
        
        if (tagState.pos === index) {
            classNames += " selected-suggestion";
        }
        else {
            classNames += " hoverable-suggestion";            
        }

        return classNames;
    }

    function handleAddDrug() {
        if (tagState.pos === -1 && tagState.input) {
            const success = addDrug(tagState.input.trim());
            if (!success) { return; }
        }
        else if (tagState.pos !== -1 && tagState.suggestions !== undefined) {
            const success = addDrug(tagState.suggestions[tagState.pos].trim());    
            if (!success) { return; }                    
        }
        else if (!tagState.input) {
            return;
        }

        setTagState(state =>({...state, input: "", suggestions: matchingSuggestions("") , pos: -1}));
    }

    function changeTakenAt(index: number, takenAt: string) {
        updateTakenAtTime(index, takenAt);
    }

    const placeholder = "+ Medication";
    const inputSize = Math.max(placeholder.length, tagState.input.length);
    const inputHtmlSize = inputSize === 0 ? 1 : Math.ceil(inputSize);

    const SuggestionsDropdown = () => (
        <ul className="list-group text-sm border-0 shadow" style={{maxHeight: "300px", overflowY: "auto", minWidth: "100px", fontSize: ".9rem"}}>
            { tagState.suggestions?.map((tag, index) => {
                return <li className={`${getSuggestionDecoration(index)} text-nowrap`} key={index} style={{cursor:"pointer"}}
                onMouseDown={e => chooseDrugSuggestion(tag)}>{tag}</li>
            })}
            { tagState.suggestions === undefined &&
            <>
                <li 
                    className={`${getSuggestionDecoration(0)} text-nowrap`}
                >
                    <div className="card-title placeholder-glow">
                        <div className="placeholder placeholder-xs bg-secondary w-100"></div>
                    </div>
                </li>
                <li 
                    className={`${getSuggestionDecoration(0)} text-nowrap`}
                >
                    <div className="card-title placeholder-glow">
                        <div className="placeholder placeholder-xs bg-secondary w-100"></div>
                    </div>
                </li>
                <li 
                    className={`${getSuggestionDecoration(0)} text-nowrap`}
                >
                    <div className="card-title placeholder-glow">
                        <div className="placeholder placeholder-xs bg-secondary w-100"></div>
                    </div>
                </li>
            </>
            }
        </ul>
    );

    return (
        <div>
            {drugs.map((drug, index) => (
                <div className="mt-2"key={index} style={{display: "flex", gap: "12px", alignItems: "center"}}>
                    <span className={`ant-tag ant-tag-${tagColor} rounded-pill`}>
                        {drug.name}
                        { editable && <span>&nbsp;&nbsp;</span> }
                        {editable && 
                            <i 
                                className="bi bi-x-circle text-muted" 
                                style={{cursor: "pointer", "WebkitTextStrokeWidth": ".25px"}}
                                onClick={e => deleteDrug(index, drug.name)}
                            >    
                            </i>
                        }
                    </span>

                    <div className="d-flex" style={{gap: "4px"}}>
                        <input type="number"
                            inputMode="numeric" 
                            value={drug.dose ?? ""}
                            placeholder=""    
                            className="form-control text-sm px-1 py-0" style={{width: ((drug.dose?.length ?? 1) + 2) + "ch", borderWidth: "0px 0px 1px 0px", borderRadius: "0"}}
                            disabled={!editable}
                            onChange={e => updateDose(e.target.value, index, drug.name)}>
                        </input>
                        <span className="text-sm"> mg</span>
                    </div>

                    <input type="time" 
                        className="text-sm rounded"
                        style={{"border": "1px dashed #ced4da"}}
                        value={drug.takenAt ?? ""}
                        disabled={!editable}
                        onChange={e => changeTakenAt(index, e.target.value)}>
                    </input>
                </div>
            ))}
            <form>
                { editable &&
                <Row className="my-2">
                    <Col md="auto" className="position-relative d-flex">
                        <input type="text"
                            ref={triggerRef}                        
                            placeholder={placeholder}
                            className="form-control px-1 py-0 text-sm d-inline-block"
                            size={inputHtmlSize}
                            style={{"borderStyle": "dashed", width: "auto", position: "relative"}}
                            value={tagState.input}                        
                            onKeyDown={handleKeyPress}
                            onChange={handleChange}
                            onFocus={e => revealSuggestions()}
                            onBlur={hideSuggestions}
                        >
                        </input>
                        <Overlay
                            show={tagState.showSuggestions}
                            offset={[0, 5]}
                            placement="bottom"
                            target={triggerRef.current}
                            flip={true}
                        >
                            {({ ...props }) => (
                                <div {...props} style={{
                                    position: 'absolute',
                                    zIndex: 10000,
                                    ...props.style,
                                    }}
                                >
                                    <SuggestionsDropdown />
                                </div>
                            )}
                        </Overlay>                      
                        <button type="submit"
                                className="d-inline-block btn btn-sm btn-create py-0 ms-1 text-sm" 
                                onClick={(e) => { e.preventDefault(); handleAddDrug(); }}
                            >
                                +
                        </button> 
                    </Col>            
                </Row>
                }
            </form>
        </div>
    );
}