type Subscriber<T> = (state: T) => void;

export default abstract class Store<T> {
    constructor(state: T) {
        this._state = state;
    }

    protected get state(): T {
        return this._state;
    }

    protected set state(state: T) {
        this._state = state;
    }

    subscribe(subscriber: Subscriber<T>) {
        this.subscribers.push(subscriber);
        return () => {
            this.subscribers = this.subscribers.filter(s => s !== subscriber)
        };
    }

    protected notifySubscribers() {
        for (const subscriber of this.subscribers) {
            subscriber(this._state);
        }
    }    
    
    public getState() {
        return this._state;
    }

    protected _state: T;
    private subscribers: Subscriber<T>[] = [];
}
