import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import { StatusMessage, StatusMessageType } from '../../common/StatusMessages';
import BrandLogo from "../../common/BrandLogo";
import { useNavigate } from 'react-router-dom';
import AppPage from '../../../app/AppPage';
import { HttpException } from '../../../types/Exceptions';
import { useContext, useEffect } from 'react';
import { AccountHttpServiceContext } from '../../http/account-http-service.provider';
import { logger } from '../../../logger/EventLogger';

interface Props {
  addStatus: (msg: StatusMessage) => any;
}

export default function SignupView({addStatus}: Props) {

    const navigate = useNavigate();

    const accountHttpService = useContext(AccountHttpServiceContext)!;

    useEffect(() => {
        logger.log("View Signup Page");
    }, []);  

    async function onSignup(e: any) {
        e.preventDefault();

        let username = (document.querySelector("#signup-username") as HTMLInputElement).value;
        let password = (document.querySelector("#signup-password") as HTMLInputElement).value;
        let confirmPassword = (document.querySelector("#signup-confirm-password") as HTMLInputElement).value;

        let email = (document.querySelector("#signup-email") as HTMLInputElement).value;   
        
        logger.log("User Tried To Signup", { username, email });

        if (!username) {
            addStatus({type: StatusMessageType.Fail, msg: "Username is required."});      
            return;            
        }

        if (!password || !confirmPassword) {
            addStatus({type: StatusMessageType.Fail, msg: "Password is required."});
            return; 
        }    

        if (username.length < 3) {
            addStatus({type: StatusMessageType.Fail, msg: "Username must be at least 3 letters long."});     
            return; 
        }

        if (username.length > 20) {
            addStatus({type: StatusMessageType.Fail, msg: "Username can be at most 20 letters."});     
            return; 
        }

        let accountNamePattern = /^[a-zA-Z0-9_\-\.]+$/;

        if (!accountNamePattern.test(username)) {
            addStatus({type: StatusMessageType.Fail, msg: "Username can only contain letters, numbers, dashes, underscores, or periods."});   
            return false;
        }

        //
        // todo: keep logic in sync with reset password and move to helper function...
        //
        if (password.length < 8) {
            addStatus({type: StatusMessageType.Fail, msg: "Password must be at least 8 letters long."});     
            return; 
        }

        if (password.length > 64) {
            addStatus({type: StatusMessageType.Fail, msg: "Password can be at most 64 letters."});     
            return; 
        }    

        if (password !== confirmPassword) {
            addStatus({type: StatusMessageType.Fail, msg: "The confirmed password does not match the password."});
            return;        
        }

        try {
            await accountHttpService.createAccount(username, password, email);

            addStatus({type: StatusMessageType.Success, msg: "Your account was successfully created!" });
            logger.log("User Signed Up", { username });
            
            navigate("/login");
        }
        catch (e: any) {
            logger.log("User Sign Up Failed", { username });

            if (e instanceof HttpException) {
                addStatus({type: StatusMessageType.Fail, msg: `Signup failed. Reason: ${e.message}`});
            }
            else {
                addStatus({type: StatusMessageType.Fail, msg: "Signup failed. Please refresh the page or try again later."});
            }      
        }
  }

  const labelStyle: React.CSSProperties = { fontWeight: 500 };

  return (
    <AppPage className="app-bg-light pt-5">
        <Container className="">      
        <div className="justify-content-center d-flex">
            <div style={{width: "400px"}}>
            <div className="mt-4 py-4 px-5 rounded bg-white contrast-shadow">
                <BrandLogo />
                <div className="mt-3"></div>
                <h4 className="text-center fw-semibold">Signup</h4>
                <Form>
                <Form.Group controlId="signup-username" className="mt-4 text-xs">
                    <Form.Label style={labelStyle}>Username *</Form.Label>
                    <Form.Control type="text" className="input-underline text-sm" placeholder="Enter username" />
                </Form.Group>
                <Form.Group controlId="signup-password" className="mt-3 text-xs">
                    <Form.Label style={labelStyle}>Password *</Form.Label>
                    <Form.Control type="password" className="input-underline text-sm" placeholder="Enter password" />
                </Form.Group>      
                <Form.Group controlId="signup-confirm-password" className="mt-3 text-xs">           
                    <Form.Label style={labelStyle}>Confirm password *</Form.Label>
                    <Form.Control type="password" className="input-underline text-sm" placeholder="Re-enter password" /> 
                </Form.Group>
                <Form.Group controlId="signup-email" className="mt-3 text-xs">           
                    <Form.Label style={labelStyle}>Email address (optional)</Form.Label>
                    <Form.Control type="email" className="input-underline text-sm" placeholder="Enter email" /> 
                </Form.Group>                                           
                <div className="text-center mt-4">
                    <Button 
                        variant="primary" 
                        type="submit" 
                        className="form-control shadow-sm fw-semibold"
                        onClick={onSignup}
                    >
                        Sign up
                    </Button>
                </div>
                </Form>
                <p className="mt-5 text-center text-xs">
                <span>Have an account already? Click </span>
                <LinkContainer to="/login">
                    <a href="#">here</a>
                </LinkContainer>
                <span> to login.</span>
                </p>
                <p className="mt-3 text-center text-xs">Forgot password? Click <LinkContainer to="/forgot-password"><a href="#">here</a></LinkContainer> to reset.</p>   
            </div>
            </div>
        </div>
        </Container>
    </AppPage>
  );
}