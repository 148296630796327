import React from "react";
import { AppState } from "../types/AppState";

export const AppStateContext = React.createContext<[AppState, (value: AppState) => void]>(
    [
        { 
            sessionId: ""
        },
        (value: AppState) => 0
    ]
);