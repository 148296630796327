import { DateTime } from "luxon";
import DateTimeUtils from "./DateTimeUtils";

const dateNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const dateNameAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const daysOfWeekAbbreviations = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// Use this class as a wrapper around methods
export class FormatUtils {
    static formatDate(date: string, omitThisYear = true, omitYear = false) {
        if (!date) {
            return '';
        }
    
        const [year, month, day] = splitDate(date);
    
        const now = new Date(Date.now());        
        const currentYear = now.getFullYear();        
    
        if ((omitThisYear && year === currentYear) || omitYear) {
            return `${month}/${day}`;
        }
        else {
            return `${month}/${day}/${year % 100}`;
        }
    }

    // Formats to date strings to a time range.
    // Example: 1:30am - 4:30am
    static formatSleepRange(start: string, end: string): string {

        // todo: use date.parse?
        const startTime = formatTime(start);
        const endTime = formatTime(end);

        const range = `${startTime} - ${endTime}`;
        return range;
    }

    static formatSleepRangeFromDate(start: Date, end: Date): string {
        const startTime = FormatUtils.formatTimeFromComponents(start.getHours(), start.getMinutes());
        const endTime = FormatUtils.formatTimeFromComponents(end.getHours(), end.getMinutes());
    
        const range = `${startTime} - ${endTime}`;
        return range;    
    }    
    
    static formatSleepRangeFromDateTime(start: DateTime, end: DateTime): string {
        const startTime = FormatUtils.formatTimeFromComponents(start.hour, start.minute);
        const endTime = FormatUtils.formatTimeFromComponents(end.hour, end.minute);
    
        const range = `${startTime} - ${endTime}`;
        return range;    
    }

    static getTwelveHourPeriod(hourIn24: number): "am" | "pm" {
        return hourIn24 >= 12 ? "pm" : "am";
    }

    // Formats just a time, e.g. 03:12
    static formatTime(time: string) {
        const [hours, mins] = time.split(":");
        return FormatUtils.formatTimeFromComponents(Number(hours), Number(mins));
    }

    static formatTimeFromComponents(hourIn24: number, minutes: number, includeMins = true, includePeriod = true) {
        let hour = hourIn24 >= 12 ? hourIn24 - 12 : hourIn24;
        hour = (hour === 0) ? 12 : hour;
    
        const formattedMins = (minutes < 10 ? '0' : '') + minutes.toString(); 
        const period = includePeriod ? (hourIn24 >= 12 ? "pm" : "am") : undefined;
        
        return includeMins ? `${hour}:${formattedMins}${period ?? ''}` : `${hour}${period ?? ''}`;    
    }
}

// Splits a date with format YYYY-MM-DD
export function splitDate(date: string) {
    const parts = date.split("-");
    const year = Number(parts[0]);
    const month = Number(parts[1]);
    const day = Number(parts[2]);

    return [year, month, day];
}

export function formatDateTypeToString(date: Date) {
    return `${dateNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export function getDayOfWeekAbbreviation(date: string) {
    const [year, month, day] = splitDate(date);

    const dayOfWeek = new Date(Number(year), Number(month) - 1, Number(day)).getDay();
    return daysOfWeekAbbreviations[dayOfWeek];
}

export function getDayOfWeek(date: string) {
    const [year, month, day] = splitDate(date);

    const dayOfWeek = new Date(Number(year), Number(month) - 1, Number(day)).getDay();
    return daysOfWeek[dayOfWeek];
}


export function formatMonth(month: number, year?: number, abbreviateMonth?: boolean, abbreviateYear?: boolean) {
    const monthLabels = abbreviateMonth ? dateNameAbbreviations : dateNames;
    year = year !== undefined && abbreviateYear ? year % 100 : year;
    return year !== undefined ? `${monthLabels[month - 1]} ${year}` : monthLabels[month - 1];
}

export function formatSleepLogTitleDate(date: string): string {
    const parts = date.split("-");
    const month = Number(parts[1]);
    const day = Number(parts[2]);
    const year = parts[0];
    const dayOfWeek = new Date(Number(year), Number(month) - 1, Number(day)).getDay();    

    return `${daysOfWeekAbbreviations[dayOfWeek]}, ${dateNameAbbreviations[month - 1]} ${day}, ${year}`;    
}

export function formatTime(date: string): string {
    if (!date) {
        return "";
    }
    
    const [hourIn24, minutes] = DateTimeUtils.parseDateTimeIntoHoursMins(date);
    return FormatUtils.formatTimeFromComponents(hourIn24, minutes);
}

/**
 * @deprecated, use FormatUtils.format...
 */
export function formatTimeFromComponents(hourIn24: number, minutes: number, includeMins = true, includePeriod = true) {
    let hour = hourIn24 >= 12 ? hourIn24 - 12 : hourIn24;
    hour = (hour === 0) ? 12 : hour;

    const formattedMins = (minutes < 10 ? '0' : '') + minutes.toString(); 
    const period = includePeriod ? (hourIn24 >= 12 ? "pm" : "am") : undefined;
    
    return includeMins ? `${hour}:${formattedMins}${period ?? ''}` : `${hour}${period ?? ''}`;    
}

export function formatDateAsLocalTime(date: Date, includeDay: boolean = true) {
    const month = date.getMonth() + 1;
    const monthStr = month >= 10 ? month : ("0" + month);

    const day = date.getDate();
    const dayStr = day >= 10 ? day : ("0" + day);

    const dateStr = includeDay ? `${date.getFullYear()}-${monthStr}-${dayStr}` : `${date.getFullYear()}-${monthStr}`;

    return dateStr;
} 

export function formatDateUtc(date: Date, includeDay: boolean = true) {
    const month = date.getUTCMonth() + 1;
    const monthStr = month >= 10 ? month : ("0" + month);

    const day = date.getUTCDate();
    const dayStr = day >= 10 ? day : ("0" + day);

    const dateStr = includeDay ? `${date.getUTCFullYear()}-${monthStr}-${dayStr}` : `${date.getUTCFullYear()}-${monthStr}`;

    return dateStr;
} 

export function isInteger(val: string) {
    return /^\d+$/.test(val);
}

export function prettyFormatTimeDurationInHours(durationInHours: number) {
    return `${Math.floor(durationInHours)}h ${Math.floor((durationInHours * 60) % 60)}m`;
}

export const ratingColorMap = new Map([
    [0, "#dc3545"], 
    [1, "#e35d6a"], 
    [2, "#ea868f"], 
    [3, "#fd7e14"], 
    [4, "#fd9843"], 
    [5, "#ffc107"],
    [6, "#ffcd39"], 
    [7, "#75b798"],
    [8, "#479f76"], 
    [9, "#198754"],    
    [10, "#146c43"],                    
]);        