import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap"
import { useNavigate, useSearchParams } from "react-router-dom";
import AppPage from "../../../app/AppPage";
import { HttpException } from "../../../types/Exceptions";

import BrandLogo from "../../common/BrandLogo";
import { StatusMessage, StatusMessageType } from "../../common/StatusMessages";
import { AccountHttpServiceContext } from "../../http/account-http-service.provider";

interface Props {
    addStatus: (msg: StatusMessage) => any;
}

export default function ResetPassword({addStatus}: Props) {
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    const accountHttpService = useContext(AccountHttpServiceContext)!;
    const [user, setUser] = useState("");
    const [resetPasswordToken, setResetPasswordToken] = useState<any>({});
    const [signature, setSignature] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        if (!searchParams.has("token")) {
            navigate("/login");
            return;
        }

        const encodedSignedToken = searchParams.get("token")!;
        const [encodedToken, signature] = encodedSignedToken.split(".");

        let token: any;

        try {
            token = JSON.parse(atob(toBase64(encodedToken)));
        }
        catch (e) {
            navigate("/login");
            return;            
        }

        if (!token.accountName || !token.expires || token.action !== "reset_password") {
            navigate("/login");
            return;            
        }

        const expiry = DateTime.fromISO(token.expires);
        if (expiry < DateTime.now()) {
            addStatus({
                type: StatusMessageType.Info,
                msg: `The password reset link has expired.`
            });
            
            navigate("/forgot-password");
            return;     
        }

        setUser(token.accountName);
        setResetPasswordToken(token);
        setSignature(signature);
    }, []);

    async function resetPassword(e: any) {
        e.preventDefault();

        // TODO: keep n sync with signup view
        if (password.length < 8) {
            addStatus({type: StatusMessageType.Fail, msg: "Password must be at least 8 letters long."});     
            return; 
          }
      
          if (password.length > 64) {
            addStatus({type: StatusMessageType.Fail, msg: "Password can be at most 64 letters."});     
            return; 
          }    
      
          if (password !== confirmPassword) {
            addStatus({type: StatusMessageType.Fail, msg: "The confirmed password does not match the password."});
          return;        
          }

        try {
            await accountHttpService.resetPassword(user, password, resetPasswordToken, signature);
            addStatus({type: StatusMessageType.Success, msg: "Your password was successfully updated."});
            navigate("/login");
        }
        catch (e) {
            if (e instanceof HttpException) {
                addStatus({type: StatusMessageType.Fail, msg: `Reset password failed. Reason: ${e.message}`});
            }
            else {
                addStatus({type: StatusMessageType.Fail, msg: "Reset password failed. Please refresh the page or try again later."});     
            }

            return;
        } 
    }


    return (
        <AppPage className="app-bg-light pt-5">
            <Container className="">
                <div className="justify-content-center d-flex">
                <div style={{width: "400px"}}>
                    <div className="mt-4 py-4 px-5 rounded bg-white contrast-shadow">
                    <BrandLogo />
                    <div className="mt-4"></div>
                    <h4 className="text-center">Reset Password</h4>
                    <div className="text-center text-xs mt-3">Reset the password for {user}.</div>
                    <Form>
                        <Form.Group controlId="login-username" className="mt-4 text-xs">
                            <Form.Label style={{fontWeight: 500}}>New Password</Form.Label>
                            <Form.Control type="password" 
                                className="text-xs"
                                placeholder="Enter password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="login-password" className="mt-3 text-xs">
                            <Form.Label style={{fontWeight: 500}}>Confirm Password</Form.Label>
                            <Form.Control type="password"
                                className="text-xs" 
                                placeholder="Enter password" 
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                            />           
                        </Form.Group>
                        <div className="text-center mt-3">
                        <Button type="submit" variant="create" size="sm" onClick={resetPassword}>Reset Password</Button>
                        </div>           
                    </Form>       
                    </div>
                </div>       
                </div>
            </Container>
        </AppPage>
    );
}

/**
 * base64url to base64. see: https://stackoverflow.com/a/51838635/3721127
 * @param input base64url encoded string
 * @returns 
 */
function toBase64(input: string) {
    // Replace non-url compatible chars with base64 standard chars
    input = input
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    // Pad out with standard base64 required padding characters
    var pad = input.length % 4;
    if(pad) {
      if(pad === 1) {
        throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
      }
      input += new Array(5-pad).join('=');
    }

    return input;
}