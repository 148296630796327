import ISleepLogView from "../types/ISleepLogView";

export class CustomValueUtils {
    private static readonly CUSTOM_VALUE_SUFFIX = " (Custom)";

    static isCustom(suffixedName: string) {
        return suffixedName.indexOf(CustomValueUtils.CUSTOM_VALUE_SUFFIX) >= 0;
    }

    static addSuffix(name: string) {
        return `${name}${CustomValueUtils.CUSTOM_VALUE_SUFFIX}`;
    }

    static removeSuffix(suffixedName: string) {
        const customFieldIndex = suffixedName.indexOf(CustomValueUtils.CUSTOM_VALUE_SUFFIX);
        return suffixedName.substring(0, customFieldIndex);
    }
    
    static getFromSuffixedName(suffixedName: string, sleepLog: ISleepLogView) {
        if (CustomValueUtils.isCustom(suffixedName)) {
            const customFieldIndex = suffixedName.indexOf(CustomValueUtils.CUSTOM_VALUE_SUFFIX);
            const name = suffixedName.substring(0, customFieldIndex);
            return sleepLog.customMetrics?.find((m => m.name === name));
        }
        else {
            return undefined;
        }
    } 
}