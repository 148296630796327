import { AccountProperties, AccountSettings, ConnectedAccountState, ConnectedAccountType } from "../../types/AccountSettings";
import { AppState } from "../../types/AppState";
import { HttpException } from "../../types/Exceptions";
import HttpErrorBody from "../../types/HttpErrorResponse";
import { HttpService } from "./HttpService";

export type TimePeriod = 'latest' | 'lastWeek' | 'lastMonth' | 'lastYear' | 'all';

export default class AccountHttpService extends HttpService {
    public async getProperties() {
        const res = await fetch(`${this.baseAccountPath}`, {
            method: 'GET',
            headers: {
                ...this.commonHeaders
            },
        });

        const body = await res.json();

        if (res.status !== 200) {
            HttpService.throwHttpError(res, body);
        }          
    
          const settings = body as AccountProperties;

          return settings;
    }

    public async updateSettings(settings: AccountSettings) {
        const res = await fetch(`${this.baseAccountPath}/settings`, {
            method: 'PATCH',
            headers: {
                ...this.commonHeaders,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(settings)
        });

        if (res.status !== 200) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);
        }        
    }    

    public async syncConnectedAccounts(type: ConnectedAccountType | "all", period: TimePeriod) {
        let url = `${this.baseAccountPath}/connectedAccounts/sync`;
        if (type !== "all") {
            url += `/${type}`;
        }

        url += `?period=${period}`;
        
        let res = await fetch(url, {
            method: 'POST',
            headers: {
                ...this.commonHeaders,
            }
        });

        const body = await res.json();

        if (res.status !== 200) {
            HttpService.throwHttpError(res, body);
        }
        
        const syncStatus = body as ConnectedAccountState[];
        return syncStatus;
    }

    public async disconnectConnectedAccount(type: ConnectedAccountType) {
        const url = `${this.baseAccountPath}/connectedAccounts/${type}`;   
        
        let res = await fetch(url, {
            method: 'DELETE',
            headers: {
                ...this.commonHeaders,
            }
        });

        if (res.status !== 200) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);  
        }
    }

    public async cancelSync(failInProgressSyncs: boolean) {
        const url = `${this.baseAccountPath}/connectedAccounts/cancelSync?failInProgressSyncs=${failInProgressSyncs}`;   
        
        let res = await fetch(url, {
            method: 'POST',
            headers: {
                ...this.commonHeaders,
            }
        });

        if (res.status !== 200) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);
        }        
    }

    public async createSession(username: string, password: string) {
        const res = await fetch(`${AccountHttpService.basePath}/accounts/${username}/sessions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...this.commonHeaders,
            },
            body: JSON.stringify({username, password})
        });
        
        if (res.status !== 201) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);
        }         
    }

    public async deleteSession() {
        const res = await fetch(`${this.baseAccountPath}/session`, {
            method: 'DELETE',
            headers: {
                ...this.commonHeaders,
            },            
        });
        
        if (res.status !== 200) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);
        }             
    }

    public async clearSessionCookie() {
        const url = `${this.baseAccountPath}/sessions/cookie`;
        
        let res = await fetch(url, {
            method: 'DELETE',
            headers: {
                ...this.commonHeaders,
            }
        });

        if (res.status !== 200) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);  
        }            
    }

    public async updatePassword(currentPassword: string, newPassword: string) {
        const url = `${this.baseAccountPath}/password`;

        const res = await fetch(url, {
            method: 'PATCH',
            headers: {
                ...this.commonHeaders,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({password: currentPassword, newPassword: newPassword})
        });
    
        if (res.status !== 200) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);
        }     
    }

    public async updateEmail(currentPassword: string, newEmail: string) {
        const url = `${this.baseAccountPath}/email`;

        const res = await fetch(url, {
            method: 'PATCH',
            headers: {
                ...this.commonHeaders,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({password: currentPassword, newEmail: newEmail})
        });
    
        if (res.status !== 200) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);
        }     
    }
    
    public async sendResetPasswordEmail(accountName: string) {
        const url = `${this.baseEmailServicePath}/resetPassword`;

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                ...this.commonHeaders,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ accountName: accountName })
        });
    
        if (res.status !== 200) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);
        }          
    }

    public async resetPassword(accountName: string, newPassword: string, token: any, signature: string) {
        const url = `${this.baseAccountPath}/password/reset`;

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                ...this.commonHeaders,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: token, signature: signature, newPassword: newPassword })
        });
    
        if (res.status !== 200) {
            const error = await res.json();
            throw new HttpException(res.status, error.error, error.msg);
        }          
    }

    public async addConnectedAccount(code: string, source: ConnectedAccountType) {
        const res = await fetch(`${this.baseAccountPath}/connectedAccounts`, {
            method: 'POST',
            headers: {
                ...this.commonHeaders,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code: code,
                source: source
            })
        });
        
        if (res.status !== 201) {
            const body = await res.json();
            HttpService.throwHttpError(res, body);
        }   
    }

    public async createAccount(username: string, password: string, email: string) {
        const res = await fetch(`${AccountHttpService.basePath}/accounts`, {
            method: 'POST',
            headers: {
                ...this.commonHeaders,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password: password,
                email: email
            })
          });        
    }

    private readonly baseEmailServicePath = `${AccountHttpService.basePath}/emailService`;
}