import React, { useContext } from "react";
import { useRef } from "react";
import { AccountHttpServiceContext } from "../../http/account-http-service.provider";
import AccountService from "./account.service";

export const AccountServiceContext = React.createContext<AccountService | undefined>(undefined);

export const useAccountServiceProvider = () => {
    const serviceRef = useRef<AccountService | undefined>(undefined);
    const accountHttpService = useContext(AccountHttpServiceContext);

    if (!accountHttpService) {
        throw new Error("Account http service undefined!");
    }

    if (serviceRef.current === undefined) {
        serviceRef.current = new AccountService(accountHttpService);
    }

    const service = serviceRef.current;
    return service;
}

export function AccountServiceProvider(props: any) {
    const service = useAccountServiceProvider();
    return (
        <AccountServiceContext.Provider value={service}>
            {props.children}
        </AccountServiceContext.Provider>
    );
}