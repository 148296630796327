import React from "react";
import { useRef, useContext, useEffect, useState } from "react";
import SleepLogSettingsService from "./SleepLogSettingsService";
import { SleepLogHttpServiceContext } from "../../http/sleep-log-http-service.provider";

export const SleepLogSettingsServiceContext = React.createContext<SleepLogSettingsService | undefined>(undefined);

export const useSleepLogSettingsServiceProvider = () => {
    const serviceRef = useRef<SleepLogSettingsService | undefined>(undefined);
    const sleepLogHttpService = useContext(SleepLogHttpServiceContext)!;

    if (serviceRef.current === undefined) {
        serviceRef.current = new SleepLogSettingsService(sleepLogHttpService);
    }

    const service = serviceRef.current;
    return service;
}

export function SleepLogSettingsServiceProvider(props: any) {
    const service = useSleepLogSettingsServiceProvider();

    return (
        <SleepLogSettingsServiceContext.Provider value={service}>
            {props.children}
        </SleepLogSettingsServiceContext.Provider>
    );
}