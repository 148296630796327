import React, { useState, useEffect, useRef, useContext } from "react";
import {
    Accordion,
    Col,
    Nav,
    Row} from "react-bootstrap";
import {IDBPDatabase, openDB } from "idb";

import styles from "./styles/sleep-log-analytics.module.css";

import { LoginState } from "../../../types/CommonInterfaces";
import SleepLog from "../../../types/SleepLog";
import { FilterEvaluator } from "../../../utils/SleepLogFilterUtils";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import SleepPerMedicationChart from "../charts/medication/SleepPerMedicationChart";
import SleepStatsSummary from "./SleepStatsSummary";
import RatingBreakdownChart from "../charts/rating/RatingBreakdownChart";
import RatingSummaryChart from "../charts/rating/RatingSummaryChart";
import { formatDateAsLocalTime, FormatUtils } from "../../../utils/FormatUtility";
import SleepLogSettings from "../../../types/SleepLogSettings";
import SleepMedCountChart from "../charts/medication/SleepMedCountChart";
import { FilterSummaryCardHost } from "./components/FilterSummaryCard";
import SleepLogDashboard from "./SleepLogDashboard";
import ISleepLogView from "../../../types/ISleepLogView";
import ChartCard from "../cards/ChartCard";
import { useImmer } from "use-immer";
import { StatusMessage, StatusMessageType } from "../../common/StatusMessages";
import RatingPerMedicationChart from "../charts/medication/RatingPerMedicationChart";
import SleepMedicationDoseChart from "../charts/medication/SleepMedicationDoseChart";
import classNames from "classnames";
import RatingVsAverageSleepChart from "../charts/rating/RatingVsAverageSleepChart";
import SleepVsAverageRatingChart from "../charts/sleep/SleepVsAverageRatingChart";
import { Aggregation } from "../../../types/types";
import ErrorBoundary from "../../common/ErrorBoundary";
import { SimpleSleepLogFilterSet, SleepLogFilter } from "../../../types/SleepLogFilter";
import useStore from "../../../shared/store/useStoreService.hook";
import SleepLogSettingsService from "../../sleep-log-settings/services/SleepLogSettingsService";
import SleepLogViewFactory from "../../../types/SleepLogViewFactory";
import EventsChart from "../charts/events/event-chart";
import { SleepLogSettingsServiceContext } from "../../sleep-log-settings/services/sleep-log-settings-service.provider";
import { SleepLogHttpServiceContext } from "../../http/sleep-log-http-service.provider";
import { HttpException } from "../../../types/Exceptions";
import _ from "lodash";
import { SleepLogAnalyticsTab } from "./SleepLogAnalyticsTab";
import { RatingPerDayChartCard } from "../charts/RatingPerDayChartCard";

interface TimePeriod {
    startDate?: string;
    endDate?: string;
}

interface PropTypes {
    loginState: LoginState;
    currentTab: string;

    addStatus: (msg: StatusMessage) => any;
}

export default function SleepLogsAnalytics({loginState, currentTab, addStatus}: PropTypes) {

    const [sleepLogsState, setSleepLogsState] = useState<ISleepLogView[]>([]);
    const [dashboardSleepLogs, setDashboardSleepLogs] = useState([] as SleepLog[]);

    const [filteredSleepLogs, setFilteredLogs] = useState<ISleepLogView[] | undefined>(undefined);
    const [unfilteredSleepLogs, setUnfilteredLogs] = useState<ISleepLogView[] | undefined>(undefined);  

    const [currentDashboardTab, setCurrentDashboardTab] = useState("summary");
    const [currentAnalyticsDataTab, setCurrentAnalyticsDataTab] = useState("summary");
    const [showSpinner, setShowSpinner] = useState(false);
    
    const defaultTimePeriod: TimePeriod = {
        startDate: DateTimeUtils.calculateThresholdDate(30),
        endDate: formatDateAsLocalTime(new Date(Date.now()))
    };

    const [filterState, setFilterState] = useState<SimpleSleepLogFilterSet>({minDate: defaultTimePeriod.startDate, maxDate: defaultTimePeriod.endDate});
    const filterTimePeriod = useRef<TimePeriod | undefined>(undefined);
    
    const hasLoadedComponent = useRef(false);
    const hasFetchedLogs = useRef(false);

    const [ratingPerDayOptions, setRatingPerDayOptions] = useImmer({
        useColorCoding: true,
        aggregation: "day" as Aggregation,
        showLine: false,
        alwaysShowPoints: false
    });

    const [useCache, setUseCache] = useState(false);
    const isCachePopulated = useRef(false);
    const [cachedSleepLogs, setCachedSleepLogs] = useState<ISleepLogView[]>([]);
    const [lastCachedLog, setLastCachedLog] = useState<string>("-");
    const [isCacheRefreshing, setIsCacheRefreshing] = useState(false);

    const[showFilterSpinner, setShowFilterSpinner] = useState(false);

    const [__, sleepLogSettings] = useStore<SleepLogSettingsService, SleepLogSettings | undefined>(SleepLogSettingsServiceContext);

    const sleepLogHttpService = useContext(SleepLogHttpServiceContext)!;

    useEffect(() => {
        if (hasLoadedComponent.current) {
            return;
        }

        if (currentTab === "sleep-analytics") {
            hasLoadedComponent.current = true;
        }
    }, [currentTab]);

    useEffect(() => {
        if (hasFetchedLogs.current || currentTab !== "sleep-analytics") {
            return;
        }

        let fetchInitialLogs = async () => {
            const sleepLogs = await fetchLogs(defaultTimePeriod);
            const sleepLogViews = SleepLogViewFactory.createViews(sleepLogs);

            setSleepLogsState(sleepLogViews);
            setDashboardSleepLogs([...sleepLogs]);
            hasFetchedLogs.current = true;
        };

        fetchInitialLogs();
    }, [loginState, currentTab]);

    async function fetchLogs(timePeriod?: TimePeriod): Promise<SleepLog[]> {
        if (loginState.loggedIn) {

            console.log("Fetching logs for analytics");
            setShowSpinner(true);

            let limit: number = 100;

            let requestFilter = {
                minDate: timePeriod?.startDate,
                maxDate: timePeriod?.endDate
            };

            let nextExclusiveStartId: string | undefined;

            let query = `sleep-logs?limit=${limit}&scan-forward=false&use-eventual-consistency=true&filter=${JSON.stringify(requestFilter)}`;

            let sleepLogs: SleepLog[] = [];

            do {
                let requestQuery = query;

                if (nextExclusiveStartId) {
                    requestQuery += `&exclusive-start-id=${nextExclusiveStartId}`;
                }

                try {
                    const { logs, lastEvaluatedId } = await sleepLogHttpService.getSleepLogs(
                        requestFilter,
                        undefined /* advancedFilter */,
                        limit,
                        false /* scanForward */,
                        nextExclusiveStartId,
                        true /* useEventualConsistency */,
                    );

                    sleepLogs = [...sleepLogs, ...logs];                 
                    nextExclusiveStartId = lastEvaluatedId;                    
                }
                catch (e: any) {
                    if (e instanceof HttpException) {
                        addStatus({
                            msg: `Fetching sleep logs failed. Reason: ${e.message}`,
                            type: StatusMessageType.Fail
                        });
                    }
                    else {
                        addStatus({
                            msg: `Fetching sleep logs failed. Please try again.`,
                            type: StatusMessageType.Fail
                        });
                    }    
                }
            }
            while (nextExclusiveStartId);

            setShowSpinner(false);

            sleepLogs.reverse();

            return sleepLogs;
        } 
        else {
            return [];
        }     
    }

    async function applyFilters(startDate: string, endDate: string, filters: SleepLogFilter[][]) {
        console.log("Applying filter");
        setShowFilterSpinner(true);

        let sleepLogs = sleepLogsState;
        const hasTimePeriodChanged = filterTimePeriod.current === undefined || startDate !== filterTimePeriod.current.startDate || endDate !== filterTimePeriod.current.endDate;

        if (useCache) {
            if (!isCachePopulated.current) {
                console.log("Populating cache");

                let db = await openDB(
                    dbName,
                    1,
                    {
                        upgrade: upgradeDb
                    }
                );

                let cachedLogs: SleepLog[];
        
                {
                    let tx = db.transaction(dbName, 'readonly');
                    let store = tx.objectStore(dbName);

                    // TODO: Get only needed logs if time period has changed or cache is not populated
                    cachedLogs = await store.getAll();
                    
                    await tx.done;
                }

                sleepLogs = await SleepLogViewFactory.createViewsAsync(cachedLogs);
                setCachedSleepLogs(sleepLogs);
                isCachePopulated.current = true;
                console.log("Populated cache");

                sleepLogs = sleepLogs.filter(sleepLog => sleepLog.date.asString >= startDate && sleepLog.date.asString <= endDate);
                setSleepLogsState(sleepLogs);
                
            }
            else {
                if (hasTimePeriodChanged) {
                    sleepLogs = cachedSleepLogs;
                    sleepLogs = sleepLogs.filter(sleepLog => sleepLog.date.asString >= startDate && sleepLog.date.asString <= endDate);
                    setSleepLogsState(sleepLogs);
                }
            }
        }
        else {
            if (hasTimePeriodChanged) {
                const fetchedSleepLogs = await fetchLogs({startDate, endDate});
                sleepLogs = await SleepLogViewFactory.createViewsAsync(fetchedSleepLogs);
                setSleepLogsState(sleepLogs);

                console.log("Fetched new logs for filter");            
            }
        }

        if (filters.flat().map(f => f.enabled).length > 0) {
            try {
                const filteredLogs = FilterEvaluator.filter(sleepLogs, filters);
                const unfilteredLogs = _.difference(sleepLogs, filteredLogs);

                setFilteredLogs(filteredLogs);
                setUnfilteredLogs(unfilteredLogs); 
            }
            catch(e) {
                addStatus({
                    msg: `Applying filters failed. One of the filters may have an invalid input.`,
                    type: StatusMessageType.Fail
                });  
            }    
        }
        else {
            setFilteredLogs(undefined);
            setUnfilteredLogs(undefined);
        }

        setFilterState({minDate: startDate, maxDate: endDate});
        filterTimePeriod.current = { startDate, endDate};

        setShowFilterSpinner(false);
    }

    useEffect(() => {
        async function getLastCachedLog() {
            const lastCachedLog = await getLatestDateFromDb();
            setLastCachedLog(FormatUtils.formatDate(lastCachedLog));
        }

        getLastCachedLog();
    }, [isCacheRefreshing]);

    async function getLatestDateFromDb() {
        let lastCachedLog = "";

        let db = await openDB(
            dbName,
            1,
            {
                upgrade: upgradeDb
            }
        );

        {
            let tx = db.transaction(dbName, 'readonly');
            let store = tx.objectStore(dbName);
            const cursor = await store.openKeyCursor(null, "prev");
            if (cursor?.key) {
                lastCachedLog = cursor.key;
            }
            
            await tx.done;
        }

        return lastCachedLog;
    }

    const dbName = "SleepLogs";

    function upgradeDb(upgradeDb: IDBPDatabase<any>) {
        // TODO: Store logs for a given user
        if (!upgradeDb.objectStoreNames.contains(dbName)) {
          upgradeDb.createObjectStore(dbName, {keyPath: 'date'});
        }
    }

    function getSleepLogDatasets() {
        return filteredSleepLogs !== undefined ? [filteredSleepLogs, unfilteredSleepLogs] : [sleepLogsState];
    }

    function getSleepLogViews(returnOne: boolean): ISleepLogView[][] {
        if (returnOne) {
            return filteredSleepLogs !== undefined && unfilteredSleepLogs !== undefined ?
                [filteredSleepLogs] : [sleepLogsState];
        }
        else {
            return filteredSleepLogs !== undefined && unfilteredSleepLogs !== undefined ?
                [filteredSleepLogs, unfilteredSleepLogs] : [sleepLogsState];
        }
    }

    function getSleepLogView(): ISleepLogView[] {
        return getSleepLogViews(true /* returnOne */)[0];
    }

    if (!hasLoadedComponent.current) {
        return (<div></div>);
    }

    async function refreshCache(lastNumDays?: number) {
        if (!('indexedDB' in window)) {
            console.log("This browser doesn't support IndexedDB");
            return;
        }

        let timePeriod: TimePeriod | undefined;

        if (lastNumDays != null) {
            const lastCachedLog = await getLatestDateFromDb();

            if (lastCachedLog) {
                const threshold = DateTimeUtils.calculateThresholdDate(lastNumDays);
                const start = lastCachedLog < threshold ? lastCachedLog : threshold;
                timePeriod = { startDate: start, endDate: DateTimeUtils.calculateThresholdDate(0) };
            }
        }

        setIsCacheRefreshing(true);
        let sleepLogs: SleepLog[] = await fetchLogs(timePeriod);

        let db = await openDB(
            dbName,
            1,
            {
                upgrade: upgradeDb
            }
        );
        {
            let tx = db.transaction(dbName, 'readwrite');
            let store = tx.objectStore(dbName);

            for (const sleepLog of sleepLogs) {
                await store.put(sleepLog);
            }
            
            await tx.done;
        }

        setIsCacheRefreshing(false);        
        isCachePopulated.current = false;
    }

    return (
        <div className="mt-3">
            <Nav variant="pills" className="justify-content-center mb-2" activeKey={currentDashboardTab}
                    onSelect={(selectedKey) => setCurrentDashboardTab(selectedKey ?? "summary")}>
                    <Nav.Item>
                        <Nav.Link className="rounded-pill" eventKey="summary">Summary</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className="rounded-pill" eventKey="analytics">Explore Data</Nav.Link>
                    </Nav.Item>                         
            </Nav>
            { currentDashboardTab === "summary" &&
                <SleepLogDashboard sleepLogs={dashboardSleepLogs} sleepLogSettings={sleepLogSettings} />
            }
            { currentDashboardTab === "analytics" &&                                
                <div className="">
                    <div className="">                         
                        <FilterSummaryCardHost
                            sleepLogsState={sleepLogsState}
                            settings={sleepLogSettings}
                            filteredSleepLogs={filteredSleepLogs}
                            filter={filterState}
                            applyFilters={applyFilters}
                            showSpinner={showFilterSpinner}
                            showCache={true}
                            useCache={useCache}
                            lastCachedLog={lastCachedLog}
                            isCacheRefreshing={isCacheRefreshing}
                            onRefreshCache={(lastNumDays) => refreshCache(lastNumDays)}
                            toggleUseCache={() => setUseCache(state => !state)}
                        />

                        <Nav variant="pills" className="justify-content-center text-sm my-3" activeKey={currentAnalyticsDataTab}
                            onSelect={(selectedKey) => setCurrentAnalyticsDataTab(selectedKey ?? "summary")}>
                            <Nav.Item>
                                <Nav.Link className="rounded-pill" eventKey="summary">Overview</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="rounded-pill" eventKey="sleep">Sleep</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="rounded-pill" eventKey="rating">Rating</Nav.Link>
                            </Nav.Item>                 
                            <Nav.Item>
                                <Nav.Link className="rounded-pill" eventKey="medication">Medication</Nav.Link>
                            </Nav.Item>                           
                        </Nav>

                        <Accordion>
                            <Accordion.Item className={classNames("border-0 contrast-shadow-sm", styles.timeline)} eventKey="0">
                                <Accordion.Header>
                                    <div className="w-100 text-center text-muted">Timeline</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ErrorBoundary>
                                        <EventsChart
                                            allSleepLogs={sleepLogsState}
                                            filteredSleepLogs={filteredSleepLogs}
                                            sleepLogSettings={sleepLogSettings}
                                        />
                                    </ErrorBoundary>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <div className="mt-3">
                            
                        {currentAnalyticsDataTab === "summary" &&
                            <React.Fragment>
                                <SleepStatsSummary sleepLogGroups={getSleepLogViews(false /* returnOne */)} />                
                            </React.Fragment>
                        }

                        {currentAnalyticsDataTab === "sleep" &&
                            <SleepLogAnalyticsTab 
                                allSleepLogs={sleepLogsState}
                                filteredSleepLogs={filteredSleepLogs}
                                filteredOutSleepLogs={unfilteredSleepLogs}
                                sleepLogSettings={sleepLogSettings}
                            />
                        }

                        {currentAnalyticsDataTab === "rating" &&
                            <React.Fragment>

                                <div className="mt-1 row g-0">
                                    <ErrorBoundary>                
                                        <RatingSummaryChart
                                            sleepLogsState={sleepLogsState}
                                            filteredSleepLogs={filteredSleepLogs ?? []}
                                            unfilteredSleepLogs={unfilteredSleepLogs ?? []}
                                        />
                                    </ErrorBoundary>                                           
                                </div>

                                <Row className="g-4">
                                    <Col lg="6">
                                        <ErrorBoundary>
                                            <RatingPerDayChartCard 
                                                sleepLogGroups={getSleepLogViews(false)}
                                                aggregation={ratingPerDayOptions.aggregation}
                                                showLine={ratingPerDayOptions.showLine}
                                                alwaysShowPoints={ratingPerDayOptions.alwaysShowPoints}
                                                useColorCoding={ratingPerDayOptions.useColorCoding}
                                                onAggregationChange={aggregation => setRatingPerDayOptions(state => { state.aggregation = aggregation; })}
                                                onShowLine={showLine => setRatingPerDayOptions(state => { state.showLine = showLine; })}
                                                onAlwaysShowPointsChange={alwaysShowPoints => setRatingPerDayOptions(state => { state.alwaysShowPoints = alwaysShowPoints; })}
                                                onColorCodingChange={useColorCoding => setRatingPerDayOptions(state => { state.useColorCoding = useColorCoding; })}
                                            />
                                        </ErrorBoundary>
                                    </Col>

                                    <Col lg="6">
                                        <ChartCard title="Rating Breakdown">
                                            <ErrorBoundary>                   
                                                <RatingBreakdownChart
                                                    sleepLogs={getSleepLogView()}
                                                />
                                            </ErrorBoundary>
                                        </ChartCard>
                                    </Col>

                                    <Col lg="6">
                                        <ChartCard title="Rating vs Average Sleep">
                                            <ErrorBoundary>
                                                <RatingVsAverageSleepChart sleepLogs={getSleepLogView()} />
                                            </ErrorBoundary>
                                        </ChartCard>
                                    </Col>

                                    <Col lg="6">
                                        <ChartCard title="Sleep vs Average Rating">
                                            <ErrorBoundary>
                                                <SleepVsAverageRatingChart  sleepLogs={getSleepLogView()} />
                                            </ErrorBoundary>
                                        </ChartCard>
                                    </Col>

                                </Row>    
                                                             
                            </React.Fragment>
                        }
                        {currentAnalyticsDataTab === "medication" &&
                            <React.Fragment>
                                <Row className="justify-content-center mt-1 g-4">
                                    <Col lg="6">
                                        <ChartCard title="Sleep Medications">
                                            <SleepMedCountChart sleepLogs={getSleepLogView()} useSleepMedications={true} limit={5} />
                                        </ChartCard>    
                                    </Col>

                                    <Col lg="6">         
                                        <ChartCard title="Hours Slept">
                                            <SleepPerMedicationChart sleepLogs={getSleepLogView()} />
                                        </ChartCard>
                                    </Col>

                                    <Col lg="6">         
                                        <ChartCard title="Rating">
                                            <RatingPerMedicationChart sleepLogs={getSleepLogView()} />
                                        </ChartCard>
                                    </Col>

                                    <Col lg="6">
                                        <ChartCard title="Medication Dose">
                                            <SleepMedicationDoseChart sleepLogs={getSleepLogView()} />
                                        </ChartCard>    
                                    </Col>

                                    <Col lg="6">
                                        <ChartCard title="Other Medications">
                                            <SleepMedCountChart sleepLogs={getSleepLogView()} useSleepMedications={false} limit={5} />
                                        </ChartCard>    
                                    </Col>                                        
                                </Row>
                            </React.Fragment>
                        }                     
                        </div>
                    </div>
                </div>
            }        
        </div>
    );
}