import { DateTime } from "luxon";
import Store from "../../../shared/store/store";
import { AccountProperties, AccountSettings, ConnectedAccountState, ConnectedAccountType } from "../../../types/AccountSettings";
import Utils from "../../../utils/utils";
import AccountHttpService, { TimePeriod } from "../../http/AccountHttpService";

export default class AccountService extends Store<AccountProperties | undefined> {
    
    constructor(private accountHttpService: AccountHttpService) {
        super(undefined);
    }

    update(properties: AccountProperties) {
        this.state = properties;
        this.notifySubscribers();
    }

    updateConnectedAccountSyncState(connectedAccounts: ConnectedAccountState[]) {
        this.state = {...this.state, connectedAccounts: connectedAccounts };
        this.notifySubscribers();
    }
    
    //
    // Calls that make http requests
    //
    //

    async fetchProperties() {
        const settings = await this.accountHttpService.getProperties();
        this.state = settings;

        this.notifySubscribers();       
    }

    async disconnectAccount(type: ConnectedAccountType) {
        if (!this.state) {
            return;
        }

        await this.accountHttpService.disconnectConnectedAccount(type);
        this.state = {...this.state, connectedAccounts: this.state.connectedAccounts.filter(account => account.type !== type)};

        this.notifySubscribers();
    }

    async updateSettings(settings: AccountSettings) {
        if (!this.state) {
            return;
        }

        await this.accountHttpService.updateSettings(settings);
        
        if (settings.primaryConnectedAccount) {
            this.state = {...this.state, primaryConnectedAccount: settings.primaryConnectedAccount };
        }

        this.notifySubscribers();        
    }

    async updateEmail(currentPassword: string, newEmail: string) {
        if (!this.state) {
            return;
        }

        await this.accountHttpService.updateEmail(currentPassword, newEmail);
        this.state = {...this.state, email: newEmail};

        this.notifySubscribers();  
    }

    async syncConnectedAccounts(type: ConnectedAccountType | "all", period: TimePeriod) {
        this.isSyncing = true;

        let connectedAccounts: ConnectedAccountState[] = this.state?.connectedAccounts.map(
            account => ({
                ...account,
                status: (type === "all" || type === account.type) ? "InProgress" : account.status,
                lastModifiedTime: (type === "all" || type === account.type) ? DateTime.utc().toISO() : account.lastModifiedTime
            })
        ) ?? [];

        this.update({...this.state, connectedAccounts: connectedAccounts });

        const syncedAccounts = await this.accountHttpService.syncConnectedAccounts(type, period);
        connectedAccounts = connectedAccounts.map(account => {
            const syncedAccount = syncedAccounts.find(syncedAccount => syncedAccount.type === account.type);
            return syncedAccount ?? account;
        });

        this.update({...this.state, connectedAccounts: connectedAccounts });

        let interval = 2000;
        let syncInProgress = true;

        do {
            await Utils.delay(interval);

            try {
                const properties  = await this.accountHttpService.getProperties();
                this.update(properties);

                connectedAccounts = properties.connectedAccounts;
                syncInProgress = connectedAccounts.findIndex(device => device.status === 'InProgress') !== -1;
            }
            catch (e) {
                // do nothing, keep trying
            }

            interval = Math.max(interval + 500, 5000);
        } while (syncInProgress);

        this.isSyncing = false;

        this.notifySubscribers();
    }

    async pollSyncState() {
        if (this.isSyncing || this.isPollingSyncState) {
            return;
        }

        this.isPollingSyncState = true;

        let interval = 2000;
        let syncInProgress = true;

        do {
            await Utils.delay(interval);

            try {
                const properties  = await this.accountHttpService.getProperties();
                this.update(properties);

                let connectedAccounts = properties.connectedAccounts;
                syncInProgress = connectedAccounts.findIndex(device => device.status === 'InProgress') !== -1;
            }
            catch (e) {
                // do nothing, keep trying
            }

            interval = Math.max(interval + 500, 5000);
        } while (syncInProgress);  
        
        this.isPollingSyncState = false;
    }

    async cancelSync(failInProgressSyncs: boolean) {
        await this.accountHttpService.cancelSync(failInProgressSyncs);
        
        const properties  = await this.accountHttpService.getProperties();
        this.update(properties);
    }

    private isPollingSyncState = false;
    private isSyncing = false;
}