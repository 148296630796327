import { DateTime } from "luxon";
import ISleepLogView from "../types/ISleepLogView";
import { SimpleSleepLogFilterSet, SleepLogFilter, Field, FieldType } from "../types/SleepLogFilter";
import SleepLog, { Medication } from "../types/SleepLog";
import { CustomMetric } from "../types/SleepLogSettings";
import SleepLogViewFactory from "../types/SleepLogViewFactory";
import { getDayOfWeek } from "./FormatUtility";
import SleepStagesUtils from "./SleepStagesUtils";
import StringUtils from "./StringUtils";
import { CustomValueUtils } from "./CustomValueUtils";

export class FilterEvaluator {
    public static filter(sleepLogs: ISleepLogView[], filters: SleepLogFilter[][]) {
        filters = filters.map(filterGroup =>
            filterGroup
                .filter(f => f.enabled)
                .map(f => ({...f, parameters: f.parameters.map(p => p.trim()) }))
        );

        return sleepLogs.filter((_, i) => this.matchesAny(sleepLogs, i, filters));
    }

/**
 * 
 * @param sleepLogs 
 * @param index - index of the log to check if it matches the filters
 * @param filters - (a && b) || (c && d) || ...
 * @returns 
 */
    private static matchesAny(sleepLogs: ISleepLogView[], index: number, filters: SleepLogFilter[][]) {
        for (const filterGroup of filters) {
            const matches = this.matches(sleepLogs, index, filterGroup);
            if (matches) {
                return matches;
            }
        }

        return false;
    }

    /**
     * 
     * @param sleepLogs 
     * @param index - index of the log to check if it matches the filters
     * @param filters (a && b)
     * @returns 
     */
    private static matches(sleepLogs: ISleepLogView[], index: number, filters: SleepLogFilter[]) {    
        for (const filter of filters) {
            let sleepLog: ISleepLogView | undefined;
            let sleepLogIndex = index;
    
            switch (filter.applyTo) {
                case 'day of':
                    sleepLog = sleepLogs[index];
                    sleepLogIndex = index;
                    break;
                case 'day before':
                {
                    const prevSleepLog = index > 0 ? sleepLogs[index - 1] : undefined;
                    const prevDate = sleepLogs[index].date.addDays(-1);
    
                    if (prevSleepLog && prevSleepLog.date.equals(prevDate)) {
                        sleepLog = prevSleepLog;
                        sleepLogIndex = index - 1;
                    }
                    else {
                        return false;
                    }
                }
    
                    break;
                case 'day after':
                {
                    const nextSleepLog = index - 1 < sleepLogs.length ? sleepLogs[index + 1] : undefined;
                    const nextDate = sleepLogs[index].date.addDays(1);
    
                    if (nextSleepLog && nextSleepLog.date.equals(nextDate)) {
                        sleepLog = nextSleepLog;
                        sleepLogIndex = index + 1;
                    }
                    else {
                        return false;
                    }
                }
    
                    break;
                case '2 days before':
                    {
                        const prevDate = sleepLogs[index].date.addDays(-2);
    
                        let prevSleepLog = index - 1 >= 0 ? sleepLogs[index - 1] : undefined;
        
                        if (prevSleepLog && prevSleepLog.date.equals(prevDate)) {
                            sleepLog = prevSleepLog;
                            sleepLogIndex = index - 1;
                        }
                        else {
                            prevSleepLog = index - 2 >= 0 ? sleepLogs[index - 2] : undefined;
                            
                            if (prevSleepLog && prevSleepLog.date.equals(prevDate)) {
                                sleepLog = prevSleepLog;
                                sleepLogIndex = index - 2;
                            }
                            else {
                                return false;
                            }
                        }
                    }
        
                        break;                
                default:
                    sleepLog = undefined;
            }
    
            if (sleepLog === undefined) {
                return false;
            }
    
            switch (filter.type) {
                case 'tags':
                {
                    const prop = this.getTagsProp(sleepLog, filter.field);
    
                    if (!this.tagPropMatchesFilter(prop, filter)) {
                        return false;
                    }
    
                    break;
                }
                case 'string':
                {
                    const prop = this.getStringProp(sleepLog, filter.field);
                    if (!this.stringPropMatchesFilter(prop, filter)) {
                        return false;
                    }
    
                    break;
                }
                case 'number':
                {
                    const prop = this.getNumberProp(sleepLogs, sleepLogIndex, filter.field);
                    
                    if (!this.numberPropMatchesFilter(prop, filter)) {
                        return false;
                    }
    
                    break;
                }
                case 'time':
                {
                    const prop = this.getTimeProp(sleepLog, filter.field);
    
                    if (!this.timePropMatchesFilter(prop, filter)) {
                        return false;
                    }
    
                    break;
                }
                case 'medication':
                {
                    const prop = this.getMedicationProp(sleepLog, filter.field);
    
                    if (!this.medicationPropMatchesFilter(prop, filter)) {
                        return false;
                    }
                    
                    break;
                }
                case 'date':
                {
                    const prop = this.getDateProp(sleepLog, filter.field);
                    if (!this.datePropMatchesFilter(prop, filter)) {
                        return false;
                    }
    
                    break;
                }
                case 'awakening threshold':
                {
                    const prop = this.getAwakeningThresholdProp(sleepLog, filter);
    
                    if (!this.awakeningThresholdPropMatchesFilter(prop, filter)) {
                        return false;
                    }
    
                    break;                
                }
                case 'medication taken':
                {
                    const prop = this.getMedicationTakenAtProp(sleepLog, filter);

                    if (!this.medicationTakenAtThresholdPropMatchesFilter(prop, filter)) {
                        return false;
                    }

                    break;
                }

                default:
                    return false;
            }        
        }
    
        return true;
    }

    private static getTagsProp(sleepLog: ISleepLogView, field: string | Field): string[] | undefined {
        switch (field) {
            case "Tags":
                return sleepLog.baseSleepLog.tags;
            case "Feeling":
                return sleepLog.baseSleepLog.feelings?.map(f => f.feeling);
            case "Medication":
                const sleepMeds = sleepLog.baseSleepLog.medications?.map(m => m.name) ?? [];
                const otherMeds = sleepLog.baseSleepLog.otherMedications?.map(m => m.name) ?? [];
                return [...sleepMeds, ...otherMeds];
            case "Sleep medication":
                return sleepLog.baseSleepLog.medications?.map(m => m.name);
            case "Other medication":
                return sleepLog.baseSleepLog.otherMedications?.map(m => m.name);
            case "Events":
                return sleepLog.baseSleepLog.events?.map(event => event.name);
            default:
                return undefined;
        }
    }
    
    private static getStringProp(sleepLog: ISleepLogView, field: string | Field): string | undefined {
        switch (field) {
            case "Day of week":
                return getDayOfWeek(sleepLog.date.asString);
            case "Notes":
                return sleepLog.baseSleepLog.notes;          
            default:
                return undefined;
        }
    }
    
    private static getNumberProp(sleepLogs: ISleepLogView[], index: number, field: string | Field): number | undefined {
        const sleepLog = sleepLogs[index];
    
        if (CustomValueUtils.isCustom(field)) {
            const metric = CustomValueUtils.getFromSuffixedName(field, sleepLog);
            if (!metric || SleepLogFilterUtil.convertMetricType(metric) !== "number") {
                return undefined;
            }
            else {
                return metric.value as number;
            }
        }
    
        let staticField = field as Field;
    
        switch (staticField) {
            case "Rating":
                return sleepLog.rating;
            case "Hours slept":
                {
                    return sleepLog.minutesAsleep !== undefined ? sleepLog.minutesAsleep / 60 : undefined;
                }
            case "Time to fall asleep (minutes)":
                {
                    return sleepLog.mainSleep ? SleepStagesUtils.getTimeToFallAsleepInMins(sleepLog.mainSleep) : undefined;
                }
            case "Longest awakening (minutes)":
                {
                    // Remove time to fall asleep and last awakening, if present.
                    return sleepLog.mainSleep ?
                        Math.max(...sleepLog.mainSleep.sleepStages.slice(1, -1).filter(stage => stage.type === 'awake').map(stage => stage.seconds / 60)) :
                        undefined;
                }
            case "Minutes awake":
                {
                    return sleepLog.mainSleep?.minutesAwake;
                }
            case "Hours slept (3 day moving average)":
            case "Hours slept (7 day moving average)":
                {
                    let sum = 0;
                    let start = index;
                    let count = 0;        
                    const endDate = DateTime.fromISO(sleepLog.date.asString, { zone: 'UTC' });
    
                    let numDays: number;
    
                    if (staticField === "Hours slept (3 day moving average)") {
                        numDays = 3;
                    }
                    else {
                        numDays = 7;
                    }
        
                    while (start >= 0 && endDate.diff(DateTime.fromISO(sleepLogs[start].date.asString, { zone: 'UTC'}), 'days').toObject().days! < numDays) {
                        const value = sleepLogs[start].minutesAsleep;
                        if (value !== undefined) {
                            sum += (value / 60);
                            count++;
                        }
                        
                        start--;
                    }
        
                    const average = (count === numDays) ? (sum / (count)) : undefined;
                    return average;           
                }
            case "Sleep efficiency":
                if (sleepLog.mainSleep) {
                    const mainSleep = sleepLog.mainSleep;
                    return mainSleep.minutesAsleep / (mainSleep.minutesAsleep + mainSleep.minutesAwake);
                }
                else {
                    return undefined;
                }
            case "Time in bed (hours)":
                {
                    return sleepLog.mainSleep ? ((sleepLog.mainSleep.minutesAwake + sleepLog.mainSleep.minutesAsleep) / 60) : undefined;
                }


            default:
                return undefined;
        }
    }
    
    private static getTimeProp(sleepLog: ISleepLogView, field: string | Field): DateTime[] | undefined {
        if (!sleepLog.mainSleep) {
            return undefined;
        }
    
        const { bedtime, sleeptime, waketime } = SleepStagesUtils.getSleepAndWakeupTime(sleepLog.mainSleep);
        const timezone = sleepLog.mainSleep.timezone ?? "utc";
    
        switch (field) {
            case "Bedtime":
                return [bedtime.setZone(timezone)];
            case "Sleep time":
                return [sleeptime.setZone(timezone)];
            case "Wakeup time":
                return [waketime.setZone(timezone)];
            case "Awakening (middle of night)":
                if (sleepLog.mainSleep) {
                    return sleepLog.mainSleep.sleepStages
                        .slice(1, -1)
                        .filter(stage => stage.type == "awake")
                        .flatMap(stage => [DateTime.fromISO(stage.start), DateTime.fromISO(stage.start).plus({ seconds: stage.seconds })])
                        .map(date => date.setZone(timezone));
                }
                else {
                    return undefined;
                }
            default:
                return undefined;
        }
    }
    
    private static getDateProp(sleepLog: ISleepLogView, field: string | Field): string | undefined {
        switch (field) {
            case "Date":
                return sleepLog.date.asString;
            default:
                return undefined;
        }
    }
    
    private static getMedicationProp(sleepLog: ISleepLogView, field: string | Field): Medication[] | undefined{
        switch (field) {
            case "Medication dose":
                let meds = sleepLog.baseSleepLog.medications ?? [];
                meds = meds.concat(sleepLog.baseSleepLog.otherMedications ?? []);
                return meds;
            default:
                return undefined;
        }
    }
    
    // Returns array of awakenings in seconds sans time to fall asleep and final awakening.
    private static getAwakeningThresholdProp(sleepLog: ISleepLogView, filter: SleepLogFilter): number | undefined {
    
        if (!sleepLog.mainSleep) {
            return undefined;
        }
    
        const thresholdInMins = Number(filter.parameters[1]) ?? 0;
    
        switch (filter.field) {
            case "Time to first awakening (hours)":
                const stages = sleepLog.mainSleep.sleepStages;
                const middleOfNightAwakening = stages
                    .slice(1, -1)
                    .find(stage => stage.type === "awake" && stage.seconds / 60 >= thresholdInMins);
    
                const { sleeptime, waketime } = SleepStagesUtils.getSleepAndWakeupTime(sleepLog.mainSleep);
                let end: DateTime;
    
                if (!middleOfNightAwakening) {
                    end = waketime;
                }
                else {
                    end = DateTime.fromISO(middleOfNightAwakening.start);
                }
    
    
                return end.diff(sleeptime).as("minutes") / 60;
    
            case "Awakening count (middle of night)":   
    
                return sleepLog.mainSleep
                    .sleepStages.slice(1, -1)
                    .filter(stage => stage.type === 'awake')
                    .map(stage => stage.seconds / 60)
                    .filter(d => d >= thresholdInMins).length;
            default:
                return undefined;
        }
    }

    private static getMedicationTakenAtProp(sleepLog: ISleepLogView, filter: SleepLogFilter): DateTime[] | undefined {
        const selectedMed = filter.parameters[2];
        if (!selectedMed) {
            return undefined;
        }

        const sleepMeds = sleepLog.baseSleepLog.medications ?? [];
        const otherMeds = sleepLog.baseSleepLog.otherMedications ?? [];
        const meds = [...sleepMeds, ...otherMeds]
            .filter(med => StringUtils.containsCaseInsensitive(med.name, selectedMed))
            .filter(med => med.takenAt)
            .map(med => med.takenAt!)
            .map(takenAt => DateTime.fromISO(takenAt).set({ year: 0, month: 1, day: 1}));

        return meds;
    }
    
    /*
    
        Filter matchers
    
    */
    
    private static stringPropMatchesFilter(prop: string | undefined, filter: SleepLogFilter) {
        switch (filter.operator) {
            case "equals":
                return prop === undefined ? false : prop === filter.parameters[0];
            case "contains":
                return prop === undefined ? false : prop.toLowerCase().includes(filter.parameters[0].toLowerCase());
            case "contains any of (comma separated)":
                const params = filter.parameters[0].split(',').map(param => param.trim());
                return prop === undefined ? false : params.findIndex(param => prop.toLowerCase().includes(param.toLowerCase())) !== -1;
            default:
                return false;
        }
    }
    
    private static numberPropMatchesFilter(prop: number | undefined, filter: SleepLogFilter) {
        switch (filter.operator) {
            case "equals":
                return prop === undefined ? false : prop === Number(filter.parameters[0]);
            case "does not equal":
                return prop === undefined ? true : prop !== Number(filter.parameters[0]);
            case "greater than or equal to":
                return prop === undefined ? false : prop >= Number(filter.parameters[0]);
            case "less than or equal to":
                return prop === undefined ? false : prop <= Number(filter.parameters[0]);
            case "greater than":
                return prop === undefined ? false : prop > Number(filter.parameters[0]);
            case "less than":
                return prop === undefined ? false : prop < Number(filter.parameters[0]);
            case "is null":
                return prop === undefined;
            default:
                return false;
        }
    }
    
    private static tagPropMatchesFilter(prop: string[] | undefined, filter: SleepLogFilter) {
        switch (filter.operator) {
            case "does not contain":
            case "contains":
            {
                const containsFilter = filter.operator === "contains";
    
                if (filter.parameters.length !== 1) {
                    throw new Error("Filter should have one argument.");
                }
    
                // No arguments -- ignore filter
                if (!filter.parameters[0]) {
                    return true;
                }
    
                if (prop === undefined) {
                    return !containsFilter;
                }
                
                const index = prop.findIndex(tag => tag.toLowerCase().includes(filter.parameters[0].toLowerCase()));
                return containsFilter ? index !== -1 : index === -1;
            }
            case "contains only":
                if (filter.parameters.length !== 1) {
                    throw new Error("Filter should have one argument.");
                }
                    
                // No arguments -- ignore filter
                if (!filter.parameters[0]) {
                    return true;
                }
    
                if (prop === undefined) {
                    return false;
                }
    
                const index = prop.findIndex(tag => tag.toLowerCase().includes(filter.parameters[0].toLowerCase()));
                const otherIndex = prop.findIndex(tag => !tag.toLowerCase().includes(filter.parameters[0].toLowerCase()));
                
                return index !== -1 && otherIndex === -1;
    
    
            case "contains any of (comma separated)":
            case 'does not contain any of (comma separated)':
            {
                if (filter.parameters.length !== 1) {
                    throw new Error("Filter should have one argument.");
                }
    
                const notCondition = filter.operator === 'does not contain any of (comma separated)';
    
                if (prop === undefined) {
                    return notCondition ? true : false;
                }
    
                const params = filter.parameters[0].split(',').map(param => param.trim()).filter(param => param);
                
                const index = prop.findIndex(tag => {
                    return params.findIndex(param => tag.toLowerCase().includes(param.toLowerCase())) !== -1;
                });
    
                return notCondition ? index === -1 : index !== -1;
            }
            case "has exact value":
            {
                if (filter.parameters.length !== 1) {
                    throw new Error("Filter should have one argument.");
                }
    
                if (prop === undefined) {
                    return false;
                } 
                
                const index = prop.findIndex(tag => tag.toLowerCase() === filter.parameters[0].toLowerCase());
                return index !== -1;            
            }
            case "has none":
            {
                if (prop === undefined || prop.length === 0) {
                    return true;
                }
                
                return false;
            }
            case "has any":
            {
                if (prop !== undefined && prop.length > 0) {
                    return true;
                }
                
                return false;
            }        
            default:
                return false;
        } 
    }
    
    private static timePropMatchesFilter(props: DateTime[] | undefined, filter: SleepLogFilter) {
        switch (filter.operator) {
            case "between":
                if (props === undefined) {
                    return false;
                }
    
                const start = filter.parameters[0].split(':').map(t => parseInt(t));
                const end = filter.parameters[1].split(':').map(t => parseInt(t));
    
                const startInMins = 60 * start[0] + start[1];
                const endInMins = 60 * end[0] + end[1];

                let matches = false;
    
                for (const prop of props) {
                    const propTime = [prop.hour, prop.minute];
                    const propTimeInMins = 60 * propTime[0] + propTime[1];
        
                    if (startInMins <= endInMins) {
                        matches ||= propTimeInMins >= startInMins && propTimeInMins <= endInMins;
                    }
                    else {
                        matches ||= propTimeInMins <= endInMins || propTimeInMins >= startInMins;
                    }

                    if (matches) {
                        return matches;
                    }
                }

                return matches;
                
            default:
                return false;
        }
    }
    
    /**
     * 
     * @param prop - date in YYYY-MM-DD
     * @param filter - date filter
     * @returns 
     */
     private static datePropMatchesFilter(prop: string | undefined, filter: SleepLogFilter) {
        if (!prop) {
            return false;
        }
    
        // YYYY-MM-DD
        const start = filter.parameters[0];
        const end = filter.parameters[1];
    
        if (!start || !end) {
            return false;
        }
    
        switch (filter.operator) {
            case "between":
                return prop >= start && prop <= end;
            case "not between":
                return prop < start || prop > end;
            default:
                return false;
        }
    }
    
    private static medicationPropMatchesFilter(prop: Medication[] | undefined, filter: SleepLogFilter) {
        if (filter.parameters.length !== 2) {
            throw new Error("Unexpected number of params");
        }
    
        if (!prop) {
            return false;
        }
    
        const filteredMed = filter.parameters[1];
        if (!filteredMed) {
            return false;
        }
    
        const meds = prop.filter(med => StringUtils.containsCaseInsensitive(med.name, filteredMed));
    
        if (meds.length === 0) {
            return false;
        }
    
        const medDose = meds.map(med => Number(med.dose) ?? 0).reduce((prev, cur) => prev + cur, 0);
            
        const doseParam = Number(filter.parameters[0]);
    
        if (Number.isNaN(doseParam)) {
            return false;
        }
    
        switch (filter.operator) {
            case 'greater than or equal to':
                return medDose >= doseParam;
            case 'less than or equal to':
                return medDose <= doseParam;
            case 'greater than':
                return medDose > doseParam;
            case 'less than':
                return medDose < doseParam;            
            case 'equals':
                    return medDose === doseParam;            
            default:
                return false;
        }
    }
    
    private static awakeningThresholdPropMatchesFilter(prop: number | undefined, filter: SleepLogFilter) {
        if (filter.parameters.length !== 2) {
            throw new Error("Unexpected number of params");
        }
    
        if (prop === undefined) {
            return false;
        }
            
        const comparisonValue = Number(filter.parameters[0]);
    
        if (Number.isNaN(comparisonValue)) {
            return false;
        }
    
        switch (filter.operator) {
            case 'greater than or equal to':
                return prop >= comparisonValue;
            case 'less than or equal to':
                return prop <= comparisonValue;
            case 'greater than':
                return prop > comparisonValue;
            case 'less than':
                return  prop < comparisonValue;           
            case 'equals':
                    return comparisonValue === prop;            
            default:
                return false;
        }
    }    

    private static medicationTakenAtThresholdPropMatchesFilter(props: DateTime[] | undefined, filter: SleepLogFilter) {
        if (filter.parameters.length !== 3) {
            throw new Error("Unexpected number of params");
        }

        switch (filter.operator) {
            case "between":
                if (props === undefined) {
                    return false;
                }
                
                // parameters[2] is the medication name
                const start = filter.parameters[0].split(':').map(t => parseInt(t));
                const end = filter.parameters[1].split(':').map(t => parseInt(t));
    
                const startInMins = 60 * start[0] + start[1];
                const endInMins = 60 * end[0] + end[1];

                let matches = false;
    
                for (const prop of props) {
                    const propTime = [prop.hour, prop.minute];
                    const propTimeInMins = 60 * propTime[0] + propTime[1];
        
                    if (startInMins <= endInMins) {
                        matches ||= (propTimeInMins >= startInMins && propTimeInMins <= endInMins);
                    }
                    else {
                        matches ||= propTimeInMins <= endInMins || propTimeInMins >= startInMins;
                    }
                }

                return matches;
                
            default:
                return false;
        }
    }
}

export class SleepLogFilterUtil {
    static convertMetricType(metric: CustomMetric): FieldType {
        switch (metric.type) {
            case "0_to_10":
            case "1_to_5":
            case "number":
                return "number";
            default:
                throw Error("Unknown metric type");
        }
    }   
}

export function isFilteredOut(sleepLog: SleepLog, filter: SimpleSleepLogFilterSet | undefined): boolean {
    if (!filter) {
        return false;
    } 

    if (filter.tag) {
        if (!sleepLog.tags) {
            return true;
        }

        let tagFound = false;

        for (const t of sleepLog.tags) {
            if (t.toLowerCase().includes(filter.tag.toLowerCase())) {
                tagFound = true;
            }
        }

        if (!tagFound) {
            return true;
        }
    }

    if (filter.feeling) {
        if (!sleepLog.feelings) {
            return true;
        }

        let feelingFound = false;

        for (const f of sleepLog.feelings) {
            if (f.feeling.toLowerCase().includes(filter.feeling.toLowerCase())) {
                feelingFound = true;
            }
        }

        if (!feelingFound) {
            return true;
        }        
    }    

    if (filter.sleepMedication) {
        if (!sleepLog.medications) {
            return true;
        }

        let medFound = false;

        for (const m of sleepLog.medications) {
            if (m.name.toLowerCase().includes(filter.sleepMedication.toLowerCase())) {
                medFound = true;
            }
        }

        if (!medFound) {
            return true;
        }
    }

    if (filter.noSleepMedication && sleepLog.medications && sleepLog.medications.length > 0) {
        return true;
    }        

    if (filter.sleepMinimum !== undefined) {
        const view = SleepLogViewFactory.createView(sleepLog);

        if (view.minutesAsleep === undefined || view.minutesAsleep === null) {
            return true;
        } 

        if (view.minutesAsleep < filter.sleepMinimum)
        {
            return true;
        }
    }

    if (filter.sleepMax !== undefined) {
        const view = SleepLogViewFactory.createView(sleepLog);

        if (view.minutesAsleep === undefined || view.minutesAsleep === null) {
            return true;
        } 
                    
        if (filter.sleepMax < view.minutesAsleep)
        {
            return true;
        }
    }    

    if (filter.minDate !== undefined) {
        if (sleepLog.date === undefined) {
            return true;
        }

        if (sleepLog.date < filter.minDate) {
            return true;
        }     
    }

    if (filter.maxDate !== undefined) {
        if (sleepLog.date === undefined) {
            return true;
        }

        if (sleepLog.date > filter.maxDate) {
            return true;
        }     
    }

    if (filter.ratingMin !== undefined) {
        if (sleepLog.rating === undefined || sleepLog.rating === null || sleepLog.rating < filter.ratingMin) {
            return true;
        }
    }

    if (filter.ratingMax !== undefined) {
        if (sleepLog.rating === undefined || sleepLog.rating === null || sleepLog.rating > filter.ratingMax) {
            return true;
        }
    }

    if (filter.notesContains) {
        if (!sleepLog.notes) {
            return true;
        }

        const lowercasedNotes = sleepLog.notes.toLowerCase();
        if (!lowercasedNotes.includes(filter.notesContains.toLowerCase())) {
            return true;
        }
    }
    
    if (filter.favorited && !sleepLog.favorited) {
        return true;
    }

    return false;
}

