import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { SimpleSleepLogFilterSet } from "../../../types/SleepLogFilter";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import { formatDateAsLocalTime } from "../../../utils/FormatUtility";
import SleepLogTimePeriodFilter from "./SleepLogTimePeriodFilter";

interface InitializeFilterOptions {
    period?: string;
}

interface Props {
    applyFilter: (filter: SimpleSleepLogFilterSet) => void;
    timeRange: TimePeriod;
    options?: InitializeFilterOptions;
}

interface TimePeriod {
    startDate: string;
    endDate: string;
}

const dateInputStyle = "";
const monthInputStyle = "btn-sm btn border border-secondary";

const SpecificDay = "specific_day";
const SpecificMonth = "specific_month";
const MonthRange = "custom_month_range";
const DateRange = "custom_date_range";

export default function SimpleSleepLogFilters({applyFilter, timeRange, options}: Props) {

    const defaultFilter = {
        noSleepMedicationChecked: false,
        sleepMedication: "",
        tag: "",
        feeling: "",
        notesContains: "",     
        sleepDuration: "any",
        rating: "any",
    };

    const [rawFilter, setRawFilter] = useState(defaultFilter);

    function handleChangeTagFilter(e: any) {
        setRawFilter({...rawFilter, tag: e.target.value});
    }

    function handleChangeFeelingFilter(e: any) {
        setRawFilter({...rawFilter, feeling: e.target.value});
    }     

    function handleNoMedicationFilterChange() {
        setRawFilter({...rawFilter, sleepMedication: "", noSleepMedicationChecked: !rawFilter.noSleepMedicationChecked});
    }

    function handleSleepMedicationFilterChange(newValue: string) {
        setRawFilter({...rawFilter, sleepMedication: newValue});
    }    

    function handleChangeNotesFilter(e: any) {
        setRawFilter({...rawFilter, notesContains: e.target.value});
    } 

    function transformRawFilter(rawFilter: any): SimpleSleepLogFilterSet {

        const [minRating, maxRating] = transformRating(rawFilter.rating);
        const [minSleep, maxSleep] = transformSleepDuration(rawFilter.sleepDuration);

        let filter: SimpleSleepLogFilterSet = {
            noSleepMedication: rawFilter.noSleepMedicationChecked ? rawFilter.noSleepMedicationChecked : undefined,
            sleepMedication: rawFilter.sleepMedication ? rawFilter.sleepMedication : undefined,
            tag: rawFilter.tag ? rawFilter.tag : undefined,
            feeling: rawFilter.feeling ? rawFilter.feeling : undefined,
            notesContains: rawFilter.notesContains ? rawFilter.notesContains : undefined,
            ratingMin: minRating,
            ratingMax: maxRating,
            sleepMinimum: minSleep,
            sleepMax: maxSleep
        };

        filter.minDate = timeRange.startDate ? timeRange.startDate : undefined;
        filter.maxDate = timeRange.endDate ? timeRange.endDate : undefined;    

        return filter;
    }

    function transformRating(ratingSelection?: string) {
        if (ratingSelection === undefined || ratingSelection === "any") {
            return [undefined, undefined];
        }

        const [ratingString, action] = ratingSelection.split("_");
        const rating = Number(ratingString);

        return [
            action === "+" ? rating : undefined,
            action === "-" ? rating : undefined
        ];
    }

    function transformSleepDuration(sleepSelection?: string) {
        if (sleepSelection === undefined || sleepSelection === "any") {
            return [undefined, undefined];
        }

        const [sleepString, action] = sleepSelection.split("_");
        const sleep = Number(sleepString) * 60; // convert to minutes

        return [
            action === "+" ? sleep : undefined,
            action === "-" ? sleep : undefined
        ];
    }    

    function handleKeyPress(event: any) {
        if (event.key === "Enter") {
            handleApplyFilters();
        }
    }    

    function handleApplyFilters() {
        const filter = transformRawFilter(rawFilter);
        applyFilter(filter);
    }

    function handleClearFilters() {

        const updatedRawFilter = {
            ...defaultFilter
        };

        setRawFilter(updatedRawFilter);

        const filter = transformRawFilter(updatedRawFilter);
        applyFilter(filter);
    }
    
    return (
        <div className="">

            <Row className="g-3 justify-content-center mb-3">                
                <Col xs="6" md="4">
                    <input id="filter-analytics-tag" className="form-control form-control-sm" type="text" placeholder="Tag"
                        value={rawFilter.tag} onChange={handleChangeTagFilter} onKeyDown={handleKeyPress}>
                    </input>
                </Col>

                <Col xs="6" md="4">
                    <input className="form-control form-control-sm" type="text" placeholder="Feeling" value={rawFilter.feeling}
                        onChange={handleChangeFeelingFilter} onKeyDown={handleKeyPress}>
                    </input>
                </Col>

                <Col xs="6" sm="4" md="3" className="d-none">
                    <input id="filter-analytics-sleep-med" className="form-control form-control-sm" type="text" placeholder="Sleep medication"
                        value={rawFilter.sleepMedication} onChange={(e: any) => handleSleepMedicationFilterChange(e.target.value)}
                        disabled={rawFilter.noSleepMedicationChecked} onKeyDown={handleKeyPress}>
                    </input>
                </Col>

                <Col xs="6" md="4" className="d-none">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="filter-analytics-no-sleep-med" checked={rawFilter.noSleepMedicationChecked}
                            onChange={handleNoMedicationFilterChange}/>
                        <label className="form-check-label" htmlFor="filter-analytics-no-sleep-med" style={{fontSize:".875rem"}}>
                                No Sleep medication
                        </label>
                    </div>
                </Col>

                <Col xs="6" md="4">
                    <select id="filter-sleep-min" className="form-select form-select-sm" value={rawFilter.sleepDuration}
                        onChange={e => setRawFilter({...rawFilter, sleepDuration: e.target.value})}>
                    <option value="any">Any sleep duration</option>
                        <option value="4_-">4 hours or fewer</option>                                            
                        <option value="5_-">5 hours or fewer</option>
                        <option value="6_-">6 hours or fewer</option>
                        <option value="7_-">7 hours or fewer</option>                             
                        <option value="5_+">5 or more hours</option>
                        <option value="6_+">6 or more hours</option>
                        <option value="7_+">7 or more hours</option>
                        <option value="8_+">8 or more hours</option>                                                                                                                                                    
                    </select>
                </Col>

                <Col xs="6" md="4">
                    <select id="filter-sleep-min" className="form-select form-select-sm" value={rawFilter.rating}
                        onChange={e => setRawFilter({...rawFilter, rating: e.target.value})}>
                    <option value="any">Any rating</option>
                    <option value="2_-">2 or less</option>                            
                    <option value="3_-">3 or less</option>                                     
                    <option value="4_-">4 or less</option>                                            
                    <option value="5_-">5 or less</option>
                    <option value="6_-">6 or less</option>                    
                    <option value="4_+">4 or more</option>                                     
                    <option value="5_+">5 or more</option>
                    <option value="6_+">6 or more</option>
                    <option value="7_+">7 or more</option>                                                                                                                                
                    </select>
                </Col>            

                <Col xs="6" md="4">
                    <div className="bg-white">
                        <Button variant="outline-primary" className="form-control" size="sm" onClick={handleApplyFilters}>
                            Apply
                        </Button>
                    </div>                 
                </Col>

                <Col xs="6" md="4">
                    <div className="bg-white">
                        <Button variant="outline-secondary" className="form-control" size="sm" onClick={handleClearFilters}>
                            Clear
                        </Button>
                    </div>
                </Col>     
                                                                 
            </Row>
    </div>
    );
}

/*
        <Col xs="2">
            <select id="filter-analytics-rating" className="form-select btn-sm" value={workingFilterState.rating} onChange={handleChangeRatingFilter}>
                <option value="any">Any rating</option>
                <option value="0">0</option>                
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>                 
                <option value="9">9</option>
                <option value="10">10</option>                                                                                                                                                                    
            </select>
        </Col>

        <Col xs="auto">
            <Button variant="outline-secondary" size="sm">
                <i className="bi bi-three-dots"></i>
            </Button>                    
        </Col>

*/