import React, { useContext, useRef } from "react";
import { LoginStateContext } from "../app/login-state.context";
import { SleepLogHttpServiceContext } from "../lib/http/sleep-log-http-service.provider";
import { TagSuggestionsService } from "./TagSuggestionsService";
import useStore from "../shared/store/useStoreService.hook";

export const TagsSuggestionsServiceContext = React.createContext<TagSuggestionsService | undefined>(undefined);

function useTagSuggestionsServiceProvider(): TagSuggestionsService {
    const [loginState] = useContext(LoginStateContext);
    const sleepLogHttpService = useContext(SleepLogHttpServiceContext)!;    

    const service = useRef<TagSuggestionsService | undefined>(undefined);
    if (!service.current) {
        service.current = new TagSuggestionsService(
            loginState.loggedIn ? loginState.username : '',
            sleepLogHttpService
        );
    }

    return service.current;
}

export function useTagSuggestionsService() {
    return useStore<TagSuggestionsService, TagSuggestionsService["state"]>(TagsSuggestionsServiceContext);
}

export function TagSuggestionServiceProvider(props: any) {
    const service = useTagSuggestionsServiceProvider();
    return (
        <TagsSuggestionsServiceContext.Provider value={service}>
            {props.children}
        </TagsSuggestionsServiceContext.Provider>
    );
}