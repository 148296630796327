import SleepLogHttpService from "../lib/http/SleepLogHttpService";
import { HttpException } from "../types/Exceptions";

export enum FlushJobState {
    None,
    Running,
    Scheduled,
    Failed
}

export interface ISleepLogUpdateFlusher {
    setUser(user: string): void;
    setLastModifiedTime(sleepLogId: string, lmt: string): void;
    push(logId: string, update: any): void;
}

export class MockSleepLogUpdateFlusher implements ISleepLogUpdateFlusher {
    setUser(user: string): void {
        throw new Error("Method not implemented.");
    }
    setLastModifiedTime(sleepLogId: string, lmt: string): void {
        throw new Error("Method not implemented.");
    }
    push(logId: string, update: any): void {
        throw new Error("Method not implemented.");
    }
    
}

export default class SleepLogUpdateFlusher implements ISleepLogUpdateFlusher {

    user: string | undefined;

    state: FlushJobState;
    updates: { [key: string]: any[]};
    private lastModifiedTimes: Record<string, string> = { };

    onJobStateChange: (state: FlushJobState, errorMessages?: string[]) => void;

    static readonly scheduleDelayInMs = 2000;

    static readonly failedSaveScheduleDelayInMs = 10000;    

    constructor(
        private sleepLogHttpService: SleepLogHttpService,
        onJobStateChange: (state: FlushJobState) => void
    ) {
        this.state = FlushJobState.None;
        this.updates = {} ;
        this.onJobStateChange = onJobStateChange;
    }

    setUser(user: string) {
        this.user = user;
    }

    public setLastModifiedTime(sleepLogId: string, lmt: string) {
        this.lastModifiedTimes[sleepLogId] = lmt;
    }

    public push(logId: string, update: any): void {

        if (!this.updates[logId]) {
            this.updates[logId] = [];
        }

        //console.log(`Pushing update for logId: ${logId}, ${JSON.stringify(update)}`);
        this.updates[logId].push(update);

        if (this.state === FlushJobState.None || this.state === FlushJobState.Failed) {
            //console.log("Scheduling save.");

            setTimeout(this.flush.bind(this), SleepLogUpdateFlusher.scheduleDelayInMs);

            this.state = FlushJobState.Scheduled;
            this.onJobStateChange(this.state);            
        }
    }

    private async flush() {
        //console.log(`Begin saving updates: ${JSON.stringify(this.updates)}`);

        if (!this.user) {
            throw new Error("Missing user.");
        }

        if (this.state !== FlushJobState.Scheduled) {
            throw new Error("flush must be run serially!");
        }

        this.state = FlushJobState.Running;
        this.onJobStateChange(this.state);        

        const localUpdates = this.updates;
        this.updates = {};

        let failedUpdates: {[key: string]: any} = {};
        let failedUpdateMessages: string[] = [];

        for (let logId in localUpdates) {
            let updateToApply = {};

            for (let updateSet of localUpdates[logId]) {
                updateToApply = {...updateToApply, ...updateSet};
            }

            //console.log(`Updating ${logId}: ${JSON.stringify(updateToApply)}`);
            let res: Response;
            
            try {
                const creationMetadata = await this.sleepLogHttpService.updateSleepLog(
                    logId,
                    updateToApply,
                    this.lastModifiedTimes[logId] ?? ''
                );

                this.setLastModifiedTime(logId, creationMetadata.lastModifiedTime);
            }
            catch (e: any) {
                if (e instanceof HttpException) {
                    if (e.statusCode === 412) {
                        failedUpdateMessages.push("The sleep log has changed. Please refresh the page to get the latest updates."); 
                    }
                    else if (e.statusCode === 404) {
                        failedUpdateMessages.push("The sleep log was not found."); 
                    }
                    else {
                        if (e.message) {
                            failedUpdateMessages.push(e.message);
                        }
                    }

                    failedUpdates[logId] = updateToApply;
                }
                else {
                    failedUpdates[logId] = updateToApply;
                    failedUpdateMessages.push("Could not connect with the server.");  
                }
            }         
        }

        if (Object.keys(failedUpdates).length > 0) {
            console.log(`Failed updates: ${JSON.stringify(failedUpdates)}`);

            for (let logId in failedUpdates) {
                if (!this.updates[logId]) {
                    this.updates[logId] = [];
                }

                this.updates[logId].unshift(failedUpdates[logId]);
            }

            this.state = FlushJobState.Failed;
            this.onJobStateChange(this.state, failedUpdateMessages);            

            // todo: reschedule updates on 5XX errors
        }

        //console.log("Update succeeded.")

        if (Object.keys(this.updates).length > 0) {

            const scheduleDelay = this.state === FlushJobState.Failed ? SleepLogUpdateFlusher.failedSaveScheduleDelayInMs : SleepLogUpdateFlusher.scheduleDelayInMs;
            setTimeout(this.flush.bind(this), scheduleDelay);

            this.state = FlushJobState.Scheduled;
            this.onJobStateChange(this.state);

            //console.log("More updates were pushed. Scheduling another update.");
        }
        else {
            //console.log("No more updates, waiting for next update.");
            this.state = FlushJobState.None;
            this.onJobStateChange(this.state);
        }
    }
}