import SleepLog from "../types/SleepLog";
import Utils from "../utils/utils";
import ISleepLogView from "./ISleepLogView";
import SleepLogView from "./SleepLogView";

export default class SleepLogViewFactory {
  static createView(sleepLog: SleepLog): ISleepLogView {
    return new SleepLogView(sleepLog);
  }

  static createViews(sleepLogs: SleepLog[]): ISleepLogView[] {
    return SleepLogView.fromSleepLogs(sleepLogs);
  }

  /**
   * Async version to prevent blocking when creating views
   * @param sleepLogs 
   * @returns 
   */
  static async createViewsAsync(sleepLogs: SleepLog[]): Promise<ISleepLogView[]> {
    const sleepLogViews: ISleepLogView[] = [];
    let i = 0;
    
    for (const sleepLog of sleepLogs) {
        const view = SleepLogViewFactory.createView(sleepLog);
        sleepLogViews.push(view);

        if (i > 0 && i % 10 === 0) {
            await Utils.delay(10);
        }

        i++;
    }

    return sleepLogViews;
  }


}