import React, { useContext } from "react";
import { useRef, useLayoutEffect } from "react";
import { AppStateContext } from "../../app/AppStateContext";
import { LoginStateContext } from "../../app/login-state.context";
import AccountHttpService from "./AccountHttpService";

export const AccountHttpServiceContext = React.createContext<AccountHttpService | undefined>(undefined);

export function useAccountHttpServiceProvider() {
    const [loginState,] = useContext(LoginStateContext);
    const [appState,] = useContext(AppStateContext);
    
    const ref = useRef<AccountHttpService | undefined>(undefined);
    if (!ref.current) {
        ref.current = new AccountHttpService(appState, loginState.loggedIn ? loginState.username : '');
    }

    const accountHttpService = ref.current;

    // useLayoutEffect to make sure this effect runs synchronously if login state changes so that
    // any callers have the correct username set.
    useLayoutEffect(() => {
        if (loginState.loggedIn) {
            accountHttpService.onUserChanged(loginState.username);
        }
        else {
            accountHttpService.onUserChanged('');
        }
    }, [loginState]);
    
    useLayoutEffect(() => {
        accountHttpService.onAppStateChanged(appState);
    }, [appState]);

    return accountHttpService;
}

export function AccountHttpServiceProvider(props: any) {
    const service = useAccountHttpServiceProvider();
    return (
        <AccountHttpServiceContext.Provider value={service}>
            {props.children}
        </AccountHttpServiceContext.Provider>
    );
}