import produce from "immer";
import { Limits } from "../constants/limits";
import { Result } from "../types/Result";
import SleepLog from "../types/SleepLog";
import { ConnectedAccountType } from "./AccountSettings";

export enum DrugType {
    Sleep,
    Other,
}

export default class SleepLogModifier {

    static addTag(sleepLog: SleepLog, tag: string): Result<SleepLog> {

        if (!tag) {
            return {succeeded: false, message: `Tag cannot be empty.`};
        }

        if (tag.length > Limits.MaxTagsLength) {
            return {succeeded: false, message: `A tag can only contain at most ${Limits.MaxTagsLength} characters.`};
        }
        
        // todo: check for valid characters

        if (sleepLog.tags) {
            if (sleepLog.tags.length >= Limits.MaxTags) {
                return {succeeded: false, message: `Only ${Limits.MaxTags} tags are allowed. Please remove a tag to add more.`};
            }

            if (sleepLog.tags.includes(tag)) {
                return {succeeded: false, message: `A tag with the same value is already present.`};
            }
        }

        let updatedTags: string[] = sleepLog.tags ? [...sleepLog.tags, tag] : [tag];

        const updatedSleepLog: SleepLog = {...sleepLog, tags: updatedTags};
        return {succeeded: true, sleepLog: updatedSleepLog};        
    }

    static deleteTag(sleepLog: SleepLog, index: number, tag: string): Result<SleepLog> {
        if (!sleepLog.tags) {
            return {succeeded: false, message: `There are no tags to remove.`};
        }

        if (index < 0 || index >= sleepLog.tags.length || sleepLog.tags[index] !== tag) {
            return {succeeded: false, message: `The tag to remove does not exist.`};            
        }

        const updatedTags = [...sleepLog.tags.slice(0, index), ...sleepLog.tags.slice(index + 1)];
        const updatedSleepLog: SleepLog = {...sleepLog, tags: updatedTags};
        
        return {succeeded: true, sleepLog: updatedSleepLog};
    }

    static moveTag(sleepLog: SleepLog, sourceIndex: number, targetIndex: number): Result<SleepLog> {
        if (sleepLog.tags === undefined) {
            return {succeeded: false, message: "Could not move tag."};
        }
            
        if (sourceIndex >= sleepLog.tags.length || targetIndex > sleepLog.tags.length) {
            return {succeeded: false, message: "Could not move tag."};            
        }

        if (sourceIndex === targetIndex) {
            return { succeeded: true, sleepLog: sleepLog};
        }

        let tags = sleepLog.tags;
        const tag = tags[sourceIndex];

        tags = produce(tags, draftTags => {
            draftTags.splice(targetIndex, 0, tag);

            if (sourceIndex < targetIndex) {
                draftTags.splice(sourceIndex, 1);
            }
            else {
                draftTags.splice(sourceIndex + 1, 1);
            }
        });

        const updatedSleepLog: SleepLog = {...sleepLog, tags };
        return {succeeded: true, sleepLog: updatedSleepLog};
    }    

    static updateTag(sleepLog: SleepLog, newTag: string, index: number, oldTag: string): Result<SleepLog> {
        if (!newTag) {
            return {succeeded: false, message: `Tag cannot be empty.`};
        }

        if (newTag.length > Limits.MaxTagsLength) {
            return {succeeded: false, message: `A tag can only contain at most ${Limits.MaxTagsLength} characters.`};
        }

        if (!sleepLog.tags || index < 0 || index >= sleepLog.tags.length || sleepLog.tags[index] !== oldTag) {
            return {succeeded: false, message: `The tag to update does not exist.`};            
        }

        const updatedTags = [...sleepLog.tags.slice(0, index), newTag, ...sleepLog.tags.slice(index + 1)];
        const updatedSleepLog: SleepLog = {...sleepLog, tags: updatedTags};

        return {succeeded: true, sleepLog: updatedSleepLog};
    }

    static addEvent(sleepLog: SleepLog, eventName: string): Result<SleepLog> {

        if (!eventName) {
            return {succeeded: false, message: `Event cannot be empty.`};
        }

        if (eventName.length > Limits.MaxTagsLength) {
            return {succeeded: false, message: `An event can only contain at most ${Limits.MaxTagsLength} characters.`};
        }
        
        // todo: check for valid characters

        if (sleepLog.events) {
            if (sleepLog.events.length >= Limits.MaxTags) {
                return {succeeded: false, message: `Only ${Limits.MaxTags} events are allowed. Please remove a tag to add more.`};
            }

            if (sleepLog.events.map(e => e.name).includes(eventName)) {
                return {succeeded: false, message: `An event with the same value is already present.`};
            }
        }

        const event = { name: eventName };

        let updatedEvents: { name: string }[] = sleepLog.events ? [...sleepLog.events, event] : [event];

        const updatedSleepLog: SleepLog = {...sleepLog, events: updatedEvents};
        return {succeeded: true, sleepLog: updatedSleepLog};        
    }

    static deleteEvent(sleepLog: SleepLog, index: number, eventName: string): Result<SleepLog> {
        if (!sleepLog.events) {
            return {succeeded: false, message: `There are no events to remove.`};
        }

        if (index < 0 || index >= sleepLog.events.length || sleepLog.events[index].name !== eventName) {
            return {succeeded: false, message: `The event to remove does not exist.`};            
        }

        const updatedEvents = [...sleepLog.events.slice(0, index), ...sleepLog.events.slice(index + 1)];
        const updatedSleepLog: SleepLog = {...sleepLog, events: updatedEvents};
        
        return {succeeded: true, sleepLog: updatedSleepLog};
    }    

    static updateRating(sleepLog: SleepLog, rating: number | undefined): Result<SleepLog> {
        if (rating !== undefined && (rating > 10 || rating < 0)) {
            return {succeeded: false, message: `Rating must be between 0 and 10.`};                 
        }

        const updatedSleepLog: SleepLog = {...sleepLog, rating: rating};
        return {succeeded: true, sleepLog: updatedSleepLog};            
    } 
    
    static updateNotes(sleepLog: SleepLog, notes: string): Result<SleepLog> {
        if (notes.length > Limits.MaxNotesLength) {
            return {succeeded: false, message: `Notes can only contain at most ${Limits.MaxNotesLength} characters.`};            
        }

        const updatedSleepLog: SleepLog = {...sleepLog, notes: notes};
        return {succeeded: true, sleepLog: updatedSleepLog};    
    }

    static updateBedtime(sleepLog: SleepLog, newBedTime: string): Result<SleepLog> {
        const updatedLog = produce(sleepLog, (draft) => {
            draft.bedtime = newBedTime;
        });

        return {succeeded: true, sleepLog: updatedLog};
    }

    static getMedicationKey(type: DrugType) {
        return type === DrugType.Sleep ? "medications" : "otherMedications";
    }

    static addDrug(sleepLog: SleepLog, type: DrugType, drugName: string): Result<SleepLog> {
        const key = SleepLogModifier.getMedicationKey(type);
        let medications = sleepLog[key];

        let updatedDrugs = [];

        updatedDrugs = medications ? [...medications, {name: drugName}] : [{name: drugName}];
        const updatedSleepLog: SleepLog = {...sleepLog, [key]: updatedDrugs};

        return {succeeded: true, sleepLog: updatedSleepLog};        
    }

    static deleteDrug(sleepLog: SleepLog, type: DrugType, index: number, drugName: string): Result<SleepLog> {
        const key = SleepLogModifier.getMedicationKey(type);
        let medications = sleepLog[key];

        if (medications === undefined) {
            return {succeeded: false, message: "There are no medications to delete."};
        }

        if (medications[index].name !== drugName) {
            return {succeeded: false, message: "Unexpected error when trying to delete medication."};
        }

        let updatedDrugs = [...medications.slice(0, index), ...medications.slice(index + 1)];

        const updatedSleepLog: SleepLog = {...sleepLog, [key]: updatedDrugs};
        return {succeeded: true, sleepLog: updatedSleepLog};
    }

    static updateDrug(sleepLog: SleepLog, type: DrugType, index: number, oldValue: string, newValue: string): Result<SleepLog> {
        const key = SleepLogModifier.getMedicationKey(type);
        let medications = sleepLog[key];

        if (medications === undefined) {
            return {succeeded: false, message: "There are no medications to update."};
        }

        if (medications[index].name !== oldValue) {
            return {succeeded: false, message: "Unexpected error when trying to update medication."};
        }

        const updatedDrug = {...medications[index], name: newValue};
        const updatedDrugs = [...medications.slice(0, index), updatedDrug, ...medications.slice(index + 1)];
        const updatedSleepLog: SleepLog = {...sleepLog, [key]: updatedDrugs};
    
        return {succeeded: true, sleepLog: updatedSleepLog};        
    }

    static updateDrugDose(sleepLog: SleepLog, type: DrugType, index: number, drugName: string, dose: string): Result<SleepLog> {
        const key = SleepLogModifier.getMedicationKey(type);
        let medications = sleepLog[key];

        if (medications === undefined) {
            return {succeeded: false, message: "There are no medications to update."};
        }

        if (medications[index].name !== drugName) {
            return {succeeded: false, message: "Unexpected error when trying to update medication."};
        }        

        const updatedDrug = {...medications[index], dose: dose};
        const updatedDrugs = [...medications.slice(0, index), updatedDrug, ...medications.slice(index + 1)];
        const updatedSleepLog: SleepLog = {...sleepLog, [key]: updatedDrugs};

        return {succeeded: true, sleepLog: updatedSleepLog};         
    }

    static changeTakenAtTime(sleepLog: SleepLog, type: DrugType, index: number, takenAt: string): Result<SleepLog> {
        const key = SleepLogModifier.getMedicationKey(type);
        let medications = sleepLog[key];

        if (medications === undefined) {
            return {succeeded: false, message: "There are no medications to update."};
        }

        const updatedDrug = {...medications[index], takenAt: takenAt};
        const updatedDrugs = [...medications.slice(0, index), updatedDrug, ...medications.slice(index + 1)];
        const updatedSleepLog: SleepLog = {...sleepLog, [key]: updatedDrugs};

        return {succeeded: true, sleepLog: updatedSleepLog};    
    }

    static addFeeling(sleepLog: SleepLog, feeling: string): Result<SleepLog> {
        if (!feeling) {
            return {succeeded: false, message: `Tag cannot be empty.`};
        }

        if (feeling.length > Limits.MaxTagsLength) {
            return {succeeded: false, message: `A tag can only contain at most ${Limits.MaxTagsLength} characters.`};
        }
        
        // todo: check for valid characters

        if (sleepLog.feelings) {
            if (sleepLog.feelings.length >= Limits.MaxTags) {
                return {succeeded: false, message: `Only ${Limits.MaxTags} feeling tags are allowed. Please remove a tag to add more.`};
            }

            if (sleepLog.feelings.find(f => f.feeling === feeling)) {
                return {succeeded: false, message: `A tag with the same value is already present.`};
            }
        }


        let updatedFeelings = [];

        updatedFeelings = sleepLog.feelings ? [...sleepLog.feelings, {feeling: feeling}] : [{feeling: feeling}];
        const updatedSleepLog: SleepLog = {...sleepLog, feelings: updatedFeelings};

        return {succeeded: true, sleepLog: updatedSleepLog};        
    }

    static deleteFeeling(sleepLog: SleepLog, index: number, feeling: string): Result<SleepLog> {
        if (sleepLog.feelings === undefined) {
            return {succeeded: false, message: "There are no feelings to delete."};
        }

        if (sleepLog.feelings[index].feeling !== feeling) {
            return {succeeded: false, message: "Unexpected error when trying to delete feeling."};
        }

        let updatedFeelings = [...sleepLog.feelings.slice(0, index), ...sleepLog.feelings.slice(index + 1)];

        const updatedSleepLog: SleepLog = {...sleepLog, feelings: updatedFeelings};
        return {succeeded: true, sleepLog: updatedSleepLog};
    }

    static moveFeelingTag(sleepLog: SleepLog, sourceIndex: number, targetIndex: number): Result<SleepLog> {
        if (sleepLog.feelings === undefined) {
            return {succeeded: false, message: "Could not move tag."};
        }
            
        if (sourceIndex >= sleepLog.feelings.length || targetIndex > sleepLog.feelings.length) {
            return {succeeded: false, message: "Could not move tag."};            
        }

        if (sourceIndex === targetIndex) {
            return { succeeded: true, sleepLog: sleepLog};
        }

        let feelings = sleepLog.feelings;
        const tag = feelings[sourceIndex];

        feelings = produce(feelings, draftFeelings => {
            draftFeelings.splice(targetIndex, 0, tag);

            if (sourceIndex < targetIndex) {
                draftFeelings.splice(sourceIndex, 1);
            }
            else {
                draftFeelings.splice(sourceIndex + 1, 1);
            }
        });

        const updatedSleepLog: SleepLog = {...sleepLog, feelings };
        return {succeeded: true, sleepLog: updatedSleepLog};
    }

    static updateFeeling(sleepLog: SleepLog, index: number, oldValue: string, newValue: string): Result<SleepLog> {
        if (sleepLog.feelings === undefined) {
            return {succeeded: false, message: "There are no feelings to update."};
        }

        if (sleepLog.feelings[index].feeling !== oldValue) {
            return {succeeded: false, message: "Unexpected error when trying to update feeling."};
        } 

        const updatedFeeling = {...sleepLog.feelings[index], feeling: newValue};
        const updatedFeelings = [...sleepLog.feelings.slice(0, index), updatedFeeling, ...sleepLog.feelings.slice(index + 1)];
        const updatedSleepLog: SleepLog = {...sleepLog, feelings: updatedFeelings};
    
        return {succeeded: true, sleepLog: updatedSleepLog};        
    }

    static setPrimaryConnectedAccount(sleepLog: SleepLog, account: ConnectedAccountType): Result<SleepLog> {
        const updatedSleepLog: SleepLog = {...sleepLog, primaryConnectedAccount: account};
        return {succeeded: true, sleepLog: updatedSleepLog};    
    }    
}