import SleepLog from "../types/SleepLog";

export default class SleepLogUtils {

  // YYYY-MM-DD
  private static dateFormat = /^\d\d\d\d-\d\d-\d\d$/;

  static isDateFormatValid(date: string): boolean {
    // todo: validate that date is a real date.
    return SleepLogUtils.dateFormat.test(date);
  }

  static getIdFromDate(date: string): string {
    if (!SleepLogUtils.isDateFormatValid(date)) {
      throw new Error("invalid date format");
    }

    return Date.parse(date).toString();
  }

  static getFormattedDateFromId(id: string): string {
    let date = new Date(Number(id));
    
    return SleepLogUtils.formatDate(date);
  }

  static formatDate(date: Date) {
    const month = date.getUTCMonth() + 1;
    const monthStr = month >= 10 ? month : ("0" + month);

    const day = date.getUTCDate();
    const dayStr = day >= 10 ? day : ("0" + day);

    const nowStr = `${date.getUTCFullYear()}-${monthStr}-${dayStr}`; 
    return nowStr;   
  }

  static averageTime(timesInMins: number[]) {
    function toRadians(angle: number) {
      return angle * (Math.PI / 180);
    }

    const minsInDay = 60 * 24;

    let xComponent = 0;
    let yComponent = 0;

    timesInMins.forEach(t => {
      const angleInDegrees = (t / minsInDay) * 360;

      xComponent += Math.cos(toRadians(angleInDegrees));
      yComponent += Math.sin(toRadians(angleInDegrees));
    });

    xComponent /= timesInMins.length;
    yComponent /= timesInMins.length;

    let proportion = (Math.atan2(yComponent, xComponent) / (2 * Math.PI));
    if (proportion < 0) {
      proportion = 1 - Math.abs(proportion);
    }

    const avg = proportion * minsInDay;

    return Math.round(avg);
  }  
}