import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import { formatDateAsLocalTime } from "../../../utils/FormatUtility";
import { produce } from "immer";
import { useImmer } from "use-immer";
import { DateTime } from "luxon";

const dateInputStyle = "form-control form-control-sm text-center px-3";
const monthInputStyle = "form-control form-control-sm text-center px-3";

const MonthToDate = "month_to_date";
const YearToDate = "year_to_date";
const SpecificDay = "specific_day";
const SpecificMonth = "specific_month";
const MonthRange = "custom_month_range";
const DateRange = "custom_date_range";
const AnyTime = "any_time";

interface Props {
  defaultPeriod?: string;
  emitTimePeriod: (startDate: string, endDate: string) => void; 
}

interface TimePeriod {
  period: string, 
  dateStart: string,
  dateEnd: string,
  monthStart: string,
  monthEnd: string,   
}

export default function SleepLogTimePeriodFilter({emitTimePeriod, defaultPeriod}: Props) {

  const [inputTimePeriod, setInputTimePeriod] = useImmer<TimePeriod>({
    period: defaultPeriod ?? "30", 
    dateStart: DateTimeUtils.calculateThresholdDate(30),
    dateEnd: formatDateAsLocalTime(new Date()),
    monthStart: formatDateAsLocalTime(new Date(), false),
    monthEnd: formatDateAsLocalTime(new Date(), false), 
  });

  function handleChangeTimePeriod(e: any) {
    setInputTimePeriod(produce((draft: TimePeriod) => {
      draft.period = e.target.value;

      if (draft.period === "specific_day") {
        draft.dateEnd = draft.dateStart;
      }
      else if (draft.period === "specific_month") {
        draft.monthEnd = draft.monthStart;
      }
    }));
  }  

  function updateDate(e: any) {
    // invalid date like Feb 30th is given as empty string
    if (!e.target.value) {
      return;
    }

    setInputTimePeriod(produce((draft: TimePeriod) => {
      draft.dateStart = e.target.value;
      draft.dateEnd = e.target.value;
    }));
  }  

  function updateStartDate(e: any) {
    // invalid date like Feb 30th is given as empty string
    if (!e.target.value) {
      return;
    }

    setInputTimePeriod(produce((draft: TimePeriod) => {
      draft.dateStart = e.target.value;
    }));
  }

  function updateEndDate(e: any) {
    // invalid date like Feb 30th is given as empty string
    if (!e.target.value) {
      return;
    }

    setInputTimePeriod(produce((draft: TimePeriod) => {
      draft.dateEnd = e.target.value;
    }));    
  }

  function updateMonth(e: any) {
    setInputTimePeriod(produce((draft: TimePeriod) => {
      draft.monthStart = e.target.value;
      draft.monthEnd = e.target.value;
    })); 
  }
  
  function updateStartMonth(e: any) {
    setInputTimePeriod(produce((draft: TimePeriod) => {
      draft.monthStart = e.target.value;
    })); 
  }

  function updateEndMonth(e: any) {
    setInputTimePeriod(produce((draft: TimePeriod) => {
      draft.monthEnd = e.target.value;
    }));    
  }
  
  useEffect(() => {
    let filter: {minDate: string, maxDate: string} = {
      minDate: "",
      maxDate: ""
    };

    const period = inputTimePeriod.period;

    if (period === DateRange || period === SpecificDay) {
      filter.minDate = inputTimePeriod.dateStart;
      filter.maxDate = inputTimePeriod.dateEnd;  
    }
    else if (period === MonthRange || period === SpecificMonth) {
      // Add the day to the end, since format of month input is YYYY-MM
      filter.minDate = inputTimePeriod.monthStart + "-01";
      const endDate = DateTime.fromISO(inputTimePeriod.monthEnd + "-01").plus({ months: 1 }).plus({ days: -1 });
      const end = DateTimeUtils.format(endDate, "YYYY-MM-DD");
      filter.maxDate = end;
    }
    else if (period === AnyTime) {
        filter.minDate = "";
        filter.maxDate = "";
    }
    else if (period === MonthToDate) {
        const now = DateTime.now();
        filter.minDate = now.startOf("month").toISODate();
        filter.maxDate = now.toISODate();
    }
    else if (period === YearToDate) {
        const now = DateTime.now();
        filter.minDate = now.startOf("year").toISODate();
        filter.maxDate = now.toISODate();
    }
    else {
      const period = Number(inputTimePeriod.period);
      filter.minDate = DateTimeUtils.calculateThresholdDate(period);
      filter.maxDate = formatDateAsLocalTime(new Date());            
    }

    emitTimePeriod(filter.minDate, filter.maxDate);

  }, [inputTimePeriod]);

  return (
    <>
      <Row className="g-3 justify-content-center mb-3">
        <Col xs="auto">
          <select className="form-select form-select-sm text-center" value={inputTimePeriod.period} onChange={handleChangeTimePeriod}>
            <option value="14">Last 14 days</option>
            <option value="30">Last 30 days</option>
            <option value="60">Last 60 days</option>  
            <option value="90">Last 90 days</option>
            <option value="180">Last 180 days</option>      
            <option value={MonthToDate}>Month to date</option>
            <option value={YearToDate}>Year to date</option>            
            <option value={SpecificDay}>Specific day</option>
            <option value={SpecificMonth}>Specific month</option>
            <option value={MonthRange}>Custom month range</option>                                  
            <option value={DateRange}>Custom date range</option>
            <option value={AnyTime}>Any time</option>                                                                         
          </select>
        </Col>
      </Row>
      <Row className="g-3 justify-content-center mb-3">
        {inputTimePeriod.period === SpecificDay &&
          <React.Fragment>
            <Col xs="auto">
              <input type="date" 
                value={inputTimePeriod.dateStart}
                min="2010-01-01"
                max="2030-12-31"
                className={dateInputStyle}
                onChange={updateDate}
              >
              </input>
            </Col>                      
          </React.Fragment>
        }        

        {inputTimePeriod.period === SpecificMonth &&
          <React.Fragment>
            <Col xs="auto">
              <input type="month" 
                value={inputTimePeriod.monthStart}
                min="2010-01-01"
                max="2030-12-31" 
                className={monthInputStyle} 
                onChange={updateMonth}>
                </input>
            </Col>                        
          </React.Fragment>
        }           

        {inputTimePeriod.period === MonthRange &&
          <React.Fragment>
            <Col xs="auto">
              <input type="month"
                value={inputTimePeriod.monthStart}
                min="2010-01-01"
                max="2030-12-31"
                className={monthInputStyle}
                onChange={updateStartMonth}>
              </input>
            </Col>
            <Col xs="auto" className="align-self-center text-sm">
                to
            </Col>
            <Col xs="auto">
              <input type="month"
                name="add-log-date" 
                value={inputTimePeriod.monthEnd}
                min="2010-01-01"
                max="2030-12-31"
                className={monthInputStyle}
                onChange={updateEndMonth}>
              </input>
            </Col>                         
          </React.Fragment>
        }          

        {inputTimePeriod.period === DateRange &&
          <>
            <Col xs="auto">
              <input type="date" 
                value={inputTimePeriod.dateStart}
                min="2010-01-01"
                max="2030-12-31"
                className={dateInputStyle} 
                onChange={updateStartDate}>
              </input>
            </Col>
            <Col xs="auto" className="align-self-center text-sm">
                to
            </Col>
            <Col xs="auto">
              <input type="date"
                id="filter-date-end"
                value={inputTimePeriod.dateEnd}
                min="2010-01-01"
                max="2030-12-31"
                className={dateInputStyle}
                onChange={updateEndDate}>
                </input>
            </Col>                         
          </>
        }
      </Row>
    </>            
  );
}