import React, { useState, useEffect, useRef, useMemo } from "react";

import { ChartArea, ChartData, ChartDataset, ChartOptions} from "chart.js";
import 'chartjs-adapter-luxon';
import { Line } from "react-chartjs-2";
import ChartDeferred from 'chartjs-plugin-deferred';
import { DateTime } from "luxon";
import _  from "lodash";
import TimeAggregationButtonGroup from "../../../library/TimeAggregationButtonGroup";
import { Aggregation } from "../../../../types/types";
import { useImmer } from "use-immer";
import AnalyticsUtils from "../../../../utils/analytics-utils";
import { ChartStyles } from "../constants/chart-styles";

interface Point {
    date: string;
    value: number;
}

interface Props {
    valueGroups: Point[][];
    showLines?: boolean;
    showLegend?: boolean;
    colorRows: boolean;
    enableZoom?: boolean;
}

const opacity = .5;
const filteredOpacity = .25;

const colors = [
    'rgba(255, 99, 132, X)',
    'rgba(250, 140, 22, X)',
    'rgba(255, 197, 61, X)',
    'rgba(250, 219, 20, X)',
    'rgba(115, 209, 61, X)',
];

function computeColor(sleep: number, opacity: number) {
    if (sleep >= 7 * 60) {
        return colors[4].replace('X', opacity.toString());
    }
    else if (sleep >= 6.5 * 60) {
        return colors[3].replace('X', opacity.toString());
    }
    else if (sleep >= 6 * 60) {
        return colors[2].replace('X', opacity.toString());
    }
    else if (sleep >= 5 * 60) {
        return colors[1].replace('X', opacity.toString());
    }
    else {
        return colors[0].replace('X', opacity.toString());
    }
}

const primaryColor = (opacity: number) => `rgba(153, 102, 255, ${opacity})`;

const getColor = (sleep: number) => {
    let opacity: number;

    if (sleep >= 7 * 60) {
       opacity = 0.9;
    }
    else if (sleep >= 6.5 * 60) {
        opacity = .8;
    }
    else if (sleep >= 6 * 60) {
        opacity = .7;
    }
    else if (sleep >= 5 * 60) {
        opacity = .5
    }
    else {
        opacity = .4;
    }

    return primaryColor(opacity);
}

const getFilteredColor = () => primaryColor(.075); 

export default function CustomMetricsChart({
    valueGroups,
    showLines,
    showLegend,
    colorRows, 
    enableZoom, 
}: Props) {
    const [data, setData] = useState<ChartData<"line", number[], string>>({
        labels: [],
        datasets: []
    });

    const [timePeriod, setTimePeriod] = useImmer<Aggregation>("day");

    const options: ChartOptions<"line"> = useMemo(() => {

        const unit = (() => {
            switch (timePeriod) {
                case 'day':
                    return 'day'
                case 'week':
                    return 'week';
                case 'month':
                    return 'month';
                default: 
                    return 'day'
            }
        })();        

        return {
            plugins: {            
                legend: {
                    display: showLegend ?? true
                },
                deferred: {
                },
                title: {
                    display: false
                },

                tooltip: {
                    callbacks: {
                        label: function(context) {
                            return context.raw !== undefined ? `${(context.raw as number).toFixed(3)}` : "";
                        }
                    }
                },
                zoom: {
                    pan: {
                        enabled: enableZoom,
                        mode: 'x',
                    },                
                    zoom: {
                        wheel: {
                            enabled: enableZoom,
                        },
                        pinch: {
                            enabled: enableZoom
                        },
                        mode: 'x',
                    }
                },    
            },        
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: unit,
                        displayFormats: {
                            day: 'M/d',
                            week: "M/d'-'",                          
                        },
                        tooltipFormat: "MM/dd/y"
                    },   
                    adapters: {
                        date: {
                            zone: "UTC"
                        }
                    },             
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            ...ChartStyles.axisFont
                        }
                    }
                },
                y: {        
                    beginAtZero: true,       
                    ticks: {
                            stepSize: 1,
                            font: {
                                ...ChartStyles.axisFont
                            }
                    },
                    grid: {
                        ...ChartStyles.gridYAxis,
                        display: showLines ?? true,
                    },
                    position: 'right'
                }         
            },
        };
    }, [enableZoom, showLines, showLegend, timePeriod]);

    useEffect(() => {
        if (timePeriod === 'week' || timePeriod === 'month') {
            const data = getTimePeriodAggregationData(valueGroups, timePeriod);
            setData(data);
        }
        else {
            const data = getCustomValueData(valueGroups, colorRows);
            setData(data);
        }

    }, [valueGroups[0], valueGroups[1], colorRows, timePeriod]);

    const average = useMemo(()=> {
        if (valueGroups.length === 0 || valueGroups[0].length === 0) {
            return undefined;
        }

        return valueGroups[0].map(v => v.value).filter(v => v != null).reduce((prev, cur) => prev + cur, 0) / valueGroups[0].length;
    }, [valueGroups[0]]);

    const aggregations: Aggregation[] = useMemo(() => ["day", "week", "month"], []);

    return (
        <>
            <div>        
                <Line
                    data={data}
                    options={options}
                    plugins={[ChartDeferred]}
                />     
            </div>

            <div className="mt-2 text-center">
                <TimeAggregationButtonGroup
                    aggregations={aggregations}
                    idPrefix="custom-value"
                    selected={timePeriod}
                    onSelect={(selected) => setTimePeriod(selected)} 
                />
            </div>

            <div className="mt-2 text-xs">
                Average: {average?.toFixed(1)}
            </div>
        </>
    ); 
}

function mapColor(color: 'red' | 'darkOrange' | 'orange' | 'yellow' | 'lightYellow' | 'green' | 'darkGreen') {
    const colors = {
        red: 'rgba(245, 34, 45, X)',
        darkOrange: 'rgba(250, 140, 22, X)',
        orange: 'rgba(255, 192, 105, X)',
        yellow: 'rgba(250, 219, 20, X)',
        lightYellow: 'rgba(250, 219, 20, X)',
        green: 'rgba(149, 222, 100, X)',
        darkGreen: 'rgba(56, 158, 13, X)',
    };

    const opacity = 1;
    return colors[color].replace('X', opacity.toString());
}

function getGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea, yMax: number) {
    let width, height, gradient;

    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === undefined || gradient === null || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        
        gradient.addColorStop(0, mapColor('red'));
        gradient.addColorStop(Math.min(1, 4.5 / yMax), mapColor('red'));

        gradient.addColorStop(Math.min(1, 5 / yMax), mapColor('darkOrange'));
        gradient.addColorStop(Math.min(1, 5.5 / yMax), mapColor('darkOrange'));

        gradient.addColorStop(Math.min(1, 6 / yMax), mapColor('orange'));
        gradient.addColorStop(Math.min(1, 6.25 / yMax), mapColor('orange'));

        gradient.addColorStop(Math.min(1, 6.5 / yMax), mapColor('yellow'));
        gradient.addColorStop(Math.min(1, 6.75 / yMax), mapColor('lightYellow'));
        gradient.addColorStop(Math.min(1, 6.9 / yMax), mapColor('lightYellow'));
        
        gradient.addColorStop(Math.min(1, 7.2 / yMax), mapColor('green'));
        
        gradient.addColorStop(Math.min(1, 8 / yMax), mapColor('darkGreen')); 
    }
    
  return gradient;
}

function getPrimaryColor(opacity: number) {
    return `rgba(99, 102, 241, ${opacity})`;
}

function getCustomValueData(valueGroups: Point[][], colorRows: boolean) {
    const hasFilter = valueGroups.length > 1;
    const allValues = valueGroups.map((values, i) => values.map(value => ({...value, matchesFilter: i === 0}))).flat().sort((lhs, rhs) => lhs.date <= rhs.date ? -1 : 1);

    const dataset: ChartDataset<"line", number[]> = {
        label: '',
        data: allValues.map(v => v.value),
        borderColor: function(context) {
            const calculatedOpacity = hasFilter ? filteredOpacity : opacity;
            if (!colorRows) {
                return getPrimaryColor(calculatedOpacity);
            }

            const chart = context.chart;

            const yMax = chart.scales.y.max;
            const {ctx, chartArea} = chart;
    
            if (!chartArea) {
                // This case happens on initial chart load
                return undefined;
            }
            return getGradient(ctx, chartArea, yMax);
        },
        borderJoinStyle: "bevel",
        pointRadius: allValues.map(value => hasFilter ? (value.matchesFilter ? 3 : 0) : 3),
        pointBorderColor: getPrimaryColor(.75),
        pointBackgroundColor: getPrimaryColor(1),
        pointBorderWidth: 0,
        borderWidth: 3,
        fill: {
            target: 'origin',
            above: getPrimaryColor(.05),
        },
        tension: .2
    };       
    
    const data: ChartData<"line", number[], string> = {
        labels: allValues.map(v => v.date),
        datasets: [dataset]
    };

    return data;
}

function getTimePeriodAggregationData(valueGroups: Point[][], period: 'week' | 'month') {
    const dataset: ChartDataset<"line", number[]> = {
        label: '',
        data: [],
        borderColor: getPrimaryColor(opacity),
        borderJoinStyle: "bevel",
        pointRadius: 3,
        pointBorderColor: getPrimaryColor(.75),
        pointBackgroundColor: getPrimaryColor(1),
        pointBorderWidth: 0,
        borderWidth: 3,
        fill: {
            target: 'origin',
            above: getPrimaryColor(.05),
        },
        tension: .2
    };

    const data: ChartData<"line", number[], string> = {
        labels: [],
        datasets: [dataset]
    };


    if (valueGroups.length === 0) {
        return data;
    }

    let totalLogs = valueGroups.map(logs => logs.length).reduce((prev, cur) => prev + cur, 0);

    if (totalLogs === 0) {
        return data;
    }

    let start = "9999-12-31";
    let end = "";

    for (const values of valueGroups) {
        if (values.length > 0) {
            const firstDate = values[0].date;
            const lastDate = values[values.length - 1].date;

            start = firstDate < start ? firstDate : start;
            end = lastDate > end ? lastDate : end;
        }
    };

    const dateToDataMap = Object.fromEntries(valueGroups[0].map(value => [value.date, value.value]));  
    const dateRange = AnalyticsUtils.fillDateRange(start, end);
    const values: (number | undefined)[] = dateRange.map(date => dateToDataMap[date]);

    const periodLength = (() => {
        switch (period) {
            case 'week':
                return 7;
            case 'month':
                return 30;
            default:
                return Number.MAX_SAFE_INTEGER;
        }
    })();

    const { dates, values: aggregatedValues } = AnalyticsUtils.aggregation(
        dateRange,
        values,
        period,
        1
    );

    for (const [date, value] of _.zip(dates, aggregatedValues)) {
        data.labels!.push(date!);
        dataset.data.push(value as number);
    }

    return data;    
}