import React, { useEffect, useState } from "react";
import SleepLog from "../../../../types/SleepLog";
import SortedTagCountChart, { SortedTags } from "./SortedTagCountChart";
import SortedTagsChartV2 from "./SortedTagsChartV2";

interface Props {
  sleepLogs: SleepLog[];
  limit?: number;
  showTitle?: boolean;
}

const maxLimit = 50;

export default function TagCountChart(props: Props) {

  const {sleepLogs} = props;

  const [tagCountData, setTagCountData] = useState<SortedTags>([]); 

  function calculateTagCount(sleepLogs: SleepLog[]) {
    let tagsCount: Record<string, number> = {};      

    sleepLogs.forEach(sleepLog => {
      if (sleepLog.tags) {
        sleepLog.tags.forEach(tag => {
          if (!(tag in tagsCount)) {
            tagsCount[tag] = 0;
          }

          tagsCount[tag]++;
        });
      }        
    });

    const mostCommonTags: SortedTags = Object.keys(tagsCount).map(t => {return {name: t, count: tagsCount[t]}; })
      .sort((lhs: any, rhs: any) => rhs.count - lhs.count);   

    return mostCommonTags;
  }

  const barColor = '#818cf8';
  const bgColor = "#e0e7ff";

  useEffect(() => {
    let tagCounts: any;
    tagCounts = calculateTagCount(sleepLogs); 

    setTagCountData(tagCounts);

  }, [sleepLogs]);

  return (
    <SortedTagsChartV2 
      chartTitle={props.showTitle ? "Tags" : undefined} 
      sortedTags={tagCountData}
      numDays={sleepLogs.length}
      progressBarColor={barColor}
      backgroundColor={bgColor}
      limit={props.limit} />
  );
}