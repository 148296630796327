import { ChartOptions } from "chart.js";
import { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartDeferred from 'chartjs-plugin-deferred';
import _ from "lodash";
import { ChartStyles } from "../constants/chart-styles";

interface Props {
    values: { date: string, value: number }[];
}

function getPrimaryColor(opacity: number) {
    return `rgba(99, 102, 241, ${opacity})`;
}

const bgColor = getPrimaryColor(.75);
const borderColor = getPrimaryColor(.75);

export default function CustomMetricsBreakdownChart({ values }: Props) {

    const data = useMemo(() => {
        return getData(values, bgColor, borderColor);
    }, [values]);

  const options: ChartOptions<"bar"> = {
    plugins: {
        datalabels: {
            anchor: "end",
            align: "top",
            color: "grey",
            font: {
            size: 10
            },
            formatter: function(value, context) {
            const percent = value as number;
            return percent >= 5 ? `${value.toFixed(0)}%` : "";
            }
        },      
        tooltip: {
            callbacks: {
                label: function(context) {
                    const percent = (context.raw ?? 0) as number;
                    const numDays = Math.round((percent / 100) * values.length).toFixed(0);
                    return `${numDays} days (${percent.toFixed(0)}%)`;            
                }
            }
        },
        legend: {
            display: false
        },
        title: {
            display: false
        },      
    },    
    scales: {
        x: {
            grid: {
                color: "rgba(0, 0, 0, 0)",
            },
            stacked: true,
            ticks: {
                font: {
                    ...ChartStyles.axisFont
                },
            }
        },
        y: {
            beginAtZero: true,
            ticks: {
            callback: function(value, index) {
                let numDays = value as number;
                return `${numDays}%`;
            },             
            stepSize: 1,
            font: {
                ...ChartStyles.axisFont
            },
            maxTicksLimit: 10
            },
            grid: {
                ...ChartStyles.gridYAxis,
        },        
            stacked: true,
        }      
    },  
  };

  return (
    <Bar data={data} plugins={[ChartDataLabels, ChartDeferred]} options={options} />
  );
}

function getData(values: { date: string, value: number}[], bgColor: string, borderColor: string) {

    let dataset = {
        label: "",
        data: [],
        backgroundColor: bgColor,
        borderColor: borderColor,
        borderWidth: 1,
        maxBarThickness: 100      
    };

    let breakdown: any = {
      labels: [],
      datasets: [dataset],
    };

    let minValue = Number.POSITIVE_INFINITY;
    let maxValue = Number.NEGATIVE_INFINITY;

    const labels = _.uniq(values.map(v => v.value)).sort();
    breakdown.labels = labels;

    const valueMap = new Map(labels.map((label, i) => [label, i]));

    breakdown.datasets[0].data = new Array<number>(labels.length).fill(0);
    const buckets = breakdown.datasets[0].data;

    for (const v of values) {
        buckets[valueMap.get(v.value)!]++;
    }

    for (let i = 0; i < buckets.length; ++i) {
        buckets[i] = (buckets[i] / values.length) * 100;
    }

    return breakdown;
  }