import React, { useLayoutEffect } from "react";
import { useContext, useRef } from "react";
import { AppStateContext } from "../../app/AppStateContext";
import { LoginStateContext } from "../../app/login-state.context";
import SleepLogHttpService from "./SleepLogHttpService";

export const SleepLogHttpServiceContext = React.createContext<SleepLogHttpService | undefined>(undefined);

export function useSleepLogHttpServiceProvider() {
    const [loginState] = useContext(LoginStateContext);
    const [appState] = useContext(AppStateContext);

    const sleepLogHttpServiceRef = useRef<SleepLogHttpService | undefined>(undefined);
    if (!sleepLogHttpServiceRef.current) {
        sleepLogHttpServiceRef.current = new SleepLogHttpService(appState, loginState.loggedIn ? loginState.username : '');
    }

    const sleepLogHttpService = sleepLogHttpServiceRef.current;

    // useLayoutEffect to make sure this effect runs synchronously if login state changes so that
    // any callers have the correct username set.
    useLayoutEffect(() => {
        if (loginState.loggedIn) {
            sleepLogHttpService.onUserChanged(loginState.username);
        }
        else {
            sleepLogHttpService.onUserChanged('');
        }
    }, [loginState]);

    useLayoutEffect(() => {
        sleepLogHttpService.onAppStateChanged(appState);
    }, [appState]);

    return sleepLogHttpService;
}

export function SleepLogHttpServiceProvider(props: any) {
    const service = useSleepLogHttpServiceProvider();

    return (
        <SleepLogHttpServiceContext.Provider value={service}>
            {props.children}
        </SleepLogHttpServiceContext.Provider>
    );
}