import React, { useMemo } from "react";
import FitbitAdapter from "../../../adapters/FitbitAdapter";
import { FitbitSleepData } from "../../../types/FitbitData";
import { SleepEpisode } from "../../../types/SleepLog";
import { FormatUtils } from "../../../utils/FormatUtility";
import TimeDuration from "../common/TimeDuration";
import SleepStagesChart from "../SleepStagesChart";

interface Props {
  data: FitbitSleepData;
}

export default function FitbitSleepCard({ data }: Props) {

  const episode = useMemo(() => new FitbitAdapter([data]).getSleepSummary(0, true /* inferMainSleep */)?.episodes[0], [data]) as SleepEpisode;

  const barStyle = {height: "1.25rem"};

  return (
    <div className="card contrast-shadow-sm">
      <div className="card-body">                
        <div className="card-title text-center">{FormatUtils.formatSleepRange(episode.start, episode.end)}</div>
          <div className="mt-1">
          <SleepStagesChart 
            episode={episode}
            onSelect={() => 0}
          />
          </div> 
        <div className="mt-3">
          <TimeDuration duration={data.minutesAsleep}/>
          <span className="text-muted"> asleep</span>
        </div>
        <div className="mt-1">
          <TimeDuration duration={data.timeInBed}/>
          <span className="text-muted"> in bed</span>
        </div>                   
        <div className="mt-2">
          <div className="progress w-75 bg-white" style={barStyle}>
            <div className="progress-bar" role="progressbar"
              style={{width: (100 * (data.minutesAwake / (data.minutesAwake + data.minutesAsleep))).toString() + "%",
                backgroundColor: '#e35d6a'}}>
            </div>
            <div className="ms-1 text-xs">
              <TimeDuration duration={data.minutesAwake}/>
              <span className="text-muted"> awake { data.type === 'classic' ? "or restless" : ""}</span>
            </div>               
          </div>
        </div>
        {data.type === 'stages' &&                   
        <div className="mt-1">
          <div className="progress w-75 bg-white" style={barStyle}>
            <div className="progress-bar" role="progressbar"
              style={{width: (100 * (data.levels.summary.light.minutes / (data.minutesAwake + data.minutesAsleep))).toString() + "%",
                backgroundColor: '#6ea8fe'}}>
            </div>
            <div className="ms-1 text-xs">
              <TimeDuration duration={data.levels.summary.light.minutes}/>
              <span className="text-muted"> light</span>
            </div>           
          </div>
          <div className="progress w-75 bg-white mt-1" style={barStyle}>
            <div className="progress-bar" role="progressbar"
              style={{width: (100 * (data.levels.summary.rem.minutes / (data.minutesAwake + data.minutesAsleep))).toString() + "%",
                backgroundColor: '#cfe2ff'}}>
            </div>
            <div className="ms-1 text-xs">
              <TimeDuration duration={data.levels.summary.rem.minutes}/>
              <span className="text-muted"> rem</span>
            </div>               
          </div>
          <div className="progress w-75 bg-white mt-1" style={barStyle}>
            <div className="progress-bar" role="progressbar"
              style={{width: (100 * (data.levels.summary.deep.minutes / (data.minutesAwake + data.minutesAsleep))).toString() + "%",
                backgroundColor: '#0a58ca'}}>
            </div>
            <div className="ms-1 text-xs">
              <TimeDuration duration={data.levels.summary.deep.minutes}/>
              <span className="text-muted"> deep</span>
            </div>               
          </div>                                                                             
        </div>
        }
      </div>
    </div>                                              
  ); 
}