import React, { useEffect, useState } from "react";
import ISleepLogView from "../../../../types/ISleepLogView";
import SleepLog from "../../../../types/SleepLog";
import SortedTagCountChart, { SortedTags } from "../taglike/SortedTagCountChart";
import SortedTagsChartV2 from "../taglike/SortedTagsChartV2";

interface Props {
  sleepLogs: ISleepLogView[];
  useSleepMedications: boolean;

  limit?: number;
}

const maxLimit = 50;

export default function SleepMedCountChart({sleepLogs, useSleepMedications, limit}: Props) {

  const [tagCountData, setTagCountData] = useState<SortedTags>([]); 

  const sleepBarColor = '#0ea5e9';
  const sleepBgColor = '#e0f2fe';

  const otherMedBarColor = 'rgba(255, 169, 64, 1)';
  const otherMedBgColor = 'rgba(255, 169, 64, .2)';

  const barColor = useSleepMedications ? sleepBarColor : otherMedBarColor;
  const bgColor = useSleepMedications ? sleepBgColor : otherMedBgColor;

  const noMed = "No medication";

  useEffect(() => {

    let tagCounts: any = calculateTagCount(sleepLogs);
    setTagCountData(tagCounts);

    function calculateTagCount(sleepLogs: ISleepLogView[]) {
      let medicationCount: Record<string, number> = {};
      medicationCount[noMed] = 0;
  
      sleepLogs.forEach(sleepLog => {
        const medications = useSleepMedications ? sleepLog.baseSleepLog.medications : sleepLog.baseSleepLog.otherMedications;

        if (medications && medications.length > 0) {
  
          const medSet = new Set<string>();
  
          medications.forEach(m => {
  
            if (!(m.name in medicationCount)) {
              medicationCount[m.name] = 0;
            }
  
            if (!medSet.has(m.name)) {
              medicationCount[m.name]++;
            }
  
            medSet.add(m.name);          
          });
        }
        else {
          medicationCount[noMed]++;
        } 
      });
  
      const mostCommonTags: SortedTags = Object.keys(medicationCount).map(t => {return {name: t, count: medicationCount[t]}; })
        .sort((lhs: any, rhs: any) => rhs.count - lhs.count);   
  
      return mostCommonTags;
    }    

  }, [sleepLogs]);

  return (
    <SortedTagsChartV2 
      chartTitle={undefined}
      sortedTags={tagCountData}
      numDays={sleepLogs.length}
      limit={limit}
      progressBarColor={barColor}
      backgroundColor={bgColor}
    />
  );
}