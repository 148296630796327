import { DateTime } from "luxon";
import { Alert } from "react-bootstrap";
import { ConnectedAccountState, ConnectedAccountType } from "../../types/AccountSettings";
import { FormatUtils } from "../../utils/FormatUtility";

interface Props {
    device: ConnectedAccountState;
    isPrimary: boolean;

    disconnect: () => void;
    sync: () => void;
    setAsPrimary: () => void;
    reconnect: () => void;
}

export default function ConnectedDevice({ device, isPrimary, disconnect, sync, setAsPrimary, reconnect }: Props) {

    return (
        <div>
            <div className="">
                <span className="fs-5 fw-medium">
                    {device.type.charAt(0).toUpperCase() + device.type.slice(1)}
                </span>
            </div>

            <div className="mt-2">
                { isPrimary ? 
                    <button className="btn btn-sm btn-dark text-xs" disabled={true}>
                        <i className="bi bi-award-fill"></i> Primary device
                    </button>                            
                    :
                    <button className="btn btn-sm btn-outline-dark text-xs" onClick={() => setAsPrimary()}>
                        Set as primary device
                    </button>
                }
            </div>

            <div className='mt-3'></div>  

            <div className="text-sm text-secondary">
                Last refreshed: {device.lastModifiedTime ? DateTime.fromISO(device.lastModifiedTime).toLocaleString(DateTime.DATETIME_SHORT) : "―"}
            </div>

            <div className="text-sm text-secondary">
                Last synced record: {device.lastSyncedRecord ? FormatUtils.formatDate(device.lastSyncedRecord) : "―"}
            </div>                              

            <div className="mt-4"></div>

            <div className="d-flex justify-content-center" style={{gap: "1.5rem"}}>
                <button className="btn btn-sm btn-outline-primary text-xs" disabled={device.status === "InProgress"} onClick={() => sync()}>
                    { device.status === "InProgress" ?
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        :
                        <i className="bi bi-arrow-clockwise"></i>
                    }
                    <span> Sync</span>
                </button>

                <button className="btn btn-sm btn-outline-danger text-xs" onClick={() => disconnect()}>
                    Disconnect
                </button>
            </div>

            { device.status === "Failed" &&
                <>
                <Alert className="mt-3 mb-0 py-1 text-sm" variant={"danger"}>
                    <i className="bi bi-exclamation-octagon-fill"></i>
                    <span className="ms-3">
                       Error syncing data.
                    </span>
                    <div className="mt-2"></div>
                    <span>{device?.infoMessage}</span>
                    <div className="mt-2 text-xs">Reconnecting your device may fix this problem.</div>
                    <div className="mt-2 text-center">
                        <button className="btn btn-danger btn-sm" onClick={reconnect}>
                            Reconnect
                        </button>
                    </div>
                </Alert>
                </>  
            }
        </div>
    );
}