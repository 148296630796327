import { TailwindColors } from "../../../../constants/tailwindColors"

export class ChartStyles {
    static readonly gridYAxis = {
        display: true,
        color: TailwindColors["neutral-200"],
        borderDash: [4, 4],
        lineWidth: 1
    }

    static readonly axisFont = {
        size: 10,
        family: "Segoe UI"
    }
}