import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { SleepEpisode } from "../../types/SleepLog";
import { FormatUtils } from "../../utils/FormatUtility";
import TimeDuration from "./common/TimeDuration";
import SleepStagesChart from "./SleepStagesChart";

interface Props {
    episode: SleepEpisode;
    source?: string;
}

export default function SleepStagesCard({ episode, source }: Props) {

    const [start, end] = useMemo(() => {
        const start = DateTime.fromISO(episode.start, { zone: 'UTC' }).setZone(episode.timezone ?? 'UTC');
        const end = DateTime.fromISO(episode.end, { zone: 'UTC' }).setZone(episode.timezone ?? 'UTC');
        return [start, end];
    }, [episode]);

    return (
        <div className="card contrast-shadow-sm">
            <div className="card-body">                                
                <div className="card-title text-center">{FormatUtils.formatSleepRangeFromDateTime(start, end)}</div>
                    <div className="mt-1">
                    <SleepStagesChart 
                        episode={episode}
                        onSelect={() => 0}
                    />
                    </div> 
                <div className="mt-3">
                    <TimeDuration duration={episode.minutesAsleep}/>
                    <span className="text-muted"> asleep</span>
                </div>
                <div className="mt-1">
                    <TimeDuration duration={episode.minutesAsleep + episode.minutesAwake}/>
                    <span className="text-muted"> in bed</span>
                </div>                                     
                <div className="mt-2">
                    <div className="progress w-75 bg-white" style={{height: "1.25rem"}}>
                        <div className="progress-bar" role="progressbar"
                            style={{width: (100 * (episode.minutesAwake / (episode.minutesAwake + episode.minutesAsleep))).toString() + "%",
                                backgroundColor: '#e35d6a'}}>
                        </div>
                        <div className="ms-1 text-xs">
                            <TimeDuration duration={episode.minutesAwake}/>
                            <span className="text-muted"> awake</span>
                        </div>                             
                    </div>
                </div>
                { source && 
                    <div className="mt-2 text-secondary text-xs">
                        Source: {source}
                    </div>
                }
            </div>
        </div>                                                                                            
    ); 
}