import { DateTime } from "luxon";
import { SleepEpisode, SleepStage } from "../types/SleepLog";

export default class SleepStagesUtils {

    static readonly DEFAULT_COMBINE_THRESHOLD = 3;

    //
    // Takes a list of sleep records for a given day and tries to combine all ones near the one marked main sleep
    //
    static CoalesceMainSleep(episodes: SleepEpisode[], combineThresholdInHours: number, includeFirstAndLastAwakenings: boolean) {
        if (episodes.length === 0) {
            return { };
        }

        if (episodes.length === 1) {
            let start = DateTime.fromISO(episodes[0].start);
            let end = DateTime.fromISO(episodes[0].end);
            const mainEpisodes = [episodes[0]];
            
            const bedtime = start;
            const firstStage = mainEpisodes[0].sleepStages[0];

            if (!includeFirstAndLastAwakenings) {
                if (firstStage.type === "awake") {
                    start = start.plus({ seconds: firstStage.seconds })
                }
    
                const lastEpisode = mainEpisodes[mainEpisodes.length - 1];
                const lastStage = lastEpisode.sleepStages[lastEpisode.sleepStages.length - 1]
                if (lastStage.type === "awake") {
                    end = end.minus({ seconds: lastStage.seconds});
                }
            }

            return {
                start,
                end,
                bedtime,
                mainEpisodes: mainEpisodes,
                timeToFallAsleepInMins: firstStage.seconds / 60
            };
        }

        episodes = episodes.slice(); // copy to prevent modification
        episodes.sort((lhs, rhs) =>    new Date(lhs.start).getTime() - new Date(rhs.start).getTime());

        const mainSleepCount = episodes.filter(episode => episode.isMainSleep).length;

        let mainIndex = -1;

        if (mainSleepCount !== 1) {
            // Shouldn't happen ideally, but if so, choose the longest one
            let maxTimeInBed = -1;

            for (let i = 0; i < episodes.length; i++) {
                const timeInBed = episodes[i].minutesAsleep + episodes[i].minutesAwake;

                if (timeInBed > maxTimeInBed) {
                    mainIndex = i;
                    maxTimeInBed = timeInBed;
                }
            }
        }
        else {
            mainIndex = episodes.findIndex(data => data.isMainSleep);
        }

        const mainEpisodes = [episodes[mainIndex]];
        let start = DateTime.fromISO(episodes[mainIndex].start);
        let end = DateTime.fromISO(episodes[mainIndex].end);

        let i = mainIndex + 1;

        while (i < episodes.length && DateTime.fromISO(episodes[i].start).diff(end).as("hours") <= combineThresholdInHours) {
            end = DateTime.fromISO(episodes[i].end);
            mainEpisodes.push(episodes[i]);
            i++;
        }

        i = mainIndex - 1;
        while (i >= 0 && start.diff(DateTime.fromISO(episodes[i].end)).as("hours") <= combineThresholdInHours) {
            start = DateTime.fromISO(episodes[i].start);
            mainEpisodes.unshift(episodes[i]);
            i--;
        }

        const firstStage = mainEpisodes[0].sleepStages[0];
        const bedtime = start;

        if (!includeFirstAndLastAwakenings) {
            if (firstStage.type === "awake") {
                start = start.plus({ seconds: firstStage.seconds })
            }

            const lastEpisode = mainEpisodes[mainEpisodes.length - 1];
            const lastStage = lastEpisode.sleepStages[lastEpisode.sleepStages.length - 1]
            if (lastStage.type === "awake") {
                end = end.minus({ seconds: lastStage.seconds});
            }
        }

        return {
            start, 
            end,
            bedtime, 
            mainEpisodes: mainEpisodes,
            timeToFallAsleepInMins: firstStage.seconds / 60
        };        
    }

    /**
     * You might need to call .setZone(episode?.timezone ?? "utc"); after using this...
     * Why doesn't this method already do that?
     */
    static getSleepAndWakeupTime(sleepEpisode: SleepEpisode) {
        const firstStage = sleepEpisode.sleepStages[0];
        const timeToSleep = firstStage && firstStage.type === "awake" ? firstStage.seconds : 0;

        const lastStage = sleepEpisode.sleepStages[sleepEpisode.sleepStages.length - 1];
        const timeToGetUp = lastStage && lastStage.type === "awake" ? lastStage.seconds : 0;        

        const bedtime = DateTime.fromISO(sleepEpisode.start);
        const sleeptime = bedtime.plus({ seconds: timeToSleep });
        const waketime = DateTime.fromISO(sleepEpisode.end).plus({ seconds: -1 * timeToGetUp });

        return {
            bedtime,
            sleeptime,
            waketime
        };
    }

    static getTimeToFallAsleepInMins(sleepEpisode: SleepEpisode) {
        const { bedtime, sleeptime, waketime } = SleepStagesUtils.getSleepAndWakeupTime(sleepEpisode);
        return sleeptime.diff(bedtime).as('minutes');        
    }

    static compress(sleepStages: SleepStage[]) {
        if (sleepStages.length === 0) {
            return sleepStages;
        }

        let prev = {...sleepStages[0] };
        let compressedStages = [];

        for (let i = 1; i < sleepStages.length; ++i) {
            if (sleepStages[i].type !== prev.type) {
                compressedStages.push(prev);
                prev = {...sleepStages[i] };
            }
            else {
                prev.seconds += sleepStages[i].seconds;
            }
        }

        compressedStages.push(prev);
        return compressedStages;
    }
}