import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import StringUtils from "../../../../utils/StringUtils";
import { SortedTags } from "./SortedTagCountChart";

interface Props {
    chartTitle?: string
    sortedTags: SortedTags;
    numDays: number;
    progressBarColor: string;
    backgroundColor?: string;
    limit?: number;
}

export default function SortedTagsChartV2(props: Props) {

    const [showMore, setShowMore] = useState(false);
    const [keyword, setKeyWord] = useState('');
    
    const limit = props.limit ?? 10;

    function handleShowMore() {
        setShowMore(!showMore);
    } 
    
    function formatPercent(count: number) {
        const percent = props.numDays > 0 ? Math.round((count / props.numDays) * 100) : 0;
        return percent;
    }

    return (
        <div>
            {props.chartTitle &&
                <div className="text-center mb-3 fs-5">{props.chartTitle}</div>
            }
            <div className="overflow-auto px-2 px-lg-5" style={{maxHeight: "200px"}}>
                { props.sortedTags.filter(tag => StringUtils.containsCaseInsensitive(tag.name, keyword)).slice(0, showMore ? undefined : limit).map((tag, i) =>
                    <div>
                        <div className="d-flex pt-3 pb-1 text-sm justify-content-between" key={tag.name}>
                            <span className="text-truncate">{tag.name}</span>
                            <span className="text-xs text-muted">{tag.count} days <span className="fw-bold">({formatPercent(tag.count)}%)</span></span>
                        </div>
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={`tooltip-${'top'}`}>
                                    <span>{tag.count}/{props.numDays} days ({formatPercent(tag.count)}%)</span>
                                </Tooltip>
                            }
                        >
                            <div 
                                className="progress align-self-center" 
                                style={{
                                    ["--bs-progress-bar-bg" as any]: props.progressBarColor,
                                    ["--bs-progress-bg" as any]: props.backgroundColor,
                                    ["--bs-progress-height" as any]: "8px",
                                    ["--bs-progress-border-radius" as any]: "999px"
                                }}
                            >
                                <div className="progress-bar" role="progressbar"
                                    style={{width: `${((tag.count / props.numDays) * 100).toFixed(0)}%`}}>
                                </div>
                            </div>
                        </OverlayTrigger>
                    </div>                  
                )}
            </div>
            <div className="text-center mt-1">
                <button type="button" className="btn btn-link link-secondary text-xxs" onClick={handleShowMore}>
                    {showMore ? "Show less" : "Show more"}
                </button>
            </div>
            <div className="d-flex justify-content-center mt-1">
                <div className="p-1">
                    <i className="bi bi-search mt-1"></i>
                </div>
                <input type="text"
                    className="form-control form-control-sm w-50"
                    placeholder="Find"
                    value={keyword}
                    onChange={e => setKeyWord(e.target.value)}
                >
                </input>
            </div>           
        </div>
    );
}