import { useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import ChartDeferred from 'chartjs-plugin-deferred';
import { DateTime } from "luxon";

import ISleepLogView from "../../../../types/ISleepLogView";
import DateTimeUtils from "../../../../utils/DateTimeUtils";
import { FormatUtils, formatTimeFromComponents } from "../../../../utils/FormatUtility";
import SleepLogUtils from "../../../../utils/SleepLogUtils";
import { Aggregation } from "../../../../types/types";
import _ from "lodash";
import SleepStagesUtils from "../../../../utils/SleepStagesUtils";
import { ChartStyles } from "../constants/chart-styles";

interface Props {
    sleepLogs: ISleepLogView[][];
    aggregation: Aggregation;
    enableZoom?: boolean;
    targetSleepTime?: string;
    targetWakeTime?: string;
}

export default function SleepWakePerDayChart({
    sleepLogs,
    aggregation,
    enableZoom,
    targetSleepTime,
    targetWakeTime,
}: Props) {

    const [showBedTime,setShowBedTime] = useState(true);

    const [sleepWakeDatasets, setSleepWakeDatasets] = useState<ChartData<"bar", number[], string>>({
        labels: [],
        datasets: []
    });
    
    const [sleepWakeTimeTooltips, setSleepWakeTimeTooltips] = useState<string[]>([]);

    const windowSize = 14;

    const [windowStart, windowEnd] = useMemo(() => {
        let logs: ISleepLogView[] = [];
        logs = logs.concat(...sleepLogs).filter(log => log.mainSleep);

        if (logs.length === 0) {
            return [0, 0];
        }

        const midpoints = logs.map(log => {
            const mainSleepEpisode = log.mainSleep!;
            let { bedtime, waketime } = SleepStagesUtils.getSleepAndWakeupTime(mainSleepEpisode);
            bedtime = bedtime.setZone(mainSleepEpisode.timezone ?? "utc");
            waketime = waketime.setZone(mainSleepEpisode.timezone ?? "utc");

            let midpoint = DateTime.fromMillis((+bedtime + +waketime) / 2, { zone: "utc"});
            midpoint = midpoint.setZone(mainSleepEpisode.timezone ?? "utc");
            var midInMins = midpoint.hour * 60 + midpoint.minute;
            return midInMins;
        });

        const averageTimeInMins = SleepLogUtils.averageTime(midpoints);
        let [hours, mins] = DateTimeUtils.getHoursMins(averageTimeInMins);


        // 14 hour window

        let start = hours - (windowSize / 2);
        if (start < 0)
            start = 24 + start;

        let end = hours + (windowSize / 2);

        return [start, end];


    }, [sleepLogs]);

    function getSleepTimeColor(opacity: number) {
        return `rgba(59, 130, 246, ${opacity})`;
    }

    function getWakeTimeColor(opacity: number) {
        return `rgba(30, 64, 175, ${opacity})`;
    }    

    const primaryBgColor = getSleepTimeColor(.75);
    const primaryBorderColor = getSleepTimeColor(1);

    const filteredBgColor = getSleepTimeColor(.15);
    const filteredBorderColor = getSleepTimeColor(.15);

    const data = useMemo(() => {
        const { data, tooltips } = aggregation === "day" ? getPerDayData(sleepLogs) : getAggregatedData(sleepLogs[0]);
        setSleepWakeDatasets(data);
        setSleepWakeTimeTooltips(tooltips);

    }, [aggregation, sleepLogs[0], sleepLogs[1], showBedTime]);

    let targetSleepAnnotation: any = undefined

    const targetSleepComponents = targetSleepTime?.split(":");
    if (targetSleepComponents) {
        const targetSleepInHours = Number(targetSleepComponents[0]) + Number(targetSleepComponents[1]) / 60;
        if (contains(windowStart, windowEnd, targetSleepInHours)) {
            const offset = targetSleepInHours >= windowStart ? targetSleepInHours - windowStart : targetSleepInHours + (24 - windowStart);
            targetSleepAnnotation = {
                type: 'line',
                yMin:  offset,
                yMax: offset,
                borderColor: getSleepTimeColor(.5),
                borderWidth: 2,
                borderDash: [8, 4]            
            }
        }
    }

    let targetWakeupAnnotation: any = undefined

    const targetWakeupComponents = targetWakeTime?.split(":");
    if (targetWakeupComponents) {
        const targetWakeupInHours = Number(targetWakeupComponents[0]) + Number(targetWakeupComponents[1]) / 60;
        if (contains(windowStart, windowEnd, targetWakeupInHours)) {
            const offset = targetWakeupInHours >= windowStart ? targetWakeupInHours - windowStart : targetWakeupInHours + (24 - windowStart);
            targetWakeupAnnotation = {
                type: 'line',
                yMin:  offset,
                yMax: offset,
                borderColor: getSleepTimeColor(.5),
                borderWidth: 2,
                borderDash: [8, 4]            
            }
        }
    }    
    
    const options: ChartOptions<"bar"> = {
        plugins: {
            annotation: {
                annotations: {
                    sleepTime: targetSleepAnnotation,
                    wakeupTime: targetWakeupAnnotation
                }                
            },              
            deferred: {
            },
            legend: {
                display: false
            },
            title: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        return context.dataIndex < sleepWakeTimeTooltips.length ? sleepWakeTimeTooltips[context.dataIndex] : 'Loading tooltips...';                        
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: enableZoom,
                    mode: 'x',
                },                
                zoom: {
                    wheel: {
                        enabled: enableZoom,
                    },
                    pinch: {
                        enabled: enableZoom
                    },
                    mode: 'x',
                }
            },            
        },        
        scales: {
            x: {
                ticks: {
                    display: true,
                    font: {
                        ...ChartStyles.axisFont
                    }
                },
                grid: {
                        display: false,
                },
                stacked: true,
            },
            y: {
                position: "right",
                ticks: {
                    callback: function(value, index) {
                        let v = value as number;
                        v = (v + windowStart) % 24;
                        return formatTimeFromComponents(v, 0, false);
                    },            
                    font: {
                        ...ChartStyles.axisFont
                    },            
                    stepSize: 1,
                },
                grid: {
                    ...ChartStyles.gridYAxis,
                    display: true,             
                },
            }     
        }
    };

    function getAggregatedData(sleepLogs: ISleepLogView[]) {
        const data: any = {
            labels: [],
            datasets: [             
                {
                    backgroundColor: [],                    
                    borderRadius: {
                        bottomLeft: showBedTime ? 0 : 2,
                        bottomRight: showBedTime ? 0 : 2,
                        topLeft: 2,
                        topRight: 2
                    },
                    borderSkipped: false,
                    borderWidth: 0,
                    label: [],
                    data: [] as number[][],
                    maxBarThickness: 25,
                },
                {
                    backgroundColor: [],                    
                    borderRadius: {
                        bottomLeft: 2,
                        bottomRight: 2,
                        topLeft: 0,
                        topRight: 0
                    },
                    borderSkipped: false,
                    borderWidth: 0,
                    label: [],
                    data: [] as number[][],
                    maxBarThickness: 25,
                },                               
            ],
        };

        const dataset = data.datasets[0];

        const bedtimes: number[] = [];
        const sleepTimes: number[] = [];
        const wakeTimes: number[] = [];

        let tooltips: string[] = [];

        if (sleepLogs.length === 0) {
            return { data, tooltips: [] };
        }

        let label = FormatUtils.formatDate(sleepLogs[0]?.date.asString ?? '');

        const minDate = sleepLogs.map(sleepLog => DateTime.fromISO(sleepLog.date.asString)).reduce((prev, cur) => prev < cur ? prev : cur);
        const maxDate = sleepLogs.map(sleepLog => DateTime.fromISO(sleepLog.date.asString)).reduce((prev, cur) => prev > cur ? prev : cur);
        let date = minDate;

        const sleepLogMap = new Map<string, ISleepLogView>(sleepLogs.map(sleepLog => [sleepLog.date.asString, sleepLog]));

        while (date <= maxDate) {
            const curDate = date.toFormat("y-LL-dd");
            const sleepLog = sleepLogMap.get(curDate);

            if (sleepLog) {
                if (sleepLog.mainSleep) {
                    let { bedtime: bedtimeDate, sleeptime: sleeptimeDate, waketime: waketimeDate } = SleepStagesUtils.getSleepAndWakeupTime(sleepLog.mainSleep);
                    bedtimeDate = bedtimeDate?.setZone(sleepLog.mainSleep.timezone ?? "utc");
                    sleeptimeDate = sleeptimeDate?.setZone(sleepLog.mainSleep.timezone ?? "utc");
                    waketimeDate = waketimeDate?.setZone(sleepLog.mainSleep.timezone ?? "utc");

                    const bedtime = (bedtimeDate.hour * 60) + bedtimeDate.minute;
                    const sleepTime = (sleeptimeDate.hour * 60) + sleeptimeDate.minute;
                    const wakeTime = (waketimeDate.hour * 60) + waketimeDate.minute;
    
                    bedtimes.push(bedtime);
                    sleepTimes.push(sleepTime);
                    wakeTimes.push(wakeTime);
                }
            }

            if (aggregation === "week" || aggregation === "month") {
                if ((aggregation === "week" && date.weekday === 7) ||
                    (aggregation === "month" && date.plus({ days: 1 }).month > date.month) ||
                    sleepLog === sleepLogs[sleepLogs.length - 1])
                {
                    data.labels.push(label + "-");

                    // See assumption made in calculateSleepInterval
                    const avgBedtime = SleepLogUtils.averageTime(bedtimes);
                    const avgSleepTime = SleepLogUtils.averageTime(sleepTimes);
                    const avgWakeTime = SleepLogUtils.averageTime(wakeTimes);

                    const bedtimeInHours = avgBedtime / 60;
                    const sleepTimeInHours = avgSleepTime / 60;
                    const wakeTimeInHours = avgWakeTime / 60;

                    const timeToFallAsleepInMins = avgSleepTime && avgBedtime ? avgSleepTime - avgBedtime : undefined

                    const interval = calculateSleepInterval(sleepTimeInHours, wakeTimeInHours, windowStart, windowEnd, windowSize);
                    if (interval) {
                        const [start, end] = interval;
                        dataset.data.push([start, end]);
                        dataset.backgroundColor.push(primaryBgColor); 

                        const bedtime = DateTimeUtils.getHoursMins(bedtimeInHours * 60);
                        const startTime = DateTimeUtils.getHoursMins(sleepTimeInHours * 60);
                        const wakeTime = DateTimeUtils.getHoursMins(wakeTimeInHours * 60);

                        tooltips.push(`${formatTimeFromComponents(showBedTime ? bedtime[0] : startTime[0], showBedTime ? bedtime[1] : startTime[1])}-${formatTimeFromComponents(wakeTime[0], wakeTime[1])}`);
                    }
                    else {
                        dataset.data.push(undefined);
                        dataset.backgroundColor.push("white");
                        tooltips.push('');                   
                    }

                    if (showBedTime && timeToFallAsleepInMins !== undefined) {
                        const timeToSleepInterval = calculateSleepInterval(
                            sleepTimeInHours - (timeToFallAsleepInMins / 60),
                            sleepTimeInHours,
                            windowStart,
                            windowEnd,
                            windowSize);
    
                            if (timeToSleepInterval) {
                                const [start, end] = timeToSleepInterval;
                                data.datasets[1].data.push([start, end]);
                                data.datasets[1].backgroundColor.push(getWakeTimeColor(.75));
                            }
                            else {
                                data.datasets[1].data.push(undefined);
                                data.datasets[1].backgroundColor.push("white");                
                            }
                    }
                    else {
                        data.datasets[1].data.push(undefined);
                        data.datasets[1].backgroundColor.push("white");                    
                    }

                    bedtimes.length = 0;
                    sleepTimes.length = 0;
                    wakeTimes.length = 0;
                }
                else {                    
                    if ((aggregation === "week" && date.weekday === 1) ||
                        (aggregation === "month" && date.day === 1)) 
                    {
                        label = FormatUtils.formatDate(curDate);
                    }
                }
            }                
            else {
                
            }

            date = date.plus({days: 1});
        }
        
        return { data, tooltips };
    }

    function getPerDayData(sleepLogGroups: ISleepLogView[][]) {
        const data: any = {
            labels: [],
            datasets: [
                {
                    backgroundColor: [],
                    borderRadius: {
                        bottomLeft: showBedTime ? 0 : 2,
                        bottomRight: showBedTime ? 0 : 2,
                        topLeft: 2,
                        topRight: 2
                    },
                    borderSkipped: false,
                    borderWidth: 0,
                    label: ["Sleep"],
                    data: [] as number[][],
                    maxBarThickness: 25,
                },     
                {
                    backgroundColor: [],
                    borderRadius: {
                        bottomLeft: 2,
                        bottomRight: 2,
                        topLeft: 0,
                        topRight: 0
                    },
                    topLeft: 0,
                    borderSkipped: false,
                    borderWidth: 0,
                    label: ["Awake"],
                    data: [] as number[][],
                    maxBarThickness: 25,
                },                            
            ],
        };    

        let datesMap: {
            [key: string]: {
                bedtime: DateTime | undefined,
                sleeptime: DateTime | undefined,
                waketime: DateTime | undefined,
                timeToFallAsleepInMins: number | undefined,
                bgColor: string,
                borderColor: string,
                wakeTimeColor: string,
            }
        } = {};        

        sleepLogGroups.forEach((sleepLogs, i) => {
            sleepLogs.forEach((log, j) => {
                const rating = log.rating ?? 0;
                const backgroundColor = (i === 0) ? primaryBgColor : filteredBgColor;
                const borderColor = (i === 0 ? primaryBorderColor : filteredBorderColor);
                const wakeTimeColor = (i === 0) ? getWakeTimeColor(.75) : getWakeTimeColor(.2);

                let {
                    bedtime,
                    sleeptime,
                    waketime
                } = log.mainSleep ? 
                    SleepStagesUtils.getSleepAndWakeupTime(log.mainSleep):
                    { bedtime: undefined, sleeptime: undefined, waketime: undefined };
                
                bedtime = bedtime?.setZone(log.mainSleep?.timezone ?? "utc");
                sleeptime = sleeptime?.setZone(log.mainSleep?.timezone ?? "utc");
                waketime = waketime?.setZone(log.mainSleep?.timezone ?? "utc");

                datesMap[log.baseSleepLog.date] = {
                    bedtime: bedtime,
                    sleeptime: sleeptime,
                    waketime: waketime,
                    timeToFallAsleepInMins: sleeptime && bedtime ? sleeptime.diff(bedtime).as('minutes') : undefined,
                    bgColor: backgroundColor,
                    borderColor: borderColor,
                    wakeTimeColor: wakeTimeColor
                };
            });
        });

        const labels = Object.keys(datesMap).sort();
        data.labels = labels.map(l => FormatUtils.formatDate(l, true /* omitThisYear */));

        let tooltips: string[] = [];

        for (const date of labels) {
            let dateData = datesMap[date];

            const white = 'rgb(255,255,255)';

            if (dateData.sleeptime && dateData.waketime) {
                const sleepTime = dateData.sleeptime;
                const wakeTime = dateData.waketime;

                const sleepTimeInHours = sleepTime.hour + (sleepTime.minute / 60);
                const wakeTimeInHours = wakeTime.hour + (wakeTime.minute / 60);

                const interval = calculateSleepInterval(
                    sleepTimeInHours,
                    wakeTimeInHours,
                    windowStart,
                    windowEnd,
                    windowSize);

                if (interval) {
                    const [start, end] = interval;
                    data.datasets[0].data.push([start, end]);
                    data.datasets[0].backgroundColor.push(dateData.bgColor); 
                    tooltips.push(FormatUtils.formatSleepRangeFromDateTime(
                        showBedTime && dateData.bedtime ? dateData.bedtime : dateData.sleeptime,
                        dateData.waketime
                    ));
                }
                else {
                    data.datasets[0].data.push(undefined);
                    data.datasets[0].backgroundColor.push(white);
                    tooltips.push('');                   
                }

                if (showBedTime && dateData.bedtime && dateData.timeToFallAsleepInMins !== undefined) {
                    const timeToSleepInterval = calculateSleepInterval(
                        sleepTimeInHours - (dateData.timeToFallAsleepInMins / 60),
                        sleepTimeInHours,
                        windowStart,
                        windowEnd,
                        windowSize);

                        if (timeToSleepInterval) {
                            const [start, end] = timeToSleepInterval;
                            data.datasets[1].data.push([start, end]);
                            data.datasets[1].backgroundColor.push(dateData.wakeTimeColor);
                        }
                        else {
                            data.datasets[1].data.push(undefined);
                            data.datasets[1].backgroundColor.push(white);                
                        }
                }
                else {
                    data.datasets[1].data.push(undefined);
                    data.datasets[1].backgroundColor.push(white);                    
                }
            }
            else {
                data.datasets[0].data.push(undefined);
                data.datasets[0].backgroundColor.push(white);
                tooltips.push('');

                data.datasets[1].data.push(undefined);
                data.datasets[1].backgroundColor.push(white);               
            }    
        };

        return  { data, tooltips };
    }
    
    return (
        <div>
            <div className="position-relative">             
                <Bar 
                    data={sleepWakeDatasets}
                    options={options}
                    plugins={[ChartDeferred]}
                />               
            </div>
            <div className="mt-2">
                <div className="form-check form-switch">
                    <input type="checkbox"
                        className="form-check-input" 
                        role="switch"
                        checked={showBedTime}
                        onChange={() => setShowBedTime(state => !state)}>
                    </input>
                    <label className="form-check-label text-xs">Show bedtime</label>
                </div>    
            </div>             
        </div>        
    );
}
 
function contains(start: number, end: number, point: number) {
    if (start <= end) {
        return point >= start && point <= end;
    }
    else {
        return point >= start || point <= end;
    }
}

function calculateSleepInterval(
    sleepTimeInHours: number,
    wakeTimeInHours: number,
    windowStart: number, 
    windowEnd: number,
    windowSize: number)
{
    // The wake time is technically ambiguous: you don't know if, for example, 4am is 4am tomorrow, the day after that, etc.
    // We will assume that wake time is not ambiguous because a person shouldn't be sleeping more than 24 hours per day. This
    // means that there is one possible wake time: scan forward from sleepTime.
    let durationInHours = wakeTimeInHours >= sleepTimeInHours ? wakeTimeInHours - sleepTimeInHours : ((24 - sleepTimeInHours) + wakeTimeInHours);

    const containsStart = contains(windowStart, windowEnd, sleepTimeInHours);
    const containsEnd = contains(windowStart, windowEnd, wakeTimeInHours);

    let offsetInHours = 0;

    if (!containsStart && !containsEnd) {
        // Hacky way to determine if the circular intervals are disjoint or the time window encapsulates the sleep interval.
        if (contains(sleepTimeInHours, wakeTimeInHours, windowStart) || contains(sleepTimeInHours, wakeTimeInHours, windowEnd)) {
            offsetInHours = 0;
            durationInHours = windowSize;
        }
        else {
            return undefined;
        }
    }
    else if (containsStart && containsEnd) {
        offsetInHours = ((sleepTimeInHours < windowStart) ? sleepTimeInHours + 24 : sleepTimeInHours) - windowStart;
    }
    else if (!containsStart && containsEnd) {
        offsetInHours = 0;

        if (sleepTimeInHours < windowStart) {
            durationInHours -= (windowStart - sleepTimeInHours);
        }
        else {
            durationInHours -= (windowStart + 24 - sleepTimeInHours);
        }
        
        durationInHours = Math.min(windowSize, durationInHours);
    }
    else { // containsStart && !containsEnd
        offsetInHours = ((sleepTimeInHours < windowStart) ? sleepTimeInHours + 24 : sleepTimeInHours) - windowStart;
        durationInHours = windowSize - offsetInHours;
    }

    return [offsetInHours, offsetInHours + durationInHours];
}