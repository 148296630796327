import React, { useRef } from "react";
import { useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import ISleepLogView from "../../../../types/ISleepLogView";
import { SleepEpisode, SleepStage, SleepStageType } from "../../../../types/SleepLog";
import { FormatUtils } from "../../../../utils/FormatUtility";
import Utils from "../../../../utils/utils";
import SleepStagesChart from "../../../sleep-log-view/SleepStagesChart";
import { FixedSizeList } from 'react-window';
import { DateTime } from "luxon";

import styles from "./styles/sleep-episodes-table.module.css";
import classNames from "classnames";

interface Props {
    sleepLogGroups: ISleepLogView[][];
}

export default function SleepEpisodesTable({ sleepLogGroups }: Props) {

    const [state, setState] = useImmer({
        showTimeToFallAsleep: true,
        showTimeToLeaveBed: true,
        compactView: false,
        hideFiltered: false,
        useRelativeOffset: false,
        showSleepStages: false
    });

    const headerRef = useRef<HTMLDivElement>(null);
    const linesRef = useRef<HTMLDivElement>(null);

    const scrollLeftRef = useRef(0);
    const tableRef = (element: HTMLDivElement) => {
        element?.addEventListener("scroll", (e) => {
            const e2 = e as unknown as React.UIEvent<HTMLDivElement, UIEvent>;
            console.log(element.scrollLeft !== scrollLeftRef.current);

            if (element.scrollLeft !== scrollLeftRef.current) {
                scrollLeftRef.current = element.scrollLeft;

                if (headerRef.current) {
                    headerRef.current.scrollLeft = element.scrollLeft;
                }

                if (linesRef.current) {
                    linesRef.current.scrollLeft = element.scrollLeft;
                }
            }
        });
    }

    const mainSleepEpisodes = useMemo(() => {
        const sleepLogs =  (sleepLogGroups[0] ?? [])
            .filter(sleepLog => sleepLog.mainSleep)
            .map(sleepLog => ({ date: sleepLog.date.asString, mainSleep: sleepLog.mainSleep!, filteredOut: false }));

        const filteredOutLogs = (sleepLogGroups[1] ?? [])
            .filter(sleepLog => sleepLog.mainSleep)
            .map(sleepLog => ({ date: sleepLog.date.asString, mainSleep: sleepLog.mainSleep!, filteredOut: true }));


        const mainSleepEpisodes = state.hideFiltered ? sleepLogs : sleepLogs.concat(filteredOutLogs).sort((lhs, rhs) => lhs.date <= rhs.date ? -1 : 1);
        return mainSleepEpisodes.reverse()
    },
    [sleepLogGroups[0], sleepLogGroups[1], state.hideFiltered]);

    const [displayedSleepEpisodes, setDisplayedSleepEpisodes] = useState<{
        date: string; 
        mainSleep: SleepEpisode;
        filteredOut: boolean;
    }[]>([]);

    useEffect(() => {
        (async function f() {
            //compress stages

            function transformStageType(stage: SleepStageType): SleepStageType {
                switch (stage) {
                    case "asleep":
                    case "deep":
                    case "light":
                    case "rem":
                        return "asleep";
                    default:
                        return "awake";
                }
            }

            let displayedEpisodes = mainSleepEpisodes;

            if (!state.showSleepStages) {
                displayedEpisodes = mainSleepEpisodes.map(episode => {
                    const compressedStages: SleepStage[] = [];
                    for (const stage of episode.mainSleep.sleepStages) {
                        if (compressedStages.length > 0) {
                            const lastStage = compressedStages[compressedStages.length - 1];
                            if (lastStage.type === transformStageType(stage.type)) {
                                lastStage.seconds += stage.seconds;
                            }
                            else {
                                compressedStages.push({...stage, type: transformStageType(stage.type) });
                            }
                        }
                        else {
                            compressedStages.push({...stage, type: transformStageType(stage.type) });
                        }
                    }

                    return {...episode, mainSleep: {...episode.mainSleep, sleepStages: compressedStages } };
                });
            }


            if (!state.showTimeToFallAsleep) {
                const displayedEpisodesPromises = displayedEpisodes.map(async (episode, i) => {
                    await Utils.delay(2 * i);

                    const mainSleep = episode.mainSleep;
                    const fallAsleepStage = mainSleep.sleepStages.length > 1 && mainSleep.sleepStages[0].type === "awake" ? mainSleep.sleepStages[0] : undefined;
                    const timeToFallAsleepInMins = (fallAsleepStage?.seconds ?? 0) / 60;

                    const stages = fallAsleepStage ? mainSleep.sleepStages.slice(1) : mainSleep.sleepStages;

                    return {
                        ...episode,
                        mainSleep: {
                            ...mainSleep,
                            sleepStages: stages,
                            start: stages[0].start,
                            minutesAwake: mainSleep.minutesAwake - timeToFallAsleepInMins
                        }
                    };
                });

                displayedEpisodes = await Promise.all(displayedEpisodesPromises);
            }

            if (!state.showTimeToLeaveBed) {
                const displayedEpisodesPromises = displayedEpisodes.map(async (episode, i) => {
                    await Utils.delay(2 * i);

                    const mainSleep = episode.mainSleep;
                    const length = mainSleep.sleepStages.length;
                    const outOfBedStage = mainSleep.sleepStages.length > 1 && mainSleep.sleepStages[length - 1].type === "awake" ? mainSleep.sleepStages[length - 1] : undefined;
                    const timeToLeaveBedInMins = (outOfBedStage?.seconds ?? 0) / 60;

                    const stages = outOfBedStage ? mainSleep.sleepStages.slice(0, length - 1) : mainSleep.sleepStages;

                    return {
                        ...episode,
                        mainSleep: {
                            ...mainSleep,
                            sleepStages: stages,
                            start: stages[0].start,
                            minutesAwake: mainSleep.minutesAwake - timeToLeaveBedInMins
                        }
                    };
                });

                displayedEpisodes = await Promise.all(displayedEpisodesPromises);
            }            

            setDisplayedSleepEpisodes(displayedEpisodes);
        })();

    }, [mainSleepEpisodes, state]);

    const episodeMargin = state.compactView? 0 : 3;
    const episodeHeight = 8;
    const maxWidth = "500px";
    const absoluteHour = 23;

    function calculateOffsetInHours(episode?: SleepEpisode) {
        if (episode === undefined) {
            return 0;
        }

        const start = DateTime.fromISO(episode.start).setZone(episode.timezone ?? 'UTC');

        if (start.hour < absoluteHour) {
            return start.hour + (start.minute / 60) + (24 - absoluteHour);
        }
        else {
            return start.hour - absoluteHour + (start.minute / 60);
        }
    }

    const hourTicks = useMemo(() => {
        const generateSeries = (n: number) => Array.from({length: n + 1}, (_, i) => i);
        return generateSeries(48);
    }, []);

    const SleepEpisode = ({ index, style }: { index: number, style: any}) => (
        <div key={displayedSleepEpisodes[index].date} className="d-flex" style={style}>
            <div className="text-xxs text-muted" style={{width: "15%", paddingTop: `${episodeMargin}px`, lineHeight: ".5rem"}}>
                { !state.compactView &&
                    <div className="position-absolute" style={{width: "inherit", textAlign: "end", paddingRight: "2px"}}>
                        {FormatUtils.formatDate(displayedSleepEpisodes[index].date, true)}
                    </div>
                }
            </div>
            <div style={{width: "85%", maxWidth: maxWidth }}>
                <div
                    className=""
                    style={
                        {
                            position: "relative",
                            width: (80 * (displayedSleepEpisodes[index].mainSleep!.minutesAsleep + displayedSleepEpisodes[index].mainSleep!.minutesAwake) / (8 * 60)) + "%",
                            paddingTop: `${ episodeMargin}px`,
                            opacity: displayedSleepEpisodes[index].filteredOut ? .3 : .9,
                            left: state.useRelativeOffset ? (80 * (calculateOffsetInHours(displayedSleepEpisodes[index].mainSleep) / (8))) + "%" : 0
                        }
                    }
                >
                    <div style={{minWidth: "300%", height: ".1px"}}></div>
                    <SleepStagesChart
                        episode={displayedSleepEpisodes[index].mainSleep!}
                        height={`${episodeHeight}px`}
                        onSelect={() => 0} 
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div style={{contentVisibility: "auto"}}>
            <header className="d-flex" style={{height: "1.5rem"}}>
                <div style={{width: "15%"}}></div>
                <div 
                    className={classNames("d-flex position-relative", styles.hideScrollBar)}
                    style={{width: "85%", maxWidth: maxWidth, overflow: "auto"}}
                    ref={headerRef}
                >
                    { hourTicks.map((h, i) =>
                        <React.Fragment key={i}>
                            <div className="position-absolute text-xxs text-muted translate-middle-x" style={{left: (10 * i) + "%"}}>
                                { state.useRelativeOffset ?
                                    (FormatUtils.formatTimeFromComponents((absoluteHour + h) % 24, 0, false))
                                    :
                                    (h > 0 ? h+'h' : '')
                                }
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </header>

            <figure
                className="position-relative"
                style={{
                    height: "300px",
                    overflowY: "auto",
                    overflowX: "hidden"
                }}
            >

                <div 
                    className={classNames("d-flex position-absolute w-100")}
                    style={{ zIndex: -1}}
                >
                    <div style={{width: "15%"}}></div>

                    <div style={{width: "85%", maxWidth: maxWidth}}>
                        <div className={classNames("d-flex position-relative")}
                            style={{overflowX: "hidden", overflowY: "hidden", scrollbarGutter: "stable"}}
                            ref={linesRef}
                        >
                            { hourTicks.map((h, i) =>
                                <React.Fragment key={i}>
                                    <div style={{
                                        zIndex: 100,
                                        //left: (10 * h) + "%",
                                        height: ((300)) + "px",
                                        borderLeft: "solid",
                                        //position: "absolute",
                                        borderWidth: "1px",
                                        borderColor: "purple",
                                        opacity: ".2",
                                        flex: "0 0 10%"
                                    }}>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>

                <FixedSizeList
                    className="List"
                    height={300}
                    itemCount={displayedSleepEpisodes.length}
                    itemSize={episodeHeight + episodeMargin}
                    width={"100%"}
                    outerRef={tableRef}
                >
                    {SleepEpisode}
                </FixedSizeList>
            </figure>

            <div className="mt-4"></div>

            <section className="d-flex gap-3 flex-wrap">

                <div className="d-flex text-xxs flex-shrink-0" style={{flexBasis: "calc(50% - .5rem)", gap: "1rem"}}>
                    <input type="checkbox"
                        className="form-check-input" 
                        role="switch"
                        checked={state.showTimeToFallAsleep}
                        onChange={e => setState(state => { state.showTimeToFallAsleep = e.target.checked; })}
                    >
                    </input>
                    <label className="form-check-label">
                        Show time to fall asleep
                    </label>
                </div>

                <div className="d-flex text-xxs w-50 flex-shrink-0" style={{flexBasis: "calc(50% - .5rem)", gap: "1rem"}}>
                    <input type="checkbox"
                        className="form-check-input" 
                        role="switch"
                        checked={state.showTimeToLeaveBed}
                        onChange={e => setState(state => { state.showTimeToLeaveBed = e.target.checked; })}
                    >
                    </input>
                    <label className="form-check-label">
                        Show time to get out of bed
                    </label>
                </div>                

                <div className="d-flex text-xxs d-none w-50 flex-shrink-0" style={{flexBasis: "calc(50% - .5rem)", gap: "1rem"}}>
                    <input type="checkbox"
                        className="form-check-input" 
                        role="switch"
                        checked={state.compactView}
                        onChange={e => setState(state => { state.compactView = e.target.checked; })}
                    >
                    </input>
                    <label className="form-check-label">
                        Compact view
                    </label>
                </div>

                <div className="d-flex text-xxs w-50 flex-shrink-0" style={{flexBasis: "calc(50% - .5rem)", gap: "1rem"}}>
                    <input type="checkbox"
                        className="form-check-input" 
                        role="switch"
                        checked={state.useRelativeOffset}
                        onChange={e => setState(state => { state.useRelativeOffset = e.target.checked; })}
                    >
                    </input>
                    <label className="form-check-label">
                        Show hour offset
                    </label>
                </div>

                <div className="d-flex text-xxs w-50 flex-shrink-0" style={{flexBasis: "calc(50% - .5rem)", gap: "1rem"}}>
                    <input type="checkbox"
                        className="form-check-input" 
                        role="switch"
                        checked={state.showSleepStages}
                        onChange={e => setState(state => { state.showSleepStages = e.target.checked; })}
                    >
                    </input>
                    <label className="form-check-label">
                        Show sleep stages
                    </label>
                </div>                

                { sleepLogGroups.length > 1 &&
                    <div className="d-flex text-xxs w-50" style={{gap: "1rem"}}>
                        <input type="checkbox"
                            className="form-check-input" 
                            role="switch"
                            checked={state.hideFiltered}
                            onChange={e => setState(state => { state.hideFiltered = e.target.checked; })}
                        >
                        </input>
                        <label className="form-check-label">
                            Show filtered only
                        </label>
                    </div>
                }                

            </section>

        </div>
    );
}