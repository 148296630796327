import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { StatusMessage, StatusMessageType } from "../../common/StatusMessages";
import { LoginState } from "../../../types/CommonInterfaces";
import ConnectedDevice from "../../../devices/components/connected-device";
import useStore from "../../../shared/store/useStoreService.hook";
import { AccountProperties, ConnectedAccountType } from "../../../types/AccountSettings";
import AccountService from "../services/account.service";
import { LinkContainer } from "react-router-bootstrap";
import { AccountServiceContext } from "../services/account-service.provider";
import { AccountHttpServiceContext } from "../../http/account-http-service.provider";
import AppPage from "../../../app/AppPage";

interface Props {
    loginState: LoginState;
    addStatus: (msg: StatusMessage) => any;
}

export default function AccountDevices({ loginState, addStatus }: Props) {
    const [accountService, accountProperties] = useStore<AccountService, AccountProperties | undefined>(AccountServiceContext);

    async function disconnect(type: ConnectedAccountType) {
        try {
            await accountService.disconnectAccount(type);
        }
        catch (e) {
            addStatus({
                msg: `Error when disconnecting ${type}.`,
                type: StatusMessageType.Fail
            });
        }
    }

    async function sync(type: ConnectedAccountType) {
        try {
            await accountService?.syncConnectedAccounts(type, "latest");
        }
        catch (e) {
            addStatus({
                msg: `Syncing ${type} failed.`,
                type: StatusMessageType.Fail
            });            
        }
    }

    async function setAsPrimary(type: ConnectedAccountType) {
        try {
            await accountService.updateSettings({ primaryConnectedAccount: type })
        }
        catch (e) {
            addStatus({
                msg: `Error when setting ${type} as the primary device.`,
                type: StatusMessageType.Fail
            });
        }        
    }


    return (
        <AppPage className="app-bg-light pt-4 pb-5">
            <Container className="">
                <div>
                    <h2 className="">Connected Devices</h2>
                    <div className="mt-2 border-bottom"></div>
                </div>
                <div className="mt-5">   
                    { accountProperties && accountProperties.connectedAccounts.length > 0 ?   
                        <div 
                            className="d-flex justify-content-center justify-content-sm-start"
                            style={{ flexWrap: "wrap", gap: "25px" }}
                        >
                            { accountProperties.connectedAccounts.map((device, index) =>
                                    <div
                                        key={index}
                                        className="d-flex justify-content-center rounded tw-shadow ring-1 ring-gray-200 p-3 bg-white"
                                        style={{flexBasis: "300px", flexShrink: 0 }}
                                    >
                                        <ConnectedDevice
                                            device={device}
                                            isPrimary={accountProperties.primaryConnectedAccount === device.type}
                                            disconnect={() => disconnect(device.type)}
                                            sync={() => sync(device.type)}
                                            setAsPrimary={() => setAsPrimary(device.type)}
                                            reconnect={() => 0}
                                        />
                                    </div>
                            )}
                        </div>
                        :
                        <div>
                            { accountProperties ?
                                <div>
                                    You haven't connected any devices yet.
                                    Click <LinkContainer to="/import"><a href="#">here</a></LinkContainer> to get data.
                                </div>
                                :
                                <div className="d-flex justify-content-center mt-5" style={{gap: "1rem"}}>
                                    <span className="spinner-grow text-secondary" role="status" aria-hidden="true"></span>
                                    <span className="spinner-grow text-secondary" role="status" aria-hidden="true"></span>
                                    <span className="spinner-grow text-secondary" role="status" aria-hidden="true"></span>
                                </div>  
                            }
                        </div>
                    }
                </div>      
            </Container>
        </AppPage>
    );
} 