import React, { useState, useEffect, useRef, useMemo } from "react";

import { Chart, ChartData, ChartDataset, ChartOptions} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDeferred from 'chartjs-plugin-deferred';

import ISleepLogView from "../../../../types/ISleepLogView";
import DateTimeUtils, { ticksInDay } from "../../../../utils/DateTimeUtils";
import { FormatUtils, prettyFormatTimeDurationInHours } from "../../../../utils/FormatUtility";
import { Aggregation, AggregationSummary } from "../../../../types/types";
import AnalyticsUtils from "../../../../utils/analytics-utils";
import _ from "lodash";
import { ChartStyles } from "../constants/chart-styles";

interface Props {
    sleepLogs: ISleepLogView[][];
    aggregationPeriod: Aggregation;
    goalSleepInMins?: number;
    showLines?: boolean;
    showLegend?: boolean;
    colorRows: boolean;
    enableZoom?: boolean;
}

const aggregationPeriodMap: Record<Aggregation, number> = {
    'day': 1,
    'week': 7,
    'month': 30,
    'quarter': 91,
    'threeDayMoving': 3,
    'sevenDayMoving': 7,
    'fourteenDayMoving': 14
}

export default function SleepPerDayChart({
    sleepLogs,
    aggregationPeriod,
    goalSleepInMins,
    showLines,
    showLegend,
    colorRows, 
    enableZoom: enableZoom, 
}: Props) {
    const [data, setData] = useState<ChartData<"bar", number[], string>>({
        labels: [],
        datasets: []
    });

    function getPrimaryColor(opacity: number, goalMet: boolean) {
        return goalMet ? `rgba(124, 58, 237, ${opacity})` : `rgba(167, 139, 250, ${opacity})`;
    } 

    let goodSleepBgColors = [getPrimaryColor(.75, true), getPrimaryColor(.15, true)];
    let goodSleepBorderColors = [getPrimaryColor(.75, false), getPrimaryColor(.15, false)];

    let badSleepBgColors = [getPrimaryColor(.75, false), getPrimaryColor(.15, false)];
    let badSleepBorderColors = [getPrimaryColor(.75, false), getPrimaryColor(.15, false)]; 

    function getAggregationAverage(sleepLogGroups: ISleepLogView[][], aggregation: AggregationSummary): ChartData<"bar", number[], string> {
        const dataset = {
            label: "Hours slept",
            data: [] as number[],
            backgroundColor: [] as string[],
            borderColor: [] as string[],
            borderWidth: 1,
            maxBarThickness: 100
        };    

        const data = {
            labels: [] as string[],
            datasets: [dataset]
        };

        if (sleepLogGroups.length === 0) {
            return data;
        }

        let totalLogs = sleepLogGroups.map(logs => logs.length).reduce((prev, cur) => prev + cur);

        if (totalLogs === 0) {
            return data;
        }

        let start = "9999-12-31";
        let end = "";
    
        for (const sleepLogs of sleepLogGroups) {
            if (sleepLogs.length > 0) {
                const firstDate = sleepLogs[0].date.asString;
                const lastDate = sleepLogs[sleepLogs.length - 1].date.asString;
    
                start = firstDate < start ? firstDate : start;
                end = lastDate > end ? lastDate : end;
            }
        };
    
        const dateToSleepDurationMap = Object.fromEntries(
            sleepLogGroups[0].map(sleepLog => [sleepLog.date.asString, sleepLog.minutesAsleep ? (sleepLog.minutesAsleep / 60) : undefined])
        );
        
        const dateRange = AnalyticsUtils.fillDateRange(start, end);
        const values: (number | undefined)[] = dateRange.map(date => dateToSleepDurationMap[date]);
    
        const { dates, values: aggregatedValues } = AnalyticsUtils.aggregation(
            dateRange,
            values,
            aggregation,
            1
        );
    
        for (const [date, value] of _.zip(dates, aggregatedValues)) {
            data.labels.push(date!);
            dataset.data.push(value as number);

            const goal = goalSleepInMins ?? 7 * 60;
            const reachedGoal = (value ?? 0) >= goal;

            const [bgColor, borderColor] = (() => {
                if (colorRows) {
                    return [getContrastColor((value ?? 0) * 60, 1), getContrastColor((value ?? 0) * 60, 1)];
                }
                else {
                    return reachedGoal ? [goodSleepBgColors[0], goodSleepBorderColors[0]] : [badSleepBgColors[0], badSleepBorderColors[0]];
                }
            })();
    
            dataset.backgroundColor.push(bgColor);
            dataset.borderColor.push(borderColor);
        }

        return data;
    }

    function calculateSleepData(sleepLogDatasets: ISleepLogView[][], labels: string[]): ChartData<"bar", number[], string> {                
        let datasets = sleepLogDatasets.map((set, i) => {
            const dataset: ChartDataset<"bar"> = {
                label: "Hours slept" + labels[i],
                data: [] as number[],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 0,
                maxBarThickness: 75,
                minBarLength: 6
            };
            
            return dataset;
        });

        const data: ChartData<"bar", number[], string> = {
            labels: [],
            datasets: datasets
        };

        let datesMap: {[key: string]: { sleepDuration: number | undefined, dataSetIndex: number }} = {};

        sleepLogDatasets.forEach((sleepLogs, i) => {
            sleepLogs.forEach((log: ISleepLogView) => {

                const date = log.date.asString;

                datesMap[date] = {
                    sleepDuration: log.minutesAsleep !== undefined ? Number((log.minutesAsleep / 60).toFixed(3)) : undefined,
                    dataSetIndex: i,    
                };
            });
        });

        const getDataPointColor = (minutesAsleep: number | undefined, datasetIndex: number): [bgColor: string, borderColor: string] => {
            if (colorRows) {
                return datasetIndex === 0 ? 
                    [getContrastColor((minutesAsleep ?? 0), .7), getContrastColor((minutesAsleep ?? 0), 1)] :
                    [getContrastColor((minutesAsleep ?? 0), .15), getContrastColor((minutesAsleep ?? 0), .15)];
            }
            else {
                const goal = goalSleepInMins ?? 7 * 60;
                const reachedGoal = (minutesAsleep ?? 0) >= goal;                
                return reachedGoal ? [goodSleepBgColors[datasetIndex], goodSleepBorderColors[datasetIndex]] : [badSleepBgColors[datasetIndex], badSleepBorderColors[datasetIndex]];
            }
        };

        const initialDates = Object.keys(datesMap).sort();

        if (initialDates.length === 0) {
            return data;
        }
    
        const dateRange = AnalyticsUtils.fillDateRange(initialDates[0], initialDates[initialDates.length - 1]);
        const values: (number | undefined)[] = dateRange.map(date => datesMap[date]?.sleepDuration);
        const windowSize = aggregationPeriodMap[aggregationPeriod];
        const movingAverages = AnalyticsUtils.movingAverage(dateRange, values, windowSize, 1);
    
        data.labels = dateRange;
    
        for (const [date, value] of _.zip(dateRange, movingAverages)) {
            const metadata = datesMap[date!];

            const datasets = data.datasets;

            for (let i = 0; i < datasets.length; i++) {
                const dataset: any = datasets[i];
                dataset.data.push((i === metadata?.dataSetIndex ? value : undefined) as number);
                const [bgColor, borderColor] = value !== undefined && metadata ? getDataPointColor(value * 60, metadata.dataSetIndex) : [undefined, undefined];
                dataset.backgroundColor.push(bgColor);
                dataset.borderColor.push(borderColor);
            }
        }

        return data;
    }

    const options = useMemo(() => {
        const unit = (() => {
            switch (aggregationPeriod) {
                case 'day':
                    return 'day'
                case 'week':
                    return 'week';
                case 'month':
                    return 'month';
                case 'quarter':
                    return "quarter";
                default: 
                    return 'day'
            }
        })();          

        const timeType = {
            type: 'time',
            time: {
                unit: unit,
                displayFormats: {
                    day: 'M/d',
                    week: "M/d'-'",
                },
                tooltipFormat: "MM/dd/y"
            },   
            adapters: {
                date: {
                    zone: "UTC"
                }
            }, 
        };    
    
        const options: ChartOptions<"bar"> = {
            plugins: {            
                legend: {
                    display: showLegend ?? true
                },
                deferred: {
                },
                title: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            return context.raw !== undefined ? prettyFormatTimeDurationInHours(context.raw as number) : "";
                        }
                    }
                },
                zoom: {
                    pan: {
                        enabled: enableZoom,
                        mode: 'x',
                    },                
                    zoom: {
                        wheel: {
                            enabled: enableZoom,
                        },
                        pinch: {
                            enabled: enableZoom
                        },
                        mode: 'x',
                    },
                    limits: {
                        y: {min: 0, max: 48},
                    }
                },
                annotation: {
                    annotations: {
                        sleepGoal: {
                          type: 'line',
                          yMin: (goalSleepInMins ?? 0) / 60,
                          yMax: (goalSleepInMins ?? 0) / 60,
                          borderColor: "rgba(192, 38, 211, .5)",
                          borderWidth: 2,
                          borderDash: [8, 4]
                        }
                      }                
                }
            },        
            scales: {
                x: {
                    ...(timeType as any),
                    grid: {
                        display: false
                    },
                    stacked: true,
                    ticks: {
                        font: {
                            ...ChartStyles.axisFont
                        }
                    }
                },
                y: {
                    beginAtZero: true,                
                    ticks: {
                        font: {
                            ...ChartStyles.axisFont
                        }
                    },
                    grid: {
                        ...ChartStyles.gridYAxis,
                        display: showLines ?? true,
                    },
                    position: 'right'
                }         
            },
        };

        return options;
    }, [showLines, enableZoom, showLegend, aggregationPeriod, goalSleepInMins]);

    function isAggregation(aggregation: Aggregation): aggregation is AggregationSummary {
        return aggregation === "week" || aggregation === "month" || aggregation === "quarter";
    }    

    useEffect(() => {
        const data = isAggregation(aggregationPeriod) ? 
            getAggregationAverage(sleepLogs.slice(0, 1), aggregationPeriod) : 
            calculateSleepData(sleepLogs, ["", ", filtered"]);

        setData(data);

    }, [sleepLogs[0], sleepLogs[1], aggregationPeriod, colorRows]);

    return (
        <div>        
            <Bar
                data={data}
                options={options}
                plugins={[ChartDeferred]}
            />     
        </div>
    ); 
}

const opacity = .5;

const colors = [
    'rgba(255, 99, 132, X)',
    'rgba(250, 140, 22, X)',
    'rgba(255, 197, 61, X)',
    'rgba(250, 219, 20, X)',
    'rgba(75, 192, 192, X)',
];

function getContrastColor(sleep: number, opacity: number) {
    if (sleep >= 7 * 60) {
        return colors[4].replace('X', opacity.toString());
    }
    else if (sleep >= 6.5 * 60) {
        return colors[3].replace('X', opacity.toString());
    }
    else if (sleep >= 6 * 60) {
        return colors[2].replace('X', opacity.toString());
    }
    else if (sleep >= 5 * 60) {
        return colors[1].replace('X', opacity.toString());
    }
    else {
        return colors[0].replace('X', opacity.toString());
    }
}