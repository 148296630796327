import { ChartData, ChartOptions } from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartDeferred from 'chartjs-plugin-deferred';

import { prettyFormatTimeDurationInHours } from "../../../../utils/FormatUtility";
import ISleepLogView from "../../../../types/ISleepLogView";

interface Props {
    sleepLogs: ISleepLogView[];
}

export default function RatingVsAverageSleepChart({sleepLogs}: Props) {

    const [ratingBreakdown, setRatingBreakdown] = useState<ChartData<"line", number[], string>>({
        labels: [],
        datasets: []
    });

    function calculateRatingBreakdown(sleepLogsDatasets: ISleepLogView[][], bgColors: string[], borderColors: string[]) {

        let datasets = sleepLogsDatasets.map((e, i) => {
            return {
                label: "Rating",
                data: [],
                backgroundColor: bgColors[i],
                borderColor: borderColors[i],
                borderWidth: "2"
            }            
        });

        let ratingBreakdown: any = {
            labels: [],
            datasets: datasets,
        };

        const maxRating = 10;
        const bucketCount = maxRating + 1; // 0 to 10

        ratingBreakdown.datasets.forEach((e: any, i: number) => {
            ratingBreakdown.datasets[i].data = new Array<number>(bucketCount).fill(0);
        });

        for (let i = 0; i < bucketCount; ++i) {
            ratingBreakdown.labels.push(`${i}`);
        }

        sleepLogsDatasets.forEach((sleepLogs, i) => {
            const buckets = ratingBreakdown.datasets[i].data;
            const counts = new Array<number>(bucketCount).fill(0);
        
            sleepLogs.forEach(log => {
                if (log.rating !== undefined && log.minutesAsleep !== undefined) {
                    buckets[log.rating] += (log.minutesAsleep / 60);
                    counts[log.rating]++;
                }
            });

            for (let i = 0; i < buckets.length; ++i) {
                buckets[i] = counts[i] > 0 ? (buckets[i] / counts[i]) : undefined;
            }
        });
        
        return ratingBreakdown;
    }

    const options: ChartOptions<"line"> = {
        plugins: {
            datalabels: {
                anchor: "end",
                align: "top",
                color: "grey",
                font: {
                    size: 10
                },
                formatter: function(value, context) {
                    const sleep = value as number | undefined;
                    return sleep ? prettyFormatTimeDurationInHours(sleep) : '';
                }
            },            
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const sleep = context.raw as number | undefined;
                        return sleep ? prettyFormatTimeDurationInHours(sleep) : '';                
                    }
                }
            },
            legend: {
                display: false
            },
            title: {
                display: false
            },            
        },        
        scales: {
            x: {
                    grid: {
                        color: "rgba(0, 0, 0, 0)",
                    },
                    stacked: true,
                    ticks: {
                        font: {
                            size: 10
                        },
                    }
            },
            y: {
                ticks: {
                    callback: function(value, index) {
                        let sleep = value as number;
                        return `${sleep} hrs`;
                    },                         
                    stepSize: 1,
                    font: {
                        size: 10
                    },
                    maxTicksLimit: 10
                },
                grid: {
                    color: "rgba(0, 0, 0, .04)",
            },                
                stacked: true,
            }            
        },    
    };

    const bgColor = "rgba(255, 214, 102, .7)";
    const borderColor = "rgba(255, 214, 102, 1)";

    useEffect(() => {
        const sleepData = calculateRatingBreakdown([sleepLogs], [bgColor], [bgColor]);
        setRatingBreakdown(sleepData);     

    }, [sleepLogs]);

    return (
        <Line data={ratingBreakdown} plugins={[ChartDataLabels, ChartDeferred]} options={options} />
    );
}