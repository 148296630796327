import { useState } from "react";

export enum StatusMessageType {
    Info,
    Success,
    Fail,
    UnexpectedFail
}

export interface StatusMessage {
    type: StatusMessageType;
    msg: string | any;
}

function hashCode(s: string) {
    let i = 0;
    let h = 0;

    for(; i < s.length; i++)
            h = Math.imul(31, h) + s.charCodeAt(i) | 0;
    return h;
}

export default function StatusMessages(props: {messages: StatusMessage[], closeStatus: () => any}) {
    const {messages, closeStatus} = props;
    const [hide, setHide] = useState(false);


    function getAlertClass(type: StatusMessageType) {
        switch (type) {
            case StatusMessageType.Fail:
            case StatusMessageType.UnexpectedFail:
                return 'alert-danger';
            case StatusMessageType.Success:
                return 'alert-success';
            case StatusMessageType.Info:
                return 'alert-info';
        }
    }

    const close = () => {
        setHide(true);
        setTimeout(() => {
            closeStatus();
            setHide(false);
        }, 150);
    };

    return (
        <div className="position-fixed w-100" style={{zIndex: 9999999, top:"1px"}}>
            {messages.slice(0, 1).map((m, i) =>
                <div
                    key={hashCode(m.msg)}
                    className={`container alert ${getAlertClass(m.type)} show alert-dismissible fade text-center shadow-sm`}
                    role="alert"
                    style={{opacity: hide ? 0 : 1}}
                >
                    <span className="">
                        <span className="fs-6">
                            {m.type === StatusMessageType.Fail &&
                                <i className="bi bi-exclamation-triangle-fill text-danger me-2"></i>
                            }
                            {m.type === StatusMessageType.Success &&
                                <i className="bi bi-check-circle-fill text-success me-2"></i>
                            }
                            {m.type === StatusMessageType.Info &&
                                <i className="bi bi-info-circle text-info me-2"></i>
                            }                        
                            {m.msg}
                        </span>
                        {messages.length > 1 &&
                            <div className="mt-2 text-sm">+{messages.length - 1} more messages</div>
                        }        
                    </span>            
                    <button type="button" className="btn-close" onClick={() => close()}></button>
                </div>            
            )}
        </div>
    );
}