import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { Row, Col, Button, ButtonGroup, Dropdown, Pagination, OverlayTrigger, Tooltip, ToggleButton, ToggleButtonGroup, Alert } from "react-bootstrap";
import { LoginState } from "../../types/CommonInterfaces";
import SleepLog from "../../types/SleepLog";
import SleepLogUpdateFlusher, { FlushJobState } from "../../utils/SleepLogUpdateFlusher";
import SleepLogUtils from "../../utils/SleepLogUtils";
import SleepLogRow from "./SleepLogRow";
import SleepLogModal from "./SleepLogModal";
import SleepLogSettingsModal from "../sleep-log-settings/components/SleepLogSettingsModal";
import { CustomMetric } from "../../types/SleepLogSettings";
import { StatusMessage, StatusMessageType } from "../common/StatusMessages";
import SavingChanges from "../common/SavingChanges";
import SimpleSleepLogFilters from "../filters/components/SimpleSleepLogFilters";
import { SimpleSleepLogFilterSet, SleepLogFilter } from "../../types/SleepLogFilter";
import { SleepLogFiltersHost } from "../filters/components/SleepLogFilters";
import DateTimeUtils from "../../utils/DateTimeUtils";
import SimpleTooltip from "./common/SimpleTooltip";
import { LinkContainer } from "react-router-bootstrap";
import { formatDateAsLocalTime, FormatUtils } from "../../utils/FormatUtility";
import SleepLogTimePeriodFilter from "../filters/components/SleepLogTimePeriodFilter";
import { Routes, Route, Outlet, useNavigate, Navigate } from "react-router-dom";
import { produce } from "immer";
import useStore from "../../shared/store/useStoreService.hook";
import SleepLogSettingsService from "../sleep-log-settings/services/SleepLogSettingsService";
import ColoredIcon from "../common/ColoredIcon";
import AccountService from "../account/services/account.service";
import { SleepLogSettingsServiceContext } from "../sleep-log-settings/services/sleep-log-settings-service.provider";
import { AccountServiceContext } from "../account/services/account-service.provider";
import { DateTime } from "luxon";
import classNames from "classnames";
import { TimePeriod } from "../http/AccountHttpService";
import { HttpException } from "../../types/Exceptions";
import { SleepLogHttpServiceContext } from "../http/sleep-log-http-service.provider";
import { useImmer } from "use-immer";
import { TagSuggestionsService } from "../../services/TagSuggestionsService";
import { TagsSuggestionsServiceContext, useTagSuggestionsService } from "../../services/TagSuggestionsService.provider";
import SleepLogViewFactory from "../../types/SleepLogViewFactory";

interface Props {
    loginState: LoginState;
    currentTab: string;

    addStatus: (msg: StatusMessage) => any;
}

interface State {
    showRefreshAfterSyncButton: boolean;
    refreshAfterSyncButtonDate: string;
}

export default function SleepLogsTable(props : Props) {
    const {loginState, currentTab, addStatus} = props;

    const [state, setState] = useImmer<State>(() => ({
        showRefreshAfterSyncButton: false,
        refreshAfterSyncButtonDate: ""
    }));

    const [sleepLogState, setSleepLogState] = useState<SleepLog[]>([]);
    const sleepLogs = useMemo(() => SleepLogViewFactory.createViews(sleepLogState), [sleepLogState]);

    const [showFilters, setShowFilters] = useState(false);
    const [useSimpleFilter, setUseSimpleFilter] = useState(false);    
    const [showAddComponent, setShowAddComponent] = useState(false);

    const [isSaving, setIsSaving] = useState(false);    
    
    const [showSleepLogModal, setShowSleepLogModal] = useState<{show: boolean, id?: string}>({show: false});
    let navigate = useNavigate();

    const [isSyncingLogs, setIsSyncingLogs] = useState(false);

    const [newSleepLogDate, setNewSleepLogDate] = useState(formatNowLocal());

    const logsPerPage = 14;

    const hasDoneFirstLogFetch = useRef(false);
    const isFetchingLogs = useRef(false);
    const [showFetchLogSpinner, setShowFetchLogSpinner] = useState(false);    

    const [filterState, setFilterState] = useState<SimpleSleepLogFilterSet | undefined>(undefined);
    let prevFilterState = useRef<any>(undefined)

    const [advancedFilterState, setAdvancedFilterState] = useState<{startDate: string, endDate: string, filters: SleepLogFilter[][]} | undefined>(undefined);
    let prevAdvancedFilterState = useRef<any>(undefined);
    
    const [isNotesExpanded, setIsNotesExpanded] = useState(false);

    const [tagSuggestionsService] = useTagSuggestionsService();

    const [timeRangeFilter, setTimeRangeFilter] = useState(() => ({
        startDate: DateTimeUtils.calculateThresholdDate(30),
        endDate: formatDateAsLocalTime(new Date()) 
    }));

    useEffect(() => {
        // Prefetch suggestions but delay by a couple of seconds
        setTimeout(() => tagSuggestionsService.load(), 1500);
    }, []);

    const [, settings] = useStore<SleepLogSettingsService, SleepLogSettingsService["state"]>(SleepLogSettingsServiceContext);
    const [showSleepLogSettings, setShowSleepLogSettings] = useState(false);

    const [showEmptyMessage, setShowEmptyMessage] = useState(false);

    const [accountService, accountSettings] = useStore<AccountService, AccountService["state"]>(AccountServiceContext);
    
    const prevConnectedAccountsState = useRef(accountSettings?.connectedAccounts);

    const sleepLogHttpService = useContext(SleepLogHttpServiceContext)!;

    const sleepLogUpdateFlusher = useRef(new SleepLogUpdateFlusher(sleepLogHttpService, onFlushJobStateChange));    

    const connectedAccountSyncErrors = useMemo(() => {
        const devicesWithErrors = accountSettings?.connectedAccounts.filter(device => device.status === 'Failed') ?? [];
        return devicesWithErrors;

    }, [accountSettings]);


    function onFlushJobStateChange(state: FlushJobState, errorMessages?: string[]) {
        setIsSaving(state === FlushJobState.None ? false : true);

        if (state === FlushJobState.Failed) {

            addStatus({
                msg: `Saving changes failed and will be retried in a couple of seconds. ${errorMessages && errorMessages.length > 0 ? "Reason: " + errorMessages[0] : ""}`,
                type: StatusMessageType.Fail
            });
        }
    }

    const handleClose = () => { 
        setShowSleepLogModal({show: false});
        navigate("");
    };

    const handleShowSleepLogModal = (sleepLogId: string) => {
        const selection = window.getSelection();
        if(selection && selection.type !== "Range") {
            setShowSleepLogModal({show: true, id: sleepLogId});
            navigate('modal');
        }
    };

    function toggleShowFilters() {
        setShowFilters(!showFilters);
    }

    function toggleShowAddComponent() {
        const show = !showAddComponent;
        setShowAddComponent(show);

        if (show) {
            setNewSleepLogDate(formatNowLocal());
        }
    }

    useEffect(() => {
        if (loginState.loggedIn && loginState.username)
        {
            sleepLogUpdateFlusher.current.setUser(loginState.username);
        }
    }, [loginState]);

    interface Suggestions {
        tags: string[];
        feelings: string[];
        sleepMeds: string[];
        otherMeds: string[];
    }
        
    async function fetchLogs(scanForward: boolean, limit: number, exclusiveStartId?: string, filter?: SimpleSleepLogFilterSet, advancedFilter?: any) {

        if (isFetchingLogs.current) {
            return;
        }

        setShowEmptyMessage(false);

        console.log("Fetching logs from server.");

        isFetchingLogs.current = true;
        setShowFetchLogSpinner(true);

        if (!loginState.loggedIn) {
            console.log("Username not set. No logs to fetch, returning.");
            return;
        }
     
        let logsFetchCount = 0;
        let nextExclusiveStartId = exclusiveStartId;

        let isFirstLoop = true;

        // Fetch more than needed to avoiding going back to server to many times when there's a filter.
        const optimisticLimit = filter || advancedFilter ? limit * 2 : limit;

        do {
            let sleepLogs: SleepLog[];
            
            try {
                const { logs, lastEvaluatedId } = await sleepLogHttpService.getSleepLogs(
                    filter,
                    advancedFilter,
                    optimisticLimit,
                    scanForward,
                    nextExclusiveStartId
                );

                sleepLogs = logs;    
                nextExclusiveStartId = lastEvaluatedId;                
            }
            catch (e: any) {
                if (e instanceof HttpException) {
                    addStatus({
                        msg: `Fetching sleep logs failed. Reason: ${e.message}`,
                        type: StatusMessageType.Fail
                    });
                }
                else {
                    addStatus({
                        msg: `Fetching sleep logs failed. Please refresh the page.`,
                        type: StatusMessageType.Fail
                    });
                }

                isFetchingLogs.current = false;

                return;
            }

            // trim to make sure that we only get up to the limit

            const neededLogCount = limit - logsFetchCount;

            sleepLogs = scanForward ? 
                sleepLogs.slice(Math.max(0, sleepLogs.length - neededLogCount)) : sleepLogs.slice(0, neededLogCount);

            if (isFirstLoop) {
                setSleepLogState(sleepLogs);
            }
            else {
                setSleepLogState(prev => scanForward ? [...sleepLogs, ...prev] : [...prev, ...sleepLogs]);         
            }

            for (const sleepLog of sleepLogs) {
                if (sleepLog.lastModifiedTime) {
                    sleepLogUpdateFlusher.current.setLastModifiedTime(sleepLog.id, sleepLog.lastModifiedTime);
                }
            }

            isFirstLoop = false;
            logsFetchCount += sleepLogs.length;

        } while (nextExclusiveStartId && logsFetchCount < limit);

        setShowFetchLogSpinner(false);
        isFetchingLogs.current = false;

        return logsFetchCount;
    };

    useEffect(() => {
        if (hasDoneFirstLogFetch.current) {
            return;
        }

        async function bootstrapLogs() {
            if (loginState.loggedIn && loginState.username && currentTab === "sleep-logs")
            {
                const count = await fetchLogs(false /* scanForward */, logsPerPage);
                hasDoneFirstLogFetch.current = true;

                if (count !== undefined) {
                    setShowEmptyMessage(true);
                }
            }            
        }

        bootstrapLogs();
    }, [loginState, currentTab]);

    useEffect(() => {
        if (loginState.loggedIn && loginState.username)
        {
            if (filterState !== prevFilterState.current) {
                fetchLogs(false /* scanForward */, logsPerPage, undefined, filterState, undefined);
            }
            else if (advancedFilterState !== prevAdvancedFilterState.current) {
                fetchLogs(false /* scanForward */, logsPerPage, undefined, undefined, advancedFilterState);
            }
        }

        prevFilterState.current = filterState;
        prevAdvancedFilterState.current = advancedFilterState;
    }, [loginState, filterState, advancedFilterState]);

    useEffect(() => {
        if (settings?.expandNotesByDefault) {
            setIsNotesExpanded(true);
        }
    }, [settings?.expandNotesByDefault]);

    async function wait(ms: number) {
        return new Promise(resolve => { setTimeout(resolve, ms); });
    }

    async function syncSleepLogs(period: TimePeriod) {
        if (!props.loginState.loggedIn) {
            return;
        }

        try {
            await accountService.syncConnectedAccounts("all", period);
        }
        catch (e) {
            if (e instanceof HttpException) {
                addStatus({
                    msg: `Syncing sleep logs failed. Reason: ${e.message}`,
                    type: StatusMessageType.Fail
                });
            }
            else {
                addStatus({
                    msg: `Syncing sleep logs failed. Please try again in a bit.`,
                    type: StatusMessageType.Fail
                });
            }
        }

        setFilterState(undefined);
        await fetchLogs(false /*scanForward*/, logsPerPage);
    }

    useEffect(() => {
        const syncInProgress = accountSettings && accountSettings.connectedAccounts.findIndex(device => device.status === "InProgress") !== -1;

        (async () => {
            if (syncInProgress) {
                try {
                    setIsSyncingLogs(true);

                    const stalledSync = accountSettings!.connectedAccounts
                        .filter(device => device.status === "InProgress")
                        .map(device => device.lastModifiedTime)
                        .filter(lmt => {
                            return lmt;
                        })
                        .filter(lmt => DateTime.now().diff(DateTime.fromISO(lmt!)).as("minutes") >= 2)
                        .length > 0;

                    if (stalledSync) {

                        await accountService.cancelSync(true /* failInProgressSyncs */);

                        return;
                    }
                    else {
                        await accountService.pollSyncState();
                    }
                }
                catch(e) {
                    addStatus({
                        msg: `Syncing sleep logs failed. Please try again in a bit.`,
                        type: StatusMessageType.Fail
                    });
                }
            }
            else {
                setIsSyncingLogs(false);
            }
        })();

    }, [accountSettings]);

    useEffect(() => {
        const inProgressSyncs = (accountSettings?.connectedAccounts ?? []).filter(connectedAccount => connectedAccount.status === "InProgress");
        let minLastSyncedRecord = "";
        let changed = false;

        if (inProgressSyncs.length > 0 && prevConnectedAccountsState.current) {
            for (const connectedAccount of inProgressSyncs) {
                const prev = prevConnectedAccountsState.current.find(prevState => prevState.type === connectedAccount.type);

                if (prev?.lastSyncedRecord !== connectedAccount?.lastSyncedRecord) {
                    if (connectedAccount?.lastSyncedRecord && !minLastSyncedRecord) {
                        minLastSyncedRecord = connectedAccount.lastSyncedRecord;
                        changed = true;
                    }
                    else if (connectedAccount?.lastSyncedRecord && connectedAccount.lastSyncedRecord < minLastSyncedRecord) {
                        minLastSyncedRecord = connectedAccount.lastSyncedRecord;
                        changed = true;
                    }
                }
            }
            
            if (changed) {
                setState(state => {
                    state.showRefreshAfterSyncButton = true;
                    state.refreshAfterSyncButtonDate = minLastSyncedRecord;
                });
            }
        }
        else {
            setState(state => {
                state.showRefreshAfterSyncButton = false;
                state.refreshAfterSyncButtonDate = "";
            });
        }

    }, [accountSettings?.connectedAccounts]);

    useEffect(() => {
        prevConnectedAccountsState.current = accountSettings?.connectedAccounts;
    }, [accountSettings?.connectedAccounts]);

    function formatNowLocal(): string {
        const now = new Date(Date.now());

        const month = now.getMonth() + 1;
        const monthStr = month >= 10 ? month : ("0" + month);

        const day = now.getDate();
        const dayStr = day >= 10 ? day : ("0" + day);

        const nowStr = `${now.getFullYear()}-${monthStr}-${dayStr}`;
        return nowStr;
    }

    async function onAddLog(event: any)
    {
        event.preventDefault();
        const sleepLogId = await addLog();
        if (sleepLogId) {
            setShowSleepLogModal({show: true, id: sleepLogId});
            navigate('modal');
        }
    }

    async function handleKeyDownOnPickDate(event: any) {
        if (event.key === "Enter") {
            await onAddLog(event);
        }
    }

    async function addLog(): Promise<string | undefined> {
        if (!loginState.loggedIn) {
            return undefined;
        }

        const element: any = document.querySelector("#new-log-date");

        if (!element) {
            return undefined;
        }

        if (!element.value) {
            addStatus({
                msg: "Date for new log is invalid.",
                type: StatusMessageType.Fail
            });

            return undefined;
        }

        let sleepLogToCreate = {
            date: element.value,
            notes: (settings && settings.useNotesTemplate) ? settings.notesTemplate : undefined,
            customValues: settings?.customMetrics?.map((metric): CustomMetric => ({ name: metric.name, type: metric.type})),
            primaryConnectedAccount: accountSettings?.primaryConnectedAccount
        };

        let sleepLog: SleepLog;

        try {
            sleepLog = await sleepLogHttpService.createSleepLog(
                sleepLogToCreate.date,
                sleepLogToCreate.notes,
                sleepLogToCreate.customValues,
                sleepLogToCreate.primaryConnectedAccount);
        }
        catch (e: any) {
            if (e instanceof HttpException) {
                addStatus({
                    msg: `Could not create the sleep log. Reason: ${e.message}`,
                    type: StatusMessageType.Fail
                });
            }
            else {
                addStatus({
                    msg: "Could not create the sleep log. Please retry again after refreshing the page.",
                    type: StatusMessageType.Fail
                });
            }

            return undefined;
        }

        setSleepLogState(prev => {
                let updatedLogs = [sleepLog, ...prev];
                return updatedLogs;
        });

        setShowSleepLogModal({show: true, id: sleepLog.id});

        sleepLogUpdateFlusher.current.setLastModifiedTime(sleepLog.id, sleepLog.lastModifiedTime!);

        return sleepLog.id;   
    }

    async function onDeleteLog(sleepLogId: string)
    {
        if (!loginState.loggedIn) {
            return;
        }

        try {
            await sleepLogHttpService.deleteSleepLog(sleepLogId);
        }
        catch (e: any) {
            addStatus({
                msg: "Deleting the sleep log failed. Please retry again after refreshing the page.",
                type: StatusMessageType.Fail
            });

            return;
        }

        setSleepLogState(prev => {
            let index = prev.findIndex(elem => elem.id === sleepLogId);

            console.log(`Deleting log ${sleepLogId} at index ${index}`);

            let updatedLogs = [...prev.slice(0, index), ...prev.slice(index + 1)];
            return updatedLogs;
        });
    }

    function onPickDate(e: any) {
        setNewSleepLogDate(e.target.value);
    }

    function getFocusedSleepLog(id: string): SleepLog {
        let sleepLog = sleepLogState.find(log => log.id === id);

        if (!sleepLog) {
            console.log("Could not find the sleep log id: " + id);
            console.log(sleepLogState);
            throw new Error();
        }

        return sleepLog;
    }

    function setNextFocusedSleepLog(curSleepLogId: string, direction: number) {
        let index = sleepLogState.findIndex(log => log.id === curSleepLogId);

        if (index === -1) {
            console.log(sleepLogState);
            throw new Error();
        }
        
        if (direction < 0 && index < sleepLogState.length - 1) {
            setShowSleepLogModal({show: true, id: sleepLogState[index + 1].id});
        }
        else if (direction > 0 && index > 0) {
            setShowSleepLogModal({show: true, id: sleepLogState[index - 1].id});
        }
    }

    async function handleChangePage(direction: number) {
        if (sleepLogState.length === 0) {
            return;
        }

        if (direction === 1 && sleepLogState.length < logsPerPage) {
            return;
        }

        if (isFetchingLogs.current) {
            return;
        }

        const date = direction === 1 ? sleepLogState[sleepLogState.length - 1].date : sleepLogState[0].date;
        const exclusiveStartId = SleepLogUtils.getIdFromDate(date);
        const scanForward = direction === -1;

        await fetchLogs(scanForward, logsPerPage, exclusiveStartId, filterState, advancedFilterState);

        if (direction > 0) {
            window.scrollTo({top: 0});
        }        
    }

    function updateSleepLogFromModal(sleepLog: SleepLog) {
        setSleepLogState(prev => {
            const i = prev.findIndex(log => log.id === sleepLog.id);
            if (i === -1) {
                return prev;
            }

            const updatedSleepLog = {...prev[i], ...sleepLog};

            const updatedSleepLogs = produce(prev, (draft: SleepLog[]) => {
                draft[i] = updatedSleepLog;
            });

            return updatedSleepLogs;
        });        
    }

    async function cancelSync() {
        try {
            await accountService.cancelSync(false /* failInProgressSyncs */);
        }
        catch (e) {
            addStatus({
                msg: "Cancelling sync failed.",
                type: StatusMessageType.Fail
            });
        }

        addStatus({
            msg: "Sync is being cancelled.",
            type: StatusMessageType.Info
        });        
    }

    return (
        <React.Fragment>
        {!showSleepLogModal.show &&
            <div className="position-fixed" style={{bottom: "12px", right: "12px", zIndex: 1054}}>
                <SavingChanges isSaving={isSaving} showSpinner={true} />
            </div>
        }
        <div className="">

            { connectedAccountSyncErrors.length > 0 &&
                <Alert className="mt-3 mb-0 py-1 text-sm" variant={"danger"}>
                    <i className="bi bi-exclamation-octagon-fill"></i>
                    <span className="ms-4">
                        There were errors when syncing data from: <b>{connectedAccountSyncErrors.map((e, i) => `${e.type}${i < connectedAccountSyncErrors.length - 1 ? ", " : ""}`)}</b>. 
                    </span>
                    <span> Error: {connectedAccountSyncErrors[0].infoMessage}</span>
                    <span className="fw-bold"> If this error persists after retrying syncing, reconnecting your device may fix this issue.</span>
                </Alert>                
            }

            <Row className="mt-3 justify-content-center">

                <Col xs="auto">
                    <OverlayTrigger placement={"top"} overlay={
                        <Tooltip id={`tooltip-top`}>
                            Create sleep log
                        </Tooltip>}>                        
                        <Button variant="sleep-table" className="shadow-sm" onClick={toggleShowAddComponent}>
                            <i className="bi bi-plus-lg" style={{"WebkitTextStrokeWidth": ".35px"}}></i>
                        </Button>
                    </OverlayTrigger>                        
                </Col>

                <Col xs="auto"> 
                    <OverlayTrigger placement={"top"} overlay={
                        <Tooltip id={`tooltip-top`}>
                            Sync latest sleep logs
                        </Tooltip>}>                        
                        <Dropdown as={ButtonGroup} className="shadow-sm">
                            <Button 
                                variant="sleep-table"
                                className=""
                                onClick={() => syncSleepLogs('latest')}
                            >
                                { isSyncingLogs ?
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span> </span>
                                    </>
                                    :
                                    <>
                                        <i className="bi bi-arrow-clockwise"></i>
                                        <span>&nbsp;&nbsp;</span>
                                    </>
                                }                 
                                Sync
                            </Button>
                            <Dropdown.Toggle split variant="sleep-table" id="dropdown-split-basic" />
                            <Dropdown.Menu className="text-sm shadow-sm">
                                <Dropdown.Item as="button" onClick={() => syncSleepLogs('latest')}>Sync latest</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={() => syncSleepLogs('lastWeek')}>Sync last week</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={() => syncSleepLogs('lastMonth')}>Sync last month</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={() => syncSleepLogs('lastYear')}>Sync last year</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={() => syncSleepLogs('all')}>Sync all time</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item as="button" onClick={() => navigate("/devices")}>View connected devices</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item as="button"
                                    className={classNames("text-danger fw-bold", { "opacity-25": !isSyncingLogs})}
                                    disabled={!isSyncingLogs}
                                    onClick={() => cancelSync()}
                                >
                                    Cancel sync
                                </Dropdown.Item>                                                       
                            </Dropdown.Menu>
                        </Dropdown>
                    </OverlayTrigger>                    
                </Col>
                <SimpleTooltip message="Import sleep data from a device"> 
                <Col xs="auto">
                        <LinkContainer to="/import">    
                                <Button variant="sleep-table" className="shadow-sm">
                                    <i className="bi bi-download"></i>
                                    <span>&nbsp;&nbsp;</span>
                                    <span>Import Data</span>
                                </Button>
                        </LinkContainer>
                    
                </Col>
                </SimpleTooltip>
            </Row>

            {showAddComponent &&
                <Row className="mt-4 justify-content-center">
                    <Col xs="auto">
                        <input type="date" id="new-log-date" name="add-log-date" value={newSleepLogDate} min="2010-01-01" max="2030-12-31"
                            onChange={onPickDate} onKeyDown={handleKeyDownOnPickDate} className="form-control"></input>
                    </Col>
                    <Col xs="auto">
                        <Button variant="primary" onClick={onAddLog}>Create Log</Button>
                    </Col>            
                </Row>
            }

            <Row className="mt-2 gx-0">
                <Col xs="auto" className="me-auto">
                    <div>
                        <Button variant="outline-secondary" size="sm" className="d-none" onClick={e => setIsNotesExpanded(!isNotesExpanded)}>
                            {isNotesExpanded ? "Unexpand Notes" : "Expand Notes" }
                        </Button>
                        { state.showRefreshAfterSyncButton &&
                        <button className="btn btn-sm btn-outline-primary" onClick={() => fetchLogs(false /*scanForward*/, logsPerPage)}>
                            <i className="bi bi-arrow-clockwise"></i>
                            <span> Logs synced to {FormatUtils.formatDate(state.refreshAfterSyncButtonDate)} ― Refresh </span>
                        </button>
                        }
                    </div>
                </Col>                
                <Col xs="auto" className="">
                    <SimpleTooltip message="Filter sleep logs">
                        <Button variant="outline-dark border-0 fs-5" size="sm" onClick={toggleShowFilters}>
                            <i className="bi bi-funnel-fill"></i>
                        </Button>
                    </SimpleTooltip>
                </Col>
                <Col xs="auto" className="">
                    <SimpleTooltip message="View and customize settings">
                        <Button variant="outline-dark border-0 fs-5" size="sm" onClick={e => setShowSleepLogSettings(!showSleepLogSettings)}>
                            <i className="bi bi-sliders"></i>
                        </Button> 
                    </SimpleTooltip>                        
                </Col>                            
            </Row>

            {showFilters &&
                <> 
                    <div className="text-center d-none">
                        <ToggleButtonGroup
                            type="radio"
                            name="sleep-log-filter"
                            size="sm"
                            value={useSimpleFilter ? ["simple"] : ["advanced"]}>
                            
                            <ToggleButton type="radio"
                                id="sleep-log-simple-filter" 
                                value="simple" 
                                variant="outline-secondary"
                                onChange={(e) => setUseSimpleFilter(true)}
                            >
                                Simple
                            </ToggleButton>

                            <ToggleButton type="radio"
                                id="sleep-log-advanced-filter"
                                value="advanced"
                                variant="outline-secondary"
                                onChange={(e) => setUseSimpleFilter(false)}
                            >
                                Custom
                            </ToggleButton>                        
                        </ToggleButtonGroup>
                    </div>
                    <div className="mt-2">
                        <SleepLogTimePeriodFilter
                            emitTimePeriod={(startDate, endDate) => setTimeRangeFilter({ startDate, endDate })}
                        />
                    </div>
                    <div className={`mt-2 ${!useSimpleFilter ? 'd-none' : ''}`}>                 
                        <SimpleSleepLogFilters
                            applyFilter={(filter) => { setFilterState(filter); setAdvancedFilterState(undefined); }}
                            timeRange={timeRangeFilter}
                        />
                    </div>
                    <div className={`mt-2 mb-3 ${useSimpleFilter ? 'd-none' : ''}`}>                 
                        <SleepLogFiltersHost
                            applyFilters={(startDate, endDate, filters) => {setAdvancedFilterState({startDate, endDate, filters}); setFilterState(undefined); }}
                            timeRange={timeRangeFilter}
                            showSpinner={showFetchLogSpinner}
                            showCache={false}
                            showApplyDifferentDay={false}
                            useCache={false}
                            lastCachedLog={""}
                            isCacheRefreshing={false}
                            onRefreshCache={() => 0}
                            toggleUseCache={() => 0}
                        />
                    </div>
                </>
            }

            { showSleepLogSettings &&
                <SleepLogSettingsModal 
                    show={showSleepLogSettings} 
                    onClose={() => setShowSleepLogSettings(false)} 
                    addStatus={addStatus}
                    loginState={loginState} 
                />
            }

            <Routes>
                    <Route 
                        path="/" 
                        element={ <Outlet /> }
                    />
                    <Route
                        path="modal" 
                        element={
                            showSleepLogModal.show && showSleepLogModal.id ?
                            <SleepLogModal 
                                show={showSleepLogModal.show}
                                loginState={loginState}
                                focusedSleepLog={getFocusedSleepLog(showSleepLogModal.id!)}
                                editable={true}
                                isSaving={isSaving}
                                recentSleepLogs={sleepLogs}                            
                                sleepLogUpdateFlusher={sleepLogUpdateFlusher.current}
                                handleClose={handleClose}
                                updateSleepLog={updateSleepLogFromModal}
                                setNextFocusedSleepLog={setNextFocusedSleepLog}
                                handleDeleteLog={onDeleteLog}
                                addStatus={addStatus}
                                openSettings={() => setShowSleepLogSettings(true)}
                            />
                            :
                            <Navigate to="/" />
                        }
                    />
                </Routes>

        </div>
        
        <div className="rounded tw-shadow ring-1 ring-gray-200 px-3 py-2" style={{backgroundColor: 'white'}}>
            <Row className="border-bottom gx-2">
                <Col xs="3" lg="2">
                    <OverlayTrigger placement={"top"} overlay={
                        <Tooltip id={`tooltip-top`}>
                            Date
                        </Tooltip>}>                        
                        <h5><i className="bi bi-calendar-date"></i></h5>    
                    </OverlayTrigger>                    
                </Col>
                <Col xs="6" lg="3" xl="2">
                    <OverlayTrigger placement={"top"} overlay={
                        <Tooltip id={`tooltip-top`}>
                            Sleep duration
                        </Tooltip>}>                        
                        <h5><i className="bi bi-clock"></i></h5> 
                    </OverlayTrigger>                         
                </Col>         
                <Col xs="3" lg="1" xl="1">
                    <OverlayTrigger placement={"top"} overlay={
                        <Tooltip id={`tooltip-top`}>
                            Subjective rating
                        </Tooltip>}>                        
                        <h5><i className="bi bi-star"></i></h5>
                    </OverlayTrigger>     
                </Col> 
                <Col sm="5" lg="6" xl="4" className="d-none d-lg-block">
                    <OverlayTrigger placement={"top"} overlay={
                        <Tooltip id={`tooltip-top`}>
                            Tags
                        </Tooltip>}>                        
                        <h5><i className="bi bi-tags"></i></h5>
                    </OverlayTrigger>                         
                </Col>
                <Col xl="3" className="d-none d-xl-block">
                    <OverlayTrigger placement={"top"} overlay={
                        <Tooltip id={`tooltip-top`}>
                            Notes
                        </Tooltip>}>                        
                        <h5><i className="bi bi-journal-text"></i></h5>
                    </OverlayTrigger>        
                </Col>
            </Row>        
            { showFetchLogSpinner &&
                <div className="text-center mt-4" style={{height: sleepLogState.length > 0 ? "auto" : "50vh"}}>
                    <span className="spinner-border text-secondary" role="status" aria-hidden="true"></span>
                </div>
            }
            { showEmptyMessage && sleepLogState.length === 0 &&
                <div className="py-4 text-center">
                    <div className="d-flex justify-content-center">
                        <ColoredIcon sizeInPx={64} bgColor="rgb(224 207 252)" fontColor="#3d0a91" borderRadius="16px">
                            <i className="bi bi-journal-plus"></i>
                        </ColoredIcon>
                    </div>
                    <div className="mt-2 fs-4">
                        You haven't created any sleep logs yet.
                    </div>
                    <div className="mt-2 text-muted">
                        Click the <i className="bi bi-plus-lg"></i> button to create a new log or you can import your device data by
                        clicking the 'Import Data' button above.
                    </div>
                </div>
            }
            { sleepLogState.map((sleepLog, index) => (
                <div key={sleepLog.id} className="border-bottom">
                    <SleepLogRow 
                        sleepLog={sleepLog} 
                        isNotesExpanded={isNotesExpanded}
                        setSleepLogState={setSleepLogState}
                        sleepLogUpdateFlusher={sleepLogUpdateFlusher.current}
                        sleepLogSettings={settings}
                        showMedicationDose={true}
                        onClick={handleShowSleepLogModal}
                    />
                </div>
            ))}
            <Row className="justify-content-center mt-4">
                <Col xs="auto" className="">
                    <Pagination>
                        <li className="page-item border-primary">
                            <a className="page-link px-4" role="button" onClick={() => handleChangePage(-1)}>
                                <span aria-hidden="true">‹</span>
                            </a>
                        </li>                    
                        <li className="page-item">
                            <a className="page-link px-4" role="button" onClick={() => handleChangePage(1)}>
                                <span aria-hidden="true">›</span>
                            </a>
                        </li>
                    </Pagination>
                </Col>    
            </Row>
        </div>
        </React.Fragment>
    );
}