import { useMemo } from "react";
import DateTimeUtils from "../../../../utils/DateTimeUtils";
import { formatTimeFromComponents } from "../../../../utils/FormatUtility";
import SleepLogUtils from "../../../../utils/SleepLogUtils";
import ISleepLogView from "../../../../types/ISleepLogView";
import SleepStagesUtils from "../../../../utils/SleepStagesUtils";

interface Props {
  sleepLogs: ISleepLogView[];
  filteredSleepLogs: ISleepLogView[];
  unfilteredSleepLogs: ISleepLogView[];
}

interface SleepTime {
  average: number;
  min: number;
  max: number;
}

export default function SleepWakeTable({sleepLogs: sleepLogs, filteredSleepLogs, unfilteredSleepLogs}: Props) {

  const averageSleepTime = useMemo(() => {
    const selectedSleepLogs = filteredSleepLogs.length > 0 ? filteredSleepLogs : sleepLogs;

    const filtered = selectedSleepLogs.filter(log => log.mainSleep)

    const intervals = filtered.map(log => {
        const mainSleepEpisode = log.mainSleep!;
        let { bedtime, sleeptime, waketime } = SleepStagesUtils.getSleepAndWakeupTime(mainSleepEpisode);
        bedtime = bedtime.setZone(mainSleepEpisode.timezone ?? "utc");
        sleeptime = sleeptime.setZone(mainSleepEpisode.timezone ?? "utc");
        waketime = waketime.setZone(mainSleepEpisode.timezone ?? "utc");

        return { bedtime, sleeptime, waketime };
    });

    const bedTimesInMins = intervals.map(interval => {
        return (interval.bedtime.hour * 60) + interval.bedtime.minute;
    });

    const sleepTimesInMins = intervals.map(interval => {
      return (interval.sleeptime.hour * 60) + interval.sleeptime.minute;
    });

    const wakeTimesInMins = intervals.map(interval => {
        return (interval.waketime.hour * 60) + interval.waketime.minute;
    });

    // 6pm in 24h time converted into minutes
    // This represents an arbitrary threshold that precede any earliest sleep time.
    const sleepThreshold = 18 * 60;

    // 3am, represents an arbitary threshold that precedes earliest wake time.
    const wakeThreshold = 3 * 60;

    let earliestBedTime: number = Infinity;
    let latestBedTime: number = sleepThreshold;    
    
    let earliestSleepTime: number = Infinity;
    let latestSleepTime: number = sleepThreshold;

    let earliestWakeTime: number = Infinity;
    let latestWakeTime: number = wakeThreshold;

    function difference(start: number, end: number) {
      return end >= start ? end - start : (24 * 60) - start + end;
    }

    bedTimesInMins.forEach(time => {
        if (difference(sleepThreshold, time) < difference(sleepThreshold, earliestBedTime)) {
          earliestBedTime = time;
        }
  
        if (difference(sleepThreshold, time) > difference(sleepThreshold, latestBedTime)) {
          latestBedTime = time;
        }
      });    

    sleepTimesInMins.forEach(time => {
      if (difference(sleepThreshold, time) < difference(sleepThreshold, earliestSleepTime)) {
        earliestSleepTime = time;
      }

      if (difference(sleepThreshold, time) > difference(sleepThreshold, latestSleepTime)) {
        latestSleepTime = time;
      }
    });

    wakeTimesInMins.forEach(time => {
      if (difference(wakeThreshold, time) < difference(wakeThreshold, earliestWakeTime)) {
        earliestWakeTime = time;
      }

      if (difference(wakeThreshold, time) > difference(wakeThreshold, latestWakeTime)) {
        latestWakeTime = time;
      }      
    });    

    return sleepTimesInMins.length > 0 && wakeTimesInMins.length > 0 ?
      {
        bedTime: {
            average: SleepLogUtils.averageTime(bedTimesInMins),
            min: earliestBedTime,
            max: latestBedTime            
        },
        sleep: {
          average: SleepLogUtils.averageTime(sleepTimesInMins),
          min: earliestSleepTime,
          max: latestSleepTime
        },
        wake: {
          average: SleepLogUtils.averageTime(wakeTimesInMins),
          min: earliestWakeTime,
          max: latestWakeTime          
        }
      }
      : undefined;

}, [sleepLogs, filteredSleepLogs]);  

  return (
    <>
    <div>
        <table className="table table-sm">
            <thead>
            <tr>
                <td></td>
                <td>Average</td> 
                <td>Earliest</td>        
                <td>Latest</td>
            </tr>
            </thead>
            <tbody>
                <SleepWakeTableRow title="Bedtime" stat={averageSleepTime?.bedTime} />
                <SleepWakeTableRow title="Sleep" stat={averageSleepTime?.sleep} />
                <SleepWakeTableRow title="Wake" stat={averageSleepTime?.wake} />
                <tr></tr>
            </tbody>
        </table>
    </div>
    </>
  );
}

function SleepWakeTableRow(props: {stat?: SleepTime, title: string}) {
    const {stat, title} = props;
    const avgTime = stat?.average ? DateTimeUtils.getHoursMins(stat.average) : undefined;
    const minTime = stat?.average ? DateTimeUtils.getHoursMins(stat.min) : undefined; 
    const maxTime = stat?.average ? DateTimeUtils.getHoursMins(stat.max) : undefined; 
  
    return (
        <tr style={{backgroundColor: "white"}}>
            <td className="ps-2">
                {title}
            </td>
            <td>
                {avgTime ?
                formatTimeFromComponents(avgTime[0], avgTime[1])
                :
                "--:--am"
                }
            </td>        
            <td>
                {minTime ?
                formatTimeFromComponents(minTime[0], minTime[1])
                :
                "--:--am"
                }
            </td>
            <td>
                {maxTime ?
                    formatTimeFromComponents(maxTime[0], maxTime[1])
                    :
                    "--:--am"
                }
            </td>                       
        </tr>
    );
  }