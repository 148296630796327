import { ChartOptions } from "chart.js";
import { useMemo } from "react";
import { useImmer } from "use-immer";
import ISleepLogView from "../../../types/ISleepLogView";
import { ChartStyles } from "./constants/chart-styles";
import ChartDeferred from 'chartjs-plugin-deferred';
import { Bar } from "react-chartjs-2";
import { HoursSleptBucketingStrategy, AwakeningCountBucketStrategy, TimeToFallAsleepBucketStrategy, SleepTimeBucketingStrategy, RatingBucketingStrategy, UserTagsBucketingStrategy, SleepMedTagBucketingStrategy, BucketAggregator, Category, IBucketingStrategy, Extractors, FeelingsBucketingStrategy, MonthBucketingStrategy, Aggregation, CustomValueBucketingStrategy } from "../../../utils/ComparisonChartUtils";
import SleepLogSettings from "../../../types/SleepLogSettings";
import { CustomValueUtils } from "../../../utils/CustomValueUtils";
import { MathUtils } from "../../../utils/MathUtils";

interface Props {
    sleepLogSettings?: SleepLogSettings;
    sleepLogs: ISleepLogView[];
}

interface State {
    xAxis: string;
    aggregation: Aggregation;
    yAxis: string;
}

export function ComparisonChart({ sleepLogSettings, sleepLogs }: Props) {
    const [state, setState] = useImmer<State>(() => ({
        xAxis: "HoursSlept",
        aggregation: "AVERAGE",
        yAxis: "AwakeningCount"
    }));

    const customValueOptions = useMemo(() => {
        return sleepLogSettings?.customMetrics?.map(customValue => CustomValueUtils.addSuffix(customValue.name)) ?? [];
    }, [sleepLogSettings?.customMetrics]);

    const comparisonChartBuilder = useMemo(() => {
        const xAxisSelector = convertCategoryOption(state.xAxis);
        const yAxisSelector = convertCategoryOption(state.yAxis);
        return new ComparisonChartBuilder(sleepLogs, sleepLogSettings, xAxisSelector, state.aggregation, yAxisSelector);
    }, [sleepLogs, state]);

    const options = useMemo(() => getOptions(), []);
    const data = useMemo(() => getDataset(comparisonChartBuilder?.getData(), bgColor, borderColor), [comparisonChartBuilder]);

    return (
        <div>
            <Bar data={data} plugins={[ChartDeferred]} options={options} />
            <div className="p-2"></div>
            <div className="p-1">X-axis</div>
            <select
                className="form-select form-select-sm w-auto"
                value={state.xAxis} 
                onChange={e => setState(state => { 
                    state.xAxis = e.target.value; 
                })}
            >
                <option value="Month">Month</option>
                <option value="HoursSlept">Hours Slept</option>
                <option value="AwakeningCount">Awakening Count</option>
                <option value="TimeToFallAsleep">Time to Fall Asleep</option>
                <option value="SleepTime">Sleep time</option>
                <option value="Rating">Rating</option>
                <option value="Feelings">Feeling</option>
                <option value="Tags">Tags</option>
                <option value="SleepMeds">Sleep medications</option>
                { customValueOptions.map(option => 
                    <option value={option}>{option}</option>
                )}
                
            </select>
            <div className="p-2"></div>
            <div className="p-1">Y-axis</div>
            <div className="d-flex column-gap-1">
                <select className="form-select form-select-sm w-auto"
                    value={state.aggregation}
                    onChange={e => setState(state => {
                        state.aggregation = e.target.value as any; 
                    })}                    
                >
                    <option value="AVERAGE">Average</option>
                    <option value="MIN">Min</option>
                    <option value="MAX">Max</option>
                    <option value="COUNT">Count</option>
                    <option value="STANDARD DEVIATION">Standard deviation</option>
                </select>
                { state.aggregation !== "COUNT" &&
                    <select
                        className="form-select form-select-sm w-auto"
                        value={state.yAxis}
                        onChange={e => setState(state => {
                            state.yAxis = e.target.value; 
                        })}
                    >
                        <option value="HoursSlept">Hours slept</option>
                        <option value="AwakeningCount">Awakening count</option>
                        <option value="TimeToFallAsleep">Time to fall asleep</option>
                        <option value="Rating">Rating</option>
                        <option value="SleepEfficiency">Sleep efficiency</option>
                        { customValueOptions.map(option => 
                            <option value={option}>{option}</option>
                        )}
                    </select>
                }
            </div>        
        </div>
    );
}

function convertCategoryOption(option: string): CategorySelector {
    if (CustomValueUtils.isCustom(option)) {
        return {
            category: "CustomValue",
            name: CustomValueUtils.removeSuffix(option)
        };
    }
    else {
        return { category: option as Exclude<Category, "CustomValue"> };
    }
}

function getPrimaryColor(opacity: number) {
    return `rgba(129, 140, 248, ${opacity})`;
}

const bgColor = getPrimaryColor(.75);
const borderColor = getPrimaryColor(.75);

function getOptions() {
    const options: ChartOptions<"bar"> = {
        plugins: {     
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const value = (context.raw ?? 0) as number;
                        return MathUtils.roundToDecimalPlace(value, 2).toString();     
                    }
                }
            },
            legend: {
                display: false
            },
            title: {
                display: false
            },      
        },    
        scales: {
          x: {
              grid: {
                color: "rgba(0, 0, 0, 0)",
              },
              stacked: true,
              ticks: {
                font: {
                    ...ChartStyles.axisFont
                },
              }
          },
          y: {
            beginAtZero: true,
            ticks: {
              font: {
                ...ChartStyles.axisFont
              },
              maxTicksLimit: 10
            },
            grid: {
                ...ChartStyles.gridYAxis,
          },        
            stacked: true,
          }      
        },  
    };

    return options;
}

function getDataset(values: { xLabel: string, value: number}[] | undefined, bgColor: string, borderColor: string) {

    let dataset = {
        label: "",
        data: values?.map(v => v.value) ?? [],
        backgroundColor: bgColor,
        borderColor: borderColor,
        borderWidth: 1,
        maxBarThickness: 100      
    };

    let data: any = {
        labels: values?.map(v => v.xLabel) ?? [],
        datasets: [dataset],
    };

    return data;
}


type CategorySelector = { category: Exclude<Category, "CustomValue"> } | { category: "CustomValue", name: string };

class ComparisonChartBuilder {
    constructor(
        sleepLogs: ISleepLogView[],
        sleepLogSettings: SleepLogSettings | undefined,
        xAxis: CategorySelector,
        aggregation: Aggregation,
        yAxis: CategorySelector)
    {
        const bucketingStrategy = ComparisonChartBuilder.getBucketingStrategy(xAxis, sleepLogs, sleepLogSettings);
        const aggregator = ComparisonChartBuilder.getAggregator(aggregation, yAxis, sleepLogSettings);

        for (const sleepLog of sleepLogs) {
            bucketingStrategy.add(sleepLog);
        }

        const buckets = bucketingStrategy.getBuckets();
        this.points = aggregator.aggregate(buckets);
    }

    getData() {
        return this.points;
    }

    private static getBucketingStrategy(
        selector: CategorySelector,
        sleepLogs: ISleepLogView[],
        sleepLogSettings: SleepLogSettings | undefined): IBucketingStrategy<ISleepLogView>
    {
        if (selector.category === "CustomValue") {
            return new CustomValueBucketingStrategy(sleepLogSettings!, sleepLogs, selector.name);
        }
        else {
            switch (selector.category) {
                case "Month":
                    return new MonthBucketingStrategy(sleepLogs);
                case "HoursSlept":
                    return new HoursSleptBucketingStrategy(sleepLogs);
                case "AwakeningCount":
                    return new AwakeningCountBucketStrategy(sleepLogs);
                case "TimeToFallAsleep":
                    return new TimeToFallAsleepBucketStrategy(sleepLogs);
                case "SleepTime":
                    return new SleepTimeBucketingStrategy(sleepLogs);
                case "Rating":
                    return new RatingBucketingStrategy(sleepLogs);
                case "Tags":
                    return new UserTagsBucketingStrategy(sleepLogs);
                case "SleepMeds":
                    return new SleepMedTagBucketingStrategy(sleepLogs);
                case "Feelings":
                    return new FeelingsBucketingStrategy(sleepLogs);
                default:
                    throw new Error("Unrecognized category");
            }
        }
    }

    private static getAggregator(aggregation: Aggregation, selector: CategorySelector, sleepLogSettings?: SleepLogSettings) {
        if (selector.category === "CustomValue") {
            const customValueTemplate = sleepLogSettings?.customMetrics?.find(customValue => customValue.name === selector.name)!;
            const extractor = Extractors.customValue(selector.name, customValueTemplate.type);
            return new BucketAggregator(aggregation, extractor);
        }
        else {
            switch (selector.category) {
                case "HoursSlept":
                    return new BucketAggregator(aggregation, Extractors.hoursSlept);
                case "AwakeningCount":
                    return new BucketAggregator(aggregation, Extractors.awakeningCount);
                case "TimeToFallAsleep":
                    return new BucketAggregator(aggregation, Extractors.timeToFallAsleep);
                case "Rating":
                    return new BucketAggregator(aggregation, Extractors.rating);
                case "SleepEfficiency":
                    return new BucketAggregator(aggregation, Extractors.mainSleepEfficiency);
                default:
                    throw new Error("Unrecognized category");
            }
        }
    }

    private points: { xLabel: string, value: number }[];
}