import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SleepLog from "../../../../types/SleepLog";
import SortedTagCountChart, { SortedTags } from "./SortedTagCountChart";
import SortedTagsChartV2 from "./SortedTagsChartV2";

interface Props {
  sleepLogs: SleepLog[];
  limit?: number;
  showTitle?: boolean;
}

export default function FeelingTagCountChart(props: Props) {

  const {sleepLogs} = props;

  const [feelingCountData, setFeelingCountData] = useState<SortedTags>([]);  

  const barColor = '#fb7185';
  const bgColor = "#ffe4e6";

  useEffect(() => {
    let tagCounts: any;
    tagCounts = calculateTagCount(sleepLogs); 

    setFeelingCountData(tagCounts);

    function calculateTagCount(sleepLogs: SleepLog[]) {
      let feelingCount: Record<string, number> = {}       
  
      sleepLogs.forEach(sleepLog => {
        if (sleepLog.feelings) {
          sleepLog.feelings.forEach(f => {
            if (!(f.feeling in feelingCount)) {
              feelingCount[f.feeling] = 0;
            }
  
            feelingCount[f.feeling]++;
          });
        }      
      }); 
  
      const mostCommonFeelings: SortedTags = Object.keys(feelingCount).map(f => {return {name: f, count: feelingCount[f]}; })
        .sort((lhs: any, rhs: any) => rhs.count - lhs.count);
  
      
      return mostCommonFeelings;
    }    

  }, [sleepLogs]);

  return (
    <div>
      <SortedTagsChartV2 
        chartTitle={props.showTitle ? "How I've been feeling" : undefined}
        sortedTags={feelingCountData}
        numDays={sleepLogs.length}
        progressBarColor={barColor}
        backgroundColor={bgColor}
        limit={props.limit} />
    </div>  
  );
}