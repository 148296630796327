export class ColorUtils {
    static getRatingColor(rating: number, opacity: number) {
        // Ensure rating is within bounds
        if (rating < 0) rating = 0;
        if (rating > 10) rating = 10;

        // Get the color from the map
        let color = ratingColorMap.get(rating)!;

        // Replace 'X' with the opacity value
        color = color.replace('X', opacity.toString());

        return color;
    }
}

const ratingColorMap = new Map([
    [0, "rgba(220, 53, 69, X)"], 
    [1, "rgba(227, 93, 106, X)"], 
    [2, "rgba(234, 134, 143, X)"], 
    [3, "rgba(253, 126, 20, X)"], 
    [4, "rgba(253, 152, 67, X)"], 
    [5, "rgba(255, 193, 7, X)"],
    [6, "rgba(255, 205, 57, X)"], 
    [7, "rgba(117, 183, 152, X)"],
    [8, "rgba(71, 159, 118, X)"], 
    [9, "rgba(25, 135, 84, X)"],    
    [10, "rgba(20, 108, 67, X)"],                    
]);