import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './app/App';

import './styles/bootstrap-style-overrides.css';
import './styles/global-styles.css';
import './styles/utility-styles.css';
import './styles/tailwind-styles.css';

const container = document.getElementById('root');
const root = createRoot(container);

if (container.hasChildNodes()) {
    root.hydrate(
        //<React.StrictMode>
        <BrowserRouter>
        <App />
        </BrowserRouter>
        //</React.StrictMode>
    )
}
else {
    root.render(
        //<React.StrictMode>
            <BrowserRouter>
            <App />
            </BrowserRouter>
        //</React.StrictMode>
    );
}
