import { useContext, useState } from "react";
import { useImmer } from "use-immer";

import { Container, Row, Col, Accordion, Button, Form } from "react-bootstrap";
import { StatusMessage, StatusMessageType } from "../../common/StatusMessages";
import { AccountHttpServiceContext } from "../../http/account-http-service.provider";
import { HttpException } from "../../../types/Exceptions";
import useStore from "../../../shared/store/useStoreService.hook";
import { AccountProperties } from "../../../types/AccountSettings";
import { AccountServiceContext } from "../services/account-service.provider";
import AccountService from "../services/account.service";
import { LoginStateContext } from "../../../app/login-state.context";
import AppPage from "../../../app/AppPage";

interface Props {
  addStatus: (msg: StatusMessage) => any;
}

interface UpdateEmailState {
    password: string;
    newEmail: string;
}

export default function AccountSettings(props: Props) {
    const { addStatus } = props;
    const [accountService, accountProperties] = useStore<AccountService, AccountProperties | undefined>(AccountServiceContext);
    const accountHttpService = useContext(AccountHttpServiceContext)!;
    const [loginState, _] = useContext(LoginStateContext);

    const [updateEmailState, setUpdateEmailState] = useImmer<UpdateEmailState>({ password: "", newEmail: "" });

    async function updatePassword(e: any) {
        e.preventDefault();

        let password = (document.querySelector("#current-password") as HTMLInputElement).value;
        let newPassword = (document.querySelector("#update-password") as HTMLInputElement).value;     
        let newConfirmedPassword = (document.querySelector("#update-confirm-password") as HTMLInputElement).value;
        
        if (!password || !newPassword || !newConfirmedPassword) {
            addStatus({type: StatusMessageType.Fail, msg: "Updating password failed: password and new password are required fields!"});
            return;     
        }

            if (newPassword !== newConfirmedPassword) {
            addStatus({type: StatusMessageType.Fail, msg: "The confirmed password does not match the new password."});
            return; 
        }

        try {
            await accountHttpService.updatePassword(password, newPassword);
            addStatus({type: StatusMessageType.Success, msg: "Your password was successfully updated."});
        }
        catch (e) {
            if (e instanceof HttpException) {
                addStatus({type: StatusMessageType.Fail, msg: `Update password failed. Reason: ${e.message}`});
            }
            else {
                addStatus({type: StatusMessageType.Fail, msg: "Updating password failed. Please refresh the page or try again later."});     
            }
        }  
    }

    async function updateEmail(e: any) {
        e.preventDefault();

        try {
            await accountService.updateEmail(updateEmailState.password, updateEmailState.newEmail);
            addStatus({type: StatusMessageType.Success, msg: "Your email was successfully updated."});
        }
        catch (e) {
            if (e instanceof HttpException) {
                addStatus({type: StatusMessageType.Fail, msg: `Update password failed. Reason: ${e.message}`});
            }
            else {
                addStatus({type: StatusMessageType.Fail, msg: "Updating password failed. Please refresh the page or try again later."});     
            }
        }         
    }

    async function sendResetEmail() {
        if (!loginState.loggedIn) {
            return;
        }

        try {
            await accountHttpService.sendResetPasswordEmail(loginState.username);
            addStatus({type: StatusMessageType.Info, msg: `An email has been sent.`});
        }
        catch (e) {
            if (e instanceof HttpException) {
                addStatus({type: StatusMessageType.Fail, msg: `Resetting password failed. Reason: ${e.message}`});
            }
            else {
                addStatus({type: StatusMessageType.Fail, msg: "Resetting password failed. Please try again later."});     
            }            
        }
    }

    return (
        <AppPage className="app-bg-light pt-4 pb-5">
            <Container className="">
            <Row>
                <Col>
                <h2 className="">Account Settings</h2>
                <div className="mt-2 border-bottom"></div>
                </Col>
            </Row>   
            <Row className="mt-5">     
                <Col>
                <Accordion className="bg-white border-0">

                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Update Password
                        </Accordion.Header>
                        <Accordion.Body>
                            <Container>
                            <Row>
                                <Col xs="3"></Col>
                                <Col xs="6">
                                <Form>
                                    <Form.Group controlId="current-password" className="mt-3 text-sm">
                                    <Form.Label>Current password</Form.Label>
                                    <Form.Control type="password" placeholder="Password" />
                                    </Form.Group>      
                                    <Form.Group controlId="update-password" className="mt-3 text-sm">           
                                    <Form.Label>New password</Form.Label>
                                    <Form.Control type="password" placeholder="New password" /> 
                                    </Form.Group>
                                    <Form.Group controlId="update-confirm-password" className="mt-3 text-sm">           
                                    <Form.Label>Confirm new password</Form.Label>
                                    <Form.Control type="password" placeholder="New password" /> 
                                    </Form.Group>                                                         
                                    <div className="text-center my-4">
                                    <Button variant="primary" type="submit" onClick={updatePassword}>Update</Button>
                                    </div>
                                </Form>
                                </Col>
                                <Col xs="3"></Col>
                            </Row>
                            </Container>
                        </Accordion.Body>            
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            Reset Password
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="text-center">
                                To reset your password, an email will be sent to your inbox containing a reset link.
                            </div>
                            <div className="text-center my-4">
                                <Button type="submit"
                                    variant="primary"
                                    disabled={!accountProperties?.email}
                                    onClick={sendResetEmail}
                                >
                                    Send Email
                                </Button>
                            { accountProperties?.email ?
                                <div className="mt-4">
                                    An email will be sent to {accountProperties.email}.
                                </div>
                                :
                                <div className="mt-4 text-danger">
                                    You have not added an email address to your account yet.
                                </div>
                            }

                            </div>
                        </Accordion.Body>            
                    </Accordion.Item>                

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            Update Email
                        </Accordion.Header>

                        <Accordion.Body>
                            <Row>
                                <Col xs="3"></Col>
                                <Col xs="6">
                                    <Form>
                                        <Form.Group className="mt-3 text-sm">
                                            <Form.Label>Current email</Form.Label>
                                            <Form.Control type="input" disabled={true} value={accountProperties?.email} />
                                        </Form.Group>      
                                        <Form.Group className="mt-3 text-sm">           
                                        <Form.Label>New email</Form.Label>
                                            <Form.Control type="input" 
                                                placeholder="user@example.com" 
                                                value={updateEmailState.newEmail}
                                                onChange={(e) => setUpdateEmailState(state => { state.newEmail = e.target.value; }) }                                            
                                            /> 
                                        </Form.Group>
                                        <Form.Group className="mt-3 text-sm">           
                                            <Form.Label>Confirm password</Form.Label>
                                            <Form.Control type="password" 
                                                placeholder="Password"
                                                value={updateEmailState.password}
                                                onChange={(e) => setUpdateEmailState(state => { state.password = e.target.value; }) }  
                                            /> 
                                        </Form.Group>                                                         
                                        <div className="text-center my-4">
                                        <Button variant="primary " type="submit" onClick={updateEmail}>Update</Button>
                                        </div>
                                    </Form>
                                </Col>
                                <Col xs="3"></Col>
                                </Row>                
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>        
                </Col>
            </Row>      
            </Container>
        </AppPage>
    );
}