import { DateTime } from "luxon";
import SleepEpisodeEditor from "../services/SleepEpisodeEditor";
import { FitbitSleepLevel, FitbitClassicSleepLevel, FitbitSleepData } from "../types/FitbitData";
import { SleepSummary, SleepStage, SleepEpisode, SleepStageType } from "../types/SleepLog";
import DateTimeUtils from "../utils/DateTimeUtils";
import SleepStagesUtils from "../utils/SleepStagesUtils";

export default class FitbitAdapter {
    constructor(private sleepEpisodes: FitbitSleepData[] | undefined) {
    }

    public getSleepSummary(shortDataThreshold: number, inferMainSleep: boolean) {
        if (!this.sleepEpisodes) {
            return undefined;
        }

        const sleepSummary: SleepSummary = {
            episodes: []
        };

        const calculateMinutesAsleep = (stages: SleepStage[]) => {
            return stages
                .filter(stage => stage.type !== "awake")
                .map(stage => stage.seconds / 60)
                .reduce((prev, cur) => prev + cur, 0);
        };

        const calculateMinutesAwake = (stages: SleepStage[]) => {
            return stages
                .filter(stage => stage.type === "awake")
                .map(stage => stage.seconds / 60)
                .reduce((prev, cur) => prev + cur, 0);
        };
        
        sleepSummary.episodes = this.sleepEpisodes.map(fitbitSleep => {

            if (fitbitSleep.type === 'stages') {
                const stages = fitbitSleep.levels.data.map(fitbitStage => {
                    const stage: SleepStage = {
                        start: FitbitAdapter.reformat(fitbitStage.dateTime),
                        seconds: fitbitStage.seconds,
                        type: FitbitAdapter.mapModernSleepStage(fitbitStage)
                    };

                    return stage;
                });

                const minutesAsleep = calculateMinutesAsleep(stages);
                const minutesAwake = calculateMinutesAwake(stages);

                let episode: SleepEpisode = {
                    start: FitbitAdapter.reformat(fitbitSleep.startTime),
                    end: FitbitAdapter.reformat(fitbitSleep.endTime),
                    isMainSleep: fitbitSleep.isMainSleep,
                    minutesAsleep: minutesAsleep,
                    minutesAwake: minutesAwake,
                    sleepStages: stages
                };

                const episodeEditor = new SleepEpisodeEditor(episode);

                
                const shortStages = fitbitSleep.levels.shortData
                    .filter(stage => stage.seconds >= shortDataThreshold)
                    .map(fitbitStage => {
                        const stage: SleepStage = {
                            start: FitbitAdapter.reformat(fitbitStage.dateTime),
                            seconds: fitbitStage.seconds,
                            type: FitbitAdapter.mapModernSleepStage(fitbitStage)
                        };
    
                        return stage;
                    });

                episodeEditor.addStages(shortStages);
                    
                return episode;
            } 
            else /* classic */ {
                const stages = fitbitSleep.levels.data.map(fitbitStage => {
                    const stage: SleepStage = {
                        start: FitbitAdapter.reformat(fitbitStage.dateTime),
                        seconds: fitbitStage.seconds,
                        type: FitbitAdapter.mapClassicSleepStage(fitbitStage)
                    }

                    return stage;
                });

                const minutesAsleep = calculateMinutesAsleep(stages);
                const minutesAwake = calculateMinutesAwake(stages);

                const episode: SleepEpisode = {
                    start: FitbitAdapter.reformat(fitbitSleep.startTime),
                    end: FitbitAdapter.reformat(fitbitSleep.endTime),
                    timezone: "utc", // Fitbit has no timezone, so infer as UTC
                    isMainSleep: fitbitSleep.isMainSleep,
                    minutesAsleep: minutesAsleep,
                    minutesAwake: minutesAwake,
                    sleepStages: stages
                }

                return episode;
            }
        });

        if (inferMainSleep) {
            // Fitbit only has one main sleep episode even if a person
            // has a 2-3 hour awakening then falling asleep. Treat near
            // sleep episodes as being the main sleep
            const mainSleep = SleepStagesUtils.CoalesceMainSleep(
                sleepSummary.episodes, 
                SleepStagesUtils.DEFAULT_COMBINE_THRESHOLD, 
                true /* includeFirstAndLastAwakenings */
            );

            for (const episode of sleepSummary.episodes) {
                if (mainSleep.mainEpisodes?.includes(episode)) {
                    episode.isMainSleep = true;
                }
            }
        }

        sleepSummary.episodes = sleepSummary.episodes.sort((lhs, rhs) => +DateTime.fromISO(lhs.start) - +DateTime.fromISO(rhs.start));
        return sleepSummary;
    }

    private static reformat(date: string) {
        // Treat times as if they are in UTC for display. Fitbit times are zoneless.
        return DateTimeUtils.format(DateTime.fromISO(date, { zone: "utc" }), "YYYY-MM-DDTHH:MM:SSZ");
    }

    private static mapModernSleepStage(stage: FitbitSleepLevel): SleepStageType {
        switch (stage.level) {
            case "wake":
                return "awake";
            case "light":
                return "light";
            case "deep":
                return "deep";
            case "rem":
                return "rem";
            default:
                return "awake";
        }
    }

    private static mapClassicSleepStage(stage: FitbitClassicSleepLevel): SleepStageType {
        switch (stage.level) {
            case "awake":
                return "awake";
            case "restless":
                return "awake";
            case "asleep":
                return "asleep";
            default:
                return "awake";
        }
    }
}