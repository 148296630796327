import DateTimeUtils from "../utils/DateTimeUtils";
import { splitDate } from "../utils/FormatUtility";

export default class SimpleDate {
  readonly year: number;
  readonly month: number;
  readonly day: number;

  // Not used yet
  readonly timezone?: string;

  constructor(private readonly date: string) {
    const [year, month, day] = splitDate(date);
    this.year = year;
    this.month = month;
    this.day = day;
  }

  addDays(numDays: number): SimpleDate {
    const utcDate = this.utcDate;
    const result = DateTimeUtils.addDays(utcDate, numDays);

    const newDate = SimpleDate.createDateString(result);
    return new SimpleDate(newDate);
  }

  get utcDate(): Date {
    return new Date(Date.UTC(this.year, this.month - 1, this.day));
  }

  /**
   * Returns date with format: YYYY-MM-DD.
   */
  get asString(): string {
    return this.date;
  }

  equals(other: SimpleDate) {
    return this.date === other.date;
  }

  private static createDateString(utcDate: Date) {
    const month = utcDate.getUTCMonth() + 1;
    const monthText = (month < 10 ? '0' : '') + month;

    const day = utcDate.getUTCDate();
    const dayText = (day < 10 ? '0' : '') + day;

    return `${utcDate.getUTCFullYear()}-${monthText}-${dayText}`;
  }
}