import _ from "lodash";
import { DateTime } from "luxon";
import DateTimeUtils from "./DateTimeUtils";
import { AggregationSummary } from "../types/types";

export default class AnalyticsUtils {
  static calculateAverage(values: number[]): number {
    if (values.length === 0) {
      return 0;
    }

    let sum = 0;
    let count = 0;

    values.forEach(v => {
      sum += v;
      count++;
    });

    if (count === 0) {
      return -1;
    }

    return sum / count;
  }

  static calculateMinMax(values: number[]): [number, number] {
    if (values.length === 0) {
      return [0, 0];
    }

    let minValue: number = Infinity;
    let maxValue: number = 0;

    values.forEach(v => {
      if (v !== undefined) {
        minValue = Math.min(minValue, v);
        maxValue = Math.max(maxValue, v);
      }
    });    

    return [minValue, maxValue];
  }
  
  static calculateStandardDeviation(values: number[], mean?: number) {
    if (values.length <= 1) {
      return 0;
    }

    const narrowedMean = mean !== undefined? mean : values.reduce((a, b) => a + b) / values.length;

    let sum = 0;

    values.forEach(v => {
      sum += Math.pow(v - narrowedMean, 2);
    });

    // values.length - 1 to compensate for bias
    return Math.sqrt(sum / (values.length - 1));
  }

  static calculateAbsoluteDeviation(values: number[], mean: number) {
    if (values.length <= 1) {
      return 0;
    }

    let sum = 0;

    values.forEach(v => {
      sum += Math.abs(v - mean);
    });

    return sum / (values.length);
  }

    /**
     * Fill in a range of dates in form of YYYY-MM-DD
     * @param start - String format YYYY-MM-DD
     * @param end - same as start
     */
    static fillDateRange(start: string, end: string) {
        if (!DateTimeUtils.isValid_YYYY_MM_DD(start) || !DateTimeUtils.isValid_YYYY_MM_DD(end)) {
            console.log(`Invalid date format: ${start}, ${end}`);
            throw Error(`Invalid date format: ${start}, ${end}`);
        }

        // UTC to avoid changing time offsets, e.g., PDT to PST
        const startDate = DateTime.fromISO(start);
        const endDate = DateTime.fromISO(end);

        if (endDate < startDate) {
            throw Error("endDate < startDate");
        }

        const dates = [];
        let cur = startDate;

        while (cur <= endDate) {
            const formattedDate = DateTimeUtils.format(cur, 'YYYY-MM-DD');
            dates.push(formattedDate);

            cur = cur.plus({ days: 1 });
        }

        return dates;
    }

    static movingAverage(dates: string[], values: (number | undefined)[], numDays: number, threshold: number) {
        const result: (number | undefined)[] = [];

        for (let i = 0; i < values.length; i++) {
            if (i < numDays - 1) {
                result.push(undefined);
                continue;
            }

            let sum = 0;
            let start = i;
            let count = 0;        
            const endDate = DateTime.fromISO(dates[i], { zone: 'UTC' });

            while (start >= 0 && endDate.diff(DateTime.fromISO(dates[start], { zone: 'UTC'}), 'days').toObject().days! < numDays) {
                const value = values[start];
                if (value !== undefined) {
                    sum += value;
                    count++;
                }
                
                start--;
            }

            const average = count >= threshold && values[i] !== undefined ? sum / (count) : undefined;
            result.push(average);
        }
        
        return result;
    }

    /**
     * Calculates weekly/monthly aggregation. Date is start date of the aggregation, e.g. 2022-04-01 for monthly.
     * @param inputDates 
     * @param inputValues 
     * @param period 
     * @param threshold - number of samples in aggregation to compute a value otherwise undefined.
     * @returns 
     */
    static aggregation(inputDates: string[], inputValues: (number | undefined)[], period: AggregationSummary, threshold: number) {
        const dates: DateTime[] = [];
        const counts: number[] = [];
        const values: (number | undefined)[] = [];

        if (inputDates.length === 0) {
            return { dates: inputDates, values };
        }

        if (inputDates.length !== inputValues.length) {
            throw Error("length mismatch");
        }

        // UTC to avoid changing time offsets, e.g., PDT to PST
        const start = DateTime.fromISO(inputDates[0], { zone: 'UTC'} );
        const endDate = DateTime.fromISO(inputDates[inputDates.length - 1], { zone: 'UTC'} );
        let cur = start;

        const getAggregationDate = (date: DateTime) => {
            switch (period) {
                case "week":
                    return date.set({ weekday: 1})
                case "month":
                    return date.set({day: 1});
                case "quarter":
                    return date.set({ month: Math.floor((date.month - 1) / 3) * 3 + 1, day: 1 });
            }
        };

        for (let i = 0; i < inputDates.length; i++) {
            const date = DateTime.fromISO(inputDates[i], { zone: 'UTC' });
            const value = inputValues[i];

            const aggregationDate = getAggregationDate(date);
            const lastAggregationDate = dates.length > 0 ? dates[dates.length - 1] : undefined;

            if (!lastAggregationDate || +lastAggregationDate !== +aggregationDate) {
                dates.push(aggregationDate);
                values.push(value);
                counts.push(value !== undefined ? 1 : 0);

            }
            else if (value !== undefined) {
                const l = values.length - 1;
                const last = values[l];
                
                if (last !== undefined) {
                    values[l] = value + last;
                }
                else {
                    values[l] = value;
                }

                counts[l]++;
            }
        }

        return {
            dates: dates.map(date => DateTimeUtils.format(date, 'YYYY-MM-DD')),
            values: _.zip(values, counts).map(v => v[1] != null && v[1] >= threshold ? (v[0] ?? 0) / (v[1] ?? 1) : undefined)
        }
    }
}