import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';

import BrandLogo from "./../../common/BrandLogo";
import { StatusMessage, StatusMessageType } from '../../common/StatusMessages';
import { useContext, useEffect } from 'react';
import AppPage from '../../../app/AppPage';
import { AccountHttpServiceContext } from '../../http/account-http-service.provider';
import { HttpException } from '../../../types/Exceptions';
import { logger } from '../../../logger/EventLogger';

interface Props {
  addStatus: (msg: StatusMessage) => any;
  setLoggedIn: (username: string) => void;
}

export default function LoginView({addStatus, setLoggedIn} : Props) {

    const accountHttpService = useContext(AccountHttpServiceContext)!;

    useEffect(() => {
        var urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has("showDemo")) {
            login("demo_account", "");
        }
    }, []);

    useEffect(() => {
        logger.log("View Login Page");
    }, []);  

    function onLogin(e: any) {
        e.preventDefault();

        let username = (document.querySelector("#login-username") as HTMLInputElement).value;
        let password = (document.querySelector("#login-password") as HTMLInputElement).value;

        if (!username || !password) {
        return;            
        }

        login(username, password);
    }

    async function login(username: string, password: string) {
        try {
            await accountHttpService.createSession(username, password);
            setLoggedIn(username);
        }
        catch (e: any) {
            logger.log("User Login Failed", { username: username });

            if (e instanceof HttpException) {
                if (e.statusCode >= 500 && e.statusCode <= 599) {
                    addStatus({type: StatusMessageType.Fail, msg: "An unexpected error occured. Please try again later."});
                }
                else {
                    addStatus({type: StatusMessageType.Fail, msg: "Username or password is incorrect."});
                }
            }
            else {
                addStatus({type: StatusMessageType.Fail, msg: "Login failed. Could not connect to the backend server. Please try again."});
            }
        }
    }

  return (
    <AppPage className="app-bg-light pt-5">
        <Container className="">
            <div className="justify-content-center d-flex">
                <div style={{width: "400px"}}>
                    <div className="mt-4 py-4 px-5 rounded bg-white contrast-shadow">
                        <BrandLogo />
                        <div className="mt-3"></div>
                        <h4 className="text-center fw-semibold">Login</h4>
                        <Form>
                            <Form.Group controlId="login-username" className="mt-4 text-xs">
                            <Form.Label style={{fontWeight: 500}}>Username</Form.Label>
                            <Form.Control type="text" className="input-underline text-sm" placeholder="Enter username" />
                            </Form.Group>
                            <Form.Group controlId="login-password" className="mt-3 text-xs">
                            <Form.Label style={{fontWeight: 500}}>Password</Form.Label>
                            <Form.Control type="password" className="input-underline text-sm" placeholder="Enter password" />           
                            </Form.Group>
                            <div className="text-center mt-4">
                            <Button 
                                variant="primary"
                                type="submit"
                                className="form-control shadow-sm fw-semibold"
                                onClick={onLogin}
                            >
                                Log In
                            </Button>
                            </div>           
                        </Form>

                        <p className="mt-5 text-center text-xs">
                            <span>Don't have an account? Click </span>
                            <LinkContainer to="/signup">
                            <a href="#">here</a>
                            </LinkContainer>
                            <span> to signup.</span>
                        </p>

                        <p className="mt-3 text-center text-xs">Forgot password? Click <LinkContainer to="/forgot-password"><a href="#">here</a></LinkContainer> to reset.</p>          
                    </div>
                </div>       
            </div>
        </Container>
    </AppPage>
  );
}