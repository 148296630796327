import { Button, Modal } from "react-bootstrap";

interface Props {
  show: boolean;
  confirm: () => void;
  close: () => void;
}

export default function ResetSleepEpisodesDialog({ show, confirm, close }: Props) {
  return (
    <Modal 
        centered
        show={show}
        onHide={close}
        dialogClassName="sleep-log-modal-confirm"
        contentClassName="shadow"
        style={{zIndex: 5000}}
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title>Reset to device</Modal.Title>
        <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={close}></button>
      </Modal.Header>
      
      <Modal.Body className="text-sm">Are you sure you want to delete your edited sleep episodes and reset to your device data?</Modal.Body>

      <Modal.Footer>
        <Button variant="danger" size="sm" onClick={() => confirm() }>Confirm</Button>
        <Button variant="secondary" size="sm" onClick={() => close() }>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}