import logo from './../../assets/pie-chart-128px.png';

export default function BrandLogo() {
  return (
    <div className="text-center">
      <img
        src={logo}
        alt="A description of the image."
        width="38"
        height="38"
        style={{ verticalAlign: "middle"}}
      />
      <span className="" style={{fontSize: "38px", color: "#531dab", verticalAlign: "middle", fontWeight: 600}}> Sleepchart</span>
    </div>
  );
}