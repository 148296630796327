import React from "react";

export default function ChangeArrow(props: {val: number, baseLine: number | undefined}) {
  const {val, baseLine} = props;

  return (
    <span className="fs-5" style={{"WebkitTextStrokeWidth": ".35px"}}>
      {baseLine !== undefined && val > baseLine &&
        <i className="bi bi-arrow-up-short text-success"></i>
      }
      {baseLine !== undefined && val < baseLine &&
        <i className="bi bi-arrow-down-short text-danger"></i>
      }
      {baseLine !== undefined && val === baseLine &&
        <i className="bi bi-arrow-up-short d-none"></i>
      }
    </span>
  );
}