import { Container, Row, Col, Button } from "react-bootstrap";
import React, { useEffect } from "react";
import AppPage from "../../app/AppPage";
import { LinkContainer } from "react-router-bootstrap";
import { logger } from "../../logger/EventLogger";

import "./styles/styles.css";
import annotationsImg from './../../assets/annotation_screenshot.png';
import chartImg from './../../assets/chart_screenshot.png';
import filterImg from './../../assets/filter_screenshot.png';
import editStagesImg from './../../assets/edit_screenshot.png';
import connectImg from './../../assets/connect_screenshot.png';
import productImg from './../../assets/product_screenshot.png';
import ColoredIcon from "../common/ColoredIcon";

export default function LandingPage() {

    useEffect(() => {
        logger.log("View Landing Page");
    }, []);

    function navigateToDemo() {
        logger.log("View Demo");
        window.open("https://demo.sleepchart.io/login?showDemo=1", "_blank");
    }

    const marginTop = { marginTop: "5rem" };

    const jumbotron: React.CSSProperties = { 
        fontSize: "3rem",
        fontWeight: 600,
        lineHeight: "1",
        position: "relative"
    };  

    const jumbotronDescription: React.CSSProperties = {
        fontSize: "1.25rem",
        fontWeight: 500,
    };

    const subtitle: React.CSSProperties = {
        fontSize: "1.75rem",
        fontWeight: 600,
    };

    return (
        <AppPage className="pt-4">
            <main className="pt-5">
                <Container>
                    <Row className="jumbotron justify-content-center position-relative gy-5">
                        <Col xs="12" lg="5" className="text-center text-md-start">           
                            <h1 style={jumbotron}>
                                Get more insight into your sleep.
                            </h1>
                            <p className="mt-4" style={jumbotronDescription}>
                                Add notes and tags to the sleep data from your Fitbit, Oura, Withings, and more.
                            </p>
                            <p style={jumbotronDescription}>
                                View dashboards and search your sleep data.
                            </p>

                            <div className="d-flex flex-gap-5 pt-5 justify-content-center justify-content-md-start">
                                <Button 
                                    variant="primary"
                                    size="lg"
                                    type="submit"
                                    className="shadow-sm px-4 fw-semibold"
                                    onClick={ () => navigateToDemo() }
                                >
                                    Demo
                                </Button>
                                <LinkContainer to="/signup">
                                    <Button 
                                        variant="outline-primary"
                                        size="lg"
                                        type="submit"
                                        className="shadow-sm px-4 fw-semibold"
                                    >
                                        Get Started
                                    </Button>
                                </LinkContainer>
                            </div>
                        </Col>
                        
                        <Col xs="12" lg="5" className="d-none d-lg-block">
                            <img src={productImg} className="product-screenshot"></img>
                        </Col>
                    </Row>
                </Container>
            </main>

            <section className="py-5"></section>

            <section className="section py-5">
                <Container>
                    <Row className="jumbotron justify-content-center gy-5 flex-md-row-reverse">
                        <Col xs="12" md="5" className="text-center">
                            <div className="d-flex justify-content-center">
                                <Icon icon="bi-pencil-square" />
                            </div>
                            <p className="fs-3 fw-bold">
                                Annotate your sleep data
                            </p>
                            <p className="fs-6">
                                Add notes, tags, and custom values to your sleep logs, as well as how you're feeling and your medications.
                            </p>
                        </Col>                        
                        <Col xs="12" md="5" className="text-center">
                            <img src={annotationsImg} className="screenshot"></img>
                        </Col>
                    </Row>
                </Container>   
            </section>

            <section className="py-5">
                <Container>
                    <Row className="jumbotron justify-content-center gy-5">
                        <Col xs="12" md="5" className="text-center">
                            <div className="d-flex justify-content-center">
                                <Icon icon="bi-bar-chart-fill" />
                            </div>                            
                            <p className="fs-3 fw-bold">
                                Visualize your data
                            </p>
                            <p>
                                View charts.
                            </p>
                        </Col>

                        <Col xs="12" md="5" className="text-center">
                            <img src={chartImg} className="screenshot"></img>
                        </Col>
                    </Row>
                </Container>   
            </section>

            <section className="section py-5">
                <Container>
                    <Row className="jumbotron justify-content-center gy-5 flex-md-row-reverse">
                        <Col xs="12" md="5" className="text-center">
                            <div className="d-flex justify-content-center">
                                <Icon icon="bi-search" />
                            </div>
                            <p className="fs-3 fw-bold">
                                Search and filter your logs
                            </p>
                            <p className="fs-6">
                                
                            </p>
                        </Col>                        
                        <Col xs="12" md="5" className="text-center">
                            <img src={filterImg} className="screenshot"></img>
                        </Col>
                    </Row>
                </Container>   
            </section>

            <section className="py-5">
                <Container>
                    <Row className="jumbotron justify-content-center gy-5">
                        <Col xs="12" md="5" className="text-center">
                            <div className="d-flex justify-content-center">
                                <Icon icon="bi-moon-stars-fill" />
                            </div>
                            <p className="fs-3 fw-bold">
                                Fully edit your sleep stages
                            </p>
                            <p>
                                
                            </p>
                        </Col>

                        <Col xs="12" md="5" className="text-center">
                            <img src={editStagesImg} className="screenshot"></img>
                        </Col>
                    </Row>
                </Container>   
            </section>

            <section className="section py-5">
                <Container>
                    <Row className="jumbotron justify-content-center gy-5 flex-md-row-reverse">
                        <Col xs="12" md="5" className="text-center">
                            <div className="d-flex justify-content-center">
                                <Icon icon="bi-cloud-download" />
                            </div>
                            <p className="fs-3 fw-bold">
                                Import your sleep data
                            </p>
                            <p className="fs-6">
                                
                            </p>
                        </Col>

                        <Col xs="12" md="5" className="text-center">
                            <img src={connectImg} className="screenshot"></img>
                        </Col>
                    </Row>
                </Container>   
            </section>                                                         
        </AppPage>
    );
}

function Icon({ icon }: { icon: string }) {
    return (
        <ColoredIcon sizeInPx={48} bgColor="rgb(224 207 252)" fontColor="#3d0a91" borderRadius="999px">
            <i className={`bi ${icon}`}></i>
        </ColoredIcon>
    );
}