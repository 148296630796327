import mixpanel from 'mixpanel-browser';

const mixpanelClientId = '559da583ff63d1e4aa0ac9d4b3ffefdc';

class EventLogger {
    constructor() {
        mixpanel.init(mixpanelClientId, { debug: process.env.NODE_ENV === 'development' });
    }

    setUser(user: string) {
        mixpanel.identify(user);
    }

    log(event: string, properties?: Record<string, any>) {
        mixpanel.track(event, properties);
    };
}

const logger = new EventLogger();
export { logger };