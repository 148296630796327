import { DateTime } from "luxon";
import { formatDateAsLocalTime } from "./FormatUtility";

export const ticksInMinute = 1000 * 60;
export const ticksInHour = ticksInMinute * 60;
export const ticksInDay = ticksInHour * 24;

export type DateFormat = 'YYYY-MM-DD' | 'YYYY-MM-DDTHH:MM:SS' | 'YYYY-MM-DDTHH:MM:SSZ';

export default class DateTimeUtils {
  static calculateThresholdDate(thresholdInDays: number) {

    const now = new Date(Date.now());
    
    const thresholdDate = DateTimeUtils.subtractDays(now, thresholdInDays - 1);

    return formatDateAsLocalTime(thresholdDate);
  }

  static addMinutes(date: Date, mins: number) {
    return new Date(date.valueOf() + (ticksInMinute * mins));    
  }

  static subtractDays(date: Date, numDays: number) {
    return new Date(date.valueOf() - (ticksInDay * numDays));
  }

  static addDays(date: Date, numDays: number) {
    return new Date(date.valueOf() + (ticksInDay * numDays));
  }  

  static getHoursMins(durationInMins: number) {
    let hours = Math.floor(durationInMins / 60);
    let mins = Math.round(durationInMins % 60);

    return [hours, mins];
  }

  static isWithinHours(d1: Date, d2: Date, numHours: number) {
    return (Math.abs(d2.valueOf() - d1.valueOf()) / ticksInHour) <= numHours;
  }

  static parseDateTimeIntoHoursMins(date: string): [number, number] {
    const timeComponents = date.split("T")[1].split(":");
  
    const hourIn24 = Number(timeComponents[0]);
    const minutes = Number(timeComponents[1]);
    return [hourIn24, minutes];
  }

  static isValid_YYYY_MM_DD(date: string) {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    return date.match(regEx) != null;
  }

  /**
   * Formats, but does not do any timezone conversions.
   * @param date 
   * @param format 
   * @returns 
   */
  static format(date: DateTime, format: DateFormat) {
    format = format ?? 'YYYY-MM-DDTHH:MM:SS';
    switch (format) {
        case 'YYYY-MM-DDTHH:MM:SS':
            return date.toFormat("y-LL-dd'T'HH:mm:ss");
        case 'YYYY-MM-DD':
            return date.toFormat("y-LL-dd");
        case 'YYYY-MM-DDTHH:MM:SSZ':
            return date.setZone("utc").toFormat("y-LL-dd'T'HH:mm:ss'Z'");
    }
  }
}