import { DateTime } from "luxon";

import SleepLog, { SleepEpisode, SleepSummary } from "./SleepLog";
import ISleepLogView from "./ISleepLogView";
import { splitDate } from "../utils/FormatUtility";
import SimpleDate from "./SimpleDate";
import FitbitAdapter from "../adapters/FitbitAdapter";
import SleepStagesUtils from "../utils/SleepStagesUtils";
import { CustomMetric } from "./SleepLogSettings";
import { WithingsAdapter } from "../adapters/WithingsAdapter";
import produce from "immer";
import DateTimeUtils from "../utils/DateTimeUtils";
import { ConnectedAccountType } from "./AccountSettings";

export default class SleepLogView implements ISleepLogView {
    public constructor(sleepLog: SleepLog) {
        this.sleepLog = sleepLog;
        this.logDate = new SimpleDate(sleepLog.date);

        this.primaryConnectedAccount = SleepLogView.getPrimaryConnectedAccount(sleepLog);
        const {sleepSummary, source} = SleepLogView.getSleepSummary(sleepLog);
        this.sleepSummary = sleepSummary;
        this.sleepSummarySource = source;

        this.minutesAsleep = SleepLogView.getSleepInMins(sleepLog, sleepSummary, false /* ignoreOverride */)
        this.mainSleep = SleepLogView.getMainSleepTime(this.sleepSummary);
    }

    public readonly minutesAsleep: number | undefined;
    public readonly mainSleep: SleepEpisode | undefined;
    
    public readonly sleepSummary: SleepSummary | undefined; 
    public readonly sleepSummarySource: string | undefined;

    public readonly primaryConnectedAccount: ConnectedAccountType | undefined;

    public get id() {
        return this.sleepLog.id;
    }

    public get baseSleepLog(): SleepLog {
        return this.sleepLog;
    }

    public get date(): SimpleDate {
        return this.logDate;
    }

    public get rating(): number | undefined {
        return this.sleepLog.rating;
    }

    public get customMetrics(): CustomMetric[] | undefined {
        return this.sleepLog.customMetrics;
    }

    public calculateAwakeningCount(awakeTimeThresholdInMinutes: number) {
        if (this.sleepSummary === undefined) {
            return undefined;
        }

        let numAwakenings = 0;

        this.sleepSummary.episodes.forEach((episode) => {
            episode.sleepStages.forEach((stage, i) => {
                if ((stage.type === "awake") &&
                    i !== 0 &&
                    i !== episode.sleepStages.length - 1 &&
                    (stage.seconds / 60) >= awakeTimeThresholdInMinutes) {
                    
                    numAwakenings++;
                }
            })
        });

        // Count all gaps between sleep records as awakenings.
        // Example records: [2am - 6am], [7:30am - 9am]. There's one long awakening between the two ranges.
        // TODO: Check the size of the gaps
        const mainSleep = SleepStagesUtils.CoalesceMainSleep(this.sleepSummary.episodes, SleepStagesUtils.DEFAULT_COMBINE_THRESHOLD, false);
        numAwakenings += mainSleep.mainEpisodes?.length ?? 1;
        numAwakenings--;

        return numAwakenings;
    }

    public calculateAwakenings() {
        if (!this.sleepSummary) {
            return [];
        }

        const episodes = this.sleepSummary.episodes
            .filter(episode => episode.isMainSleep)
            .sort((lhs, rhs) => lhs.start <= rhs.start ? -1 : 1);

        for (const episode of episodes) {
            
        }

    }

    public calculateSleepInMins(ignoreOverride: boolean) {
        return SleepLogView.getSleepInMins(this.sleepLog, this.sleepSummary, ignoreOverride);
    }

    public static fromSleepLogs(sleepLogs: SleepLog[]): ISleepLogView[] {
        return sleepLogs.map(sleepLog => new SleepLogView(sleepLog));
    }    

    private static getSleepInMins(sleepLog: SleepLog, sleepSummary: SleepSummary | undefined, ignoreOverride: boolean) {
        if (sleepLog.sleepDurationInMinutes !== undefined && !ignoreOverride) {
            return sleepLog.sleepDurationInMinutes;
        }
        else if (sleepSummary && sleepSummary.episodes.length > 0) {
            return sleepSummary.episodes.map(episode => episode.minutesAsleep).reduce((prev, cur) => prev + cur, 0);
        }
        else {
            return undefined;
        }
    }

    private static getMainSleepTime(sleepSummary: SleepSummary | undefined): SleepEpisode | undefined {
        if (!sleepSummary || sleepSummary.episodes.length === 0) {
            return undefined;
        }

        if (sleepSummary.episodes.length === 1) {
            return sleepSummary.episodes[0];
        }

        const mainSleepEpisodes = sleepSummary.episodes.filter(episode => episode.isMainSleep)
            .sort((lhs, rhs) => +DateTime.fromISO(lhs.start) - +DateTime.fromISO(rhs.start));

        let hasIntersection = false;
        for (let i = 0; i < mainSleepEpisodes.length; i++) {
            if (mainSleepEpisodes[i] < mainSleepEpisodes[i - 1]) {
                hasIntersection = true;
                break;
            }
        }

        if (hasIntersection) {
            return undefined;
        }

        // Union main sleep episodes.
        const mainSleep: SleepEpisode = {
            ...mainSleepEpisodes[0],
            sleepStages: [...mainSleepEpisodes[0].sleepStages]
        };

        for (let i = 1; i < mainSleepEpisodes.length; i++) {
            const nextEpisode = mainSleepEpisodes[i];
            const prevEnd = DateTime.fromISO(mainSleep.end);

            const start = DateTime.fromISO(nextEpisode.start);
            const diff = start.diff(prevEnd);

            if (+diff > 0) {
                mainSleep.sleepStages.push({
                    start: DateTimeUtils.format(prevEnd, "YYYY-MM-DDTHH:MM:SSZ"),
                    seconds: +diff / 1000,
                    type: "awake"
                });
            }

            mainSleep.minutesAwake += +diff / 1000 / 60;

            mainSleep.end = nextEpisode.end;
            mainSleep.minutesAsleep += nextEpisode.minutesAsleep;
            mainSleep.minutesAwake += nextEpisode.minutesAwake;
            mainSleep.sleepStages.push(...nextEpisode.sleepStages);
        }

        mainSleep.sleepStages = SleepStagesUtils.compress(mainSleep.sleepStages);

        return mainSleep;
    }

    private static getPrimaryConnectedAccount(sleepLog: SleepLog): ConnectedAccountType | undefined {
        if (sleepLog.fitbit && sleepLog.fitbit.length > 0 && (!sleepLog.primaryConnectedAccount || sleepLog.primaryConnectedAccount === "fitbit")) {
            return "fitbit";            
        }
        else if (sleepLog.withings && sleepLog.withings.episodes.length > 0 && (!sleepLog.primaryConnectedAccount || sleepLog.primaryConnectedAccount === "withings")) {
            return "withings";
        }
        else {
            return undefined;
        }        
    }
    
    private static getSleepSummary(sleepLog: SleepLog) {
        if (sleepLog.sleepSummary) {
            // Hack to fix when times don't have Z at the end to indicate utc
            const sleepSummary = produce(sleepLog.sleepSummary, draft => {
                for (const episode of draft.episodes) {
                    if (episode.start.slice(-1) !== "Z") {
                        episode.start += "Z";
                    }

                    if (episode.end.slice(-1) !== "Z") {
                        episode.end += "Z";
                    }
                    
                    for (const stage of episode.sleepStages) {
                        if (stage.start.slice(-1) !== "Z") {
                            stage.start += "Z"
                        }
                    }
                }
            });

            return { sleepSummary: sleepSummary, source: "Edited" };
        }
        else if (sleepLog.fitbit && sleepLog.fitbit.length > 0 && (!sleepLog.primaryConnectedAccount || sleepLog.primaryConnectedAccount === "fitbit")) {
            const strategy = new FitbitAdapter(sleepLog.fitbit);
            return { sleepSummary: strategy.getSleepSummary(0, true /* inferMainSleep */), source: "Fitbit" };            
        }
        else if (sleepLog.withings && sleepLog.withings.episodes.length > 0 && (!sleepLog.primaryConnectedAccount || sleepLog.primaryConnectedAccount === "withings")) {
            const strategy = new WithingsAdapter(sleepLog.withings.episodes, sleepLog.withings.episodeSleepStages);
            return { sleepSummary: strategy.getSleepSummary(), source: "Withings" };
        }
        else {
            return { };
        }
    }

    private readonly logDate: SimpleDate;
    private readonly sleepLog: SleepLog;
}