import classNames from "classnames";
import { useMemo } from "react";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import { formatTimeFromComponents, FormatUtils } from "../../../utils/FormatUtility";
import styles from "./styles/card-styles.module.css";

export default function SleepWakeCard(props: {title: string, timeInMins: number | undefined}) {

    const time = useMemo(() => {
        if (props.timeInMins !== undefined) {
            const timeComponents = DateTimeUtils.getHoursMins(props.timeInMins);
            const formattedTime = formatTimeFromComponents(timeComponents[0], timeComponents[1], true /* includeMins */, false /* includePeriod */);
            const period = FormatUtils.getTwelveHourPeriod(timeComponents[0]);

            return { formattedTime, period };
        }
        else {
            return undefined;
        }

    }, [props.timeInMins]);

        return (
        <div className={classNames("card tw-shadow ring-1 ring-gray-200", styles.cardAccent, props.title.includes("Sleep") ? styles.sleepTimeAccent : styles.wakeTimeAccent)}>
            <div className="card-body p-3 text-center">
                <div className="fs-4 fw-medium">
                { time ? time.formattedTime : "--:--" }
                <span className="fs-5">{ time ? time.period : "am" }</span>
                </div>

                <div className="text-gray-500 mt-2">{props.title}</div>
            </div>
        </div>
        );
    }