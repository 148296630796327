import classNames from "classnames";
import produce from "immer";
import { useMemo } from "react";
import { Row, Col, ToggleButtonGroup, ToggleButton, DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import { useImmer } from "use-immer";
import { InView } from 'react-intersection-observer';

import ISleepLogView from "../../../types/ISleepLogView";
import SleepLogSettings from "../../../types/SleepLogSettings";
import { Aggregation } from "../../../types/types";
import ErrorBoundary from "../../common/ErrorBoundary";
import TimeAggregationButtonGroup from "../../library/TimeAggregationButtonGroup";
import SimpleTooltip from "../../sleep-log-view/common/SimpleTooltip";
import ChartCard from "../cards/ChartCard";
import NumAwakeningsPerDayChart from "../charts/sleep/NumAwakeningsPerDayChart";
import SleepEpisodesTable from "../charts/sleep/SleepEpisodesTable";
import SleepDurationBreakdownCharts from "../charts/sleep/SleepDurationBreakdownCharts";
import SleepDurationSummaryChart from "../charts/sleep/SleepDurationSummaryChart";
import SleepPerDayChart from "../charts/sleep/SleepPerDayChart";
import SleepPerDayLineChart from "../charts/sleep/SleepPerDayLineChart";
import SleepTimeBreakdownChart from "../charts/sleep/SleepTimeBreakdownChart";
import SleepWakePerDayChart from "../charts/sleep/SleepWakePerDayChart";
import SleepWakeTable from "../charts/sleep/SleepWakeTable";
import TimeToFallAsleepChart from "../charts/sleep/TimeToFallAsleepChart";
import { SleepPerDayChartCard } from "../charts/SleepPerDayChartCard";

interface Props {
    allSleepLogs: ISleepLogView[];
    filteredSleepLogs: ISleepLogView[] | undefined;
    filteredOutSleepLogs: ISleepLogView[] | undefined;
    sleepLogSettings: SleepLogSettings | undefined;
}

export function SleepLogAnalyticsTab({
    allSleepLogs,
    filteredSleepLogs,
    filteredOutSleepLogs,
    sleepLogSettings 
}: Props) {

    const [state, setState] = useImmer(() => ({
        inView: {
            sleepAndWakeupTimes: false,
            sleepAndWakeupBreakdown: false,
            awakenings: false,
            fallAsleep: false,
            sleepEpisodesTable: false
        }
    }));

    const sleepLogGroups = useMemo(
        () => filteredSleepLogs !== undefined && filteredOutSleepLogs !== undefined ? [filteredSleepLogs, filteredOutSleepLogs] : [allSleepLogs],      
        [allSleepLogs, filteredSleepLogs, filteredOutSleepLogs]
    );

    const firstSleepLogGroup = useMemo(() => [sleepLogGroups[0]], [sleepLogGroups]);

    const [sleepChartOptions, setSleepChartOptions] = useImmer({
        useColorCoding: false,
        aggregation: "day" as Aggregation,
        showLine: false,
        alwaysShowPoints: false 
    });

    const [sleepAndWakeTimeAggregation, setSleepAndWakeTimeAggregation] = useImmer<Aggregation>("day");

    return (
        <>
            <div className="mt-1">
                <ErrorBoundary>
                    <SleepDurationSummaryChart
                        sleepLogs={allSleepLogs}
                        filteredSleepLogs={filteredSleepLogs ?? []}
                        unfilteredSleepLogs={filteredOutSleepLogs ?? []}
                    />
                </ErrorBoundary>
            </div>

            <Row className="g-4">

                <Col lg="6">
                    <SleepPerDayChartCard 
                        sleepLogGroups={sleepLogGroups}
                        goalSleepInMins={sleepLogSettings?.targetSleepDuration}
                        aggregation={sleepChartOptions.aggregation}
                        showLine={sleepChartOptions.showLine}
                        alwaysShowPoints={sleepChartOptions.alwaysShowPoints}
                        useColorCoding={sleepChartOptions.useColorCoding}
                        onAggregationChange={aggregation => setSleepChartOptions(state => { state.aggregation = aggregation; })}
                        onShowLine={showLine => setSleepChartOptions(state => { state.showLine = showLine; })}
                        onAlwaysShowPointsChange={alwaysShowPoints => setSleepChartOptions(state => { state.alwaysShowPoints = alwaysShowPoints; })}
                        onColorCodingChange={useColorCoding => setSleepChartOptions(state => { state.useColorCoding = useColorCoding; })}
                    />
                </Col>                             

                <Col lg="6">
                    <ChartCard title="Hours Slept Breakdown">
                        <ErrorBoundary>
                            <SleepDurationBreakdownCharts sleepLogs={firstSleepLogGroup} />
                        </ErrorBoundary>
                    </ChartCard>
                </Col>

                <div className="mt-4">
                    <ErrorBoundary>
                        <SleepWakeTable                                     
                            sleepLogs={allSleepLogs}
                            filteredSleepLogs={filteredSleepLogs ?? []}
                            unfilteredSleepLogs={filteredOutSleepLogs ?? []} 
                        />
                    </ErrorBoundary>
                </div>

                <Col lg="6">
                    <InView className="h-100" onChange={inView => setState(state => { state.inView.sleepAndWakeupTimes ||= inView; })}>
                        { state.inView.sleepAndWakeupTimes &&
                            <ChartCard title="Sleep and Wakeup Times">
                                <ErrorBoundary>
                                    <SleepWakePerDayChart
                                        sleepLogs={sleepLogGroups}
                                        aggregation={sleepAndWakeTimeAggregation}
                                        enableZoom={true}
                                        targetSleepTime={sleepLogSettings?.targetBedTime}
                                        targetWakeTime={sleepLogSettings?.targetWakeTime}
                                    />
                                    <div className="mt-3 text-center">
                                        <TimeAggregationButtonGroup
                                            aggregations={["day", "week", "month"]}
                                            idPrefix="sleep-wake-time"
                                            selected={sleepAndWakeTimeAggregation}
                                            onSelect={(selected) => setSleepAndWakeTimeAggregation(selected)} 
                                        />
                                    </div>
                                </ErrorBoundary>
                            </ChartCard>
                        }
                    </InView>
                </Col>                                

                <Col lg="6">
                    <InView className="h-100" onChange={inView => setState(state => { state.inView.sleepAndWakeupBreakdown ||= inView; })}>
                        { state.inView.sleepAndWakeupBreakdown &&
                            <ChartCard title="Sleep and Wakeup Times Breakdown">
                                <ErrorBoundary>
                                    <SleepTimeBreakdownChart sleepLogs={sleepLogGroups[0]} />
                                </ErrorBoundary>
                            </ChartCard>
                        }
                    </InView>
                </Col>

                <Col lg="6">
                    <InView className="h-100" onChange={inView => setState(state => { state.inView.awakenings ||= inView; })}>
                        { state.inView.awakenings &&
                            <ChartCard title="Middle of Night Awakenings">
                                <ErrorBoundary>
                                    <NumAwakeningsPerDayChart sleepLogs={sleepLogGroups} enableZoom={true} />
                                </ErrorBoundary>
                            </ChartCard>
                        }
                    </InView>
                </Col>

                <Col lg="6">
                    <InView className="h-100" onChange={inView => setState(state => { state.inView.fallAsleep ||= inView; })}>
                        { state.inView.fallAsleep &&                    
                            <ChartCard title="Time to Fall Asleep">
                                <ErrorBoundary>
                                    <TimeToFallAsleepChart sleepLogGroups={sleepLogGroups} enableZoom={true} />
                                </ErrorBoundary>
                            </ChartCard>
                        }
                    </InView>
                </Col>                                

                <Col lg="6">
                    <InView className="h-100" onChange={inView => setState(state => { state.inView.sleepEpisodesTable ||= inView; })}>
                        { state.inView.sleepEpisodesTable &&
                            <ChartCard title="Main Sleep Episodes">
                                <SleepEpisodesTable sleepLogGroups={sleepLogGroups} />
                            </ChartCard>
                        }
                    </InView>
                </Col>                                 
            </Row>                                       
        </>
    );
}