import { ChartData, ChartOptions } from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartDeferred from 'chartjs-plugin-deferred';

import ISleepLogView from "../../../../types/ISleepLogView";

interface Props {
    sleepLogs: ISleepLogView[];
}

export default function SleepVsAverageRatingChart({sleepLogs}: Props) {

    const [ratingBreakdown, setRatingBreakdown] = useState<ChartData<"line", number[], string>>({
        labels: [],
        datasets: []
    });

    function getData(sleepLogs: ISleepLogView[], bgColor: string, borderColor: string) {

        let dataset = {
            label: "Rating",
            data: [],
            backgroundColor: bgColor,
            borderColor: borderColor,
            borderWidth: "2"
        };    

        let data: any = {
            labels: [],
            datasets: [dataset],
        };

        let maxSleepInHours = 0;

        for (const sleepLog of sleepLogs) {
            if (sleepLog.minutesAsleep !== undefined) {
                maxSleepInHours = Math.max(maxSleepInHours, sleepLog.minutesAsleep / 60);
            }
        }

        const bucketCount = Math.floor(maxSleepInHours) + 1;
        data.datasets[0].data = new Array<number>(bucketCount).fill(0);

        for (let i = 0; i < bucketCount; ++i) {
            data.labels.push(`${i}-${i + 1}h`);
        }

        const buckets = data.datasets[0].data;
        const counts = new Array<number>(bucketCount).fill(0);
    
        sleepLogs.forEach(log => {
            if (log.rating !== undefined && log.minutesAsleep !== undefined) {
                let bucketIndex = Math.floor(log.minutesAsleep / 60);
                buckets[bucketIndex] += log.rating;
                counts[bucketIndex]++;
            }
        });

        for (let i = 0; i < buckets.length; ++i) {
            buckets[i] = counts[i] > 0 ? (buckets[i] / counts[i]) : undefined;
        }
        
        return data;
    }

    const options: ChartOptions<"line"> = {
        plugins: {
            datalabels: {
                anchor: "end",
                align: "top",
                color: "grey",
                font: {
                    size: 10
                },
                formatter: function(value, context) {
                    const rating = value as number | undefined;
                    return rating ? rating.toFixed(2) : '';
                }
            },            
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const rating = context.raw as number | undefined;
                        return rating ? rating.toFixed(2) : '';        
                    }
                }
            },
            legend: {
                display: false
            },
            title: {
                display: false
            },            
        },        
        scales: {
            x: {
                    grid: {
                        color: "rgba(0, 0, 0, 0)",
                    },
                    stacked: true,
                    ticks: {
                        font: {
                            size: 10
                        },
                    }
            },
            y: {
                ticks: {                     
                    stepSize: 1,
                    font: {
                        size: 10
                    },
                    maxTicksLimit: 10
                },
                grid: {
                    color: "rgba(0, 0, 0, .04)",
            },                
                stacked: true,
            }            
        },    
    };

    const bgColor = "rgba(255, 214, 102, .7)";
    const borderColor = "rgba(255, 214, 102, 1)";

    useEffect(() => {
        const sleepData = getData(sleepLogs, bgColor, bgColor);
        setRatingBreakdown(sleepData);     

    }, [sleepLogs]);

    return (
        <Line data={ratingBreakdown} plugins={[ChartDataLabels, ChartDeferred]} options={options} />
    );
}