import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
  message: string;
  children: any;  
  position?: "top" | "bottom";
}

export default function SimpleTooltip({message, children, position}: Props) {
  return (
    <OverlayTrigger 
      placement={position ?? "top"} 
      overlay={
        <Tooltip id={`tooltip-top`}>
          {message}
      </Tooltip>
      }
    >            
      {children}
    </OverlayTrigger>
  );
}