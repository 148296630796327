import { useContext, useEffect, useRef } from "react";
import { Button, Col, Container, Form, InputGroup, ListGroup, Modal, Row } from "react-bootstrap";
import { useImmer } from "use-immer";
import { Limits } from "../../../constants/limits";
import useStore from "../../../shared/store/useStoreService.hook";
import { LoginState } from "../../../types/CommonInterfaces";
import SleepLogSettings, { CustomMetricType } from "../../../types/SleepLogSettings";
import { isInteger } from "../../../utils/FormatUtility";
import { StatusMessage, StatusMessageType } from "../../common/StatusMessages";
import { SleepLogSettingsServiceContext } from "../services/sleep-log-settings-service.provider";
import SleepLogSettingsService from "../services/SleepLogSettingsService";
import SleepLogSettingsCloseConfirmation from "./SleepLogSettingsCloseConfirmation";

interface Props {
    show: boolean;
    loginState: LoginState,

    onClose: () => void;
    addStatus: (msg: StatusMessage) => any;
}

export default function SleepLogSettingsModal({ show, loginState, onClose, addStatus }: Props) {
    const [sleepLogSettingsService, initialSettings] = useStore<SleepLogSettingsService, SleepLogSettings | undefined>(SleepLogSettingsServiceContext);
    const initialSettingsRef = useRef(initialSettings);

    const [state, setState] = useImmer(() => ({
        isModified: false,
        saving: false,
        showConfirm: false,
        settings: initialSettings ?? {},
        sleepTarget:{
            hours: Math.floor((initialSettings?.targetSleepDuration ?? 0) / 60).toString(),
            mins: Math.floor((initialSettings?.targetSleepDuration ?? 0) % 60).toString() + (((initialSettings?.targetSleepDuration ?? 0) % 60) === 0 ? '0' : '')            
        },
        newMetric: {
            name: "",
            type: "0_to_10" as CustomMetricType,
            show: false            
        },
    }));

    useEffect(() => {
        if (initialSettingsRef.current === undefined && initialSettings) {
            setState(state => {
                state.settings = initialSettings;
                state.sleepTarget = {
                    hours: Math.floor((initialSettings?.targetSleepDuration ?? 0) / 60).toString(),
                    mins: Math.floor((initialSettings?.targetSleepDuration ?? 0) % 60).toString() + (((initialSettings?.targetSleepDuration ?? 0) % 60) === 0 ? '0' : '')            
                };
            });

            initialSettingsRef.current = initialSettings;
        }
    }, [initialSettings]);

    function changeExpandNotes(e: any) {
        setState(state => {
            state.settings.expandNotesByDefault = e.target.checked;
            state.isModified = true;
        });
    }

    function changeUseNotesTemplate(e: any) {
        setState(state => {
            state.settings.useNotesTemplate = e.target.checked;
            state.isModified = true;
        });
    }

    function changeNotesTemplate(e: any) {
        setState(state => {
            state.settings.notesTemplate = e.target.value;
            state.isModified = true;
        });
    }

    function changeNotesPrompt(e: any) {
        setState(state => {
            state.settings.notesPrompt = e.target.value;
            state.isModified = true;
        });
    }    

    function updateTargetSleepHours(e: any) {

        if (e.target.value.length > 0 && !isInteger(e.target.value)) {
            return;
        }

        if (e.target.value.length >= 3) {
            return;
        }

        const hours = e.target.value;

        setState(state => {
            state.sleepTarget.hours = hours;
            state.isModified = true;
        });
    }

    function updateTargetSleepMins(e: any) {
        if (e.target.value.length > 0 && !isInteger(e.target.value)) {
            return;
        }

        if (e.target.value.length >= 3) {
            return;
        }

        const mins = e.target.value;

        setState(state => {
            state.sleepTarget.mins = mins;
            state.isModified = true;
        });      
    }    

    const addNewMetric = () => {
        if (!state.newMetric.name) {
            addStatus({ type: StatusMessageType.Fail, msg: "A custom value name cannot be empty." });
            return;
        }
        
        if (state.settings.customMetrics?.map(metric => metric.name).includes(state.newMetric.name)) {
            addStatus({type: StatusMessageType.Fail, msg: "A custom value with the same name already exists."});
            return;
        }

        setState(state => {
            if (!state.settings.customMetrics) {
                state.settings.customMetrics = [];
            }

            state.settings.customMetrics.push(state.newMetric);

            state.newMetric = {
                name: "",
                type: "0_to_10",
                show: false
            };

            state.isModified = true;
        });
    }

    const deleteMetric = (index: number) => {
        setState(state => {
            state.settings.customMetrics?.splice(index, 1);
            state.isModified = true;
        });
    }

    async function handleSaveSettings() {
        if (loginState.loggedIn) {

            setState(state => {
                state.saving = true;
            });

            const sleepTargetInMins = (Number(state.sleepTarget.hours) * 60) + Number(state.sleepTarget.mins);
            const settingsToPersist = { ...state.settings, targetSleepDuration: sleepTargetInMins };
            let succeeded = false;

            try {
                await sleepLogSettingsService.save(settingsToPersist);
                addStatus({ type: StatusMessageType.Success, msg: "Settings saved." });
                succeeded = true;
            }
            catch (e: any) {
                addStatus({ type: StatusMessageType.Fail, msg: "Saving settings failed. Please try again later." });
            }

            setState(state => {
                state.saving = false;

                if (succeeded) {
                    state.isModified = false;
                }
            });
        }
    }

    function confirmClose() {
        if (state.isModified) {
            setState(state => {
                state.showConfirm = true;
            });
        }
        else {
            onClose();
        }
    }

    const displayMetricType = (type: CustomMetricType) => {
        switch (type) {
            case "0_to_10":
                return "0 to 10 scale";
            case "1_to_5":
                return "1 to 5 scale";
            case "number":
                return "number";
            default:
                return "Unknown type";
        }
    };

    const disableAddMetric = () => (state.settings.customMetrics?.length ?? 0) >= 25;

    return (
    <>
        <Modal 
            scrollable={true} 
            show={show}
            onHide={confirmClose}
            fullscreen="lg-down"
            size="lg"
            dialogClassName="sleep-log-modal"
        >
            <Modal.Header>
                <Modal.Title>Settings</Modal.Title>
                <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={confirmClose}></button>
            </Modal.Header>
            <Modal.Body style={{scrollbarGutter: "stable", scrollbarWidth: "thin"}}>
                {!initialSettings &&
                    <div className="d-flex justify-content-center mt-5" style={{gap: "1rem"}}>
                        <span className="spinner-grow text-secondary" role="status" aria-hidden="true"></span>
                        <span className="spinner-grow text-secondary" role="status" aria-hidden="true"></span>
                        <span className="spinner-grow text-secondary" role="status" aria-hidden="true"></span>
                    </div>                        
                }
                {initialSettings &&
                    <Container>
                        <div className="border-bottom pb-4"> 
                            <div className="fw-semibold mb-2">
                                Sleep Target
                            </div>
                            <div className="mt-2">
                                <input type="text" 
                                    className="underline-input text-sm"
                                    style={{width: "1.5rem"}}
                                    value={state.sleepTarget.hours}
                                    onChange={updateTargetSleepHours}
                                    onBlur={() => setState(state => { 
                                        state.sleepTarget.hours = state.sleepTarget.hours ? state.sleepTarget.hours : '0';
                                        state.isModified = true;
                                    })}
                                >
                                </input>
                                <span> : </span>
                                <input type="text"
                                    className="underline-input text-sm"
                                    style={{width: "1.5rem"}}
                                    value={state.sleepTarget.mins}
                                    onChange={updateTargetSleepMins}
                                    onBlur={() => setState(state => { 
                                        state.sleepTarget.mins = state.sleepTarget.mins ? state.sleepTarget.mins : '00';
                                        state.isModified = true;
                                    })}
                                >
                                </input>
                            </div>
                        </div>

                        <div className="border-bottom py-4"> 
                            <div className="fw-semibold mb-2">
                                Target Bedtime
                            </div>
                            <div className="mt-2">
                                <input type="time"
                                    className="form-control form-control-sm w-auto"
                                    value={state.settings.targetBedTime}
                                    onChange={e => setState(state => {
                                        state.settings.targetBedTime = e.target.value;
                                        state.isModified = true;
                                    })}
                                >
                                </input>
                            </div>                            
                        </div>

                        <div className="border-bottom py-4"> 
                            <div className="fw-semibold mb-2">
                                Target Wake Up
                            </div>
                            <div className="mt-2">
                                <input type="time"
                                    className="form-control form-control-sm w-auto"
                                    value={state.settings.targetWakeTime}
                                    onChange={e => setState(state => { 
                                        state.settings.targetWakeTime = e.target.value; 
                                        state.isModified = true;
                                    })}
                                >
                                </input>
                            </div>                            
                        </div>                                      

                        <div className="border-bottom py-4">
                            <div className="fw-semibold">
                                Custom Values
                            </div>
                            <div className="text-sm py-2">Deleting custom values does not effect existing sleep logs.</div>
                            <ListGroup>
                                <ListGroup.Item className="d-flex text-sm bg-light fw-bold" style={{justifyContent: "space-between"}}>
                                    <div style={{flex: "6 1 0"}}>Name</div>
                                    <div style={{flex: "6 1 0"}}>Type</div>
                                    <div style={{flex: "2 1 0"}}>Actions</div>
                                </ListGroup.Item>
                                { state.settings?.customMetrics?.map((metric, i) =>
                                    <ListGroup.Item key={metric.name} className="d-flex text-sm" style={{justifyContent: "space-between"}}>
                                        <div style={{flex: "6 1 0"}}>{metric.name}</div>
                                        <div style={{flex: "6 1 0"}}>{displayMetricType(metric.type)}</div>
                                        <div style={{flex: "2 1 0"}}>
                                            <Button variant="outline-danger"
                                                size="sm" 
                                                onClick={() => deleteMetric(i)}
                                            >
                                                <i className="bi bi-trash3"></i>
                                            </Button>
                                        </div>
                                    </ListGroup.Item>
                                )}
                                
                            </ListGroup>
                            { !state.newMetric.show &&
                                <div className="text-center mt-3">
                                    <Button variant="secondary rounded-5"
                                        size="sm" 
                                        onClick={() => setState(state => { state.newMetric.show = true; })}
                                    >
                                        <i className="bi bi-plus-lg"></i>
                                    </Button>
                                </div>
                            }
                            { state.newMetric.show &&
                                <div className="pt-4">
                                    <div className="fw-medium">Add new value</div>
                                    <div className="p-2"></div>
                                    <InputGroup size="sm">
                                        <InputGroup.Text>Name</InputGroup.Text>
                                        <Form.Control 
                                            type="text"
                                            maxLength={100}
                                            disabled={disableAddMetric()}
                                            placeholder="Add name"
                                            value={state.newMetric.name}
                                            onChange={e => setState(state => { 
                                                state.newMetric.name = e.target.value as any;
                                            })}
                                            autoFocus
                                        />
                                        <InputGroup.Text>Type</InputGroup.Text>
                                        <Form.Select 
                                            value={state.newMetric.type}
                                            disabled={disableAddMetric()} 
                                            onChange={e => setState(state => { 
                                                state.newMetric.type = e.target.value as CustomMetricType;
                                            })}
                                        >
                                            <option value="0_to_10">{displayMetricType("0_to_10")}</option>
                                            <option value="1_to_5">{displayMetricType("1_to_5")}</option>
                                            <option value="number">{displayMetricType("number")}</option>
                                        </Form.Select>
                                        <Button 
                                            variant="outline-success"
                                            style={{"WebkitTextStrokeWidth": ".5px"}}
                                            disabled={disableAddMetric()} 
                                            onClick={addNewMetric}
                                        >
                                            <i className="bi bi-plus-lg"></i>
                                        </Button>
                                        <Button 
                                            variant="outline-secondary"
                                            style={{"WebkitTextStrokeWidth": ".5px"}}
                                            onClick={() => setState(state => { state.newMetric.show = false; })}
                                        >
                                            <i className="bi bi-x-lg"></i>
                                        </Button>
                                    </InputGroup>
                                </div>
                            }
                        </div>

                        <Row className="pt-4 pb-2">
                            <Col>
                                <div className="fw-semibold mb-2">
                                    Use Notes Template
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="filter-analytics-no-sleep-med"
                                    onChange={changeUseNotesTemplate} checked={state.settings.useNotesTemplate}/>
                                </div>                            
                            </Col>                            
                        </Row>

                        <Row className="border-bottom py-4">
                            <Col xs="12">
                                <div className="fw-semibold mb-2">
                                    Notes Template
                                </div>
                            </Col>
                            <Col>    
                                <textarea 
                                    className="form-control border-rounded text-sm" 
                                    rows={10} 
                                    value={state.settings.notesTemplate ?? ""}
                                    placeholder="Add a notes template. This will be used for any newly created logs."
                                    onChange={changeNotesTemplate} maxLength={Limits.MaxNotesLength} style={{}}>
                                </textarea>                                             
                            </Col>  
                        </Row>
                        <Row className="py-4">
                            <Col xs="12">
                                <div className="fw-semibold mb-2">
                                    Notes Prompt
                                </div>
                            </Col>
                            <Col>    
                                <textarea 
                                    className="form-control border-rounded text-sm"
                                    rows={10}
                                    value={state.settings.notesPrompt ?? ""}
                                    placeholder="Add a notes prompt."
                                    onChange={changeNotesPrompt} maxLength={Limits.MaxNotesLength} style={{}}>
                                </textarea>                                             
                            </Col>
                        </Row>                                                                                                                         
                    </Container>
                }
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <button type="button"
                    className="btn btn-primary" 
                    onClick={handleSaveSettings}
                    disabled={!initialSettings || !state.isModified || state.saving}
                >
                    {!state.saving ?
                        "Save" :
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    }
                </button>
            </Modal.Footer>
        </Modal>

        { state.showConfirm && 
            <SleepLogSettingsCloseConfirmation
                show={true} 
                close={() => setState(state => ({...state, showConfirm: false }))} 
                confirm={onClose} 
            />
        }
    </>    
    )
}