import {
    Navigate,
    Routes,
    Route,
    useNavigate,
    Outlet
} from "react-router-dom";

import { useState, useEffect, useMemo, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import AppNavBar from "./AppNavBar";
import SignupView from "../lib/account/components/SignupView";
import Blog from "../lib/blog/Blog";
import BlogPosts from "../lib/blog/BlogPosts";
import StatusMessages, { StatusMessage, StatusMessageType } from "../lib/common/StatusMessages";
import ImportView from "../lib/import-devices/components/ImportView";
import LandingPage from "../lib/landing/LandingPage";
import SleepLogsView from "../lib/sleep-log-view/SleepLogsView";
import { SleepLogHttpServiceContext, SleepLogHttpServiceProvider } from "../lib/http/sleep-log-http-service.provider";
import { SleepLogSettingsServiceContext, SleepLogSettingsServiceProvider, useSleepLogSettingsServiceProvider } from "../lib/sleep-log-settings/services/sleep-log-settings-service.provider";
import { LoginState } from "../types/CommonInterfaces";
import { AccountHttpServiceContext, AccountHttpServiceProvider } from "../lib/http/account-http-service.provider";
import { AccountServiceContext, AccountServiceProvider } from "../lib/account/services/account-service.provider";
import AccountSettings from "../lib/account/components/AccountSettings";
import AuthorizeDevice from "../lib/account/components/AuthorizeDevice";
import LoginView from "../lib/account/components/LoginView";
import { LoginStateContext } from "./login-state.context";
import AccountDevices from "../lib/account/components/AccountDevices";
import { HttpException } from "../types/Exceptions";
import ResetPassword from "../lib/account/components/reset-password.component";
import ForgotPassword from "../lib/account/components/forgot-password.component";

import './styles/app.css';
import './styles/app-page.css';
import { AppState } from "../types/AppState";
import { AppStateContext } from "./AppStateContext";
import { logger } from "../logger/EventLogger";
import { TagSuggestionServiceProvider } from "../services/TagSuggestionsService.provider";

export default function App() {

    const [loginState, setLoginState] = useState<LoginState>(() => {
        let username = window.localStorage.getItem("username");
        console.log(`Read user: ${username} from local storage.`);

        if (username) {
            logger.setUser(username);
            logger.log("Session Started");

            return { loggedIn: true, username: username };
        }
        else {
            return { loggedIn: false };
        }
    });

    const loginStateContext: [LoginState, (value: LoginState) => void] = useMemo(() => {
        return [loginState, setLoginState];
    }, [loginState, setLoginState]);

    const [appState, setAppState] = useState<AppState>(() => ({ sessionId: uuidv4() }));

    const appStateContext: [AppState, (value: AppState) => void] = useMemo(() => [appState, setAppState]
    , [appState, setAppState]);    

    return (
        <AppStateContext.Provider value={appStateContext}>
        <LoginStateContext.Provider value={loginStateContext}>
        <AccountHttpServiceProvider>
        <AccountServiceProvider>
        <SleepLogHttpServiceProvider>
        <SleepLogSettingsServiceProvider>
        <TagSuggestionServiceProvider>
            <AppRoot />
        </TagSuggestionServiceProvider>    
        </SleepLogSettingsServiceProvider>
        </SleepLogHttpServiceProvider>
        </AccountServiceProvider>
        </AccountHttpServiceProvider>
        </LoginStateContext.Provider>
        </AppStateContext.Provider>
    );
}

            
export function AppRoot() {
    let navigate = useNavigate();
    const [loginState, setLoginState] = useContext(LoginStateContext);
    const sleepLogSettingsService = useContext(SleepLogSettingsServiceContext)!;

    const [statusMessages, setStatusMessages] = useState<StatusMessage[]>([]);

    useEffect(() => {
        // TODO: This should be moved down to only the routes that need settings
        if (!loginState.loggedIn) {
            return;
        }

        (async () => {
            try {
                sleepLogSettingsService.fetch();
            }
            catch (e) {
                console.log("Fetching sleep log settings failed.");
            }     
        })();
    
    }, [loginState]);

    const accountHttpService = useContext(AccountHttpServiceContext)!;
    const accountService = useContext(AccountServiceContext)!;

    useEffect(() => {
        // TODO: This should be moved down to only the routes that need settings
        if (!loginState.loggedIn) {
            return;
        }

        (async () => {
            try {
                await accountService.fetchProperties();
            }
            catch (e) {
                if (e instanceof HttpException && e.statusCode === 401) {
                    addStatus({
                        type: StatusMessageType.Info,
                        msg: `Your session has expired. You'll need to sign in again.`
                    });

                    await accountHttpService.clearSessionCookie();
                    logoutUser(false /* showStatus */);                  
                }
                else {
                    addStatus({
                        type: StatusMessageType.Fail,
                        msg: `Fetching account settings failed.`
                    });
                }
            }     
        })();
    
    }, [loginState]);    
    
    function loginUser(username: string)
    {
        logger.log("User Logged In");

        window.localStorage.setItem("username", username);
        setLoginState({ loggedIn: true, username: username });
        logger.setUser(username);

        navigate("/sleep-logs");
    }

    async function logoutUser(showStatus = true)
    {
        if (!loginState.loggedIn) {
            return;
        }
    
        // TODO: Does cookie need to be deleted as well?
        window.localStorage.removeItem("username");    
    
        try {
            await accountHttpService.deleteSession();
        } catch (e: any) {
            console.log("Removing session failed. It will be cleaned up later.");
        }
    
        if (showStatus) {
            addStatus({
                msg: "Logged out successfully.",
                type: StatusMessageType.Success
            });
        }
    
        setLoginState({loggedIn: false});

        logger.log("User Logged Out");        
        
        navigate("/login");      
    }    

    useEffect(() => {
        window.onerror = (event) => {
            console.log("Uncaught error");

            addStatus({
                type: StatusMessageType.UnexpectedFail,
                msg: `Unexpected error occurred. Reason: ${event}`
            })
        }
    }, []);

    function addStatus(msg: StatusMessage) {
        // slice at 10 to limit the number of errors.

        if (msg.type === StatusMessageType.Fail || msg.type === StatusMessageType.UnexpectedFail) {
            logger.log("User Hit Error", { msg: msg.msg, type: msg.type === StatusMessageType.UnexpectedFail ? "Unexpected" : "Expected" });
        }

        setStatusMessages(prev => [msg, ...prev.slice(0, 9)]);
    }

    function closeStatus() {
        setStatusMessages(prev => [...prev.slice(1)]);
    }

    return (
        <> 
            <div style={{minHeight: "100vh", scrollbarGutter: "stable", scrollbarWidth: "thin"}}>
                <StatusMessages
                    messages={statusMessages} 
                    closeStatus={closeStatus} 
                />
                <AppNavBar 
                    loginState={loginState} 
                    onLogout={logoutUser}
                    addStatus={addStatus} 
                />
                <Routes>
                    <Route path="blog" element={<Blog />}>
                        { /* <Route index element={<BlogPosts />} /> */ }
                        { /* <Route path="mirtazapine-for-insomnia" element={<MirtazapineForInsomnia />} /> */ }
                    </Route>
                    <Route 
                        path="/" 
                        element={
                            <Outlet />
                        }
                    >
                        <Route index element={ loginState.loggedIn ? <Navigate to="/sleep-logs" /> : <Navigate to="/home" />} />
                        <Route path="home" element={ loginState.loggedIn ? <Navigate to="/sleep-logs" /> :<LandingPage /> } />  
                        <Route path="authorize" element={<AuthorizeDevice addStatus={addStatus} /> } /> 
                        <Route path="login" element={loginState.loggedIn? <Navigate to="/sleep-logs" /> : <LoginView setLoggedIn={loginUser} addStatus={addStatus} />} />
                        <Route path="signup" element={loginState.loggedIn? <Navigate to="/sleep-logs" /> : <SignupView addStatus={addStatus}/>} />
                        <Route path="password-reset" element={<ResetPassword addStatus={addStatus}/>} />
                        <Route path="forgot-password" element={<ForgotPassword addStatus={addStatus}/>} />
                        <Route path="import" element={loginState.loggedIn ? <ImportView addStatus={addStatus} /> : <Navigate to='/login' />} />
                        <Route path="sleep-logs/*" element={loginState.loggedIn ? <SleepLogsView loginState={loginState} initialTab="sleep-logs" addStatus={addStatus} /> : <Navigate to='/login' />} />
                        <Route path="dashboard" element={loginState.loggedIn ? <SleepLogsView loginState={loginState} initialTab="sleep-analytics" addStatus={addStatus} /> : <Navigate to='/login' />} />
                        <Route path="settings" element={loginState.loggedIn ? <AccountSettings addStatus={addStatus} /> : <Navigate to='/login' /> } />
                        <Route path="devices" element={loginState.loggedIn ? <AccountDevices addStatus={addStatus} loginState={loginState} /> : <Navigate to='/login' /> } />
                        <Route
                            path="*"
                            element={
                                <main>
                                    <p className="mt-5 fs-1 text-center">The page was not found :(</p>
                                </main>
                            }
                        >
                        </Route>
                    </Route>
                </Routes>
            </div>
            <div className="text-center p-3 bg-dark text-light" style={{fontSize: ".75rem"}}>
                <p>Build version 23.11.01</p>
            </div>                            
        </>
    );     
}