export const FIELDS_STATIC = [
    'Awakening (middle of night)',
    'Awakening count (middle of night)',
    'Bedtime',
    'Date',
    'Day of week',
    'Events',
    'Feeling',
    'Hours slept',
    'Hours slept (3 day moving average)',
    'Hours slept (7 day moving average)',    
    'Longest awakening (minutes)',
    'Medication',
    'Medication dose',
    'Medication taken at',  
    'Minutes awake',
    'Notes',
    //'Other medication',  -> deprecated in favor of medication
    'Rating',
    'Sleep efficiency',
    'Sleep medication',
    'Sleep time',
    'Tags',
    'Time in bed (hours)',
    'Time to fall asleep (minutes)',
    'Time to first awakening (hours)',
    'Wakeup time',
    //'Favorited',
] as const;

export type Field = typeof FIELDS_STATIC[number];

export const OPERATORS = [
    'contains',
    'contains any of (comma separated)',
    'contains only',
    'does not contain any of (comma separated)',
    'does not contain',
    'has exact value',
    'has none',
    'has any',
    'equals',
    'does not equal',
    'greater than or equal to',
    'greater than',
    'less than or equal to',
    'less than',
    'between',
    'not between',
    'is null'
] as const;

export type Operator = typeof OPERATORS[number];

export type FieldType = 'tags' | 'string' | 'number' | 'time' | 'medication' | 'medication taken' | 'date' | 'awakening threshold';

export type ApplyTo = "day of" | "day before" | "day after" | "2 days before";

export interface SleepLogFilter {
    field: string;
    type: FieldType;
    operator: Operator;
    parameters: string[];
    applyTo: ApplyTo;
    enabled: boolean;
};

export const fieldToTypeMap: Record<Field, FieldType> = {
    'Tags': 'tags',
    'Feeling': 'tags',
    'Sleep medication': 'tags',
    //'Other medication': 'tags',
    'Day of week': 'string',
    'Events': 'tags',
    'Rating': 'number',
    'Hours slept': 'number',
    'Hours slept (3 day moving average)': 'number',
    'Hours slept (7 day moving average)': 'number',    
    'Notes': 'string',
    'Sleep time': 'time',
    'Wakeup time': 'time',
    'Medication': 'tags',
    'Medication dose': 'medication',
    'Time to fall asleep (minutes)': 'number',
    'Awakening count (middle of night)': 'awakening threshold',
    'Longest awakening (minutes)': 'number',
    'Time to first awakening (hours)': 'awakening threshold',
    'Minutes awake': 'number',
    'Bedtime': 'time',
    'Date': 'date',
    'Sleep efficiency': "number",
    'Medication taken at': 'medication taken',
    'Awakening (middle of night)': 'time',
    'Time in bed (hours)': 'number'
};

export const typeToOperatorsMap: Record<FieldType, Operator[]> = {
    'tags': [
        'contains',
        'contains any of (comma separated)',
        'contains only',
        'has exact value',
        'does not contain',
        'does not contain any of (comma separated)',
        'has none',
        'has any',
    ],
    'string': ['contains', 'contains any of (comma separated)', 'equals', 'has exact value', 'has none', 'does not contain', 'does not equal'],
    'number': ['equals', 'does not equal', 'greater than', 'greater than or equal to', 'less than', 'less than or equal to', 'is null'],
    'time': ['between'],
    'medication': ['greater than or equal to', 'greater than', 'less than', 'less than or equal to', 'equals'],
    'date': ['between', 'not between'],
    'awakening threshold': ['greater than or equal to', 'greater than', 'less than', 'less than or equal to', 'equals'],
    'medication taken': ['between']
};

export interface SimpleSleepLogFilterSet {
    noSleepMedication?: boolean;
    sleepMedication?: string;
    otherMedication?: string;
    tag?: string;
    feeling?: string;
    sleepMinimum?: number;
    sleepMax?: number;
    minDate?: string;
    maxDate?: string;
    ratingMin?: number;
    ratingMax?: number;
    notesContains?: string;
    favorited?: true
};