import { useContext, useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import AppPage from "../../../app/AppPage";
import { logger } from "../../../logger/EventLogger";
import { HttpException } from "../../../types/Exceptions";
import BrandLogo from "../../common/BrandLogo";
import { StatusMessage, StatusMessageType } from "../../common/StatusMessages";
import { AccountHttpServiceContext } from "../../http/account-http-service.provider";

interface Props {
    addStatus: (msg: StatusMessage) => any;
}

export default function ForgotPassword({addStatus}: Props) {
    const [username, setUserName] = useState("");
    const [emailSent, setEmailSent] = useState(false);

    const accountHttpService = useContext(AccountHttpServiceContext)!;

    useEffect(() => {
        logger.log("View Forgot Password Page");
    }, []);      

    async function sendResetEmail(e: any) {
        e.preventDefault();

        try {
            await accountHttpService.sendResetPasswordEmail(username);
            addStatus({type: StatusMessageType.Info, msg: `An email has been sent.`});
            setEmailSent(true);

            logger.log("Sent Reset Email");
        }
        catch (e) {
            if (e instanceof HttpException) {
                addStatus({type: StatusMessageType.Fail, msg: `Resetting password failed. Reason: ${e.message}`});
            }
            else {
                addStatus({type: StatusMessageType.Fail, msg: "Resetting password failed. Please try again later."});     
            }            
        }
    }    

    return (
        <AppPage className="app-bg-light pt-5">
            <Container className="">
                <div className="justify-content-center d-flex">
                <div style={{width: "400px"}}>
                    <div className="mt-4 py-4 px-5 rounded bg-white contrast-shadow">
                    <BrandLogo />
                    <div className="mt-4"></div>
                    <h4 className="text-center">Reset your password</h4>
                    <div className="text-center text-xs mt-3">An email will be sent to your inbox containing a link to reset your password.</div>
                    <Form>
                        <Form.Group controlId="login-username" className="mt-4 text-xs">
                        <Form.Label style={{fontWeight: 500}}>Username</Form.Label>
                            <Form.Control type="text" 
                                className="input-underline text-sm"
                                value={username}
                                placeholder="Enter username"
                                disabled={emailSent} 
                                onChange={e => setUserName(e.target.value)} 
                            />
                        </Form.Group>
                        <div className="text-center mt-4">
                        <Button type="submit"
                            variant="primary"
                            className="shadow-sm"
                            onClick={sendResetEmail}
                        >
                            {emailSent ? 'Send Email Again' : 'Send Email'}
                        </Button>
                        </div>           
                    </Form>       
                    </div>
                </div>       
                </div>
            </Container>
        </AppPage>
    );
}