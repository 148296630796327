import { DateTime } from "luxon";
import { SleepEpisode, SleepStage, SleepStageType } from "../types/SleepLog";
import DateTimeUtils from "../utils/DateTimeUtils";

interface IntermediarySleepStage {
    start: DateTime;
    end: DateTime;
    type: SleepStageType;
};

export default class SleepEpisodeEditor {
  constructor(private episode: SleepEpisode) {
  }

  public get() {
    return this.episode;
  }

  public add(stage: SleepStage) {
    const newStage = {
      start: DateTime.fromISO(stage.start),
      end: DateTime.fromISO(stage.start).plus({ seconds: stage.seconds }),
      seconds: stage.seconds,
      type: stage.type
    };

    const sleepStages = this.episode.sleepStages.map(stage => {
      return {
        start: DateTime.fromISO(stage.start),
        end: DateTime.fromISO(stage.start).plus({ seconds: stage.seconds }),
        seconds: stage.seconds,
        type: stage.type
      };
    }); 

    // Must be adjacent to start or end
    if (newStage.start > sleepStages[sleepStages.length - 1].end || newStage.end < sleepStages[0].start) {
      return;
    }

    if (sleepStages.length === 0) {
      this.episode.sleepStages = [];
      this.episode.sleepStages.push({
        start: DateTimeUtils.format(newStage.start, "YYYY-MM-DDTHH:MM:SSZ"),
        seconds: newStage.end.diff(newStage.start).as("seconds"),
        type: newStage.type
      });

      this.episode.start = DateTimeUtils.format(newStage.start, "YYYY-MM-DDTHH:MM:SSZ");
      this.episode.end = DateTimeUtils.format(newStage.end, "YYYY-MM-DDTHH:MM:SSZ");

      this.episode.minutesAsleep = this.calculateMinutesAsleep(this.episode.sleepStages);
      this.episode.minutesAwake = this.calculateMinutesAwake(this.episode.sleepStages);

      return;
    }

    if (+newStage.start === +sleepStages[sleepStages.length - 1].end) {
      this.episode.sleepStages.push({
        start: DateTimeUtils.format(newStage.start, "YYYY-MM-DDTHH:MM:SSZ"),
        seconds: newStage.end.diff(newStage.start).as("seconds"),
        type: newStage.type
      });

      this.episode.end = DateTimeUtils.format(newStage.end, "YYYY-MM-DDTHH:MM:SSZ");

      this.episode.minutesAsleep = this.calculateMinutesAsleep(this.episode.sleepStages);
      this.episode.minutesAwake = this.calculateMinutesAwake(this.episode.sleepStages);

      this.episode.sleepStages = this.compress(this.episode.sleepStages);

      return;
    }      

    if (+newStage.end === +sleepStages[0].start) {
      this.episode.sleepStages.unshift({
        start: DateTimeUtils.format(newStage.start, "YYYY-MM-DDTHH:MM:SSZ"),
        seconds: newStage.end.diff(newStage.start).as("seconds"),
        type: newStage.type
      });

      this.episode.start = DateTimeUtils.format(newStage.start, "YYYY-MM-DDTHH:MM:SSZ");

      this.episode.minutesAsleep = this.calculateMinutesAsleep(this.episode.sleepStages);
      this.episode.minutesAwake = this.calculateMinutesAwake(this.episode.sleepStages);

      this.episode.sleepStages = this.compress(this.episode.sleepStages);

      return;
    }         

    const newSleepStages: SleepStage[] = [];
    let i = 0;

    if (newStage.start < sleepStages[i].start) {
      i = -1;
      newSleepStages.push({
        start: DateTimeUtils.format(newStage.start, "YYYY-MM-DDTHH:MM:SSZ"),
        seconds: sleepStages[0].start.diff(newStage.start).as("seconds"),
        type: newStage.type
      });
    }
    else {
      while (!(sleepStages[i].start <= newStage.start && newStage.start < sleepStages[i].end)) {
        newSleepStages.push(this.episode.sleepStages[i]);
        ++i;
      }
    }

    // newStage.start and newStage.end are in the same interval
    if (i !== -1 && sleepStages[i].start < newStage.end && newStage.end <= sleepStages[i].end) {
      // split into 1,2, or 3 intervals
      if (sleepStages[i].start < newStage.start) {
        newSleepStages.push({...this.episode.sleepStages[i], seconds: newStage.start.diff(sleepStages[i].start).as("seconds")});    
      }

      newSleepStages.push({
        start: DateTimeUtils.format(newStage.start, "YYYY-MM-DDTHH:MM:SSZ"),
        seconds: newStage.end.diff(newStage.start).as("seconds"),
        type: newStage.type
      });

      if (newStage.end < sleepStages[i].end) {
        newSleepStages.push({
          start: DateTimeUtils.format(newStage.end, "YYYY-MM-DDTHH:MM:SSZ"),
          seconds: sleepStages[i].end.diff(newStage.end).as("seconds"),
          type: sleepStages[i].type
        });            
      }

      i++;
    }
    else {
      if (i !== -1) {
        // split into 1 or 2 intervals
        if (sleepStages[i].start < newStage.start) {
          newSleepStages.push({...this.episode.sleepStages[i], seconds: newStage.start.diff(sleepStages[i].start).as("seconds")});    
        }

        newSleepStages.push({
          start: DateTimeUtils.format(newStage.start, "YYYY-MM-DDTHH:MM:SSZ"),
          seconds: sleepStages[i].end.diff(newStage.start).as("seconds"),
          type: newStage.type
        });          
      }

      i++;

      while (i < sleepStages.length && !(sleepStages[i].start < newStage.end && newStage.end <= sleepStages[i].end)) {
        newSleepStages.push({...this.episode.sleepStages[i], type: newStage.type });
        i++;
      }

      if (i < sleepStages.length) {
        // split into 1 or 2 intervals
        newSleepStages.push({
          start: this.episode.sleepStages[i].start,
          seconds: newStage.end.diff(sleepStages[i].start).as("seconds"),
          type: newStage.type
        });

        if (newStage.end < sleepStages[i].end) {
          newSleepStages.push({
            start: DateTimeUtils.format(newStage.end, "YYYY-MM-DDTHH:MM:SSZ"),
            seconds: sleepStages[i].end.diff(newStage.end).as("seconds"),
            type: sleepStages[i].type
          });               
        }

        i++;
      }
      else {
        // add new last
        newSleepStages.push({
          start: DateTimeUtils.format(sleepStages[i - 1].end, "YYYY-MM-DDTHH:MM:SSZ"),
          seconds: newStage.end.diff(sleepStages[i - 1].end).as("seconds"),
          type: newStage.type
        });          
      }
    }

    while (i < sleepStages.length) {
      // push remaining
      newSleepStages.push(this.episode.sleepStages[i]);
      i++;
    }

    this.episode.sleepStages = this.compress(newSleepStages);
    const lastSleepStage = this.episode.sleepStages[this.episode.sleepStages.length - 1];

    this.episode.start = this.episode.sleepStages[0].start;
    this.episode.end = DateTimeUtils.format(DateTime.fromISO(lastSleepStage.start).plus({ seconds: lastSleepStage.seconds }), "YYYY-MM-DDTHH:MM:SSZ");

    this.episode.minutesAsleep = this.calculateMinutesAsleep(this.episode.sleepStages);
    this.episode.minutesAwake = this.calculateMinutesAwake(this.episode.sleepStages);

    return this.episode;
  }

  /**
   * 
   * @param stages - stages, must be disjoint and contained within the sleep episode
   * @returns 
   */
  public addStages(stages: SleepStage[]) {

    /*
    console.log(stages.map(stage => ({
        start: DateTimeUtils.format(DateTime.fromISO(stage.start)),
        end: DateTimeUtils.format(DateTime.fromISO(stage.start).plus({ seconds: stage.seconds })),
        seconds: stage.seconds,
        type: stage.type
    })));
    */

    const newStages = stages.map(stage => ({
        start: DateTime.fromISO(stage.start),
        end: DateTime.fromISO(stage.start).plus({ seconds: stage.seconds }),
        seconds: stage.seconds,
        type: stage.type
    }));

    /*
    console.log(this.episode.sleepStages.map(stage => {
        return {
          start: DateTimeUtils.format(DateTime.fromISO(stage.start)),
          end: DateTimeUtils.format(DateTime.fromISO(stage.start).plus({ seconds: stage.seconds })),
          seconds: stage.seconds,
          type: stage.type
        };
    }));
    */

    const sleepStages = this.episode.sleepStages.map(stage => {
        return {
          start: DateTime.fromISO(stage.start),
          end: DateTime.fromISO(stage.start).plus({ seconds: stage.seconds }),
          seconds: stage.seconds,
          type: stage.type
        };
    });     

    let i = 0;
    let j = 0;

    const mergedStages: IntermediarySleepStage[] = [];

    const SENTINEL: IntermediarySleepStage = {
        type: "awake",
        start: DateTime.now().plus({ days: 1000000}),
        end: DateTime.now().plus({ days: 1000000}),        
    };

    while (i < newStages.length || j < sleepStages.length) {
        const newStage = newStages[i] ?? SENTINEL;
        const stage = sleepStages[j] ?? SENTINEL;

        if (newStage.start <= stage.start && newStage !== SENTINEL) {
            if (mergedStages.length > 0) {
                const lastStage = mergedStages[mergedStages.length - 1];
                const lastStageEnd = lastStage.end;

                if (newStage.start < lastStage.end) {
                    lastStage.end = newStage.start;
                }

                mergedStages.push({...newStage});

                if (newStage.end < lastStageEnd) {
                    mergedStages.push({
                        type: lastStage.type,
                        start: newStage.end,
                        end: lastStageEnd
                    });
                }
            }
            else {
                mergedStages.push({...newStage});
            }

            i++;
        }
        else if (stage !== SENTINEL) {
            if (mergedStages.length > 0) {
                const lastStage = mergedStages[mergedStages.length - 1];

                if (stage.end > lastStage.end) {
                    mergedStages.push({
                        type: stage.type,
                        start: lastStage.end,
                        end: stage.end
                    });
                }
            }
            else {
                mergedStages.push({...stage});
            }

            j++;
        }
        else {
            break;
        }
    }

    const updatedStages = mergedStages.map(stage => ({
        type: stage.type,
        start: DateTimeUtils.format(stage.start, "YYYY-MM-DDTHH:MM:SSZ"),
        seconds: stage.end.diff(stage.start).as("seconds")
    }));

    /*
    console.log(updatedStages.map(stage => ({
        start: DateTimeUtils.format(DateTime.fromISO(stage.start)),
        end: DateTimeUtils.format(DateTime.fromISO(stage.start).plus({ seconds: stage.seconds })),
        seconds: stage.seconds,
        type: stage.type
    })));
    */

    this.episode.sleepStages = this.compress(updatedStages);

    const lastSleepStage = this.episode.sleepStages[this.episode.sleepStages.length - 1];

    this.episode.start = this.episode.sleepStages[0].start;
    this.episode.end = DateTimeUtils.format(DateTime.fromISO(lastSleepStage.start).plus({ seconds: lastSleepStage.seconds }), "YYYY-MM-DDTHH:MM:SSZ");

    this.episode.minutesAsleep = this.calculateMinutesAsleep(this.episode.sleepStages);
    this.episode.minutesAwake = this.calculateMinutesAwake(this.episode.sleepStages);

    return this.episode;
  }

  public removeAwakenings(thresholdInSeconds: number) {
    const stages = this.episode.sleepStages;

    // Skip time to fall asleep and time after awakening
    for (let i = 1; i < stages.length - 1; ++i) {
      if (stages[i].seconds <= thresholdInSeconds && stages[i].type === "awake") {
        // Simply algorithm: take type to left which will not be awake
        stages[i].type = stages[i - 1].type;
      }
    }

    this.episode.sleepStages = this.compress(stages);
    this.episode.minutesAsleep = this.calculateMinutesAsleep(this.episode.sleepStages);
    this.episode.minutesAwake = this.calculateMinutesAwake(this.episode.sleepStages);

    // episode.start and episode.end are unchanged
  }

  private calculateMinutesAsleep(sleepStages: SleepStage[]) {
    return sleepStages
      .filter(episode => ['asleep', 'light', 'deep', 'rem'].includes(episode.type))
      .map(episode => episode.seconds / 60)
      .reduce((sum, duration) => sum + duration, 0);    
  }

  private calculateMinutesAwake(sleepStages: SleepStage[]) {
    return sleepStages
      .filter(episode => ['awake'].includes(episode.type))
      .map(episode => episode.seconds / 60)
      .reduce((sum, duration) => sum + duration, 0);
  }

  private compress(sleepStages: SleepStage[]) {
    if (sleepStages.length === 0) {
      return sleepStages;
    }

    let prev = {...sleepStages[0] };
    let compressedStages = [];

    for (let i = 1; i < sleepStages.length; ++i) {
      if (sleepStages[i].type !== prev.type) {
        compressedStages.push(prev);
        prev = {...sleepStages[i] };
      }
      else {
        prev.seconds += sleepStages[i].seconds;
      }
    }

    compressedStages.push(prev);
    return compressedStages;
  }  
}