import { ChartOptions } from "chart.js";
import React, { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDeferred from 'chartjs-plugin-deferred';

import ISleepLogView from "../../../../types/ISleepLogView";
import TimeAggregationButtonGroup from "../../../library/TimeAggregationButtonGroup";
import { useImmer } from "use-immer";
import { Aggregation } from "../../../../types/types";
import AnalyticsUtils from "../../../../utils/analytics-utils";
import _ from "lodash";
import SleepStagesUtils from "../../../../utils/SleepStagesUtils";
import { ChartStyles } from "../constants/chart-styles";

interface Props {
    sleepLogGroups: ISleepLogView[][];
    enableZoom?: boolean;
}

export default function TimeToFallAsleepChart({
    sleepLogGroups,
    enableZoom
}: Props) {

    const [timePeriod, setTimePeriod] = useImmer<Aggregation>("day");

    const average = useMemo(() => {
        if (sleepLogGroups.length === 0) {
            return undefined;
        }

        const time = sleepLogGroups[0]
            .filter(sleepLog => sleepLog.mainSleep)
            .map(sleepLog => {
                return SleepStagesUtils.getTimeToFallAsleepInMins(sleepLog.mainSleep!);
            });

        if (time.length === 0) {
            return undefined;
        }

        const sum = time.reduce((prev, cur) => prev + cur, 0);
        return sum / time.length;

    }, [sleepLogGroups[0]])    
   
    const options: ChartOptions<"bar"> = useMemo(() => {
        const unit = (() => {
            switch (timePeriod) {
                case 'day':
                    return 'day'
                case 'week':
                    return 'week';
                case 'month':
                    return 'month';
                default: 
                    return 'day'
            }
        })();

        return {
            plugins: {
                annotation: {
                    annotations: {
                        averageAwakenings: {
                            type: 'line',
                            borderColor: getPrimaryColor(.25),
                            borderWidth: 2,

                            scaleID: 'y',

                            value: average,
                        }
                    }
                },                 
                deferred: {
                },  
                legend: {
                    display: false
                },                          
                title: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                      label: function(context) {
                        return `${Math.round(context.raw as number)} mins`;
                      }          
                    }
                }, 
                zoom: {
                    pan: {
                        enabled: enableZoom,
                        mode: 'x',
                    },                
                    zoom: {
                        wheel: {
                            enabled: enableZoom,
                        },
                        pinch: {
                            enabled: enableZoom
                        },
                        mode: 'x',
                    }
                },
            },        
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: unit,
                        displayFormats: {
                            day: "M/d",
                            week: "M/d'-'",
                            month: "M/d'-'"
                        },
                        tooltipFormat: "MM/dd/y" + (unit === "day" ? '' : '-')
                    },   
                    adapters: {
                        date: {
                            zone: "UTC"
                        }
                    },                    
                    ticks: {
                        display: true,
                        font: {
                            ...ChartStyles.axisFont
                        }
                    },
                    grid: {
                        display: false,
                    }
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                            stepSize: 10,
                            font: {
                                ...ChartStyles.axisFont
                            }
                    },
                    grid: {
                        ...ChartStyles.gridYAxis
                    }
                }                
            },
        };
    }, [enableZoom, timePeriod, average]);

    const data = useMemo(() => {
        if (timePeriod === 'month' || timePeriod === 'week') {
            return getTimePeriodAggregationData(sleepLogGroups, timePeriod);
        }
        else {
            return getDayData(sleepLogGroups);
        }

    }, [sleepLogGroups[0], sleepLogGroups[1], timePeriod]);

    const aggregations: Aggregation[] = useMemo(() => ["day", "week", "month"], []);

    return (
        <div>
            <Bar
                data={data}
                options={options}
                plugins={[ChartDeferred]}
                width={null as any}
                height={null as any} 
            />
            
            <div className="mt-2 text-center">
                <TimeAggregationButtonGroup
                    aggregations={aggregations}
                    idPrefix="time-to-fall-asleep"
                    selected={timePeriod}
                    onSelect={(selected) => setTimePeriod(selected)} 
                />
            </div>

            <div className="mt-2">
                <span className="text-xs">Average time: {average?.toFixed(1)} mins</span>
            </div>
        </div>     
    );
}

function getPrimaryColor(opacity: number) {
    return `rgba(251, 113, 133, ${opacity})`;
}

function getDayData(sleepLogGroups: ISleepLogView[][]) {
    const data: any = {
        labels: [],
        datasets: [
            {
                label: ["Time to fall asleep (mins)"],
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 0,
                maxBarThickness: 100
            },                 
        ],
    };

    let datesMap: {[key: string]: {timeToFallAsleepInMins?: number, filtered: boolean }} = {};
    sleepLogGroups.forEach((sleepLogs, i) =>
        sleepLogs.forEach((log) => {
            const date = log.date.asString;
            datesMap[date] = {
                timeToFallAsleepInMins: log.mainSleep ? SleepStagesUtils.getTimeToFallAsleepInMins(log.mainSleep) : undefined,
                filtered: i === 0
            };
        })
    );

    const labels = Object.keys(datesMap).sort();
    data.labels = labels;
    
    labels.forEach((date: any) => {
        let values = datesMap[date];

        data.datasets[0].data.push(values.timeToFallAsleepInMins ?? undefined);
        data.datasets[0].backgroundColor.push(getPrimaryColor(values.filtered ? .75 : .075));
        data.datasets[0].borderColor.push(getPrimaryColor(values.filtered ? 1 : .075));        
    });
    
    return data;
}

function getTimePeriodAggregationData(sleepLogGroups: ISleepLogView[][], period: 'week' | 'month') {
    const data: any = {
        labels: [],
        datasets: [
            {
                label: ["Time to fall asleep (mins)"],
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 0,
                maxBarThickness: 100
            },                 
        ],
    };

    const dataset = data.datasets[0];

    if (sleepLogGroups.length === 0) {
        return data;
    }

    let totalLogs = sleepLogGroups.map(logs => logs.length).reduce((prev, cur) => prev + cur, 0);

    if (totalLogs === 0) {
        return data;
    }

    let start = "9999-12-31";
    let end = "";

    for (const sleepLogs of sleepLogGroups) {
        if (sleepLogs.length > 0) {
            const firstDate = sleepLogs[0].date.asString;
            const lastDate = sleepLogs[sleepLogs.length - 1].date.asString;

            start = firstDate < start ? firstDate : start;
            end = lastDate > end ? lastDate : end;
        }
    };

    const dateToDataMap = Object.fromEntries(sleepLogGroups[0].map(sleepLog => [
        sleepLog.date.asString,
        sleepLog.mainSleep ? SleepStagesUtils.getTimeToFallAsleepInMins(sleepLog.mainSleep) : undefined
    ]));  
    const dateRange = AnalyticsUtils.fillDateRange(start, end);
    const values: (number | undefined)[] = dateRange.map(date => dateToDataMap[date]);

    const periodLength = (() => {
        switch (period) {
            case 'week':
                return 7;
            case 'month':
                return 30;
            default:
                return Number.MAX_SAFE_INTEGER;
        }
    })();

    const { dates, values: aggregatedValues } = AnalyticsUtils.aggregation(
        dateRange,
        values,
        period,
        1
    );

    for (const [date, value] of _.zip(dates, aggregatedValues)) {
        const formattedDate = date;
        data.labels.push(formattedDate);
        dataset.data.push(value as number);

        dataset.backgroundColor.push(getPrimaryColor(.75));
        dataset.borderColor.push(getPrimaryColor(1));
    }

    return data;    
}