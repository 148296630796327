import classNames from "classnames";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useImmer } from "use-immer";
import { TagSuggestionsService } from "../../../../services/TagSuggestionsService";
import ISleepLogView from "../../../../types/ISleepLogView";
import SleepLog from "../../../../types/SleepLog";
import DateTimeUtils from "../../../../utils/DateTimeUtils";
import { FormatUtils, ratingColorMap } from "../../../../utils/FormatUtility";
import { ISleepLogUpdateFlusher, MockSleepLogUpdateFlusher } from "../../../../utils/SleepLogUpdateFlusher";
import { SleepLogHttpServiceContext } from "../../../http/sleep-log-http-service.provider";
import SleepLogModal from "../../../sleep-log-view/SleepLogModal";
import SleepLogRow from "../../../sleep-log-view/SleepLogRow";
import styles from "./event-chart.module.css";
import SleepLogSettings from "../../../../types/SleepLogSettings";
import { ColorUtils } from "../../../../utils/ColorUtils";

interface Props {
    allSleepLogs: ISleepLogView[];
    filteredSleepLogs?: ISleepLogView[];
    sleepLogSettings?: SleepLogSettings;
}

interface State {
    labels: string[],
    sleepLogMap: Record<string, number>; // mapping from date (as string) to index in allSleepLogs
    sleepLogEventMap: Record<string, string[]>;
    filteredLogMap: Record<string, number>; // mapping from date to index
    expandedSleepLog: SleepLog | undefined;
    showColorCodingMenu: boolean;
    colorCoding?: "sleep" | "rating";
}

export default function EventsChart({ allSleepLogs, filteredSleepLogs, sleepLogSettings }: Props) {
    const [state, setState] = useImmer<State>({
        labels: [],
        sleepLogMap: {},
        sleepLogEventMap: {},
        filteredLogMap: {},
        expandedSleepLog: undefined,
        showColorCodingMenu: false,
        colorCoding: undefined
    });
    const { labels, sleepLogMap, sleepLogEventMap, filteredLogMap, expandedSleepLog } = state;
    const [zoom, setZoom] = useState(1);
    const [showEvents, setShowEvents] = useState(true);

    const sleepLogHttpService = useContext(SleepLogHttpServiceContext)!;

    const mockSleepLogUpdateFlusher = useRef<ISleepLogUpdateFlusher | undefined>(undefined);
    if (!mockSleepLogUpdateFlusher.current) {
            mockSleepLogUpdateFlusher.current = new MockSleepLogUpdateFlusher();
    }

    useEffect(() => {
        if (allSleepLogs.length > 0) {
            setState((state: State) => {
                const start = DateTime.fromISO(allSleepLogs[0].date.asString, { zone: "utc" });
                const end = DateTime.fromISO(allSleepLogs[allSleepLogs.length - 1].date.asString, { zone: "utc" });
                let cur = start;

                state.labels = [];

                while (cur <= end) {
                    const date = DateTimeUtils.format(cur, 'YYYY-MM-DD');
                    state.labels.push(date);
                    cur = cur.plus({ days : 1 });
                }

                const getEvents = (sleepLog: SleepLog) => {
                    const legacyEvents = sleepLog.tags?.filter(tag => tag.includes("Event:")).map(e => e.substring(7)) ?? [];
                    const events = sleepLog.events?.map(event => event.name) ?? [];
                    return legacyEvents.concat(events);
                };

                state.sleepLogMap = Object.fromEntries(allSleepLogs.map((sleepLog, i) => [sleepLog.date.asString, i]));
                state.sleepLogEventMap = Object.fromEntries(allSleepLogs.map(sleepLog => [sleepLog.date.asString, getEvents(sleepLog.baseSleepLog)]));
                state.filteredLogMap = Object.fromEntries(filteredSleepLogs?.map(((sleepLog, i) => [sleepLog.date.asString, i])) ?? []);
            });
        }
    }, [allSleepLogs, filteredSleepLogs]);

    const showDate = (date: string) => {
        return DateTime.fromISO(date).day === 1;
    }

    function onOpenModal(e: React.MouseEvent, sleepLogIndex: number | undefined, requireCtrlKey = false) {
        if ((!requireCtrlKey || e.ctrlKey) && sleepLogIndex !== undefined) {
            const sleepLog = allSleepLogs[sleepLogIndex];
            setState(state => {
                state.expandedSleepLog = sleepLog.baseSleepLog;
            });
        }
    }

    function onCloseModal() {
        setState(state => {
            state.expandedSleepLog = undefined;
        });
    }

    function setNextFocusedSleepLog(sleepLogId: string, direction: number) {
        if (!expandedSleepLog) {
            return;
        }

        let index: number;
        let sleepLogs: ISleepLogView[];

        if (filteredSleepLogs !== undefined && state.filteredLogMap[expandedSleepLog.date] !== undefined) {
            index = state.filteredLogMap[expandedSleepLog.date];
            sleepLogs = filteredSleepLogs;
        }
        else {
            index = state.sleepLogMap[expandedSleepLog.date];
            sleepLogs = allSleepLogs;
        }


        if ((direction === -1 && index > 0) || (direction === 1 && index < sleepLogs.length - 1)) {
            setState(state => {
                state.expandedSleepLog = sleepLogs[index + direction].baseSleepLog;
            });
        }        
    }

    function setColorCoding(colorCoding?: "sleep" | "rating") {
        setState(state => {
            state.colorCoding = colorCoding;
            state.showColorCodingMenu = false;
        })
    }

    function getColorCoding(
        isFiltered: boolean | undefined,
        dateLabel: string
    ) {
        const sleepLog = allSleepLogs[sleepLogMap[dateLabel]];
        if (!sleepLog) {
            return;
        }

        if (!state.colorCoding) {
            return undefined;
        }

        switch (state.colorCoding) {
            case "sleep":
                if (sleepLog.minutesAsleep === undefined) {
                    return undefined;
                }

                return getSleepColorCoding(
                    sleepLog.minutesAsleep,
                    isFiltered === undefined || isFiltered ? 1 : .15
                );
            case "rating":
                if (sleepLog.rating === undefined) {
                    return undefined;
                }

                return ColorUtils.getRatingColor(sleepLog.rating, isFiltered === undefined || isFiltered ? 1 : .15);
            default:
                return undefined;
        }
    }

    return (
        <div>
            <div className={styles.eventChart}>
                <div className={classNames("d-flex position-relative", styles.chart)} style={{transform: `scale(${zoom})`}}>
                    { labels.map((label, i) =>
                        <div key={i} className={styles.dayContainer}>
                            { showEvents &&
                                <>
                                { sleepLogEventMap[label]?.length > 0 ?
                                    <div className={classNames("pb-1", styles.event)}>
                                        <div
                                            className={classNames("text-nowrap px-1 mb-1 rounded", styles.eventLabelDate)}
                                        >
                                            {FormatUtils.formatDate(label, true /* omitThisYear */, false /* omitYear */)}
                                        </div>
                                        { sleepLogEventMap[label].slice(0, 5).map((event, j) =>
                                            <div
                                                key={j}
                                                className={classNames("text-truncate text-nowrap px-1 mb-1 bg-white border rounded", styles.eventLabel)}
                                            >
                                                {event}
                                            </div>
                                        )}
                                    </div>

                                    :

                                    <div className={classNames("pb-1", styles.noEvent)}></div>
                                }
                                </>
                            }

                            <OverlayTrigger
                                trigger={["hover", "focus"]} 
                                placement="bottom"
                                overlay={
                                <Popover id="popover-basic" className={styles.sleepLogRowTooltip}>
                                    <Popover.Body>
                                        { sleepLogMap[label] && allSleepLogs[sleepLogMap[label]] ?
                                            <div>
                                                <div className="d-flex justify-content-end">
                                                    <button className="btn btn-outline-secondary border-0">
                                                        <i className="bi bi-arrows-angle-expand" onClick={(e) => onOpenModal(e, sleepLogMap[label])}></i>
                                                    </button>    
                                                </div>
                                                <SleepLogRow
                                                    sleepLog={allSleepLogs[sleepLogMap[label]].baseSleepLog}
                                                    sleepLogSettings={sleepLogSettings}
                                                    isNotesExpanded={false}
                                                    showOutline={false}
                                                    showMedicationDose={true}
                                                    onClick={() => 0}
                                                />
                                                <span className="text-secondary text-xxs">CTRL + click to expand</span>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                    </Popover.Body>
                                </Popover>
                                }
                            >
                                <div className={ classNames(styles.day, "position-relative", { [styles.filteredDay]: filteredLogMap[label] !== undefined }) }
                                    style={{ 
                                        backgroundColor: getColorCoding(filteredSleepLogs ? filteredLogMap[label] !== undefined : undefined, label),
                                        borderColor: getColorCoding(filteredSleepLogs ? filteredLogMap[label] !== undefined : undefined, label)
                                        
                                    }}
                                    onClick={(e) => onOpenModal(e, sleepLogMap[label], true /* requireCtrlKey */)}
                                >
                                </div>
                            </OverlayTrigger>

                            { (showEvents && (showDate(label) || (i === 0) || (i + 1 === labels.length))) &&
                                <div className={classNames("position-relative start-50 translate-middle-x", styles.label)}>
                                    {FormatUtils.formatDate(label, true /* omitThisYear */, true /* omitYear */)}
                                </div>
                            }                
                        </div>
                    )}
                </div>          
            </div>
            <div className="mt-3 d-flex justify-content-center">
                <button className="btn" onClick={() => setZoom(z => z - .1)}>
                    <i className="bi bi-zoom-out"></i>
                </button>                 
                <button className="btn" onClick={() => setZoom(z => z + .1)}>
                    <i className="bi bi-zoom-in"></i>
                </button>
                <button className="btn" onClick={() => setShowEvents(show => !show)}>
                    <i className={`bi bi-calendar-check${showEvents ? '-fill' : ''}`}></i>
                </button>
                <MenuTrigger
                    show={state.showColorCodingMenu}
                    onToggle={(show) => setState(state => { state.showColorCodingMenu = show; })}
                    menu={
                        <div className="list-group">
                            <button type="button" 
                                className={classNames("list-group-item list-group-item-action", { "active": state.colorCoding === undefined})} 
                                onClick={() => setColorCoding()}
                            >
                                No color coding
                            </button>
                            <button type="button" 
                                className={classNames("list-group-item list-group-item-action", { "active": state.colorCoding === "sleep"})}
                                onClick={() => setColorCoding("sleep")}
                            >
                                Color code by hours slept
                            </button>
                            <button type="button"
                                className={classNames("list-group-item list-group-item-action", { "active": state.colorCoding === "rating"})}
                                onClick={() => setColorCoding("rating")}
                            >
                                Color code by rating
                            </button>
                        </div>
                    }
                >
                    <button className="btn">
                        <i className={classNames("bi", state.colorCoding ? "bi-palette-fill" : "bi-palette")}></i>
                    </button>
                </MenuTrigger>                         
            </div>
            {  expandedSleepLog && 
                <SleepLogModal
                    show={!!expandedSleepLog}
                    loginState={{ loggedIn: false }}
                    focusedSleepLog={expandedSleepLog}
                    editable={false}
                    isSaving={false}
                    recentSleepLogs={allSleepLogs}
                    sleepLogUpdateFlusher={mockSleepLogUpdateFlusher.current}
                    handleClose={onCloseModal}
                    updateSleepLog={() => 0}
                    setNextFocusedSleepLog={setNextFocusedSleepLog}
                    handleDeleteLog={() => 0}
                    addStatus={() => 0}
                    openSettings={() => 0}
                />
            }    
        </div>
    );
}

interface MenuProps {
    show: boolean;
    menu: React.ReactElement;
    children: React.ReactElement;
    onToggle: (show: boolean) => void;
}

function MenuTrigger({ show, menu, children, onToggle }: MenuProps) {
    return (
    <OverlayTrigger
        show={show}
        placement="bottom"
        delay={{ show: 250, hide: 100 }}
        trigger={["click"]}
        onToggle={onToggle}
        rootClose={true}
        offset={[0, 0]}
        overlay={
            (props: any) => <div {...props}>{menu}</div>
        }
    >
        { children }
    </OverlayTrigger>
    );
}

const colors = [
    'rgba(255, 99, 132, X)',
    'rgba(250, 140, 22, X)',
    'rgba(255, 197, 61, X)',
    'rgba(250, 219, 20, X)',
    'rgba(75, 192, 192, X)',
];

function getSleepColorCoding(sleep: number, opacity: number) {
    if (sleep >= 7 * 60) {
        return colors[4].replace('X', opacity.toString());
    }
    else if (sleep >= 6.5 * 60) {
        return colors[3].replace('X', opacity.toString());
    }
    else if (sleep >= 6 * 60) {
        return colors[2].replace('X', opacity.toString());
    }
    else if (sleep >= 5 * 60) {
        return colors[1].replace('X', opacity.toString());
    }
    else {
        return colors[0].replace('X', opacity.toString());
    }
}