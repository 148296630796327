interface Props {
    sizeInPx: number;
    bgColor: string;
    fontColor: string;
    borderRadius: string;
    children: any;
}

export default function Icon(props: Props) {
    return (
        <div className="d-flex justify-content-center align-items-center"
        style={{
            width: `${props.sizeInPx}px`, 
            height: `${props.sizeInPx}px`, 
            background: props.bgColor, 
            color: props.fontColor,
            borderRadius: props.borderRadius,
            fontSize: props.sizeInPx / 2
        }}
    >
        {props.children}
    </div>
    );
}