import classNames from "classnames";
import ChangeArrow from "../common/ChangeArrow";
import styles from "./styles/card-styles.module.css";

interface Props {
  rating?: number;
  comparison?: number;
}

export default function RatingCard({rating, comparison}: Props) {
  
    return (
        <div className={classNames("card tw-shadow ring-1 ring-gray-200", styles.cardAccent, styles.ratingAccent)}>
            <div className="card-body p-3 text-center">

                <div className="fw-medium">
                    { rating ?
                    <ChangeArrow val={rating} baseLine={comparison} />
                    :
                    <></>
                    }          
                    <span className="fs-4">{rating !== undefined ? rating.toFixed(1) : "-"}</span>
                    <span className="fs-6"> / 10</span>
                </div>

                <div className="text-gray-500 mt-2">Rating</div>
            </div>
        </div>
    );
}