import { useMemo, useState } from "react";
import { Scatter } from "react-chartjs-2";
import ISleepLogView from "../../../../types/ISleepLogView";
import { prettyFormatTimeDurationInHours } from "../../../../utils/FormatUtility";
import { ChartOptions } from "chart.js";

import { ChartStyles } from "../constants/chart-styles";

interface Props {
    sleepLogs: ISleepLogView[];
}

type DataType = 'sleep' | 'rating';

export default function SleepMedicationDoseChart({sleepLogs}: Props) {
    const meds = useMemo(() => {
        const uniqMeds = new Set(sleepLogs.flatMap(sleepLog => sleepLog.baseSleepLog.medications?? []).map(med => med.name));
        return Array.from(uniqMeds.keys());

    }, [sleepLogs]);
    
    const [selectedMed, setSelectedMed] = useState(() => meds[0] ?? '');
    const [isOnlyMed, setIsOnlyMed] = useState(false);
    const [type, setType] = useState<DataType>('sleep');

    const sleepMedicationData = useMemo(() => {
        return constructData(sleepLogs, type, selectedMed, isOnlyMed);
    }, [sleepLogs, type, selectedMed, isOnlyMed]);

    const options = useMemo(() => {
        const options: ChartOptions<"scatter"> = {
            plugins: {
                legend: {
                    display: false
                },            
                title: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label: function(context: any) {
                            return type === 'sleep' ? 
                                `Dose: ${context.raw.x} mg, Sleep : ${prettyFormatTimeDurationInHours(context.raw.y) ?? 0}`
                                :
                                `Dose: ${context.raw.x} mg, Rating : ${context.raw.y.toFixed(1)}}`;
                        }                    
                    }
                }
            },        
            scales: {
                x: {
                        grid: {
                                color: "rgba(0, 0, 0, 0)",
                        },
                        ticks: {
                            font: {
                                ...ChartStyles.axisFont
                            }
                        }
                },
                y: {
                    beginAtZero: false,
                    ticks: {
                        stepSize: 1,
                        font: {
                            ...ChartStyles.axisFont
                        }
                    },
                    grid: {
                        ...ChartStyles.gridYAxis
                    }
                }
            },     
        };

        return options;
    }, [type]); 

    return (
        <>
            <Scatter data={sleepMedicationData} options={options} />
            <div className="mt-3 d-flex justify-content-between">
                <div className="p-0">
                    <select
                        className="form-select form-select-sm"
                        style={{width: 'auto'}}
                        value={type}
                        onChange={(e: any) => setType(e.target.value)}
                    >
                        <option value='sleep'>Sleep</option> 
                        <option value='rating'>Rating</option>                                                          
                    </select>
                </div> 

                <div className="p-0">
                    <select
                        className="form-select form-select-sm"
                        style={{width: 'auto'}}
                        value={selectedMed}
                        onChange={(e: any) => setSelectedMed(e.target.value)}
                    >
                        {meds.map(med =>
                            <option value={med}>{med}</option>
                        )}                                                                
                    </select>
                </div>
                <div className="form-check">
                    <input type="checkbox"
                        className="form-check-input"
                        checked={isOnlyMed}
                        onChange={e => setIsOnlyMed(e.target.checked)}
                    />
                    <label className="form-check-label text-xs">
                        Only sleep med
                    </label>
                </div>
            </div>                   
        </>
    );
}

function constructData(sleepLogs: ISleepLogView[], type: 'sleep' | 'rating', selectedMed: string, isOnlyMed: boolean) {
    let points = sleepLogs
        .filter(sleepLog => (type === 'sleep' ? sleepLog.minutesAsleep != null : sleepLog.rating != null) && sleepLog.baseSleepLog.medications?.find(med => med.name === selectedMed))
        .filter(sleepLog => isOnlyMed ? !sleepLog.baseSleepLog.medications?.find(med => med.name !== selectedMed) : true)
        .map(sleepLog => ({ data: type === 'sleep' ? sleepLog.minutesAsleep! / 60 : sleepLog.rating!, meds: sleepLog.baseSleepLog.medications!.filter(med => med.name === selectedMed) }))
        .map(point => ({ data: point.data, dose: point.meds.map(med => Number(med.dose) ?? 0).reduce((prev, cur) => prev + cur, 0)}))
        .map(point => ({ x: point.dose, y: point.data }));

    if (type === 'rating') {
        // TODO: Make sure the tooltips are correct

        let minDose = Number.MAX_SAFE_INTEGER;
        let maxDose = Number.MIN_SAFE_INTEGER;

        for (const point of points) {
            if (point.x < minDose) {
                minDose = point.x;
            }

            if (point.x > maxDose) {
                maxDose = point.x;
            }
        }

        let maxDoseJitter = 0;

        if (points.length > 0) {
            maxDoseJitter = Math.min((maxDose - minDose) / 40, .5);
            console.log(maxDoseJitter);
        }

        points = points.map(point => {
            const xJitter = (Math.random() * (maxDoseJitter / 2)) - maxDoseJitter;
            const yJitter = (Math.random() * .4) - .2;
            return { x: point.x + xJitter, y: point.y + yJitter };
        });
    }

    const doseSleepSumMap = new Map<number, { sum: number, count: number }>();

    for (const point of points) {
        if (!doseSleepSumMap.has(point.x)) {
            doseSleepSumMap.set(point.x, { sum: 0, count: 0 });
        }

        let { sum, count } = doseSleepSumMap.get(point.x)!;
        sum += point.y;
        count++;

        doseSleepSumMap.set(point.x, { sum, count });
    }

    const averages = Array.from(doseSleepSumMap, ([dose, { sum, count }]) => ({ x: dose, y: count > 0 ? sum / count : undefined }))
        .sort((lhs, rhs) => lhs.x - rhs.x);

    const scatterColor = getColor(type, .2);
    const borderColor = getColor(type, .3);
    const lineColor = getColor(type, .5);

    const data: any = {
        labels: [],
        datasets: [
            {
                label: [""],
                data: points,
                fill: false,
                backgroundColor: scatterColor,
                borderColor: borderColor,
                borderWidth: 1,
                pointRadius: 6,
            },
            /*{
                type: 'line',
                label: ['Average'],
                data: averages,
                fill: false,
                backgroundColor: lineColor,
                borderColor: lineColor
            }*/             
        ],
    };

    return data;
}

function getColor(type: 'sleep' | 'rating', opacity: number) {
    switch (type) {
        case 'sleep':
            return `rgba(61, 139, 253, ${opacity})`;
        case 'rating':
            return `rgba(255, 214, 102, ${opacity + .1})`;
        default:
            return 'rgba(255, 255, 255, 1)';
    }
}