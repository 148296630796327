import { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {Tabs, Tab} from 'react-bootstrap/';
import { Chart, registerables} from "chart.js";
import ChartZoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useNavigate } from 'react-router-dom';
import classNames from "classnames";

import {LoginState} from '../../types/CommonInterfaces';
import SleepLogsAnalytics from '../sleep-log-analytics-view/main/SleepLogAnalytics';
import SleepLogsTable from './SleepLogsTable';
import { StatusMessage } from '../common/StatusMessages';
    
import { SleepLogSettingsServiceContext } from '../sleep-log-settings/services/sleep-log-settings-service.provider';
import AppPage from '../../app/AppPage';

import styles from './styles/sleep-logs-view.module.css';
import { logger } from '../../logger/EventLogger';

Chart.register(...registerables);
Chart.register(ChartZoomPlugin);
Chart.register(annotationPlugin);

type Tab = 'sleep-logs' | 'sleep-analytics';

interface Props {
    loginState: LoginState;
    initialTab: Tab;
    addStatus: (msg: StatusMessage) => any;
}

export default function SleepLogsView({loginState, initialTab, addStatus}: Props)
{
    const [currentTab, setCurrentTab] = useState(initialTab);

    useEffect(() => {
        setCurrentTab(initialTab);
    }, [initialTab]);

    useEffect(() => {
        logger.log("Viewed Sleep Logs", { tab: currentTab});
    }, [currentTab]);

    const navigate = useNavigate();

    function handleTabChange(tab: string | null) {
        tab = tab === null ? 'sleep-logs' : tab;

        let scopedTab: Tab = 'sleep-logs';
        switch (tab) {
            case 'sleep-logs':
            case 'sleep-analytics':
                scopedTab = tab;
                break;
            default:
                break;
        }

        setCurrentTab(scopedTab);

        navigate(scopedTab === 'sleep-logs' ? '/sleep-logs' : '/dashboard');
    }    

    return (
        <AppPage className="app-bg-light pt-4">
            <Container className="">
                <Row>
                    <div className="col-12">
                        <Tabs variant={"underline" as any}
                            className={classNames("border-bottom", styles.tabs)}
                            activeKey={currentTab} 
                            id="sleep-tabs" 
                            onSelect={(tab) => handleTabChange(tab)}
                        >
                            <Tab eventKey="sleep-logs" title={<span>Sleep Logs</span>}>
                                <SleepLogsTable
                                    loginState={loginState}
                                    addStatus={addStatus}
                                    currentTab={currentTab}
                                />
                            </Tab>                     
                            <Tab eventKey="sleep-analytics" title={<span>Dashboard</span>}>
                                <SleepLogsAnalytics
                                    loginState={loginState}
                                    currentTab={currentTab}
                                    addStatus={addStatus}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </Row>    
            </Container>
        </AppPage>
    );
}