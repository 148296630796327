import ErrorBoundary from "../../common/ErrorBoundary";
import SimpleTooltip from "../../sleep-log-view/common/SimpleTooltip";
import ChartCard from "../cards/ChartCard";
import SleepPerDayChart from "./sleep/SleepPerDayChart";
import SleepPerDayLineChart from "./sleep/SleepPerDayLineChart";
import ISleepLogView from "../../../types/ISleepLogView";
import { Aggregation } from "../../../types/types";
import TimeAggregationButtonGroup, { allAggregations } from "../../library/TimeAggregationButtonGroup";

interface Props {
    sleepLogGroups: ISleepLogView[][];
    aggregation: Aggregation;
    goalSleepInMins?: number;
    showLine: boolean;
    useColorCoding: boolean;
    alwaysShowPoints: boolean;

    onShowLine: (showLine: boolean) => void;
    onAggregationChange: (aggregation: Aggregation) => void;
    onColorCodingChange: (useColorCoding: boolean) => void;
    onAlwaysShowPointsChange: (alwaysShowPoints: boolean) => void;
}

export const SleepPerDayChartCard = (props: Props) =>
    <ChartCard title="Hours Slept">
        <ErrorBoundary>
            { props.showLine ?
                <SleepPerDayLineChart 
                    sleepLogGroups={props.sleepLogGroups}
                    aggregation={props.aggregation}
                    goalSleepInMins={props.goalSleepInMins}
                    showLegend={false}
                    useColorCoding={props.useColorCoding}
                    enableZoom={true}
                    alwaysShowPoints={props.alwaysShowPoints}
                />                                           
                :
                <SleepPerDayChart
                    sleepLogs={props.sleepLogGroups}
                    aggregationPeriod={props.aggregation}
                    goalSleepInMins={props.goalSleepInMins}
                    showLegend={false}
                    colorRows={props.useColorCoding}
                    enableZoom={true}
                />
            }
        </ErrorBoundary>

        <div className="mt-2"></div>

        <div className='text-center'>
            <TimeAggregationButtonGroup
                aggregations={allAggregations}
                idPrefix="sleep-per-day"
                selected={props.aggregation}
                onSelect={(selected) => props.onAggregationChange(selected)} 
            />                   
        </div>
        
        <div className="mt-3 d-flex text-sm">
            <button type="button"
                className="btn btn-link link-dark py-0"
                onClick={() => props.onShowLine(!props.showLine)}
            >
                <SimpleTooltip message="Display a bar or line chart">
                    <i className={props.showLine ? 'bi bi-bar-chart-line' : 'bi bi-graph-up'}></i>
                </SimpleTooltip>
            </button>

            <button type="button"
                className="btn btn-link link-dark py-0"
                onClick={() => props.onColorCodingChange(!props.useColorCoding)}
            >
                <SimpleTooltip message="Color code the chart">
                    <i className={props.useColorCoding ? 'bi bi-palette-fill' : 'bi bi-palette'}></i>
                </SimpleTooltip>
            </button>

            { false &&
                <button type="button"
                    className="btn btn-link link-dark py-0"
                    hidden={!props.showLine}
                    onClick={() => props.onAlwaysShowPointsChange(!props.alwaysShowPoints)}
                >
                    <SimpleTooltip message="Always show points">
                        <i className={props.alwaysShowPoints ? "bi bi-circle" : "bi bi-circle-fill"}></i>
                    </SimpleTooltip>
                </button>
            }                                                                                                                  
        </div>                                    
    </ChartCard>
;