import { TagStyle } from "../types/SleepLogSettings";
import { TailwindColors } from "./tailwindColors";

export const defaultStyles: { [category: string]: TagStyle} = {
    feelings: {
        backgroundColor: "#f9f0ff",
        borderColor: "#efdbff",
        borderWidth: 1,
        fontColor: "black",
        fontWeight: 500
    },
    tags: {
        backgroundColor: "#f0f5ff",
        borderColor: "#d6e4ff",
        borderWidth: 1,
        fontColor: "black",
        fontWeight: 500
    },
    events: {
        backgroundColor: "#fcffe6",
        borderColor: "#d3f261",
        borderWidth: 1,
        fontColor: "#254000",
        fontWeight: 500        
    }
}

export const customColors = [
    {
        backgroundColor: TailwindColors["indigo-100"],
        borderColor: TailwindColors["indigo-200"],
        fontColor: TailwindColors["indigo-950"]
    },
    {
        backgroundColor: TailwindColors["blue-100"],
        borderColor: TailwindColors["blue-200"],
        fontColor: TailwindColors["blue-950"]
    },
    {
        backgroundColor: TailwindColors["sky-100"],
        borderColor: TailwindColors["sky-200"],
        fontColor: TailwindColors["sky-950"]
    },        
    {
        backgroundColor: TailwindColors["rose-100"],
        borderColor: TailwindColors["rose-200"],
        fontColor: TailwindColors["rose-950"]
    },
    {
        backgroundColor: TailwindColors["teal-100"],
        borderColor: TailwindColors["teal-200"],
        fontColor: TailwindColors["teal-950"]
    },
    {
        backgroundColor: TailwindColors["emerald-100"],
        borderColor: TailwindColors["emerald-200"],
        fontColor: TailwindColors["emerald-950"]
    },    
    {
        backgroundColor: TailwindColors["purple-100"],
        borderColor: TailwindColors["purple-200"],
        fontColor: TailwindColors["purple-950"]
    },
    {
        backgroundColor: TailwindColors["violet-100"],
        borderColor: TailwindColors["violet-200"],
        fontColor: TailwindColors["violet-950"]
    },    
    {
        backgroundColor: TailwindColors["fuchsia-100"],
        borderColor: TailwindColors["fuchsia-200"],
        fontColor: TailwindColors["fuchsia-950"]
    },
    {
        backgroundColor: TailwindColors["pink-100"],
        borderColor: TailwindColors["pink-200"],
        fontColor: TailwindColors["pink-950"]
    },    
    {
        backgroundColor: TailwindColors["orange-100"],
        borderColor: TailwindColors["orange-200"],
        fontColor: TailwindColors["orange-950"]
    },
    {
        backgroundColor: TailwindColors["amber-100"],
        borderColor: TailwindColors["amber-200"],
        fontColor: TailwindColors["amber-950"]
    },
    {
        backgroundColor: TailwindColors["yellow-100"],
        borderColor: TailwindColors["yellow-200"],
        fontColor: TailwindColors["yellow-950"]
    },    
    {
        backgroundColor: TailwindColors["slate-100"],
        borderColor: TailwindColors["slate-200"],
        fontColor: TailwindColors["slate-950"]
    },
    {
        backgroundColor: TailwindColors["stone-100"],
        borderColor: TailwindColors["stone-200"],
        fontColor: TailwindColors["stone-950"]
    },  
];