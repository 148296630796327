import { ChartData, ChartOptions } from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartDeferred from 'chartjs-plugin-deferred';
import ISleepLogView from "../../../../types/ISleepLogView";
import { ChartStyles } from "../constants/chart-styles";

interface Props {
  sleepLogs: ISleepLogView[];
}

export default function RatingBreakdownChart({sleepLogs}: Props) {

  const [ratingBreakdown, setRatingBreakdown] = useState<ChartData<"bar", number[], string>>({
    labels: [],
    datasets: []
  });

  function calculateRatingBreakdown(sleepLogsDatasets: ISleepLogView[][], bgColors: string[], borderColors: string[]) {

    let datasets = sleepLogsDatasets.map((e, i) => {
      return {
        label: "",
        data: [],
        backgroundColor: bgColors[i],
        borderColor: borderColors[i],
        borderWidth: 0,
        maxBarThickness: 100
      }      
    });

    let ratingBreakdown: any = {
      labels: [],
      datasets: datasets,
    };

    const maxRating = 10;
    const bucketCount = maxRating + 1; // 0 to 10

    ratingBreakdown.datasets.forEach((e: any, i: number) => {
      ratingBreakdown.datasets[i].data = new Array<number>(bucketCount).fill(0);
    });

    for (let i = 0; i < bucketCount; ++i) {
      ratingBreakdown.labels.push(`${i}`);
    }

    sleepLogsDatasets.forEach((sleepLogs, i) => {
      const buckets = ratingBreakdown.datasets[i].data;
    
      sleepLogs.forEach(log => {
        if (log.baseSleepLog.rating !== undefined) {
          buckets[log.baseSleepLog.rating]++;
        }
      });

      for (let i = 0; i < buckets.length; ++i) {
        buckets[i] = (buckets[i] / sleepLogs.length) * 100;
      }
    });

    

    return ratingBreakdown;
  }

  const options: ChartOptions<"bar"> = {
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        color: "grey",
        font: {
            ...ChartStyles.axisFont
        },
        formatter: function(value, context) {
          const percent = value as number;
          return percent >= 5 ? `${value.toFixed(0)}%` : "";
        }
      },      
      tooltip: {
        callbacks: {
          label: function(context) {
            const percent = (context.raw ?? 0) as number;
            const numDays = Math.round((percent / 100) * sleepLogs.length).toFixed(0);
            return `${numDays} days (${percent.toFixed(0)}%)`;            
          }
        }
      },
      legend: {
        display: false
      },
      title: {
        display: false
      },      
    },    
    scales: {
      x: {
          grid: {
            color: "rgba(0, 0, 0, 0)",
          },
          stacked: true,
          ticks: {
            font: {
                ...ChartStyles.axisFont
            },
          }
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value, index) {
            let numDays = value as number;
            return `${numDays}%`;
          },             
          stepSize: 1,
          font: {
            ...ChartStyles.axisFont
          },
          maxTicksLimit: 10
        },
        grid: {
            ...ChartStyles.gridYAxis
      },        
        stacked: true,
      }      
    },  
  };

  const bgColor = "rgba(251, 191, 36, .75)";
  const borderColor = "rgba(251, 191, 36, 1)";

  useEffect(() => {
    const sleepData = calculateRatingBreakdown([sleepLogs], [bgColor], [borderColor]);
    setRatingBreakdown(sleepData);   

  }, [sleepLogs]);

  return (
    <Bar data={ratingBreakdown} plugins={[ChartDataLabels, ChartDeferred]} options={options} />
  );
}