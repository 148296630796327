import React, { useMemo } from "react";
import { Row, Col, Button } from "react-bootstrap";
import SleepLog, { Medication, SleepEpisode } from "../../types/SleepLog";
import { FormatUtils, getDayOfWeekAbbreviation, ratingColorMap } from "../../utils/FormatUtility";
import SleepLogUpdateFlusher from "../../utils/SleepLogUpdateFlusher";

import "../../styles/sleep-log-row-styles.css";
import styles from "./styles/sleep-log-row.module.css";

import SleepLogSettings from "../../types/SleepLogSettings";
import SleepLogViewFactory from "../../types/SleepLogViewFactory";
import FitbitSleepBreakdown from "./fitbit/FitbitSleepBreakdown";
import SleepStagesChart from "./SleepStagesChart";
import classNames from "classnames";
import SleepStagesUtils from "../../utils/SleepStagesUtils";
import { DateTime } from "luxon";
import SimpleTooltip from "./common/SimpleTooltip";
import { Tag } from "./Tag";
import _ from "lodash";

interface Props {
    sleepLog: SleepLog;
    isNotesExpanded: boolean;

    setSleepLogState?: React.Dispatch<React.SetStateAction<SleepLog[]>>;

    sleepLogUpdateFlusher?: SleepLogUpdateFlusher;

    onClick?: (sleepLogId: string) => void;

    sleepLogSettings?: SleepLogSettings;

    showOutline?: boolean;

    showMedicationDose?: boolean
}

export default function SleepLogRow({
    sleepLog,
    setSleepLogState,
    sleepLogSettings,
    onClick,
    sleepLogUpdateFlusher,
    showOutline,
    showMedicationDose
}: Props) {

    const isReadOnly = !setSleepLogState || !sleepLogUpdateFlusher;

    const sleepLogView = useMemo(() => {
        return SleepLogViewFactory.createView(sleepLog);
    }, [sleepLog]);

    function isSleepGoalReached(duration: number | undefined) {
        if (duration === undefined) {
            return false;
        }

        const goal = sleepLogSettings?.targetSleepDuration;

        return goal !== undefined && duration >= goal;
    }

    function onRatingChange(event: any, sleepLogId: string)
    {
        event.preventDefault();
        
        const newRating = event.target.value ? Number(event.target.value) : undefined;

        if (setSleepLogState) {
            setSleepLogState(prev => {
                const index = prev.findIndex(log => log.id === sleepLogId);

                if (index === -1) {
                    return prev;
                }
        
                let updatedLogs = [...prev];
                let updatedLog = {...updatedLogs[index], rating: newRating};

                updatedLogs[index] = updatedLog;

                const update = {rating: newRating === undefined ? null : newRating};

                if (sleepLogUpdateFlusher) {
                    sleepLogUpdateFlusher.push(sleepLogId, update);
                }

                return updatedLogs;
            });
        }
    }
    
    function onNotesChange(event: any, sleepLogId: string)
    {
        event.preventDefault();
        
        if (setSleepLogState) {
            setSleepLogState(prev => {
                const index = prev.findIndex(log => log.id === sleepLogId);

                if (index === -1) {
                    return prev;
                }

                let updatedLogs = [...prev];
                let updatedLog = {...updatedLogs[index], notes: event.target.value};

                updatedLogs[index] = updatedLog;

                const update = {notes: event.target.value};

                if (sleepLogUpdateFlusher) {
                    sleepLogUpdateFlusher.push(sleepLogId, update);
                }            

                return updatedLogs;
            }); 
        }
    }

    function getMedicationsToDisplay(medications: Medication[], limit: number) {
        let medicationSet = new Map<string, number | undefined>();

        let uniqueMedications = medications.filter(medication => {
            let dose = medication.dose !== undefined ? Number(medication.dose) : undefined;
            if (dose !== undefined && isNaN(dose)) {
                dose = undefined;
            }

            if (!medicationSet.has(medication.name)) {
                medicationSet.set(medication.name, dose);
                return true;
            }
            else {
                let sum = medicationSet.get(medication.name);
                if (sum !== undefined) {
                    dose = dose === undefined ? sum : sum + dose;
                }

                medicationSet.set(medication.name, dose);
                return false;
            }
        });

        return uniqueMedications.splice(0, limit).map(medication => ({...medication, dose: medicationSet.get(medication.name)}));
    }

    function formatSleepRange(episode: SleepEpisode) {
        const start = DateTime.fromISO(episode.start).setZone(episode.timezone ?? "utc");
        const end = DateTime.fromISO(episode.end).setZone(episode.timezone ?? "utc");

        return FormatUtils.formatSleepRangeFromDateTime(start, end);
    }

    const maxTagWidth = "175px";

    return (
        <>
            <Row className={classNames("gx-2", {[`${styles.rowOutline}`]: showOutline})}>
                <Col xs="3" lg="2" className="date" style={{cursor: "pointer"}} onClick={(e: any) => onClick?.(sleepLog.id)}>
                    <div className="sleep-log-row">
                        <div>
                            <SimpleTooltip message={FormatUtils.formatDate(sleepLog.date, false /* omitThisYear */)}>
                                <span>{FormatUtils.formatDate(sleepLog.date)}</span>
                            </SimpleTooltip></div>
                        <div className="text-sm text-muted">{getDayOfWeekAbbreviation(sleepLog.date)}</div>
                    </div>
                </Col>

                <Col xs="6" lg="3" xl="2" className="sleep" onClick={(e: any) => onClick?.(sleepLog.id)} style={{cursor: "pointer"}}>
                    <div className="sleep-log-row">
                        {sleepLogView.minutesAsleep !== undefined &&
                            <span className="">
                                <span className="fs-5">
                                    {Math.floor(sleepLogView.minutesAsleep / 60)}
                                </span>    
                                <span className="" style={{fontSize: ".85rem"}}>h</span>
                                <span> </span>
                                <span className="fs-5">
                                    {Math.floor(sleepLogView.minutesAsleep % 60)}
                                </span>
                                <span className="" style={{fontSize: ".85rem"}}>m</span>            
                            </span>
                        }     
                        {isSleepGoalReached(sleepLogView.minutesAsleep) &&
                            <span>
                                <span> </span>
                                <i className="bi bi-star-fill text-success" style={{fontSize: ".75rem"}}></i>
                            </span>
                        }
                        <div className="sleep-episodes">
                            {sleepLogView.sleepSummary && sleepLogView.sleepSummary.episodes.map((episode, i) => 
                                <div key={i}>
                                    <span className="text-secondary text-xs">{formatSleepRange(episode)}</span>
                                    <div
                                        className="my-1"
                                        style={{width: Math.min(80, (70 * (episode.minutesAsleep + episode.minutesAwake) / (8 * 60))) + "%", opacity: ".8"}}
                                    >
                                        <SleepStagesChart
                                            episode={episode}
                                            height="6px"
                                            onSelect={() => 0} 
                                        />
                                    </div>                        
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
                        
                <Col xs="3" lg="1" xl="1" className="rating" onClick={(e: any) => onClick?.(sleepLog.id)} style={{cursor: "pointer"}}> 
                    <div className="sleep-log-row"> 
                        <select 
                            className="sleep-log-rating sleep-log-select form-select"
                            aria-label=""
                            style={{borderColor: sleepLog.rating !== undefined ? ratingColorMap.get(sleepLog.rating) : ""}}
                            value={sleepLog.rating !== undefined ? sleepLog.rating : ""} 
                            disabled={isReadOnly}
                            onChange={e => onRatingChange(e, sleepLog.id)}
                            onClick={e => e.stopPropagation()}
                        >
                            <option value=""></option>
                            <option value="0">0</option>                        
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option> 
                            <option value="8">8</option> 
                            <option value="9">9</option>
                            <option value="10">10</option>                                                                                                                                                                
                        </select>
                    </div>
                </Col>
                <Col xs="12" lg="6" xl="4" className="tags" 
                    onClick={() => onClick?.(sleepLog.id)}
                    style={{cursor: "pointer"}}
                >
                    <div className="sleep-log-row-tags">
                        <div style={{display: "flex", gap: "6px", flexWrap: "wrap"}}>

                            {sleepLog.medications && getMedicationsToDisplay(sleepLog.medications, 100).map((medication, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <SimpleTooltip message={
                                            `${medication.name} ${medication.dose ? medication.dose + " mg" : ""} ${medication.takenAt ? FormatUtils.formatTime(medication.takenAt) : ""}`
                                        }>
                                            <span 
                                                key={index}
                                                className="ant-tag ant-tag-blue text-truncate rounded-pill"
                                                style={{maxWidth: maxTagWidth}}
                                            >
                                                {medication.name}
                                                { showMedicationDose && medication.dose !== undefined &&
                                                    <> {medication.dose}mg</>
                                                }                                    
                                            </span>
                                        </SimpleTooltip>
                                    </React.Fragment>)
                            })}

                            {sleepLog.otherMedications && getMedicationsToDisplay(sleepLog.otherMedications, 100).map((medication, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <SimpleTooltip message={
                                            `${medication.name} ${medication.dose ? medication.dose + " mg" : ""} ${medication.takenAt ? FormatUtils.formatTime(medication.takenAt) : ""}`
                                        }>
                                            <span
                                                key={index}
                                                className="ant-tag ant-tag-magenta text-truncate rounded-pill"
                                                style={{maxWidth: maxTagWidth}}
                                            >
                                                    {medication.name}
                                                    { showMedicationDose && medication.dose !== undefined &&
                                                    <> {medication.dose}mg</>
                                                }  
                                            </span>
                                        </SimpleTooltip>
                                    </React.Fragment>)
                            })}

                            {sleepLog.events && sleepLog.events.map((event, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <span className="ant-tag ant-tag-lime text-truncate rounded-pill"
                                            style={{maxWidth: maxTagWidth}} key={index}>{event.name}</span>
                                    </React.Fragment>)
                            })}   

                            {sleepLog.feelings && sleepLog.feelings.map((f, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Tag 
                                            className={classNames("text-truncate", { ["ant-tag-purple"]: sleepLogSettings?.tagStyles?.[f.feeling] ? false : true })}  
                                            style={{maxWidth: maxTagWidth}}                                    
                                            name={f.feeling}
                                            tagStyle={sleepLogSettings?.tagStyles?.[f.feeling]}
                                            onAction={_.noop}
                                        />
                                    </React.Fragment>)
                            })}  
                                                                                          
                            {sleepLog.tags && sleepLog.tags.map((tag, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Tag 
                                            className={classNames("text-truncate", { ["ant-tag-geek-blue-dark"]: sleepLogSettings?.tagStyles?.[tag] ? false : true })}  
                                            style={{maxWidth: maxTagWidth}}                                    
                                            name={tag}
                                            tagStyle={sleepLogSettings?.tagStyles?.[tag]}
                                            onAction={_.noop}
                                        />
                                    </React.Fragment>)
                            })}

                            {sleepLog.customMetrics && sleepLog.customMetrics.filter(metric => metric.type === "0_to_10" && metric.value !== undefined).map((metric, i) =>
                                <React.Fragment key={metric.name}>
                                    <span 
                                        className="ant-tag ant-tag-orange text-truncate rounded-pill"
                                        style={{maxWidth: maxTagWidth}}
                                    >
                                        {metric.name}: <span className="fw-semibold">{metric.value}/10</span>
                                    </span>
                                </React.Fragment>
                            )}

                            {sleepLog.customMetrics && sleepLog.customMetrics.filter(metric => metric.type === "number" && metric.value !== undefined).map((metric, i) =>
                                <React.Fragment key={metric.name}>
                                    <span 
                                        className="ant-tag ant-tag-orange text-truncate rounded-pill"
                                        style={{maxWidth: maxTagWidth}}
                                    >
                                        {metric.name}: <span className="fw-semibold">{metric.value}</span>
                                    </span>
                                </React.Fragment>
                            )}  

                        </div>
                    </div>                                                
                </Col>
                <Col xl="3" className="notes d-none d-xl-block">
                    <div className="sleep-log-row">
                        <textarea className="form-control border-rounded text-sm"
                            disabled={isReadOnly}
                            value={sleepLog.notes ? sleepLog.notes: ""}
                            placeholder="Add note" 
                            onChange={(e: any) => onNotesChange(e, sleepLog.id)} maxLength={4096}
                            style={{height: "100%"}}>
                        </textarea>
                    </div>
                </Col>
            </Row>
        </>                
    );
}
