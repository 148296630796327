import { findAllByTestId } from '@testing-library/react';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import AppPage from '../../../app/AppPage';
import ConnectedDevice from '../../../devices/components/connected-device';
import { logger } from '../../../logger/EventLogger';
import useStore from '../../../shared/store/useStoreService.hook';
import { AccountProperties, ConnectedAccountType } from '../../../types/AccountSettings';
import { AccountServiceContext } from '../../account/services/account-service.provider';
import AccountService from '../../account/services/account.service';
import { StatusMessage, StatusMessageType } from '../../common/StatusMessages';
import { AccountHttpServiceContext } from '../../http/account-http-service.provider';

import styles from "./../styles/import-view.module.css"

const encodedRedirectUri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URL!);
const fitbitClientId = process.env.REACT_APP_FITBIT_CLIENT_ID;
const withingsClientId = process.env.REACT_APP_WITHINGS_CLIENT_ID;

interface ConnectableDevice {
    name: string;
    url: string;
    supported: boolean;
    visible: boolean;
}

const deviceMap: Record<string, ConnectableDevice> = {
    fitbit: {
        name: "Fitbit",
        url: `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${fitbitClientId}&redirect_uri=${encodedRedirectUri}&scope=sleep&state=fitbit&prompt=consent`,
        supported: true,
        visible: true
    },
    withings: {
        name: "Withings",
        url: `http://account.withings.com/oauth2_user/authorize2?response_type=code&client_id=${withingsClientId}&state=withings&scope=user.activity&redirect_uri=${encodedRedirectUri}`,
        supported: true,
        visible: true      
    },
    oura: {
        name: "Oura",
        url: ``,
        supported: false,
        visible: true           
    },
    appleWatch: {
        name: "Apple Watch",
        url: ``,
        supported: false,
        visible: true           
    },
    garmin: {
        name: "Garmin",
        url: ``,
        supported: false,
        visible: true          
    },
    test_device: {
        name: "Test Device",
        url: `${process.env.REACT_APP_REDIRECT_URL}?code=dummy_test_code&state=test_device`,
        supported: true,
        visible: process.env.NODE_ENV === 'development'
    }
};

const devices = Object.keys(deviceMap).map((key) => deviceMap[key]);

interface PropTypes {
    addStatus: (msg: StatusMessage) => any;
}

export default function ImportView({addStatus}: PropTypes) {
    const [showNotSupported, setShowNotSupported] = useState(false);
    const [accountService, accountSettings] = useStore<AccountService, AccountProperties | undefined>(AccountServiceContext);

    const handleClose = () => setShowNotSupported(false);
    const handleShow = (name: string | undefined) => {
        logger.log("User Viewed Unsupported Device", { device: name ?? "(blank)" });
        setShowNotSupported(true)
    };

    useEffect(() => {
        logger.log("View Import Page");
    }, []);      

    const redirectToAuthPage = (device?: ConnectableDevice) => {
        if (device && device.supported) {
            addStatus({
                msg: `You'll be redirected to the ${device.name} authentication page.`,
                type: StatusMessageType.Info
            });
            
            setTimeout(() => {
                logger.log("Redirecting To Device Auth Page", { device: device });
                window.location.href = device.url;
            }, 2000);
        }
        else {
            handleShow(device?.name);
        }
    }

    async function disconnect(type: ConnectedAccountType) {
        try {
            await accountService.disconnectAccount(type);
        }
        catch (e) {
            addStatus({
                msg: `Error when disconnecting ${type}.`,
                type: StatusMessageType.Fail
            });
        }
    }

    async function sync(type: ConnectedAccountType) {
        try {
            await accountService.syncConnectedAccounts(type, "latest");
        }
        catch (e) {
            addStatus({
                msg: `Syncing ${type} failed.`,
                type: StatusMessageType.Fail
            });            
        }
    }    

    async function setAsPrimary(type: ConnectedAccountType) {
        try {
            await accountService.updateSettings({ primaryConnectedAccount: type })
        }
        catch (e) {
            addStatus({
                msg: `Error when setting ${type} as the primary device.`,
                type: StatusMessageType.Fail
            });
        }        
    }

    return (
        <AppPage className="app-bg-light pt-4">
            <Container className="">
                <header>
                    <h2>Import Sleep Data</h2>
                </header>

                <div className="border-bottom mt-1 mb-4"></div>

                <section>
                    <div className="mb-4">
                        <h4>Connect a device</h4>
                    </div> 
                    <div 
                        className="d-flex justify-content-center justify-content-sm-start"
                        style={{ flexWrap: "wrap", gap: "25px" }}
                    >
                        { devices.filter(device => device.visible).map(device =>
                            <div
                                key={device.name}
                                className={classNames("d-flex align-items-center justify-content-center rounded tw-shadow ring-1 ring-gray-200 p-4 fw-medium", styles.deviceTile)}
                                onClick={() => redirectToAuthPage(device)}
                            >
                                <span className="fs-5">{device.name}</span>
                            </div>
                        )}
                    </div>

                    <Modal show={showNotSupported} onHide={handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Device Not Yet Supported</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <p>
                                    Connecting to this device is not supported yet. We will be adding it soon!
                                </p>
                                <p>
                                    Currently only Fitbit and Withings are supported.
                                </p>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>Close</Button>
                            </Modal.Footer>
                    </Modal>
                </section>

                <div className="mt-5 mb-4 border-bottom"></div>

                <section>
                    <div className="mb-4">
                        <h4>Devices you've already connected</h4>
                    </div>

                    { accountSettings && accountSettings.connectedAccounts.length > 0 ?
                        <div 
                            className="d-flex justify-content-center justify-content-sm-start"
                            style={{ flexWrap: "wrap", gap: "25px" }}
                        >
                            { accountSettings.connectedAccounts.map((device, index) =>
                                <div
                                    key={index}
                                    className="d-flex justify-content-center rounded tw-shadow ring-1 ring-gray-200 p-3 bg-white"
                                    style={{flexBasis: "300px", flexShrink: 0 }}
                                >
                                    <ConnectedDevice
                                        device={device}
                                        isPrimary={accountSettings.primaryConnectedAccount === device.type}
                                        disconnect={() => disconnect(device.type)}
                                        sync={() => sync(device.type)}
                                        setAsPrimary={() => setAsPrimary(device.type)}
                                        reconnect={() => redirectToAuthPage(deviceMap[device.type])}
                                    />
                                </div>
                            )}
                        </div>
                        :
                        <div>
                        { accountSettings ?
                            <div>You haven't connected any devices yet.</div>
                            :
                            <div className="d-flex justify-content-center mt-5" style={{gap: "1rem"}}>
                                <span className="spinner-grow text-secondary" role="status" aria-hidden="true"></span>
                                <span className="spinner-grow text-secondary" role="status" aria-hidden="true"></span>
                                <span className="spinner-grow text-secondary" role="status" aria-hidden="true"></span>
                            </div>  
                        }
                        </div>
                    }
                </section>
            </Container>    
        </AppPage>
    );
}