import React, { useEffect } from "react";
import { Button, ButtonGroup, Col, Dropdown, DropdownButton, InputGroup, OverlayTrigger, Popover, Row } from "react-bootstrap";
import useStore from "../../../shared/store/useStoreService.hook";
import {
    SleepLogFilter,
    Field,
    Operator,
    ApplyTo } from "../../../types/SleepLogFilter";
import { SleepLogFilterServiceContext } from "../services/sleep-log-filter-context";
import { useSleepLogFilterServiceProvider } from "../services/sleep-log-filter.hooks";
import { DefaultFilter, SleepLogFilterService, State } from "../services/sleep-log-filter.service";
import { SleepLogFilterComponent} from "./SleepLogFilter";
import classNames from "classnames";

interface InitializeFilterOptions {
    period?: string;
}

interface TimePeriod {
    startDate: string;
    endDate: string;
}

interface Props {
    // Inputs
    timeRange: TimePeriod;
    options?: InitializeFilterOptions;
    showSpinner: boolean;
    showCache: boolean;
    useCache: boolean;
    lastCachedLog: string;
    isCacheRefreshing: boolean;
    showApplyDifferentDay?: boolean;

    // Actions
    applyFilters: (startDate: string, endDate: string, filters: SleepLogFilter[][]) => any;
    toggleUseCache: () => void;
    onRefreshCache: (lastNumDays?: number) => void;
}

const limit = 10;

export const SleepLogFiltersHost = (props: Props) => {

    const service = useSleepLogFilterServiceProvider(limit);

    useEffect(() => {
        service.add(0);
    }, []);

    return (
        <SleepLogFilterServiceContext.Provider value={service}>
            <SleepLogFilters {...props} />
        </SleepLogFilterServiceContext.Provider>
    );
}

export const SleepLogFilters = ({
    applyFilters,
    timeRange,
    options,
    showSpinner,
    showCache,
    useCache,
    lastCachedLog,
    isCacheRefreshing,
    showApplyDifferentDay,
    onRefreshCache,
    toggleUseCache
}: Props) => {

    const [service, state] = useStore<SleepLogFilterService, State>(SleepLogFilterServiceContext);
    const filterGroups = state.filters;

    function addFilter(group: number) {
        service.add(group);
    }

    function deleteFilter(group: number, i: number) {
        service.delete(group, i);
    }

    function clearFilters() {
        service.deleteAll();
        service.add(0);

        applyFilters(timeRange.startDate, timeRange.endDate, [[]]);
    }

    function updateField(group: number, i: number, field: Field) {
        service.updateField(group, i, field);        
    }

    function updateOperator(group: number, i: number, op: Operator) {
        service.updateOperator(group, i, op);           
    }

    function updateApplyTo(group: number, i: number, applyTo: ApplyTo) {
        service.updateApplyTo(group, i, applyTo);           
    }    

    function updateFilterInput(group: number, filterIndex: number, paramIndex: number, value: string) {
        service.updateFilterInput(group, paramIndex, filterIndex, value);           
    }

    function toggleEnabled(group: number, i: number, checked: boolean) {
        service.toggleEnabled(group, i, checked);             
    }

    const submitFilters = (event: React.FormEvent) => {
        event.preventDefault();
        applyFilters(timeRange.startDate, timeRange.endDate, filterGroups.map(filterGroup => filterGroup.filter(f => f.enabled && f != DefaultFilter)));
    };

    function addOrClause(event: any) {
        event.preventDefault();
        service.addOrClause();
    }
    
    function deleteGroup(group: number) {
        service.deleteGroup(group);
    }

    return (
        <div className="">
            <form className="" onSubmit={submitFilters}>
                <div className="py-2" style={{ padding: "2px", maxHeight: "350px", overflowY: "auto"}}>

                    { filterGroups.map((filterGroup, groupIndex) =>
                        <div key={groupIndex}>
                            { groupIndex > 0 &&
                                <div className="d-flex justify-content-between">
                                    <div className="fs-5">or</div>
                                    <i className="bi bi-trash3" style={{ cursor: "pointer"}} onClick={() => deleteGroup(groupIndex)}></i>
                                </div>
                            }

                            <div
                                className={classNames({ "ps-1": filterGroups.length > 1 })}
                                style={{
                                    borderLeft: filterGroups.length > 1 ? "thick solid var(--tw-blue-300)" : undefined,
                                }}
                            >

                                {filterGroup.map((filter, filterIndex) =>

                                    <div key={filterIndex}>
                                        <SleepLogFilterComponent
                                            filter={filter}
                                            operators={state.operators[filter.field]}
                                            fields={state.fields}
                                            onFieldChange={field => updateField(groupIndex, filterIndex, field)}
                                            onOperatorChange={op => updateOperator(groupIndex, filterIndex, op)}
                                            onEnabledChange={(enabled) => toggleEnabled(groupIndex, filterIndex, enabled)}
                                            showApplyDifferentDay={showApplyDifferentDay ?? true}
                                            onInputChange={(index, value) => updateFilterInput(groupIndex, filterIndex, index, value)}
                                            onApplyToChange={applyTo => updateApplyTo(groupIndex, filterIndex, applyTo)}
                                            onDelete={() => deleteFilter(groupIndex, filterIndex)}   
                                        />                  
                                        { filterIndex !== filterGroup.length - 1 &&
                                            <div className="text-xxs text-center my-1 text-muted">and</div>
                                        }
                                    </div>

                                )}

                                <div className="text-center pt-3">
                                        <Button 
                                            variant="secondary"
                                            size="sm"
                                            className="rounded-circle"
                                            disabled={state.limit != null && filterGroups.length >= state.limit}
                                            onClick={() => addFilter(groupIndex)}
                                        >
                                            <i className="bi bi-plus-lg" style={{"WebkitTextStrokeWidth": ".5px"}}></i>
                                        </Button>
                                </div>

                            </div>

                        </div>
                    )}

                </div>

                { showCache &&
                    <Row className="justify-content-center mt-4 gx-4">
                        <Col xs="auto">
                            <div className="form-check form-switch">
                                <input type="checkbox"
                                    className="form-check-input" 
                                    role="switch"
                                    checked={useCache}
                                    onChange={() => toggleUseCache()}
                                >
                                </input>
                                <label className="form-check-label text-xxs">
                                    Use cache
                                    &nbsp;
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]} 
                                        placement="bottom"
                                        overlay={
                                        <Popover id="popover-basic">
                                            <Popover.Body>
                                                Sleep logs can be cached locally, which speeds up searching and filtering them.
                                            </Popover.Body>
                                        </Popover>
                                        }
                                    >
                                        <i className="bi bi-info-circle"></i>
                                    </OverlayTrigger>
                                </label>
                            </div>
                        </Col>

                        <Col xs="auto">
                            <span className="text-xxs text-muted">Last synced log: {lastCachedLog ? lastCachedLog : ' -'}</span>
                        </Col>

                        <Col xs="auto">
                            <Dropdown as={ButtonGroup}>
                                <Button variant="outline-secondary" className="btn-sm text-xxs py-0" onClick={() => onRefreshCache(30)}>
                                    { isCacheRefreshing && 
                                        <>
                                            <span className="spinner-border spinner-border-sm text-xxs" role="status" aria-hidden="true"></span>
                                            &nbsp;
                                        </>
                                    }
                                    Refresh
                                </Button>

                                <Dropdown.Toggle split variant="outline-secondary" className="btn-sm text-xxs py-0" />

                                <Dropdown.Menu>
                                    <Dropdown.Item as="button" onClick={() => onRefreshCache(30)}>Refresh latest</Dropdown.Item>
                                    <Dropdown.Item as="button" onClick={() => onRefreshCache(180)}>Refresh last half year</Dropdown.Item>
                                    <Dropdown.Item as="button" onClick={() => onRefreshCache(365)}>Refresh last year</Dropdown.Item>
                                    <Dropdown.Item as="button" onClick={() => onRefreshCache()}>Refresh all</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>            
                    </Row>
                }                 

                <Row className="mt-3 gx-4 gy-1 justify-content-center align-items-center">
                    <Col xs="auto">
                        <div className="bg-white">

                            <Dropdown as={ButtonGroup}>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="form-control px-5"
                                    size="sm"
                                    disabled={showSpinner}
                                >
                                    { showSpinner && 
                                        <>
                                            <span className="spinner-border spinner-border-sm text-xxs" role="status" aria-hidden="true"></span>
                                            &nbsp;
                                        </>
                                    }                                 
                                    Apply
                                </Button>

                                <Dropdown.Toggle split variant="outline-primary" size="sm" />

                                <Dropdown.Menu>
                                    <Dropdown.Item as="button" onClick={addOrClause}>Add 'or' condition</Dropdown.Item>
                                </Dropdown.Menu>                                

                            </Dropdown> 

                        </div>
                    </Col>
                    <Col xs="auto">
                        <div className="bg-white">
                            <Button variant="outline-secondary" className="form-control px-5" size="sm" disabled={showSpinner} onClick={(e) => clearFilters()}>
                                Clear
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>         

        </div>
    );
}