import produce, { createDraft, finishDraft } from "immer";
import { SleepLogFilterUtil } from "../../../utils/SleepLogFilterUtils";
import Store from "../../../shared/store/store";

import {
    ApplyTo,
    Field, 
    FIELDS_STATIC, 
    fieldToTypeMap,
    FieldType,
    Operator,
    SleepLogFilter,
    typeToOperatorsMap 
} from "../../../types/SleepLogFilter";
import { CustomMetric, CustomMetricBase } from "../../../types/SleepLogSettings";
import { CustomValueUtils } from "../../../utils/CustomValueUtils";

export interface State {

    filters: SleepLogFilter[][]; // Groups of filters: (c1 && c2) || (c3 && c4) || ...
    fields: string[];
    operators: Record<string, Operator[]>; // Only populated for fields in 'filters' property
    limit?: number;
}

export const DefaultFilter: SleepLogFilter = {
    field: "Tags",
    type: fieldToTypeMap["Tags"],
    operator: "contains",
    parameters: [""],
    applyTo: "day of",
    enabled: true
};

abstract class SleepLogFilterServiceBase extends Store<State> {
    constructor(state: State) {
        super(state);

        this.fieldTypes = fieldToTypeMap;
    }

    add(group: number) {
        if (this.state.limit == null || this.state.filters[group].length < this.state.limit) {
            const defaultFilter = DefaultFilter;
            this.state.filters[group].push(defaultFilter);
            this.populateOperators(defaultFilter.field);
        }
    }

    delete(group: number, index: number) {
        this.state.filters[group].splice(index, 1);
    }
    
    deleteAll() {
        this.state.filters = [[]];    
    }

    updateField(group: number, index: number, field: string) {
        if (!this.state.fields.includes(field)) {
            throw Error("Unknown field");
        }

        const filter = this.state.filters[group][index];
        const prevType = filter.type;
        const type = this.getFieldType(field);

        filter.field = field;

        if (type !== prevType) {
            filter.type = type;
            filter.operator = this.getOperators(field)[0];
            filter.parameters = new Array<string>(this.getNumParameters(filter.field, filter.type, filter.operator)).fill('');
        }

        this.populateOperators(field);
    }

    updateOperator(group: number, index: number, op: Operator) {
        const filter = this.state.filters[group][index];
        filter.operator = op;

        const prevParams = filter.parameters;
        filter.parameters = new Array<string>(this.getNumParameters(filter.field, filter.type, filter.operator)).fill("");

        for (let j = 0; j < Math.min(prevParams.length, filter.parameters.length); j++) {
            filter.parameters[j] = prevParams[j];
        }         
    }

    updateApplyTo(group: number, index: number, applyTo: ApplyTo) {
        this.state.filters[group][index].applyTo = applyTo;
    }

    updateFilterInput(group: number, filterIndex: number, paramIndex: number, value: string) {
        this.state.filters[group][filterIndex].parameters[paramIndex] = value;      
    }

    toggleEnabled(group: number, index: number, enabled: boolean) {
        this.state.filters[group][index].enabled = enabled;        
    }

    updateCustomMetricFields(metrics: CustomMetric[]) {
        this.state.fields = [...FIELDS_STATIC];
        this.fieldTypes = {...fieldToTypeMap };

        for (const metric of metrics) {
            const fieldName = CustomValueUtils.addSuffix(metric.name);
            this.state.fields.push(fieldName);
            this.fieldTypes[fieldName] = SleepLogFilterUtil.convertMetricType(metric);
            this.populateOperators(fieldName);
        }
    }

    addOrClause() {
        this.state.filters.push([DefaultFilter]);
    }

    deleteGroup(group: number) {
        this.state.filters.splice(group, 1);
    }

    private getFieldType(field: string) {
        return this.fieldTypes[field];
    }

    private getOperators(field: string) {
        return typeToOperatorsMap[this.fieldTypes[field]];
    }

    private populateOperators(field: string) {
        if (!this.state.operators[field]) {
            const type = this.fieldTypes[field];
            this.state.operators[field] = typeToOperatorsMap[type];
        }
    }

    private getNumParameters(field: string, type: FieldType, operator: Operator) {
        if (type === "awakening threshold") {
            return 2;
        }

        switch (operator) {
            case "has none":
                return 0;
            case "between":
                return 2;
            case "greater than or equal to":
            case "greater than":
            case "less than":
            case "less than or equal to":
            case "equals":
                return field === 'Medication dose' ? 2 : 1;
            default:
                return 1;
        }
    }

    private fieldTypes: Record<string, FieldType>;
}

export class SleepLogFilterService extends SleepLogFilterServiceBase {
    constructor(limit?: number) {
        super({
            filters: [[]],
            fields: FIELDS_STATIC as unknown as string[], // todo: fix typings
            operators: {},
            limit: limit 
        });

        this.draft = produce(this._state, draft => {});
    }

    protected get state(): State {
        return this.draft;
    }

    protected set state(state: State) {
        this.draft = state;
    }

    add(group: number) {
        this.run(() => super.add(group));
    }

    delete(group: number, index: number) {
        this.run(() => super.delete(group, index));
    }
    
    deleteAll() {
        this.run(() => super.deleteAll());   
    }

    updateField(group: number, index: number, field: Field) {
        this.run(() => super.updateField(group, index, field));
    }

    updateOperator(group: number, index: number, op: Operator) {
        this.run(() => super.updateOperator(group, index, op));       
    }

    updateApplyTo(group: number, index: number, applyTo: ApplyTo) {
        this.run(() => super.updateApplyTo(group, index, applyTo));   
    }

    updateFilterInput(group: number, filterIndex: number, paramIndex: number, value: string) {
        this.run(() => super.updateFilterInput(group, paramIndex, filterIndex, value));      
    }

    toggleEnabled(group: number, index: number, enabled: boolean) {
        this.run(() => super.toggleEnabled(group, index, enabled));          
    }

    updateCustomMetricFields(metrics: CustomMetricBase[]) {
        this.run(() => super.updateCustomMetricFields(metrics));  
    }

    addOrClause() {
        this.run(() => super.addOrClause());
    }

    deleteGroup(group: number) {
        this.run(() => super.deleteGroup(group));
    }
   
    private run(f: () => any) {
        this.draft = createDraft(this._state);
        f();
        this._state = finishDraft(this.draft);

        this.notifySubscribers();
    }

    private draft: State;
}