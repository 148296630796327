import Store from "../../../shared/store/store";
import SleepLogSettings, { TagStyle } from "../../../types/SleepLogSettings";
import SleepLogHttpService from "../../http/SleepLogHttpService";

export default class SleepLogSettingsService extends Store<SleepLogSettings | undefined> {
    constructor(private sleepLogHttpService: SleepLogHttpService) {
        super(undefined);
    }

    public async fetch(): Promise<void> {
        const settings = await this.sleepLogHttpService.getSleepLogSettings();
        this.put(settings);
    }

    public async save(settings: SleepLogSettings) {
        await this.sleepLogHttpService.putSleepLogSettings(settings);
        this.put(settings); // TODO: get from server
    }

    public async saveCustomTagStyle(tagName: string, style: TagStyle) {
        if (!this.state) {
            throw new Error("Settings is undefined");
        }

        const mergedStyles: SleepLogSettings = {
            tagStyles: {
                ...this.state.tagStyles,
                [tagName]: style
            }
        }

        const settings = await this.sleepLogHttpService.patchSleepLogSettings(mergedStyles);
        this.put(settings);        
    }

    public async savePatch(partialSettings: SleepLogSettings) {
        // TODO: patchSleepLogSettings method
        const settings = await this.sleepLogHttpService.patchSleepLogSettings(partialSettings);
        this.put(settings);
    }

    private put(settings: SleepLogSettings) {
        this.state = settings;
        this.notifySubscribers();
    }
}