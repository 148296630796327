import { useContext, useSyncExternalStore, useMemo } from "react";
import Store from "./store";

export default function useStore<Service extends Store<State> | undefined, State>(context: React.Context<Service | undefined>): [Service, State] {
    const service = useContext(context);

    if (!service)
        throw Error("Service undefined!");

    const subscribe = useMemo(() => service.subscribe.bind(service), [service]);
    const snapshot = useMemo(() => service.getState.bind(service), [service]);
    const state = useSyncExternalStore(subscribe, snapshot);

    return [service, state];
}