import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { StatusMessage, StatusMessageType } from "../../common/StatusMessages";
import ColoredIcon from "../../common/ColoredIcon";
import { useNavigate } from "react-router-dom";
import useStore from "../../../shared/store/useStoreService.hook";
import { AccountProperties, ConnectedAccountType } from "../../../types/AccountSettings";
import AccountService from "../services/account.service";
import { AccountServiceContext } from "../services/account-service.provider";
import { TimePeriod } from "../../http/AccountHttpService";
import { ConnectedAccountUtils } from "../../../utils/ConnectedAccountUtils";
import { AccountHttpServiceContext } from "../../http/account-http-service.provider";
import { HttpException } from "../../../types/Exceptions";
import { logger } from "../../../logger/EventLogger";

interface Props {
    addStatus: (msg: StatusMessage) => void;
}

export default function AuthorizeDevice({ addStatus }: Props) {
    let navigate = useNavigate();
    const [accountService] = useStore<AccountService, AccountProperties | undefined>(AccountServiceContext);
    const [connected, setConnected] = useState(false);
    const [syncSource, setSyncSource] = useState<ConnectedAccountType | undefined>(undefined);
    const [syncDuration, setSyncDuration] = useState<TimePeriod>("lastWeek");
    const accountHttpService = useContext(AccountHttpServiceContext)!;

    useEffect(() => {
        logger.log("View Authorize Device Page");
    }, []);      

    useEffect(() => {
        var urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has("code")) {
            let username = window.localStorage.getItem("username");     
            const source = urlParams.get("state");

            logger.log("Try Authorize Device", { source: source, user: username });

            const sendCode = async () => {

                if (username) {
                    if (!source || !ConnectedAccountUtils.isValidDevice(source)) {
                        addStatus({
                            msg: "Could not import your data because the device is not recognized.",
                            type: StatusMessageType.Fail
                        });            
                        
                        return;
                    }

                    setSyncSource(source);

                    try {
                        const code = urlParams.get("code");
                        await accountHttpService.addConnectedAccount(code ?? "", source);

                        setTimeout(() => {
                            setConnected(true);
                        }, 1000);

                        try {
                            await accountService.fetchProperties();

                            logger.log("User Imported Device", { device: source });
                        }
                        catch (e) {
                            addStatus({
                                type: StatusMessageType.Fail,
                                msg: `Refreshing account settings failed.`
                            });
                        }
                    }
                    catch (e: any) {
                        if (e instanceof HttpException) {
                            addStatus({
                                msg: `Could not import your data. Reason: ${e.message} `,
                                type: StatusMessageType.Fail
                            });   
                        }
                        else {
                            addStatus({
                                msg: "Could not import your data. Please try again later.",
                                type: StatusMessageType.Fail
                            });
                        }
                    }
                }
            }
            
            sendCode();
        }
        else {
            addStatus({
                msg: "Could not import your data. Please try again later.",
                type: StatusMessageType.Fail
            });   
        }
    },
    []);

    function syncAndRedirect() {
        if (!syncSource) {
            addStatus({
                msg: "Could not import your data. Please try again later.",
                type: StatusMessageType.Fail
            });     
            
            return;
        }

        accountService.syncConnectedAccounts(syncSource, syncDuration);
        navigate("/sleep-logs");
    }

    function redirect() {
        navigate("/sleep-logs");    
    }


  return (
    <Container className="py-4">
        <div className="py-3 text-center">
            <div className="d-flex justify-content-center">
                <ColoredIcon sizeInPx={64} bgColor="rgb(224 207 252)" fontColor="#3d0a91" borderRadius="16px">
                    <i className="bi bi-arrow-repeat"></i>
                </ColoredIcon>
            </div>
            <div className="mt-2 fs-2">
                Connecting to device
            </div>
            <div className="mt-3 fs-5 text-muted">
                { connected ? 'Successfully connected device.' : 'Trying to connect to your device. Please wait...' }
            </div>

            { connected &&
                <div className="mt-4 fs-5 text-muted">
                    Sync your data from
                </div>
            }
            
            <div className="mt-4">
            </div>
            <div>
                { connected ?
                    <>
                        <div className="d-flex flex-gap-4 justify-content-center">
                            <select className="form-select w-auto"
                                value={syncDuration}
                                onChange={e => setSyncDuration(e.target.value as TimePeriod)}
                            >
                                <option value="lastWeek">Last week</option>
                                <option value="lastMonth">Last month</option>
                                <option value="lastYear">Last year</option>
                                <option value="all">All time</option>                                                                    
                            </select>
                            <button className="btn btn-primary px-3" onClick={syncAndRedirect}>Sync</button>
                            <button className="btn btn-outline-secondary px-3" onClick={redirect}>Skip</button>
                        </div>
                    </>
                    :
                    <span className="spinner-border text-secondary  fs-5" role="status" aria-hidden="true"></span>
                }
            </div>
        </div>
    </Container>
  );
}