import classNames from "classnames";
import ChangeArrow from "../common/ChangeArrow";

import styles from "./styles/card-styles.module.css";

interface PropTypes {
  sleepInMins: number | undefined;
  comparison?: number,
  title?: string;
}

export default function SleepCard({sleepInMins, comparison, title}: PropTypes) {

    return (
    <div className={classNames("card tw-shadow ring-1 ring-gray-200", styles.cardAccent, styles.sleepAccent)}>
        <div className="card-body p-3 text-center">
            <div className="fw-medium">
                { sleepInMins ?
                <ChangeArrow val={sleepInMins} baseLine={comparison} />
                :
                <></>
                }
                <span className="fs-4">
                {sleepInMins ?
                    Math.floor(sleepInMins / 60)
                    :
                    "-"
                }
                </span>  
                <span className="">h</span>
                <span> </span>
                <span className="fs-4">
                {sleepInMins ?
                    Math.round((sleepInMins % 60))
                    :
                    "-"
                }
                </span>
                <span className="">m</span>                     
            </div>
            
            <div className="text-gray-500 mt-2">{ title ?? 'Sleep' }</div>
        </div>
    </div>
    );  
}