import { ChartData, ChartOptions } from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartDeferred from 'chartjs-plugin-deferred';

import ISleepLogView from "../../../../types/ISleepLogView";
import SleepStagesUtils from "../../../../utils/SleepStagesUtils";
import { ChartStyles } from "../constants/chart-styles";

interface Props {
    sleepLogs: ISleepLogView[];

    showTitle?: boolean;
    showLegend?: boolean;
    showLines?: boolean;
}

export default function SleepTimeBreakdownChart(props: Props) {
    const {sleepLogs} = props;

    const [data, setData] = useState<ChartData<"bar", number[], string>>({
        labels: [],
        datasets: []
    });

    const [showBedTime, setShowBedTime] = useState(false);

    useEffect(() => {
        function formatTime(hourIn24: number, min: number) {
            let hour = hourIn24 >= 12 ? hourIn24 - 12 : hourIn24;
            hour = (hour === 0) ? 12 : hour;
        
            const period = hourIn24 >= 12 ? "pm" : "am";
            const minuteStr = min === 0 ? "00" : min.toString();

            return `${hour}:${minuteStr}${period}`;            
        }

        function formatHour(hourIn24: number, includePeriod = true) {
            let hour = hourIn24 >= 12 ? hourIn24 - 12 : hourIn24;
            hour = (hour === 0) ? 12 : hour;
        
            const period = hourIn24 >= 12 ? "pm" : "am";

            return `${hour}${includePeriod ? period : ""}`;            
        }        

        const filteredLogs = sleepLogs.filter(log => log.mainSleep);

        const bucketStartHour = 18;
        const bucketSizeInMins = 60;        
        const bucketCount = (24 * 60) / bucketSizeInMins; 

        const bedTimeBuckets = new Array<number>(bucketCount).fill(0);
        const wakeupTimeBuckets = new Array<number>(bucketCount).fill(0);

        for (const log of filteredLogs) {
            const mainSleepEpisode = log.mainSleep!;
            let { bedtime, sleeptime, waketime } = SleepStagesUtils.getSleepAndWakeupTime(mainSleepEpisode);
            bedtime = bedtime.setZone(mainSleepEpisode.timezone ?? "utc");
            sleeptime = sleeptime.setZone(mainSleepEpisode.timezone ?? "utc");
            waketime = waketime.setZone(mainSleepEpisode.timezone ?? "utc");

            const starttime = showBedTime ? bedtime : sleeptime;

            {
                let diffInMins = (starttime.hour * 60 + (starttime.minute) - (bucketStartHour * 60));

                if (diffInMins < 0) {
                    diffInMins = (24 * 60) + diffInMins;
                }

                const b = Math.floor(diffInMins / bucketSizeInMins);
                bedTimeBuckets[b]++;
            }
            {
                let diffInMins = ((waketime.hour * 60 + waketime.minute) - (bucketStartHour * 60));

                if (diffInMins < 0) {
                    diffInMins = (24 * 60) + diffInMins;
                }
    
                const b = Math.floor(diffInMins / bucketSizeInMins);
                wakeupTimeBuckets[b]++;                
            }
        }

        for (let i = 0; i < bedTimeBuckets.length; i++) {
            bedTimeBuckets[i] = (bedTimeBuckets[i] / sleepLogs.length) * 100;
        }

        for (let i = 0; i < wakeupTimeBuckets.length; i++) {
            wakeupTimeBuckets[i] = (wakeupTimeBuckets[i] / sleepLogs.length) * 100;
        }        

        const labels: string[] = [];

        let curBucket = bucketStartHour * 60;
        for (let i = 0; i < ((24 * 60) / bucketSizeInMins); i++) {

            let hourIn24 = Math.floor(curBucket / 60);
            let min = curBucket % 60;

            const bucketStart = formatHour(hourIn24, false); //formatTime(hourIn24, min);

            const curBucketEnd = curBucket + bucketSizeInMins;
            hourIn24 = Math.floor(curBucketEnd / 60);
            min = curBucketEnd % 60;

            const bucketEnd = formatHour(hourIn24); //formatTime(hourIn24, min);

            labels.push(`${bucketStart}-${bucketEnd}`);

            curBucket = (curBucket + bucketSizeInMins) % (24 * 60);
        }

        const data = {
            labels: labels,
            datasets: [{
                label: '(Sleep) days',
                data: bedTimeBuckets,
                backgroundColor: showBedTime ? 'rgba(47, 84, 235, .5)' : 'rgba(54, 162, 235, 0.5)',
                borderColor: showBedTime ? 'rgba(47, 84, 235, 1)' : 'rgb(54, 162, 235)',
                borderWidth: 0,
                maxBarThickness: 100
                },
                {
                    label: '(Wake) days',
                    data: wakeupTimeBuckets,
                    backgroundColor: 'rgba(227, 93, 106, .5)',
                    borderColor: 'rgb(227, 93, 106)',
                    borderWidth: 0,
                    maxBarThickness: 100
                }
            ]
        };

        setData(data);

    }, [sleepLogs, showBedTime]);

    const options: ChartOptions<"bar"> = {
        plugins: {
            datalabels: {
                anchor: "end",
                align: "top",
                color: "grey",
                font: {
                    ...ChartStyles.axisFont,
                },
                formatter: function(value, context) {
                    const percent = value as number;
                    return percent >= 5 ? `${value.toFixed(0)}%` : "";
                }
            },    
            deferred: {
            },     
            legend: {
                display: false
            },            
            title: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const percent = (context.raw ?? 0) as number;
                        const numDays = Math.round((percent / 100) * sleepLogs.length).toFixed(0);
                        return `${numDays} days (${percent.toFixed(0)}%)`;                        
                    }
                } 
            },
        },        
        scales: {
            x: {
                grid: {
                    display: props.showLines ?? true,
                    color: "rgba(0, 0, 0, 0)",
                },
                stacked: true,
                ticks: {
                    font: {
                        ...ChartStyles.axisFont
                    }
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                        stepSize: 1,
                        font: {
                            ...ChartStyles.axisFont
                        },
                        maxTicksLimit: 5,
                        callback: function(value, index) {
                            let numDays = value as number;
                            return `${numDays}%`;
                        },
                },
                stacked: true,
                grid: {
                    ...ChartStyles.gridYAxis,
                    display: props.showLines ?? true,
                },
            }     
        },    
        aspectRatio: 2
    };

    return (
        <div>
            <div className="d-flex flex-wrap text-xs text-muted justify-content-center">
                <div className="me-2">
                    <i className="bi bi-square-fill" style={{color: showBedTime ? 'rgba(47, 84, 235, 1)' : 'rgb(54, 162, 235)'}}></i>
                    &nbsp;
                    {showBedTime ? "Bed time" : "Sleep time"}
                </div>
                <div className="ms-2">
                    <i className="bi bi-square-fill" style={{color: 'rgb(227, 93, 106)'}}></i>
                    &nbsp;
                    Wake time
                </div>
            </div> 
            <Bar 
                data={data}
                options={options}
                plugins={[ChartDataLabels, ChartDeferred]}
                width={null as any}
                height={null as any} 
            />
            <div className="mt-2">
                <div className="form-check form-switch">
                    <input type="checkbox"
                        className="form-check-input" 
                        role="switch"
                        checked={showBedTime}
                        onChange={() => setShowBedTime(state => !state)}>
                    </input>
                    <label className="form-check-label text-xs">Show bedtime</label>
                </div>    
            </div>
        </div>
    );

}