import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import SleepLog from "../../../types/SleepLog";
import AnalyticsHelper from "../../../utils/analytics-utils";

import SleepDurationDoughnutChart from "../charts/sleep/SleepDurationDoughnutChart";
import RatingDoughnutChart from "../charts/rating/RatingDoughnutChart";
import FeelingTagCountChart from "../charts/taglike/FeelingTagCountChart";
import UserTagCountChart from "../charts/taglike/UserTagCountChart";
import SleepCard from "../cards/SleepCard";
import RatingCard from "../cards/RatingCard";
import SleepWakeCard from "../cards/SleepWakeCard";
import ChartCard from "../cards/ChartCard";
import SleepLogUtils from "../../../utils/SleepLogUtils";
import SleepMedCountChart from "../charts/medication/SleepMedCountChart";
import ISleepLogView from "../../../types/ISleepLogView";
import CustomMetricsDashboard from "../charts/custom/CustomMetricsDashboard";
import SleepStagesUtils from "../../../utils/SleepStagesUtils";
import { ComparisonChart } from "../charts/ComparisonChart";
import useStore from "../../../shared/store/useStoreService.hook";
import SleepLogSettingsService from "../../sleep-log-settings/services/SleepLogSettingsService";
import { SleepLogSettingsServiceContext } from "../../sleep-log-settings/services/sleep-log-settings-service.provider";

interface Props {
    sleepLogGroups: ISleepLogView[][];
}

export default function SleepStatsSummary({sleepLogGroups}: Props) {

    const [, settings] = useStore<SleepLogSettingsService, SleepLogSettingsService['state']>(SleepLogSettingsServiceContext);

    const sleepLogs = sleepLogGroups[0];

    const baseSleepLogs: SleepLog[] = useMemo(() => {
        return sleepLogs.map(log => log.baseSleepLog);
    }, [sleepLogs]);

    const sleepStat = useMemo(() => {    
        if (sleepLogs.length === 0) {
            return {};
        }

        const sleepDurations = sleepLogs.filter(sleepLog => {return sleepLog.minutesAsleep !== undefined;})
            .map(sleepLog => sleepLog.minutesAsleep as number);    

        let averageSleep = Math.round(AnalyticsHelper.calculateAverage(sleepDurations));
        let [minSleep, maxSleep] = AnalyticsHelper.calculateMinMax(sleepDurations);
        let sleepStandardDeviation = Math.round(AnalyticsHelper.calculateStandardDeviation(sleepDurations, averageSleep));

        const ratings = sleepLogs.filter(sleepLog => {return sleepLog.rating !== undefined;})
            .map(sleepLog => sleepLog.rating as number);    

        let avgRating = AnalyticsHelper.calculateAverage(ratings);
        let ratingStdDev = AnalyticsHelper.calculateStandardDeviation(ratings, avgRating);         

        return {
            averageSleep,
            minSleep,
            maxSleep, 
            sleepStandardDeviation, 
            avgRating, 
            ratingStdDev
        };

    }, [sleepLogs]);

    const averageSleepTime = useMemo(() => {
            const filteredLogs = sleepLogs.filter(log => log.mainSleep);

            const sleepTimesInMins = filteredLogs.map(log => {
                const mainSleepEpisode = log.mainSleep!;
                let { sleeptime } = SleepStagesUtils.getSleepAndWakeupTime(mainSleepEpisode);
                sleeptime = sleeptime.setZone(mainSleepEpisode.timezone ?? "utc");

                return (sleeptime.hour * 60) + sleeptime.minute;
            });

            const wakeTimesInMins = filteredLogs.map(log => {
                const mainSleepEpisode = log.mainSleep!;
                let { waketime } = SleepStagesUtils.getSleepAndWakeupTime(mainSleepEpisode);
                waketime = waketime.setZone(mainSleepEpisode.timezone ?? "utc");

                return (waketime.hour * 60) + waketime.minute;
            });

            return sleepTimesInMins.length > 0 && wakeTimesInMins.length > 0 ?
                {
                    sleepTime: SleepLogUtils.averageTime(sleepTimesInMins),
                    wakeTime: SleepLogUtils.averageTime(wakeTimesInMins)
                }
                : undefined;

    }, [sleepLogs]);

    const hasSleepMed = useMemo(() => {
        let hasSleepMed = false;

        sleepLogs.forEach(sleepLog => {
            if (sleepLog.baseSleepLog.medications && sleepLog.baseSleepLog.medications.length > 0) {
                hasSleepMed = true;
            }         
        });

        return hasSleepMed;
    }, [sleepLogs]);

    const hasOtherMed = useMemo(() => {
        let hasOtherMed = false;

        sleepLogs.forEach(sleepLog => {
            if (sleepLog.baseSleepLog.otherMedications && sleepLog.baseSleepLog.otherMedications.length > 0) {
                hasOtherMed = true;
            }                    
        });

        return hasOtherMed;
    }, [sleepLogs]); 

    const tagsToShow = 5;

    /*
            <Row className="justify-content-center">
                <Col xs="12" md="6">
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="text-center text-muted-light">Sleep</div>
                            <div className="mt-2">
                                <HeatMapChart sleepLogs={sleepLogs} />
                            </div>    
                        </div>
                    </div>                    
                </Col>
            </Row>    
    */

    return (
        <React.Fragment>

            <Row className="justify-content-center gx-4 gy-3">
                <Col xs="6" md="3">
                    <SleepCard sleepInMins={sleepStat?.averageSleep} comparison={undefined}/>
                </Col>
                <Col xs="6" md="3">
                    <RatingCard rating={sleepStat?.avgRating} comparison={undefined} />
                </Col>
                <Col xs="6" md="3">
                 <SleepWakeCard title="Sleep time" timeInMins={averageSleepTime?.sleepTime} />
                </Col>
                <Col xs="6" md="3">
                 <SleepWakeCard title="Wake time" timeInMins={averageSleepTime?.wakeTime} />
                </Col>                 
            </Row>

            <Row className="justify-content-center mt-1 g-4">
                <Col md="6">
                    <ChartCard title="Hours Slept">
                        <div className="px-4">
                            <SleepDurationDoughnutChart sleepLogs={sleepLogs} />
                        </div>
                    </ChartCard>
                </Col>
                <Col md="6">
                    <ChartCard title="My Rating">
                        <div className="px-4">
                            <RatingDoughnutChart sleepLogs={sleepLogs} />
                        </div>                
                    </ChartCard>    
                </Col>                                    
            </Row>                 

            <Row className="justify-content-center mt-1 g-4">
                <Col md="6">
                    <ChartCard title="How I've Been Feeling">
                        <FeelingTagCountChart sleepLogs={baseSleepLogs} limit={5} showTitle={false} />
                    </ChartCard>    
                </Col>
                <Col md="6">
                    <ChartCard title="Tags">
                        <UserTagCountChart sleepLogs={baseSleepLogs} limit={5} showTitle={false} />
                    </ChartCard>    
                </Col>                                
            </Row>

            <div className="mt-3">
                <CustomMetricsDashboard sleepLogGroups={sleepLogGroups} />
            </div>

            <div className="pt-4 text-center">
                <div className="fs-5 card border-0 tw-shadow ring-1 ring-gray-200 p-2">
                    <span>Compare Data</span>
                </div>
            </div>  

            <Row className="justify-content-center mt-1 g-4">
                <Col lg="6">
                    <ChartCard title="">
                        <ComparisonChart sleepLogSettings={settings} sleepLogs={sleepLogs} />
                    </ChartCard>  
                </Col>  
            </Row>                                   

            <div className="p-5"></div>
            
        </React.Fragment>                                                                                                                     
    );
}

function SleepDuration(props: {durationInMins: number}) {
    const {durationInMins} = props;

    return (
        <span>
            <span className="">
                {Math.floor(durationInMins / 60)}
            </span>    
            <span className="" style={{fontSize: ".75rem"}}>h</span>
            <span> </span>
            <span className="">
                {durationInMins % 60}
            </span>
            <span className="" style={{fontSize: ".75rem"}}>m</span>            
        </span>
    );    
}