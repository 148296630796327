import { Button, Modal } from "react-bootstrap";

interface Props {
  show: boolean;
  confirm: () => void;
  close: () => void;
}

export default function DeleteSleepEpisodeDialog({ show, confirm, close }: Props) {
  return (
    <Modal
        centered 
        show={show} 
        onHide={close} 
        dialogClassName="sleep-log-modal-confirm"
        contentClassName="shadow"
        style={{zIndex: 5000}}
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title>Delete sleep episode</Modal.Title>
        <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={close}></button>
      </Modal.Header>
      
      <Modal.Body className="text-sm">
        <div>
          Are you sure you want to remove this sleep episode?
        </div>
        <div className="mt-4 fw-bold">
        This does not delete any data from your devices.
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" size="sm" onClick={ () => confirm() }>Continue</Button>
        <Button variant="secondary" size="sm" onClick={ () => close() }>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}