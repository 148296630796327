import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Row, Col, Button, ToggleButtonGroup, ToggleButton, Modal } from "react-bootstrap";
import SleepLog from "../../../../types/SleepLog";
import { FormatUtils, formatDateAsLocalTime } from "../../../../utils/FormatUtility";

import { SimpleSleepLogFilterSet, SleepLogFilter } from "../../../../types/SleepLogFilter";
import { SleepLogFilters } from "../../../filters/components/SleepLogFilters";
import SleepLogRow from "../../../sleep-log-view/SleepLogRow";
import SimpleDate from "../../../../types/SimpleDate";
import DateTimeUtils from "../../../../utils/DateTimeUtils";
import SleepLogTimePeriodFilter from "../../../filters/components/SleepLogTimePeriodFilter";
import SleepLogModal from "../../../sleep-log-view/SleepLogModal";
import SleepLogSettings from "../../../../types/SleepLogSettings";
import { TagSuggestionsService } from "../../../../services/TagSuggestionsService";
import { ISleepLogUpdateFlusher, MockSleepLogUpdateFlusher } from "../../../../utils/SleepLogUpdateFlusher";
import ISleepLogView from "../../../../types/ISleepLogView";
import { DateTime } from "luxon";
import { useSleepLogFilterServiceProvider } from "../../../filters/services/sleep-log-filter.hooks";
import { SleepLogFilterServiceContext } from "../../../filters/services/sleep-log-filter-context";
import useStore from "../../../../shared/store/useStoreService.hook";
import { DefaultFilter, SleepLogFilterService, State } from "../../../filters/services/sleep-log-filter.service";
import classNames from "classnames";
import { LoginStateContext } from "../../../../app/login-state.context";
import { SleepLogHttpServiceContext } from "../../../http/sleep-log-http-service.provider";

interface Props {
    sleepLogsState: ISleepLogView[];
    settings?: SleepLogSettings;
    filteredSleepLogs: ISleepLogView[] | undefined;
    filter: SimpleSleepLogFilterSet;
    showSpinner: boolean;
    showCache: boolean;
    useCache: boolean;
    lastCachedLog: string;
    isCacheRefreshing: boolean;

    toggleUseCache: () => void;
    onRefreshCache: (lastNumDays?: number) => void;

    applyFilters: (startDate: string, endDate: string, filters: SleepLogFilter[][]) => any;    
}

interface FilterStat {
    count: number;
    matchingCount: number;
    sleepLogs: ISleepLogView[];
    logCount: number;
}

const limit = 10;

export const FilterSummaryCardHost = (props: Props) => {
    
    const service = useSleepLogFilterServiceProvider(limit);

    useEffect(() => {
        service.add(0);
    }, []);

    return (
        <SleepLogFilterServiceContext.Provider value={service}>
            <FilterSummaryCard {...props} />
        </SleepLogFilterServiceContext.Provider>
    );
}

interface DisplayedLogs {
    showAll: boolean;
    showPrev: boolean;
    showNext: boolean;
};

export const FilterSummaryCard = ({
    sleepLogsState, 
    settings,
    filteredSleepLogs,
    filter,
    applyFilters,
    showSpinner,
    showCache,
    useCache,
    lastCachedLog,
    isCacheRefreshing,
    toggleUseCache,
    onRefreshCache
} : Props) => {

    const [loginState, _] = useContext(LoginStateContext);

    const [sleepStat, setSleepStat] = useState<FilterStat | undefined>(undefined);
    const [showFilters, setShowFilters] = useState(true);
    const [showSleepLogModal, setShowSleepLogModal] = useState(false);
    const [displayedSleepLog, setDisplayedSleepLog] = useState<SleepLog | undefined>(undefined);
    const [displayedLogs, setDisplayedLogs] = useState<DisplayedLogs>({ showAll: false, showPrev: false, showNext: false });
    const [useSimpleFilter, setUseSimpleFilter] = useState(false);
    const [timeRangeFilter, setTimeRangeFilter] = useState(() => ({
        startDate: DateTimeUtils.calculateThresholdDate(30),
        endDate: formatDateAsLocalTime(new Date()) 
    }));
    const [logsToShow, setLogsToShow] = useState(15);

    const [, state] = useStore<SleepLogFilterService, State>(SleepLogFilterServiceContext);
    const filterGroups = state.filters;

    const mockSleepLogUpdateFlusher = useRef<ISleepLogUpdateFlusher | undefined>(undefined);
    if (!mockSleepLogUpdateFlusher.current) {
            mockSleepLogUpdateFlusher.current = new MockSleepLogUpdateFlusher();
    }

    const filteredLogMap = useMemo(() => {
        const logMap = new Map<string, ISleepLogView>();

        if (filteredSleepLogs) {
            filteredSleepLogs.forEach(log => logMap.set(log.date.asString, log));
        }

        return logMap;
    }, [filteredSleepLogs]);

    useEffect(() => {    
        const matchingCount = (filteredSleepLogs ?? sleepLogsState).length;
        const count = sleepLogsState.length;

        let sleepLogs = sleepLogsState;

        if (filteredSleepLogs && !displayedLogs.showAll) {
            sleepLogs = sleepLogs.filter(log => {
                const date = new SimpleDate(log.date.asString);
                return filteredLogMap.has(log.date.asString) || 
                    (displayedLogs.showPrev && filteredLogMap.has(date.addDays(1).asString)) ||
                    (displayedLogs.showNext && filteredLogMap.has(date.addDays(-1).asString));
            });
        }

        const logCount = sleepLogs.length;
        sleepLogs = sleepLogs.slice().reverse().slice(0, logsToShow);

        setSleepStat({count, matchingCount, sleepLogs, logCount });

    }, [filteredSleepLogs, sleepLogsState, displayedLogs, filteredLogMap, logsToShow]);


    function isAllTime() {
        return filter.minDate === undefined && filter.maxDate === undefined;
    }

    function shiftMonth(count: number) {
        const startDate = DateTimeUtils.format(DateTime.fromISO(timeRangeFilter.startDate)
            .plus({ months: count})
            .startOf("month"), "YYYY-MM-DD");

        const endDate = DateTimeUtils.format(DateTime.fromISO(timeRangeFilter.endDate)
            .plus({ months: count })
            .endOf("month"), "YYYY-MM-DD");

        setTimeRangeFilter({ startDate, endDate});
        applyFilters(startDate, endDate, filterGroups.map(filterGroup => filterGroup.filter(f => f.enabled && f != DefaultFilter)));
    }

    const setNextSleepLog = (curId: string, direction: number) => {
        direction = direction * -1; //flip because most sleep logs are sorted in descending order
        const index = sleepStat?.sleepLogs.findIndex(sleepLog => sleepLog.id === curId);
        if (sleepStat === undefined || index === undefined || index === -1 || sleepStat?.sleepLogs[index + direction] === undefined) {
            return;
        }
        else {
            setDisplayedSleepLog(sleepStat.sleepLogs[index + direction].baseSleepLog);
        }
    }

    return (
        <Row className="g-0">
            <Col>
                <div className="d-flex border-bottom">
                    <div style={{ flex: "1 1 0"}}>
                        <div 
                            className="btn-group" 
                            role="group"
                            aria-label=""
                        >
                            <button 
                                type="button"
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => shiftMonth(-1)}
                            >
                                -1m
                            </button>
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => shiftMonth(1)}
                            >
                                +1m
                            </button>
                        </div>                        
                    </div>
                    <h2 className="text-center" style={{ flex: "2 0 0"}}>
                        {isAllTime() &&
                            <span>all time</span>
                        }
                        {!isAllTime() && 
                            <React.Fragment>
                            {FormatUtils.formatDate(filter.minDate as string)}
                            <span> </span>to<span> </span>
                            {FormatUtils.formatDate(filter.maxDate as string)}
                            </React.Fragment>
                        }             
                    </h2>
                    <h2 className="d-flex justify-content-end" style={{ flex: "1 1 0"}}>
                        <Button variant="outline-dark border-0 fs-4 p-0" onClick={() => setShowFilters(!showFilters)}>
                            <i className="bi bi-funnel-fill"></i>
                        </Button>
                    </h2>
                </div>
                <div className="text-center">
                </div>
                <div className="">
                    <div className={classNames("mt-2", { "d-none": !showFilters})}>
                        <div className="text-center">

                            <ToggleButtonGroup
                                type="radio"
                                name="filterType"
                                className="d-none"
                                size="sm"
                                value={useSimpleFilter ? ["simple"] : ["advanced"]}>
                                
                                <ToggleButton type="radio"
                                    id="simple-filter" 
                                    value="simple" 
                                    variant="outline-dark"
                                    onChange={(e) => setUseSimpleFilter(true)}
                                >
                                    Simple
                                </ToggleButton>

                                <ToggleButton type="radio"
                                    id="advanced-filter"
                                    value="advanced"
                                    variant="outline-dark"
                                    onChange={(e) => setUseSimpleFilter(false)}
                                >
                                    Custom
                                </ToggleButton>                        
                            </ToggleButtonGroup>
                        </div>
                        <div className="mt-2">
                            <SleepLogTimePeriodFilter emitTimePeriod={(startDate, endDate) => setTimeRangeFilter({ startDate, endDate })} />
                        </div>
                        <div className={`mt-4 pb-4 border-bottom ${useSimpleFilter ? 'd-none' : ''}`}>                 
                            <SleepLogFilters
                                    applyFilters={applyFilters}
                                    timeRange={timeRangeFilter}
                                    showSpinner={showSpinner}
                                    showCache={showCache}
                                    useCache={useCache}
                                    lastCachedLog={lastCachedLog}
                                    isCacheRefreshing={isCacheRefreshing}
                                    onRefreshCache={onRefreshCache}
                                    toggleUseCache={toggleUseCache}                                 
                            />
                        </div>                            
                    </div>
                </div>
                <Modal centered scrollable={true} fullscreen="sm-down" size="xl"    show={showSleepLogModal} onHide={() => setShowSleepLogModal(false)}>
                    <Modal.Header>
                    <Modal.Title>Matching Sleep Logs</Modal.Title>
                    <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={() => setShowSleepLogModal(false)}></button>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "var(--tw-slate-50)" }}>

                        { filteredSleepLogs &&
                        <>
                            <div className="form-check form-switch mb-2">
                                <input type="checkbox"
                                    className="form-check-input" 
                                    role="switch"
                                    checked={displayedLogs.showAll}
                                    onChange={e => setDisplayedLogs(state => ({...state, showAll: e.target.checked}))}
                                >
                                </input>
                                <label className="form-check-label text-xs">
                                    Show all logs
                                </label>
                            </div>

                            <div className="form-check form-switch mb-2">
                                <input type="checkbox"
                                    className="form-check-input" 
                                    role="switch"
                                    disabled={displayedLogs.showAll}
                                    checked={displayedLogs.showPrev}
                                    onChange={e => setDisplayedLogs(state => ({...state, showPrev: e.target.checked}))}
                                >
                                </input>
                                <label className="form-check-label text-xs">
                                    Show previous day
                                </label>
                            </div>

                            <div className="form-check form-switch mb-2">
                                <input type="checkbox"
                                    className="form-check-input" 
                                    role="switch"
                                    disabled={displayedLogs.showAll}
                                    checked={displayedLogs.showNext}
                                    onChange={e => setDisplayedLogs(state => ({...state, showNext: e.target.checked}))}
                                >
                                </input>
                                <label className="form-check-label text-xs">
                                    Show next day
                                </label>
                            </div>
                        </>
                        }                                                            

                        <div className="border rounded tw-shadow ring-1 ring-gray-200 px-3 py-2 bg-white">
                            {sleepStat && sleepStat.sleepLogs.map((sleepLog: ISleepLogView) =>
                                <div key={sleepLog.id} className="border-bottom">
                                    <SleepLogRow key={sleepLog.id} 
                                        sleepLog={sleepLog.baseSleepLog}
                                        sleepLogSettings={settings}
                                        isNotesExpanded={false}
                                        showOutline={
                                            filteredSleepLogs && 
                                            (displayedLogs.showAll || displayedLogs.showPrev || displayedLogs.showNext ) &&
                                            filteredLogMap.has(sleepLog.date.asString)}
                                        showMedicationDose={true}
                                        onClick={() => setDisplayedSleepLog(sleepLog.baseSleepLog)}
                                    />
                                </div>
                            )}                                                                    
                        </div>

                        <div className="d-flex justify-content-center mt-4" style={{gap: "1rem"}}>
                            <div className="">
                                <button 
                                    className="btn btn-sm btn-primary"
                                    disabled={(sleepStat?.logCount ?? 0) <= logsToShow}
                                    onClick={() => setLogsToShow(count => count + 15)}
                                >
                                    Show more
                                </button>
                            </div>

                            <div className="">
                                <button 
                                    className="btn btn-sm btn-secondary"
                                    disabled={(sleepStat?.logCount ?? 0) <= logsToShow}
                                    onClick={() => setLogsToShow(count => sleepStat?.logCount ?? 0)}
                                >
                                    Show all
                                </button>
                        </div>
                        </div>                      

                    </Modal.Body>         
                </Modal>
                { displayedSleepLog && 
                    <SleepLogModal
                        show={!!displayedSleepLog}
                        loginState={loginState}
                        focusedSleepLog={displayedSleepLog}
                        editable={false}
                        isSaving={false}
                        recentSleepLogs={sleepLogsState}
                        sleepLogUpdateFlusher={mockSleepLogUpdateFlusher.current}
                        
                        handleClose={() => setDisplayedSleepLog(undefined)}
                        updateSleepLog={() => 0}
                        setNextFocusedSleepLog={setNextSleepLog}
                        handleDeleteLog={() => 0}
                        addStatus={() => 0}
                        openSettings={() => 0}
                    />
                }         
                <div className="card-text text-center">
                    <div className="pt-3 pb-1">
                        { sleepStat &&
                            <div className="fs-6">
                                <span>Matching logs: </span>
                                {sleepStat.matchingCount}
                                {sleepStat.matchingCount !== sleepStat.count &&
                                    <>
                                    <span> / </span>
                                    {sleepStat.count}
                                    <span> ({((sleepStat.matchingCount / sleepStat.count) * 100).toFixed(0)}%)</span>
                                    </>
                                }
                                <div>
                                    <button type="button" className="btn btn-link text-xs link-secondary py-0 px-1" onClick={() => setShowSleepLogModal(true)}>
                                        Show
                                    </button>
                                </div>     
                            </div>
                        }
                    </div>            
                </div>                        
            </Col>
        </Row> 
    );
}