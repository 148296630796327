import { Button, Modal } from "react-bootstrap";

interface Props {
  show: boolean;
  confirm: () => void;
  close: () => void;
}

export default function SleepLogSettingsCloseConfirmation({ show, confirm, close }: Props) {
  return (
    <Modal
        centered 
        show={show} 
        onHide={close} 
        dialogClassName=""
        contentClassName="shadow"
        style={{zIndex: 5000}}
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title>Close without saving?</Modal.Title>
        <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={close}></button>
      </Modal.Header>
      
      <Modal.Body className="text-sm">
        <div>
          You have unsaved changes.
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" size="sm" onClick={ () => confirm() }>Continue without saving</Button>
        <Button variant="secondary" size="sm" onClick={ () => close() }>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}