interface Props {
  isSaving: boolean;
  showSpinner: boolean;
}

export default function SavingChanges(props: Props) {
  return (
    <div className=''>
      {props.isSaving &&
        <span>
          {props.showSpinner &&
            <div className="spinner-border spinner-border-sm mx-1" role="status">
              <span className="visually-hidden">Saving...</span>
            </div>
          }
          <span>Saving...</span>
        </span>
      }
      {!props.isSaving &&
        <span>Changes Saved</span>
      }
    </div>
  );
}