import DateTimeUtils from "../utils/DateTimeUtils";
import _ from "lodash";
import { DateTime } from "luxon";

import { SleepEpisode, SleepStage, SleepSummary } from "../types/SleepLog";
import { WithingsSleepEpisode, WithingsSleepStage } from "../types/WithingsData";
import produce from "immer";

export class WithingsAdapter {
    constructor(private rawSleepEpisodes: WithingsSleepEpisode[], private rawExpandedEpisodes: WithingsSleepStage[][]) {
    }
  
    public getSleepSummary() {
        const sleepSummary: SleepSummary = {
            episodes: [],
        };

        let mainSleepIndex = -1;

        // Infer the main sleep episode as the API does not provide it.
        for (const [i, rawSleepEpisode] of this.rawSleepEpisodes.entries()) {
            if (i === 0) {
                mainSleepIndex = i;
            }
            else if (rawSleepEpisode.data.total_timeinbed > this.rawSleepEpisodes[mainSleepIndex].data.total_timeinbed) {
                mainSleepIndex = i;
            }
        }
        
        for (let i = 0; i < this.rawExpandedEpisodes.length; i++) {
            const rawSleepEpisode = this.rawSleepEpisodes[i];
            const rawExpandedEpisode = this.rawExpandedEpisodes[i];
    
            const devices = Object.keys(_.groupBy(rawExpandedEpisode, (stage) => stage.hash_deviceid));
            if (devices.length > 1) {
                throw new Error("Unexpected: more than one device");
            }

            let stages = rawExpandedEpisode.slice().sort((lhs, rhs) => lhs.startdate - rhs.startdate);
            stages = WithingsAdapter.fillInMissingIntervals(stages);
            stages = WithingsAdapter.compress(stages);

            const sleepStages: SleepStage[] = stages.map(stage => {
                return {
                    start: WithingsAdapter.reformat(stage.startdate),
                    seconds: stage.enddate - stage.startdate,
                    type: WithingsAdapter.mapSleepStage(stage.state)
                }
            }); 
            
            // Compute values because some times the withings summary statistics are incorrect (rawSleepEpisode.data.total_sleep_time)
            const minutesInBed = sleepStages.map(stage => stage.seconds).reduce((prev, cur) => prev + cur, 0) / 60;
            const minutesAwake = sleepStages.filter(stage => stage.type === "awake").map(stage => stage.seconds).reduce((prev, cur) => prev + cur, 0) / 60;

            const episode: SleepEpisode = {
                start: WithingsAdapter.reformat(rawSleepEpisode.startdate),
                end: WithingsAdapter.reformat(rawSleepEpisode.enddate),
                timezone: rawSleepEpisode.timezone,
                minutesAsleep: minutesInBed - minutesAwake,
                minutesAwake: minutesAwake,
                isMainSleep: i === mainSleepIndex,
                sleepStages: sleepStages
            }
    
            sleepSummary.episodes.push(episode);
        }

        sleepSummary.episodes = sleepSummary.episodes.sort((lhs, rhs) => +DateTime.fromISO(lhs.start) - +DateTime.fromISO(rhs.start));
        return sleepSummary;
    }
    
        static compress(stages: WithingsSleepStage[]) {
            stages = stages.sort((lhs, rhs) => lhs.startdate - rhs.startdate);
            const compressedStages: WithingsSleepStage[] = [];
        
            for (const stage of stages) {
                if (compressedStages.length > 0) {
                    const prevStage = compressedStages[compressedStages.length - 1];
            
                    if (stage.startdate === prevStage.enddate) {
                        if (stage.state === prevStage.state) {
                            prevStage.enddate = stage.enddate;
                        }
                        else {
                            compressedStages.push({...stage});
                        }
                    }
                    else {
                        compressedStages.push({...stage});
                    }        
                }
                else {
                    compressedStages.push({...stage});
                }
            }
        
            return compressedStages;
        }
    
        private static reformat(utcTimestampInSeconds: number) {
            return DateTimeUtils.format(DateTime.fromSeconds(utcTimestampInSeconds, { zone: "utc"}), 'YYYY-MM-DDTHH:MM:SSZ');
        }
    
        private static mapSleepStage(state: number) {
            switch (state) {
                case 0:
                return "awake";
                case 1:
                return "light";
                case 2:
                return "deep";
                case 3:
                return "rem";
                default:
                return "awake";
            }
        }
    
        /**
         * Fills in any intervals that are missing as being awake.
         * @param stages 
         * @returns contiguous array of sleep stages
         */
        private static fillInMissingIntervals(stages: WithingsSleepStage[]) {
            const newStages: WithingsSleepStage[] = [];
        
            for (const stage of stages) {
                if (newStages.length > 0) {
                    const prevStage = newStages[newStages.length - 1];
            
                    if (prevStage.enddate !== stage.startdate) {
                        newStages.push({
                        startdate: prevStage.enddate,
                        enddate: stage.startdate,
                        state: 0,
                        hash_deviceid: stage.hash_deviceid
                        });
                    }        
                }
        
                newStages.push(stage);
            }
        
            return newStages;    
        }
  }