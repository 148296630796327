import _ from 'lodash';
import ISleepLogView from '../types/ISleepLogView';
import { FormatUtils, formatMonth } from './FormatUtility';
import SleepStagesUtils from './SleepStagesUtils';
import AnalyticsUtils from './analytics-utils';
import SleepLogSettings, { CustomMetricType } from '../types/SleepLogSettings';

/**
 * Types
 */

export interface ResultBucket<T> {
    label: string;
    items: T[];
}

export interface IBucketingStrategy<T> {
    getBuckets(): ResultBucket<T>[];
    add(item: T): void;
}


interface NumericBucket<T> {
    label: string;
    start: number;
    size: number;
}

type InternalNumericBucket<T> =  NumericBucket<T> & ResultBucket<T>;


export type Category = 
    'HoursSlept' |
    'AwakeningCount' |
    'TimeToFallAsleep' |
    'SleepTime' | 
    'Rating' |
    'Tags' |
    'SleepMeds' |
    'OtherMeds' |
    'SleepEfficiency'|
    'Feelings' |
    'Month' |
    'CustomValue';

export type Aggregation = 'AVERAGE' | 'MIN' | 'MAX' | 'COUNT' | 'STANDARD DEVIATION';

/**
 * Extractors
 */

export const Extractors = {
    hoursSlept: (item: ISleepLogView) => item.minutesAsleep != undefined ? (item.minutesAsleep / 60) : undefined,
    awakeningCount: (item: ISleepLogView) => item.calculateAwakeningCount(5),
    timeToFallAsleep: (item: ISleepLogView) => item.mainSleep ? SleepStagesUtils.getTimeToFallAsleepInMins(item.mainSleep) : undefined,
    mainSleepTime: (item: ISleepLogView) => {
        if (item.mainSleep) {
            let { sleeptime } = SleepStagesUtils.getSleepAndWakeupTime(item.mainSleep);
            sleeptime = sleeptime.setZone(item.mainSleep?.timezone ?? 'utc');
            const twentyFourHourTime = sleeptime.hour + (sleeptime.minute / 60);
            return twentyFourHourTime;
        }
        else {
            return undefined;
        }
    },
    mainAwakeTime: (item: ISleepLogView) => {
        if (item.mainSleep) {
            let { waketime } = SleepStagesUtils.getSleepAndWakeupTime(item.mainSleep);
            waketime = waketime.setZone(item.mainSleep?.timezone ?? 'utc');
            const twentyFourHourTime = waketime.hour + (waketime.minute / 60);
            return twentyFourHourTime;
        }
        else {
            return undefined;
        }
    },
    rating: (item: ISleepLogView) => item.rating,
    tags: (item: ISleepLogView) => item.baseSleepLog.tags,
    feelings: (item: ISleepLogView) => item.baseSleepLog.feelings?.map(feeling => feeling.feeling),
    sleepMedications: (item: ISleepLogView) => (item.baseSleepLog.medications?.length ?? 0) > 0 ? item.baseSleepLog.medications?.map(med => med.name) : ['No medication'],
    otherMedications: (item: ISleepLogView) => item.baseSleepLog.otherMedications?.map(med => med.name),
    numericCustomMetric: (name: string) => 
        (item: ISleepLogView): number | undefined => {
            const value = item.baseSleepLog.customMetrics?.find(metric => metric.name === name)?.value;
            return typeof value === 'number' ? value : undefined; 
        },
    mainSleepEfficiency: (item: ISleepLogView) => item.mainSleep ? (item.mainSleep.minutesAsleep / (item.mainSleep.minutesAsleep + item.mainSleep.minutesAwake)) : undefined,
    month: (item: ISleepLogView) => formatMonth(item.date.month, item.date.year, true /* abbreviateMonth */),
    customValue: (customValueName: string, type: CustomMetricType) =>
        (sleepLog: ISleepLogView) => {
            if (!sleepLog.customMetrics) {
                return undefined;
            }

            const customValue = sleepLog.customMetrics
                .filter(customValue => customValue.type === type)
                .find(customValue => customValue.name === customValueName);

            return customValue?.value as (number | undefined);
        }
}

/**
 * Base bucketing strategies
 */

class NumberBucketingStrategy<T> implements IBucketingStrategy<T> {
    constructor(buckets: NumericBucket<T>[], extractor: (item: T) => number | undefined) {
        this.resultBuckets = buckets.map(bucket => ({...bucket, items: [] }));
        this.extractor = extractor;
    }

    add(item: T) {
        let value = this.extractor(item);

        if (value === undefined) {
            return;
        }

        for (let i = 0; i < this.resultBuckets.length; i++) {
            const bucket = this.resultBuckets[i];
            if (value >= bucket.start && value < bucket.start + bucket.size) {
                bucket.items.push(item);
                break;
            }
        }
    }

    getBuckets(): ResultBucket<T>[] {
        return this.resultBuckets;
    }

    private resultBuckets: InternalNumericBucket<T>[];
    private extractor: (item: T) => number | undefined;
}

class CategoryBucketingStrategy<T> implements IBucketingStrategy<T> {
    constructor(buckets: string[], extractor: (item: T) => string[] | undefined) {
        this.resultBuckets = buckets.map(bucket => ({ label: bucket, items:[] }));
        this.extractor = extractor;

        this.bucketMap = new Map<string, ResultBucket<T>>();
        for (const bucket of this.resultBuckets) {
            this.bucketMap.set(bucket.label, bucket);
        }
    }

    add(item: T) {
        const values = this.extractor(item);

        for (const value of values ?? []) {
            const bucket = this.bucketMap.get(value);
            bucket?.items.push(item);
        }
    }

    getBuckets(): ResultBucket<T>[] {
        return this.resultBuckets;
    }

    private resultBuckets: ResultBucket<T>[];
    private extractor: (item: T) => string[] | undefined;

    private bucketMap: Map<string, ResultBucket<T>>;
}

/**
 * Bucketing strategies
 */

export class MonthBucketingStrategy extends CategoryBucketingStrategy<ISleepLogView> {
    constructor(sleepLogs: ISleepLogView[]) {
        let extractor = Extractors.month;
        const buckets = _(sleepLogs).map(sleepLog => extractor(sleepLog)).uniq().value();
        super(buckets, (item) => [extractor(item)]);
    }
}

export class UserTagsBucketingStrategy extends CategoryBucketingStrategy<ISleepLogView> {
    constructor(sleepLogs: ISleepLogView[]) {
        let extractor = Extractors.tags;
        let tagMap: Record<string, number> = {};

        for (const sleepLog of sleepLogs) {
            const tags = extractor(sleepLog);
            for (const tag of tags ?? []) {
                if (tagMap[tag] === undefined) {
                    tagMap[tag] = 0;
                }
                
                tagMap[tag]++;
            }
        }

        const topN = Object.entries(tagMap).sort((lhs, rhs) => rhs[1] - lhs[1]).map(tag => tag[0]).slice(0, 20);
        super(topN, extractor);
    }
}

export class FeelingsBucketingStrategy extends CategoryBucketingStrategy<ISleepLogView> {
    constructor(sleepLogs: ISleepLogView[]) {
        let extractor = Extractors.feelings;
        let tagMap: Record<string, number> = {};

        for (const sleepLog of sleepLogs) {
            const tags = extractor(sleepLog);
            for (const tag of tags ?? []) {
                if (tagMap[tag] === undefined) {
                    tagMap[tag] = 0;
                }
                
                tagMap[tag]++;
            }
        }

        const topN = Object.entries(tagMap).sort((lhs, rhs) => rhs[1] - lhs[1]).map(tag => tag[0]).slice(0, 20);
        super(topN, extractor);
    }
}

export class SleepMedTagBucketingStrategy extends CategoryBucketingStrategy<ISleepLogView> {
    constructor(sleepLogs: ISleepLogView[]) {
        let extractor = Extractors.sleepMedications;
        let tagMap: Record<string, number> = {};

        for (const sleepLog of sleepLogs) {
            const tags = extractor(sleepLog);
            for (const tag of tags ?? []) {
                if (tagMap[tag] === undefined) {
                    tagMap[tag] = 0;
                }
                
                tagMap[tag]++;
            }
        }

        const topN = Object.entries(tagMap).sort((lhs, rhs) => rhs[1] - lhs[1]).map(tag => tag[0]);
        super(topN, extractor);
    }
}

export class HoursSleptBucketingStrategy extends NumberBucketingStrategy<ISleepLogView> {
    constructor(sleepLogs: ISleepLogView[]) {
        let extractor = Extractors.hoursSlept;

        const maxSleep = sleepLogs.map(extractor).reduce(
            (prev, next) => next === undefined ? prev : (prev === undefined ? next : Math.max(prev, next)),
            undefined
        );

        let buckets: NumericBucket<ISleepLogView>[] = [];

        if (maxSleep !== undefined) {
            for (let i = 0; i <= maxSleep; i++) {
                buckets.push({ start: i, size: 1, label: `[${i} - ${i+1}h)` });
            }
        }

        super(buckets, extractor);
    }
}

export class CustomValueNumericBucketingStrategy extends NumberBucketingStrategy<ISleepLogView> {
    constructor(sleepLogs: ISleepLogView[], name: string) {
        let extractor = Extractors.numericCustomMetric(name);

        const maxValue = sleepLogs.map(extractor).reduce(
            (prev, next) => next === undefined ? prev : (prev === undefined ? next : Math.max(prev, next)),
            undefined
        );

        let buckets: NumericBucket<ISleepLogView>[] = [];

        if (maxValue !== undefined) {
            const bucketSize = Math.floor(maxValue / 10);

            for (let i = 0; i <= maxValue; i++) {
                buckets.push({ start: i, size: 1, label: bucketSize === 0 ? `${i}` : `[${i} - ${i+1}h)` });
            }
        }

        super(buckets, extractor);
    }
}

export class RatingBucketingStrategy extends NumberBucketingStrategy<ISleepLogView> {
    constructor(sleepLogs: ISleepLogView[]) {
        let extractor = Extractors.rating;

        const maxRating = sleepLogs.map(extractor).reduce(
            (prev, next) => next === undefined ? prev : (prev === undefined ? next : Math.max(prev, next)),
            undefined
        );

        let buckets: NumericBucket<ISleepLogView>[] = [];

        if (maxRating !== undefined) {
            for (let i = 0; i <= maxRating; i++) {
                buckets.push({ start: i, size: 1, label: `${i}` });
            }
        }

        super(buckets, extractor);
    }
}

export class AwakeningCountBucketStrategy extends NumberBucketingStrategy<ISleepLogView> {
    constructor(sleepLogs: ISleepLogView[]) {
        let extractor = Extractors.awakeningCount;

        const maxAwakeningCount = sleepLogs.map(extractor).reduce(
            (prev, next) => next === undefined ? prev : (prev === undefined ? next : Math.max(prev, next)),
            undefined
        );

        let buckets: NumericBucket<ISleepLogView>[] = [];

        if (maxAwakeningCount !== undefined) {
            for (let i = 0; i <= maxAwakeningCount; i++) {
                buckets.push({ start: i, size: 1, label: `${i}` });
            }
        }

        super(buckets, extractor);        
    }    
}

export class TimeToFallAsleepBucketStrategy extends NumberBucketingStrategy<ISleepLogView> {
    constructor(sleepLogs: ISleepLogView[]) {
        let extractor = Extractors.timeToFallAsleep;

        const maxTime = sleepLogs.map(extractor).reduce(
            (prev, next) => next === undefined ? prev : (prev === undefined ? next : Math.max(prev, next)),
            undefined
        );

        let buckets: NumericBucket<ISleepLogView>[] = [];
        const bucketSize = 20;

        if (maxTime !== undefined) {
            for (let i = 0; i <= maxTime; i += bucketSize) {
                buckets.push({ start: i, size: bucketSize, label: `[${i}-${i + bucketSize}m)` });
            }
        }

        super(buckets, extractor);        
    }    
}

export class SleepTimeBucketingStrategy extends NumberBucketingStrategy<ISleepLogView> {
    constructor(sleepLogs: ISleepLogView[]) {
        let extractor = Extractors.mainSleepTime;

        let buckets: NumericBucket<ISleepLogView>[] = [];

        for (let i = 0; i < 24; i++) {
            buckets.push({
                start: i,
                size: 1,
                label: `${FormatUtils.formatTimeFromComponents(i, 0, false /* includeMins */, false /* includePeriod*/)}-${FormatUtils.formatTimeFromComponents((i + 1) % 24, 0, false /* includeMins */)}`});
        }

        super(buckets, extractor);
    }
}

export class CustomValueBucketingStrategy implements IBucketingStrategy<ISleepLogView> {
    constructor(sleepLogSettings: SleepLogSettings, sleepLogs: ISleepLogView[], customValueName: string) {
        const customValueTemplate = sleepLogSettings.customMetrics?.find(customValue => customValue.name === customValueName)!;

        switch (customValueTemplate.type) {
        case "0_to_10":
        case "1_to_5":
        case "number":
            let numBuckets: number;
            let bucketSize: number;
            let start: number;

            if (customValueTemplate.type === "0_to_10") {
                numBuckets = 11;
                bucketSize = 1;
                start = 0;
            }
            else if (customValueTemplate.type === "1_to_5") {
                numBuckets = 5;
                bucketSize = 1;
                start = 1;
            }
            else {
                const values = sleepLogs
                .map(sleepLog => sleepLog.customMetrics?.find(customValue => customValue.name === customValueTemplate.name))
                .filter(customValue => customValue !== undefined && customValue.type === customValueTemplate.type && customValue.value !== undefined)
                .map(customValue => customValue?.value!) as number[];

                const uniqueCount = _.uniq(values).length;

                const min = Math.min(...values);
                const max = Math.max(...values);

                numBuckets = Math.min(uniqueCount, 10);
                bucketSize = Math.ceil((max - min) / numBuckets);
                start = min;
            }

            const numericBuckets: NumericBucket<ISleepLogView>[] = [];

            for (let i = 0; i < numBuckets; i++) {
                const curStart = start + (bucketSize * i);
                numericBuckets.push({
                    label: `[${curStart.toFixed(1)}-${(curStart + bucketSize).toFixed(1)})`,
                    start: curStart,
                    size: bucketSize,
                });
            }

            const extractor = Extractors.customValue(customValueTemplate.name, customValueTemplate.type);

            this.bucketingStrategy = new NumberBucketingStrategy<ISleepLogView>(numericBuckets, extractor);
            

        break;

        default:

        }
    }

    getBuckets(): ResultBucket<ISleepLogView>[] {
        return this.bucketingStrategy ? this.bucketingStrategy.getBuckets() : [];
    }
    add(item: ISleepLogView): void {
        if (this.bucketingStrategy) {
            this.bucketingStrategy.add(item);
        }
    }
 
    private bucketingStrategy: IBucketingStrategy<ISleepLogView> | undefined;
}

/**
 * Bucket Aggregation
 */

export class BucketAggregator {
    constructor(private operation: Aggregation, private extractor: (item: ISleepLogView) => number | undefined) {
    }

    aggregate(buckets: ResultBucket<ISleepLogView>[]) {
        const aggregatedBuckets: { xLabel: string, value: number }[] = [];

        for (const bucket of buckets) {
            const values = bucket.items.map(this.extractor).filter((v): v is number => v != undefined);
            switch (this.operation) {
                case 'AVERAGE':
                    const sum = values.reduce((prev, next) => prev + next, 0);
                    const avg = sum / values.length;
                    aggregatedBuckets.push({ xLabel: bucket.label, value: avg });
                    break;
                case 'MIN':
                    aggregatedBuckets.push({ xLabel: bucket.label, value: Math.min(...values) });
                    break;
                case 'MAX':
                    aggregatedBuckets.push({ xLabel: bucket.label, value: Math.max(...values) });
                    break;
                case 'COUNT':
                    aggregatedBuckets.push({ xLabel: bucket.label, value: values.length });
                    break;
                case 'STANDARD DEVIATION':
                    aggregatedBuckets.push({ xLabel: bucket.label, value: AnalyticsUtils.calculateStandardDeviation(values) });
                    break;
                default:
            }
        }

        return aggregatedBuckets;
    }
}