import { DateTime } from "luxon";
import { produce } from "immer";

import React, { useEffect, useMemo, useRef, useState } from "react";
import ISleepLogView from "../../types/ISleepLogView";
import SleepLog, { SleepEpisode, SleepStage, SleepStageType, SleepSummary } from "../../types/SleepLog";
import SleepStagesEditorCard from "./SleepStagesEditorCard";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { useImmer } from "use-immer";
import { FormatUtils } from "../../utils/FormatUtility";
import SleepStagesChart from "./SleepStagesChart";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useSleepStagesEditorService } from "../../services/SleepStagesEditorService";
import { StatusMessage } from "../common/StatusMessages";
import TimeDuration from "./common/TimeDuration";
import DeleteSleepEpisodeDialog from "./DeleteSleepEpisodeDialog";
import ResetSleepEpisodesDialog from "./ResetSleepEpisodesDialog";
import { ISleepLogUpdateFlusher } from "../../utils/SleepLogUpdateFlusher";


interface PropTypes {
    username: string | undefined;
    sleepLog: ISleepLogView;
    onSave: (sleepSummary: SleepSummary | undefined) => void;
    addStatus: (msg: StatusMessage) => void;
    sleepLogUpdateFlusher: ISleepLogUpdateFlusher;
}


export interface EditingSleepStage {
    type: SleepStageType;
    start: string; // YYYY-MM-DDTHH:MM:SSZ
    end: string; // YYYY-MM-DDTHH:MM:SSZ
}

export default function SleepStagesEditor({
        username,
        sleepLog,
        onSave,
        addStatus,
        sleepLogUpdateFlusher
}: PropTypes) {

    const [state, service] = useSleepStagesEditorService(username, sleepLog, onSave, addStatus, sleepLogUpdateFlusher);
    const sleepSummary = state.sleepSummary;
    const selectedIndex = state.selectedIndex;
    const selectedEpisode = selectedIndex !== undefined ? sleepSummary.episodes[selectedIndex] : undefined;
    const editingTimeRange = state.editingTimeRange;

    const formattedTimeRange = useMemo(() => {
        if (!selectedEpisode || !editingTimeRange) {
            return undefined;
        }

        const start = DateTimeUtils.format(DateTime.fromISO(editingTimeRange.start).setZone(selectedEpisode.timezone ?? "utc"), "YYYY-MM-DDTHH:MM:SS");
        const end = DateTimeUtils.format(DateTime.fromISO(editingTimeRange.end).setZone(selectedEpisode.timezone ?? "utc"), "YYYY-MM-DDTHH:MM:SS");
        return {
            start,
            end,
        };
    }, [editingTimeRange]);

    const [deleteEpisodeDialog, setDeleteEpisodeDialog] = useState<{ show: boolean, index?: number }>({ show: false, index: undefined });
    const [showResetEpisodesDialog, setShowResetEpisodesDialog] = useState(false);    

    const [editingSleepStage, setEditingSleepStage] = useImmer<EditingSleepStage | undefined>(undefined);

    /*
            const start = DateTime.fromISO(episode.start);
        const end = DateTime.fromISO(episode.end);        

        return {
            start: DateTimeUtils.format(start, "YYYY-MM-DDTHH:MM:SSZ"),
            end: DateTimeUtils.format(end, "YYYY-MM-DDTHH:MM:SSZ"),
            stage: "asleep" as SleepStageType
        };
    */

    const formattedEditingSleepStage = useMemo(() => {
        if (!selectedEpisode || !editingSleepStage) {
            return undefined;
        }

        const timezone = selectedEpisode.timezone;

        const start = DateTimeUtils.format(DateTime.fromISO(editingSleepStage.start).setZone(timezone ?? "utc"), "YYYY-MM-DDTHH:MM:SS");
        const end = DateTimeUtils.format(DateTime.fromISO(editingSleepStage.end).setZone(timezone ?? "utc"), "YYYY-MM-DDTHH:MM:SS");
        return {
            start,
            end,
            type: editingSleepStage.type
        };
    },
    [selectedEpisode, editingSleepStage]);

    const [removeAwakeningThreshold, setRemoveAwakeningThreshold] = useState("1");

    const [isSaving, setIsSaving] = useState(false);

    const selectSleepStage = (stage: SleepStage) => {
        const start = DateTime.fromISO(stage.start);

        setEditingSleepStage({
            start: stage.start,
            end: DateTimeUtils.format(start.plus({seconds: stage.seconds}), "YYYY-MM-DDTHH:MM:SSZ"),
            type: stage.type
        });
    };

    const updateTimePeriod = (index: number, start: string | undefined, end: string | undefined) => {
        const timezone = sleepSummary.episodes[index].timezone;

        const startDate = start ? DateTime.fromISO(start, { zone: timezone ?? "utc" }).toUTC() : undefined;
        const endDate = end ? DateTime.fromISO(end, { zone: timezone ?? "utc"}).toUTC() : undefined;

        service.updateTimeRange(index, startDate, endDate);
    };

    const commitTimePeriod = (index: number) => {
        service.commitTimeRange(index);
    };

    const addSleepStage = (index: number, start: string | undefined, end: string | undefined, type: SleepStageType) => {
        if (!start || !end) {
            return;
        }

        const startDate = DateTime.fromISO(start);
        const endDate = DateTime.fromISO(end);

        service.addStage(index, startDate, endDate, type);
    }

    const cancelEdit = () => {
        service.reset();
        service.selectIndex(undefined);
    }

    const saveEdit = async () => {
        setIsSaving(true);
        const saved = await service.save();
        setIsSaving(false);
    };

    const add = () => {
        service.add();

        const date = DateTimeUtils.format(DateTime.utc().set({hour: 0, minute: 0, second: 0, millisecond: 0}), "YYYY-MM-DDTHH:MM:SSZ");
        setEditingSleepStage({
            start: date,
            end: date,
            type: "asleep"
        });
    }

    /**
     * 
     * @param index 
     * @param start - timestamp with no timezone at all
     * @param end  -  no timezone as aswell
     */
    const updateEditingSleepStage = (index: number, start: string | undefined, end: string | undefined) => {
        const timezone = sleepSummary.episodes[index].timezone;

        const startDate = start ? DateTime.fromISO(start, { zone: timezone ?? "utc" }).toUTC() : undefined;
        const endDate = end ? DateTime.fromISO(end, { zone: timezone ?? "utc"}).toUTC() : undefined;

        setEditingSleepStage(stage => {
            if (stage) {
                if (startDate !== undefined) {
                    stage.start = DateTimeUtils.format(startDate, "YYYY-MM-DDTHH:MM:SSZ");
                }

                if (endDate !== undefined) {
                    stage.end = DateTimeUtils.format(endDate, "YYYY-MM-DDTHH:MM:SSZ");
                }
            }
        });
    }

    const editEpisode = (index: number) => {
        service.selectIndex(index);

        const episode = sleepSummary.episodes[index];

        setEditingSleepStage({
            start: episode.start,
            end: episode.end,
            type: "asleep"
        });
    }

    const deleteSleepEpisode = (index: number) => {
        setDeleteEpisodeDialog({ show: true, index });
    }

    const cancelDeleteSleepEpisode = () => {
        setDeleteEpisodeDialog({ show: false });
    }    

    const confirmDeleteSleepEpisode = async () => {
        if (deleteEpisodeDialog.index !== undefined) {
            await service.delete(deleteEpisodeDialog.index);
            setDeleteEpisodeDialog({ show: false }); 
        }     
    }

    const resetAll = async () => {
        setShowResetEpisodesDialog(true);
    }

    const confirmResetAll = async () => {
        await service.deleteAll();
        setShowResetEpisodesDialog(false); 
    }

    const cancelResetAll = () => {
        setShowResetEpisodesDialog(false);
    }

    const removeAwakenings = (index: number, thresholdInMinutes: number) => {
        if (isNaN(thresholdInMinutes)) {
            return;
        }

        service.removeAwakenings(index, thresholdInMinutes);
    }


    const setIsMainSleep = (index: number, isMainSleep: boolean) => {
        service.setIsMainSleep(index, isMainSleep);
    }

    function formatSleepRange(episode: SleepEpisode) {
        const start = DateTime.fromISO(episode.start).setZone(episode.timezone ?? "utc");
        const end = DateTime.fromISO(episode.end).setZone(episode.timezone ?? "utc");
                        
        return FormatUtils.formatSleepRangeFromDateTime(start, end);
    }

    return (
        <div className="card shadow-sm">
            <div className="card-body">
                { selectedIndex === undefined &&
                        <div className="text-center fs-5 mb-2">
                        <span>Sleep Episodes</span>
                        <span> </span>
                        <OverlayTrigger
                                trigger={["hover", "focus"]} 
                                placement="bottom"
                                overlay={
                                <Popover id="popover-basic">
                                        <Popover.Body>
                                                Editing sleep episodes does not affect your device data. Sleep data can be viewed at the bottom of this log.
                                        </Popover.Body>
                                </Popover>
                                }
                        >
                                <i className="bi bi-info-circle text-xs"></i>
                        </OverlayTrigger>
                        </div>
                }
                { selectedIndex === undefined && sleepSummary.episodes.map((episode, i) => 
                    <div key={i} className="d-flex justify-content-between border-bottom mt-3 pb-3">

                        <div className="d-flex text-sm align-items-center" style={{flex: "1 1 auto", gap: ".5rem", flexWrap: "wrap"}}>
                            <div style={{flex: "1 1 200px"}}>
                                <SleepStagesChart
                                    episode={episode}
                                    height={"10px"}
                                    onSelect={() => 0} 
                                />
                            </div>
                            <div className="text-sm d-flex" style={{ gap: "1rem", flex: "0 0 auto" }}>
                                <div style={{flex: "1 0 auto"}}>
                                    {formatSleepRange(episode)}
                                </div>
                                <div style={{flex: "1 0 auto"}}>
                                    <TimeDuration duration={episode.minutesAsleep} />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end align-items-center" style={{flex: ".25 1 auto"}}>
                            <Button variant="outline-dark" className="border-0" onClick={() => editEpisode(i)}>
                                <i className="bi bi-pencil"></i>
                            </Button>
                            <Button variant="outline-danger" className="border-0" onClick={() => deleteSleepEpisode(i)}>
                                <i className="bi bi-trash3"></i>
                            </Button>
                        </div>
                    </div>
                )}

                <DeleteSleepEpisodeDialog show={deleteEpisodeDialog.show} confirm={confirmDeleteSleepEpisode} close={cancelDeleteSleepEpisode} />

                { selectedIndex === undefined &&
                    <div className="d-flex pt-3 justify-content-between">
                        <div style={{textAlign: "center" }}>
                            <Button variant="create" size="sm" disabled={sleepSummary.episodes.length >= 4} onClick={() => add() }>+ Add</Button>
                        </div>
                        <div style={{textAlign: "end" }}>
                            <Button variant="outline-danger" size="sm" onClick={() => resetAll() }>Reset to device</Button>
                        </div>
                    </div>
                }

                <ResetSleepEpisodesDialog show={showResetEpisodesDialog} confirm={confirmResetAll} close={cancelResetAll} />

                { selectedIndex !== undefined && 
                    <div className="d-flex flex-column" style={{gap: ".75rem"}}>

                        <div className="d-flex justify-content-center" style={{flexWrap: "wrap", gap: ".75rem"}}>
                            <div className="d-flex justify-content-center" style={{flexWrap: "wrap", gap: ".75rem"}}>
                                <input type="datetime-local"
                                    className="btn btn-sm border"
                                    value={formattedTimeRange?.start}
                                    onChange={(e) => updateTimePeriod(selectedIndex, e.target.value, undefined)}>
                                </input>
                                <input type="datetime-local"
                                    className="btn btn-sm border"
                                    
                                    value={formattedTimeRange?.end}
                                    onChange={(e) => updateTimePeriod(selectedIndex, undefined, e.target.value)}>
                                </input>
                            </div>
                            <button type="button"
                                className="btn btn-create btn-sm"
                                style={{flex: "0 1 auto"}}
                                onClick={() => commitTimePeriod(selectedIndex)}>
                                Update
                            </button>                         
                        </div>

                        <SleepStagesEditorCard 
                            episode={sleepSummary.episodes[selectedIndex]} 
                            onSelect={(stage) => selectSleepStage(stage)} 
                        />                     

                        <div className="d-flex justify-content-center" style={{flexWrap: "wrap", gap: ".75rem"}}>
                            <div className="d-flex justify-content-center" style={{flexWrap: "wrap", gap: ".75rem"}}>
                                <input type="datetime-local"
                                    className="btn btn-sm border"
                                    value={formattedEditingSleepStage?.start}
                                    step={30}
                                    onChange={e => updateEditingSleepStage(selectedIndex, e.target.value, undefined)}>
                                </input>
                                <input type="datetime-local"
                                    className="btn btn-sm border"
                                    value={formattedEditingSleepStage?.end}
                                    step={30}
                                    onChange={e => updateEditingSleepStage(selectedIndex, undefined, e.target.value)}>
                                </input>
                            </div>
                            <select
                                className="btn btn-sm border"
                                style={{width: "auto"}}
                                value={formattedEditingSleepStage?.type}
                                onChange={e => setEditingSleepStage(stage => { if (stage) { stage.type = e.target.value as SleepStageType; } })}
                            >
                                <option value="awake">Awake</option>
                                <option value="asleep">Asleep</option>
                                <option value="light">Light</option>
                                <option value="deep">Deep</option>
                                <option value="rem">Rem</option>                                                                                     
                            </select>
                            <button type="button"
                                className="btn btn-create btn-sm"
                                onClick={() => addSleepStage(selectedIndex, editingSleepStage?.start, editingSleepStage?.end, editingSleepStage?.type!)}
                            >
                                Add
                            </button>
                        </div>

                        <div>
                            <div className="my-2 text-xs">
                                    <button className="btn btn-link link-dark py-0 px-0 text-xs align-baseline" onClick={() => removeAwakenings(selectedIndex, Number(removeAwakeningThreshold) * 60)}>Remove</button>
                                    <span className=""> awakenings shorter than</span>
                                    <input type="number" value={removeAwakeningThreshold ?? ""}
                                        placeholder=""    
                                        className="form-control mx-1 px-1 py-0 d-inline-block text-xs"
                                        style={{width: ((removeAwakeningThreshold.toString().length ?? 1) + 4) + "ch", borderWidth: "0px 0px 1px 0px", borderRadius: "0"}}
                                        onChange={e => setRemoveAwakeningThreshold(e.target.value)}>
                                    </input>                         
                                    <span>minutes</span>
                            </div>

                            <div className="mt-4">
                            </div>                                

                            <div className="my-2 text-xs">
                                <div className="form-check">
                                        <input type="checkbox"
                                                className="form-check-input"
                                                checked={sleepSummary.episodes[selectedIndex].isMainSleep}
                                                onChange={(e) => setIsMainSleep(selectedIndex, e.target.checked)}>
                                        </input>
                                        <label className="form-check-label">
                                                Is main sleep
                                        </label>
                                </div>                                
                            </div>

                            <div className="mt-2 border-bottom">
                            </div>
                        </div>                

                        <div className="mt-2 d-flex justify-content-center" style={{gap: "2rem"}}>
                            <Button variant="primary" size="sm" className="px-3 shadow-sm" onClick={() => saveEdit() }>
                                { isSaving &&
                                    <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                }
                                Save
                            </Button>     
                            <Button variant="outline-secondary" size="sm" className="px-3 shadow-sm" onClick={() => cancelEdit() }>
                                Cancel
                            </Button>
                        </div>
                        
                    </div>
                }
            </div>
        </div>
    );
}