import classNames from "classnames";
import { TagStyle } from "../../types/SleepLogSettings";
import { ListGroup, OverlayTrigger } from "react-bootstrap";

import styles from "./styles/tags-component.module.css";
import React, { useState } from "react";

export type TagAction = "delete" | "style" | "moveLeft" | "moveRight";

interface Props {
    className?: string;
    style?: React.CSSProperties | undefined;
    children?: React.ReactNode;

    name: string;
    showContextMenu?: boolean;
    tagStyle?: TagStyle;

    onAction?: (action: TagAction) => void;
}

export function Tag({ className, style, children, name, showContextMenu, tagStyle, onAction }: Props) {
    const [show, setShow] = useState(false);

    function onToggle(show: boolean) {
        setShow(show);
    }

    function performAction(action: TagAction) {
        onAction?.(action);
        setShow(false);
    }

    return (
        <span 
            className={classNames("ant-tag rounded-pill", className)} 
            style={{
                ...style,
                backgroundColor: tagStyle?.backgroundColor,
                borderColor: tagStyle?.borderColor,
                color: tagStyle?.fontColor,
                fontWeight: tagStyle?.fontWeight,
                borderWidth: tagStyle?.borderWidth
            }}
        >
            { name }
            { children }
            { showContextMenu &&
                <span>
                    &nbsp;&nbsp;&nbsp;
                    <OverlayTrigger
                        show={show}
                        placement="right"
                        delay={{ show: 200, hide: 100 }}
                        trigger={["click"]}
                        onToggle={onToggle}
                        rootClose={true}
                        offset={[20, 10]}
                        overlay={(props: any) => 
                            <div className={classNames(styles.tagsActionMenu)} {...props}>
                                <TagActionsMenu 
                                    onDelete={() => performAction("delete")}
                                    onViewStyles={() => performAction("style")}
                                    onMove={(direction: number) => performAction(direction === -1 ? "moveLeft" : "moveRight")}
                                />
                            </div>
                        }
                    >
                        <i 
                            className="bi bi-three-dots-vertical text-muted" 
                            style={{cursor: "pointer", "WebkitTextStrokeWidth": ".25px"}}
                        >
                        </i>
                    </OverlayTrigger>
                </span>
            }
        </span>
    );
}

interface TagActionsMenuProps {
    onDelete: () => void;
    onViewStyles: () => void;
    onMove: (direction: -1 | 1) => void;
}

function TagActionsMenu({ onDelete, onViewStyles, onMove }: TagActionsMenuProps) {
    return (
        <div>
            <ListGroup className="text-sm">

                <ListGroup.Item 
                    className="d-flex column-gap-2" 
                    action
                    onClick={() => onViewStyles()}
                >
                    <i className="bi bi-brush"></i>
                    <span>Styles</span>
                </ListGroup.Item>

                <ListGroup.Item 
                    className="d-flex column-gap-2" 
                    action
                    onClick={() => onMove(-1)}
                >
                    <i className="bi bi-arrow-left"></i>
                    <span>Move left</span>
                </ListGroup.Item>

                <ListGroup.Item 
                    className="d-flex column-gap-2" 
                    action
                    onClick={() => onMove(1)}
                >
                    <i className="bi bi-arrow-right"></i>
                    <span>Move right</span>
                </ListGroup.Item>                                

                <ListGroup.Item
                    className="d-flex column-gap-2 text-danger"
                    action
                    onClick={() => onDelete()}
                >
                    <i className="bi bi-trash3"></i>
                    <span>Delete tag</span>
                </ListGroup.Item>

            </ListGroup>
        </div>
    );     
}