import _ from "lodash";
import { InView } from 'react-intersection-observer';

import ISleepLogView from "../../../../types/ISleepLogView";
import { Button, ButtonGroup, Col, Container, Dropdown, DropdownButton, Nav, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import React, { useMemo, useState } from "react";
import ChartCard from "../../cards/ChartCard";
import CustomMetricsChart from "./CustomMetricsChart";
import CustomMetricsBreakdownChart from "./CustomMetricsBreakdownChart";
import { useImmer } from "use-immer";
import SimpleTooltip from "../../../sleep-log-view/common/SimpleTooltip";
import ErrorBoundary from "../../../common/ErrorBoundary";

interface Props {
    sleepLogGroups: ISleepLogView[][];
}

interface Point {
    date: string;
    value: number;
}

interface MetricValues {
    // maps a custom value name to a point group: an array of points for the filtered values and one for the unfiltered values
    [key: string]: Point[][];
}

export default function CustomMetricsDashboard({ sleepLogGroups }: Props) {
    const [state, setState] = useImmer(() => ({
        inView: false
    }));

    const allSleepLogs = useMemo(() => {
        return sleepLogGroups.flat();
    }, [sleepLogGroups[0], sleepLogGroups[1]]);


    const metricNames = useMemo(() => {
        return getMetricNames(allSleepLogs);
    }, [allSleepLogs]);

    const [selectedMetric, setSelectedMetric] = useState(() => metricNames[0] ?? '');

    const metricValues: MetricValues = useMemo(() => {

        const metricValueGroups: { [key: string]: Point[] }[] =  sleepLogGroups.map(sleepLogs => 
            Object.fromEntries(
                metricNames.map(name => [name, getMetricValues(sleepLogs, name)])
            )
        );

        return Object.fromEntries(
            metricNames.map(metricName => ([ metricName, metricValueGroups.map(metricValues => metricValues[metricName]) ]))
        );
    }, [sleepLogGroups[0], sleepLogGroups[1], metricNames]);

    const [chartOptions, setChartOptions] = useImmer({
        color: false
    });    

    return (
        <InView className="h-100" onChange={inView => setState(state => { state.inView ||= inView; })}>
            { state.inView &&
                <>
                    <div className="text-center">
                        <div className="fs-5 card border-0 tw-shadow ring-1 ring-gray-200 p-2">
                            <span>Custom Values</span>
                        </div>
                        <select
                            className="form-select form-select-sm mt-3"
                            style={{width: 'auto'}}
                            value={selectedMetric}
                            onChange={(e: any) => setSelectedMetric(e.target.value)}
                        >
                            {metricNames.map((med, i) =>
                                <option key={i} value={med}>{med}</option>
                            )}                                                                
                        </select>
                    </div>           
                    <Row className="justify-content-center mt-1 g-4">
                        <Col lg="6">         
                            <ChartCard title={selectedMetric}>
                                <ErrorBoundary>
                                    <CustomMetricsChart 
                                        valueGroups={metricValues[selectedMetric] ?? []} 
                                        colorRows={chartOptions.color} 
                                        showLegend={false}
                                        enableZoom={true}
                                    />
                                </ErrorBoundary>
                            </ChartCard>
                        </Col> 
                        <Col lg="6">         
                            <ChartCard title="Breakdown">
                                <ErrorBoundary>
                                    <CustomMetricsBreakdownChart values={metricValues[selectedMetric]?.[0] ?? []} />
                                </ErrorBoundary>
                            </ChartCard>
                        </Col>                                   
                    </Row>
                </>
            }
        </InView>
    );
}

function getMetricNames(sleepLogs: ISleepLogView[]) {
    const metricNames = sleepLogs
        .filter(sleepLog => sleepLog.customMetrics && sleepLog.customMetrics.length > 0)
        .map(sleepLog => sleepLog.customMetrics)
        .flatMap(metrics => metrics!.filter(metric => typeof(metric.value) === 'number').map(metric => metric.name));

    return _.uniq(metricNames);
}

function getMetricValues(sleepLogs: ISleepLogView[], metricName: string) {
    const points = sleepLogs
        .filter(sleepLog => sleepLog.customMetrics && sleepLog.customMetrics.length > 0 && sleepLog.customMetrics.find(metrics => metrics.name === metricName))
        .map(sleepLog => ({date: sleepLog.date, metrics: sleepLog.customMetrics!}))
        .map(data => ({ date: data.date.asString, value: data.metrics.find(metrics => metrics.name === metricName)!.value as number }));
        
    return points;
}