import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { LoginState, Views } from '../types/CommonInterfaces';
import { StatusMessage, StatusMessageType } from '../lib/common/StatusMessages';
import { Button, Container, Dropdown, DropdownButton, Offcanvas } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import classNames from "classnames";

import logo from '../assets/pie-chart-128px.png';
import styles from './styles/app-nav-bar.module.css';

interface Props {
    loginState: LoginState;
    onLogout: () => void;
    addStatus: (msg: StatusMessage) => any;
}

export default function AppNavBar({loginState, onLogout, addStatus}: Props) {
    const navigate = useNavigate();

    const isDemo = window.location.host.includes("demo");

    const navigateToAccountSettings = () => {
        navigate("/settings");
    };

    return (
        <Navbar 
            variant="dark" 
            className="shadow-sm sticky-top"
            expand="md"
            collapseOnSelect={true}
            style={{background: "var(--app-nav-bar-color)", zIndex: 900}}
        >
            <div className="d-flex container">
                
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <LinkContainer to={loginState.loggedIn ? "/sleep-logs" : "/home"}>
                    <Navbar.Brand href="#" className="">
                        <img
                            src={logo}
                            alt="Sleepchart"
                            width="30"
                            height="30"
                        />
                    </Navbar.Brand>
                </LinkContainer>

                <Navbar.Offcanvas id="basic-navbar-nav">

                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            { loginState.loggedIn && <i className="bi bi-person-fill me-2"></i> }
                            { loginState.loggedIn ? `${loginState.username}` : "Navigation" }
                        </Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body className="d-none d-md-flex">
                        <Nav className="fw-semibold ms-5" style={{gap: "1rem"}}>
                            { loginState.loggedIn &&
                                <>
                                    <Nav.Item className="">
                                        <Nav.Link as={NavLink} to="/sleep-logs">Sleep Logs</Nav.Link>
                                    </Nav.Item>                              
                                    <Nav.Item className="">                 
                                            <Nav.Link as={NavLink} to="/import">Import Data</Nav.Link>
                                    </Nav.Item>
                                </>
                            }
                            { !loginState.loggedIn &&
                                <>
                                    <Nav.Item className="">                    
                                        <Nav.Link as={NavLink} to="/home">Home</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className="">                    
                                        <Nav.Link as={NavLink} to="/overview">Overview</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className="">                    
                                        <Nav.Link as={NavLink} to="/blog">Blog</Nav.Link>
                                    </Nav.Item>    
                                </> 
                            }                                               
                        </Nav>

                        <Nav className="ms-auto" style={{gap: "1rem"}}>
                                { loginState.loggedIn &&
                                <>
                                    <Nav.Item className="">
                                        <Dropdown>
                                            <Dropdown.Toggle className={classNames(styles.username, "fw-semibold")} variant="">
                                                {loginState.username}
                                                &nbsp;
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu variant="" className="shadow" renderOnMount={true} popperConfig={{ strategy: "fixed"}}>
                                                <Dropdown.Item href="#" onClick={navigateToAccountSettings}>Account Settings</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={() => navigate("/devices")}>Connected Devices</Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item 
                                                    href="#"
                                                    className={classNames({ [styles.disabledLink]: isDemo })}
                                                    onClick={onLogout}
                                                    disabled={isDemo}
                                                >
                                                    Logout
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav.Item>
                                </>
                                }
                                { !loginState.loggedIn && 
                                <>
                                    <Nav.Item className="">
                                    <LinkContainer to="/login">
                                        <Nav.Link href="#" className="fw-semibold text-light">
                                            Log in
                                        </Nav.Link>
                                    </LinkContainer>
                                    </Nav.Item>
                                    <Nav.Item className="">
                                    <LinkContainer to="/signup">
                                        <Button variant="warning" className="fw-semibold">
                                            Sign up
                                        </Button>
                                    </LinkContainer>
                                    </Nav.Item>                                                     
                                </>                    
                                }
                        </Nav>
                    </Offcanvas.Body>

                    <Offcanvas.Body className="d-md-none">
                        <Nav className="">
                            { !loginState.loggedIn &&
                                <>
                                    <Nav.Item className="fw-semibold">                    
                                            <Nav.Link as={NavLink} to="/home" href="/home">
                                                <i className="bi bi-house me-2"></i>
                                                Home
                                            </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className="fw-semibold">                    
                                            <Nav.Link as={NavLink} to="/overview" href="/overview">
                                                <i className="bi bi-info-square me-2"></i>
                                                Overview
                                            </Nav.Link>
                                    </Nav.Item>
                                </>
                            }

                            { loginState.loggedIn ?
                                <>
                                <Nav.Item className="">
                                    <Nav.Link as={NavLink} to="/sleep-logs" href="/sleep-logs">
                                        <i className="bi bi-journals me-2"></i>
                                        Sleep Logs
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="">
                                    <Nav.Link as={NavLink} to="/dashboard" href="/dashboard">
                                        <i className="bi bi-bar-chart-fill me-2"></i>
                                        Dashboard
                                    </Nav.Link>
                                </Nav.Item>                                                          
                                <Nav.Item className="">                 
                                    <Nav.Link as={NavLink} to="/import" href="/import">
                                        <i className="bi bi-download me-2"></i>
                                        Import Data
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="">     
                                    <Nav.Link as={NavLink} to="/settings" href="/settings">
                                        <i className="bi bi-gear me-2"></i> 
                                        Account Settings
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="">                 
                                        <Nav.Link as={NavLink} to="/devices" href="/devices">
                                            <i className="bi bi-watch me-2"></i>
                                            Connected Devices
                                        </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="">                 
                                        <Nav.Link>
                                            <div className={classNames({ [styles.disabledLink]: isDemo })} onClick={isDemo ? () => 0 : onLogout}>
                                                <i className={classNames("bi bi-box-arrow-left me-2")}></i>
                                                Logout
                                            </div>
                                        </Nav.Link>
                                </Nav.Item>
                                </>
                                :
                                <>
                                    <Nav.Item className="fw-semibold">                 
                                            <Nav.Link as={NavLink} to="/login" href="/login">
                                                <i className="bi bi-box-arrow-in-right me-2"></i>
                                                Login
                                            </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="fw-semibold">                 
                                            <Nav.Link as={NavLink} to="/signup" href="/signup">
                                                <i className="bi bi-person-plus me-2"></i>
                                                Signup
                                            </Nav.Link>
                                    </Nav.Item>                                
                                </>
                            }                          

                        </Nav>
                    </Offcanvas.Body>                    
                </ Navbar.Offcanvas>    
            </div>                                                                                
        </Navbar>
    );
}