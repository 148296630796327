import classNames from "classnames";
import { useState } from "react";
import { Modal } from "react-bootstrap";

import styles from "./styles/chart-card.module.css";

interface Props {
    title: string;
    children: React.ReactNode;
}

const titleStyle = 'text-center fs-6 fw-medium mb-2';

export default function ChartCard({title, children}: Props) {

    const [expanded, setExpanded] = useState(false);

    function close() {
        setExpanded(expanded => !expanded);
    }

    return (
    <div className="card border-0 tw-shadow ring-1 ring-gray-200" style={{height: "100%"}}>
        <div className="card-body">
            <div className={`${titleStyle} positive-relative`}>
                <button 
                    type="button"
                    className={classNames(styles.expandButton, "btn btn-sm p-0")}
                    onClick={() => setExpanded(true)}
                >
                    <i className="bi bi-arrows-angle-expand"></i>
                </button>
                {title}
            </div>
            <div style={{height: "100%", maxHeight: "600px"}}>
                {!expanded && children}
            </div>
        </div>
        { expanded &&
            <Modal
                style={{zIndex: 5000}}
                centered 
                size="xl" 
                show={expanded}
                onHide={close}
            >
                <Modal.Header className="justify-content-center">
                    <Modal.Title>{title}</Modal.Title>
                    <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={close}></button>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        }
    </div>
    );
}