import SimpleTooltip from "../../sleep-log-view/common/SimpleTooltip";
import ChartCard from "../cards/ChartCard";
import RatingPerDayChart from "./rating/RatingPerDayChart";
import ISleepLogView from "../../../types/ISleepLogView";
import { Aggregation } from "../../../types/types";
import TimeAggregationButtonGroup, { allAggregations } from "../../library/TimeAggregationButtonGroup";

interface Props {
    sleepLogGroups: ISleepLogView[][];
    aggregation: Aggregation;
    showLine: boolean;
    useColorCoding: boolean;
    alwaysShowPoints: boolean;

    onShowLine: (showLine: boolean) => void;
    onAggregationChange: (aggregation: Aggregation) => void;
    onColorCodingChange: (useColorCoding: boolean) => void;
    onAlwaysShowPointsChange: (alwaysShowPoints: boolean) => void;
}

export const RatingPerDayChartCard = (props: Props) =>
    <ChartCard title="My Rating">        
        <RatingPerDayChart
            sleepLogGroups={props.sleepLogGroups}
            aggregationPeriod={props.aggregation}
            type={props.showLine ? 'line' : 'bar'}
            useColorCoding={props.useColorCoding}
            enableZoom={true}
            showLines={true}
            alwaysShowPoints={props.alwaysShowPoints}
        />

        <div className="mt-4"></div>

        <div className='text-center'>
            <TimeAggregationButtonGroup
                aggregations={allAggregations}
                idPrefix="rating-per-day"
                selected={props.aggregation}
                onSelect={(selected) => props.onAggregationChange(selected)} 
            />
        </div>

        <div className="mt-3 d-flex text-sm">
            <button type="button"
                className="btn btn-link link-dark py-0"
                onClick={() => props.onShowLine(!props.showLine)}
            >
                <SimpleTooltip message="Display a bar or line chart">
                    <i className={props.showLine ? 'bi bi-bar-chart-line' : 'bi bi-graph-up'}></i>
                </SimpleTooltip>
            </button>

            <button type="button"
                className="btn btn-link link-dark py-0"
                onClick={() => props.onColorCodingChange(!props.useColorCoding)}
            >
                <SimpleTooltip message="Color code the chart">
                    <i className={props.useColorCoding ? 'bi bi-palette-fill' : 'bi bi-palette'}></i>
                </SimpleTooltip>
            </button>

            { false &&
                <button type="button"
                    className="btn btn-link link-dark py-0"
                    hidden={!props.showLine}
                    onClick={() => props.onAlwaysShowPointsChange(!props.alwaysShowPoints)}
                >
                    <SimpleTooltip message="Always show points">
                        <i className={props.alwaysShowPoints ? "bi bi-circle" : "bi bi-circle-fill"}></i>
                    </SimpleTooltip>
                </button>
            }                                                                                                                  
        </div>                                   
    </ChartCard>