import React, { useMemo } from "react";
import ISleepLogView from "../../../../types/ISleepLogView";
import AnalyticsHelper from "../../../../utils/analytics-utils";
import SimpleTooltip from "../../../sleep-log-view/common/SimpleTooltip";
import ChangeArrow from "../../common/ChangeArrow";

interface Props {
    sleepLogs: ISleepLogView[];
    filteredSleepLogs: ISleepLogView[];
    unfilteredSleepLogs: ISleepLogView[];
}

interface SleepStats {
    minSleep: number;
    maxSleep: number;
    avgSleep: number;
    stdDev: number;
    absoluteDev: number;
}

interface SleepTime {
    average: number;
    min: number;
    max: number;
}

export default function SleepDurationSummaryChart({sleepLogs, filteredSleepLogs, unfilteredSleepLogs}: Props) {
    const sleepStats = useMemo(() => {

        function computeSleepStats(sleepLogs: ISleepLogView[]): SleepStats {
            const sleepDurations = sleepLogs.filter(sleepLog => {return sleepLog.minutesAsleep !== undefined;})
                .map(sleepLog => sleepLog.minutesAsleep as number);
    
            let avgSleep = AnalyticsHelper.calculateAverage(sleepDurations);
            let [minSleep, maxSleep] = AnalyticsHelper.calculateMinMax(sleepDurations);
            let stdDev = AnalyticsHelper.calculateStandardDeviation(sleepDurations, avgSleep);
            let absoluteDev = AnalyticsHelper.calculateAbsoluteDeviation(sleepDurations, avgSleep);
            
            return {
                minSleep: minSleep,
                maxSleep: maxSleep,
                avgSleep: avgSleep,
                stdDev: stdDev,
                absoluteDev: absoluteDev,
            }
        }

        if (filteredSleepLogs.length > 0) {
            const stats = computeSleepStats(sleepLogs);
            const filteredStats = computeSleepStats(filteredSleepLogs);

            return [stats, filteredStats];
        }
        else {
            const stats = computeSleepStats(sleepLogs);
            return [stats];
        } 
    }, [sleepLogs, filteredSleepLogs, unfilteredSleepLogs]); 

    function getRowClass(i: number) {
        return i === 1 ? '' : '';
    }

    return (
        <div>
            <table className="table table-sm text-center">
                <thead className="text-secondary">
                    <tr>
                        <td>Min</td>
                        <td><strong>Average</strong></td>                 
                        <td>Max</td>
                    </tr>
                </thead>
                <tbody>
                    {sleepStats.map((stat, i) =>
                        <React.Fragment  key={i}>
                        <tr style={{backgroundColor: "white"}}>                    
                            <td className={getRowClass(i)}>
                                <SleepDuration durationInMins={stat.minSleep} baseLine={sleepStats.length > 1 ? sleepStats[0].minSleep : undefined} />
                            </td>
                            <td className={getRowClass(i)}>
                                <SimpleTooltip message={`Std. deviation: ${stat.stdDev.toFixed(0)} mins, Absolute deviation: ${stat.absoluteDev.toFixed(0)} mins`} position="bottom">
                                    <div>
                                    <SleepDuration durationInMins={stat.avgSleep} baseLine={sleepStats.length > 1 ? sleepStats[0].avgSleep : undefined} />
                                    </div>
                                </SimpleTooltip>
                            </td>
                            <td className={getRowClass(i)}>
                                <SleepDuration durationInMins={stat.maxSleep} baseLine={sleepStats.length > 1 ? sleepStats[0].maxSleep : undefined}/>
                            </td>                                        
                        </tr>
                        { i === 0 && sleepStats.length > 1 &&
                            <tr className="table-light fs-6">
                                <td></td>
                                <td align="center">
                                    Filtered
                                </td>
                                <td></td>
                            </tr>
                        }    
                        </React.Fragment>
                    )}
                </tbody>
            </table>
        </div>
    );
}

function SleepDuration(props: {durationInMins: number, baseLine: number | undefined}) {
    const {durationInMins, baseLine} = props;

    return (
        <span>
            <ChangeArrow val={durationInMins} baseLine={baseLine} />    
            <span className="fs-5">
                {Math.floor(durationInMins / 60)}
            </span>    
            <span className="" style={{fontSize: ".75rem"}}>h</span>
            <span> </span>
            <span className="fs-5">
                {Math.round(durationInMins % 60)}
            </span>
            <span className="" style={{fontSize: ".75rem"}}>m</span>             
        </span>
    );    
}