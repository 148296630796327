import React from "react";

export default function TimeDuration({duration}: {duration: number}) {
  return (
    <span className="">
      {duration >= 60 &&
        <React.Fragment>
          {Math.floor(duration / 60)}
          <span className="" style={{fontSize: ".85rem"}}>h</span>
          <span> </span>
        </React.Fragment>
      }
      {Math.floor(duration % 60)}
      <span className="" style={{fontSize: ".85rem"}}>m</span>        
    </span>    
  )
}