import { useEffect, useState } from "react";

import { Doughnut } from "react-chartjs-2";
import { ChartData, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import ISleepLogView from "../../../../types/ISleepLogView";
import { Col, Row } from "react-bootstrap";

interface Props {
    sleepLogs: ISleepLogView[];
}

export default function SleepDurationDoughnutChart(props: Props) {
    const {sleepLogs} = props;

    const [data, setData] = useState<ChartData<"doughnut", number[], string>>({
        labels: [],
        datasets: []
    });

    const sleepDoughnutChartOptions: ChartOptions<"doughnut"> = {
        plugins: {
            datalabels: {
                formatter: function(value, context) {
                    const sum = (context.dataset.data as number[]).reduce((a: number, b: number) => a + b, 0);

                    if (sum === 0) {
                        return "";
                    }

                    const days = value ?? 0;
                    const percent = ((days / sum) * 100).toFixed(0);

                    return days !== 0 ? `${percent}%` : "";
                }
            },
            legend: {
                display: false
            },
            title: {
                display: false
            },            
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const sum = context.dataset.data.reduce((a: number, b: number) => a + b, 0);
                        const days = (context.raw as number ?? 0);

                        const percent = ((days / sum) * 100).toFixed(0);
                        return `${context.label}: ${days} days (${percent}%)`;                        
                    }
                }
            }
        },
    }

    const labels = ['5 or less', '5-6 hrs', '6-7 hrs', '7+'];

    const colors = [
        'rgba(255, 99, 132, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 205, 86, 1)',
        'rgba(75, 192, 192, 1)',
    ];

    function calculateCoarseSleepBreakdown(sleepLogs: ISleepLogView[]) {
        const coarseSleepBreakdownData: any = {
            labels: labels,
            datasets: [
                {
                    label: 'Sleep breakdown',
                    data: [0, 0, 0, 0],
                    backgroundColor: colors,
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 205, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                    ],
                    borderWidth: 2,
                },
            ],
        };

        sleepLogs.forEach(elem => {
            let buckets = coarseSleepBreakdownData.datasets[0].data;
    
            if (elem.minutesAsleep !== undefined) {
                let duration = elem.minutesAsleep;
                if (duration < 5 * 60) {
                    buckets[0]++;
                }
                else if (duration < 6 * 60) {
                    buckets[1]++;
                }
                else if (duration < 7 * 60) {
                    buckets[2]++;
                }
                else {
                    buckets[3]++;
                }                        
            }
        });
        
        return coarseSleepBreakdownData;
    }

    useEffect(() => {
        const data = calculateCoarseSleepBreakdown(sleepLogs);
        setData(data);
    }, [sleepLogs]);        
    
    return (
        <div>
            <div className="d-flex flex-wrap text-xxs text-muted justify-content-center mb-3">
                <div className="me-1">
                    <i className="bi bi-square-fill" style={{color: colors[0]}}></i>
                    &nbsp;
                    {labels[0]}
                </div>
                <div className="mx-2">
                    <i className="bi bi-square-fill" style={{color: colors[1]}}></i>
                    &nbsp;
                    {labels[1]}
                </div>
                <div className="mx-2">
                    <i className="bi bi-square-fill" style={{color: colors[2]}}></i>
                    &nbsp;
                    {labels[2]}
                </div>
                <div className="ms-1">
                    <i className="bi bi-square-fill" style={{color: colors[3]}}></i>
                    &nbsp;
                    {labels[3]}
                </div>
            </div>
            <Row className="justify-content-center">
                <Col xs="7" sm="6" lg="5">
                    <div>
                        <Doughnut
                            data={data}
                            options={sleepDoughnutChartOptions}
                            plugins={[ChartDataLabels]} 
                        />
                    </div>
                </Col>
            </Row>
        </div>     
    );
}