import classNames from "classnames";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { useImmer } from "use-immer";
import { v4 as uuidv4 } from 'uuid';

import { TagStyle } from "../../../types/SleepLogSettings";
import { customColors } from "../../../constants/CustomTagStyles";
import { Tag } from "../../sleep-log-view/Tag";
import _ from "lodash";

interface TagStylesModalProps {
    show: boolean;
    tagName: string;
    initialStyle: TagStyle;
    isSaving: boolean;

    save: (tagName: string, style: TagStyle) => void;
    close: () => void;
}

export function CustomTagStylesModal({ show, tagName, initialStyle, isSaving, save, close }: TagStylesModalProps) {

    const selectableColors = useMemo(() => {
        return [
            {
                backgroundColor: initialStyle.backgroundColor,
                borderColor: initialStyle.borderColor,
                fontColor: initialStyle.fontColor                
            },
            ...customColors
        ]
    }, [initialStyle]);

    const [state, setState] = useImmer<{ selectedColorIndex: number; style: TagStyle }>(() => ({
        selectedColorIndex: 0,
        style: initialStyle
    }));

    function selectColor(index: number) {
        const selectedColor = selectableColors[index];

        setState(state => {
            state.selectedColorIndex = index;
            state.style.backgroundColor = selectedColor.backgroundColor;
            state.style.borderColor = selectedColor.borderColor;
            state.style.fontColor = selectedColor.fontColor;
        });
    }

    function setFontWeight(weight: TagStyle["fontWeight"]) {
        setState(state => {
            state.style.fontWeight = weight;
        });
    }

    function setBorderWeight(weight: TagStyle["borderWidth"]) {
        setState(state => {
            state.style.borderWidth = weight;
        });
    }

    const fontWeightId = useMemo(() =>  uuidv4(), []);
    const borderWeightId = useMemo(() =>  uuidv4(), []);

    const fontWeightId_Normal = fontWeightId + "_normal";
    const fontWeightId_Bold = fontWeightId + "_bold";
    const fontWeightId_Bolder = fontWeightId + "_bolder";

    const borderWeightId_Normal = borderWeightId + "_normal";
    const borderWeightId_Bold = borderWeightId + "_bold";    

    return (
        <Modal 
            centered
            show={show}
            onHide={close}
            contentClassName="shadow"
            style={{zIndex: 5000}}
        >
            <Modal.Header className="justify-content-center">
                <Modal.Title>Custom styles</Modal.Title>
                <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={close}></button>
            </Modal.Header>
            
            <Modal.Body className="text-sm">

                <h5>
                    Preview
                </h5>

                <div>
                    <Tag name={tagName} tagStyle={state.style} onAction={_.noop} />
                </div>

                <hr />

                <h5>Color</h5>

                <div className="d-flex flex-wrap row-gap-2 column-gap-2">
                    { selectableColors.map((color, i) =>
                        <div key={i} 
                            style={{ 
                                backgroundColor: color.backgroundColor,
                                border: `${i === state.selectedColorIndex ? "2px" : "1px"} solid ${i === state.selectedColorIndex ? "black" : color.borderColor}`,
                                width: "25px",
                                height: "25px",
                                borderRadius: "4px",
                                cursor: "pointer",
                            }}
                            onClick={ () => selectColor(i) }
                        >
                        </div>
                    )}
                </div>

                <hr />

                <h5>Font</h5>

                <div>
                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio"
                            className="btn-check"
                            name={fontWeightId} 
                            id={fontWeightId_Normal} 
                            checked={state.style.fontWeight === 500}
                            onChange={() => setFontWeight(500)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor={fontWeightId_Normal}>Normal</label>

                        <input type="radio"
                            className="btn-check"
                            name={fontWeightId}
                            id={fontWeightId_Bold}
                            checked={state.style.fontWeight === 600}
                            onChange={() => setFontWeight(600)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor={fontWeightId_Bold}>Bold</label>

                        <input type="radio"
                            className="btn-check"
                            name={fontWeightId}
                            id={fontWeightId_Bolder}
                            checked={state.style.fontWeight === 700}
                            onChange={() => setFontWeight(700)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor={fontWeightId_Bolder}>Bolder</label>
                    </div>
                </div>

                <hr />

                <h5>Border</h5>

                <div>
                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" 
                            className="btn-check" 
                            name={borderWeightId} 
                            id={borderWeightId_Normal} 
                            checked={state.style.borderWidth === 1}
                            onChange={() => setBorderWeight(1)}
                            
                        />
                        <label className="btn btn-outline-secondary" htmlFor={borderWeightId_Normal}>Normal</label>

                        <input type="radio"
                            className="btn-check"
                            name={borderWeightId}
                            id={borderWeightId_Bold}
                            checked={state.style.borderWidth === 2}
                            onChange={() => setBorderWeight(2)}

                        />
                        <label className="btn btn-outline-secondary" htmlFor={borderWeightId_Bold}>Thick</label>
                    </div>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" disabled={isSaving} onClick={() => save(tagName, state.style) }>
                    Save
                </Button>
                <Button variant="outline-secondary" onClick={() => close() }>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}