import classNames from "classnames";
import _ from "lodash";
import { ButtonGroup, Dropdown, ToggleButton, ToggleButtonGroup } from "react-bootstrap";

import { Aggregation } from "../../types/types";

export const allAggregations: Aggregation[] = ['day', 'week', 'month', 'quarter', 'threeDayMoving', 'sevenDayMoving', 'fourteenDayMoving'];
const menuOptions = ['quarter', 'threeDayMoving', 'sevenDayMoving', 'fourteenDayMoving'];

interface Props {
    aggregations: Aggregation[];
    selected: Aggregation;
    idPrefix: string;
    onSelect: (aggregation: Aggregation) => void;
}

export default function TimeAggregationButtonGroup({ aggregations, selected, idPrefix, onSelect }: Props) {

    return (
        <ToggleButtonGroup
            type="radio"
            name={`${idPrefix}-aggregation-button-group`}
            size="sm"
            value={selected}
        >
            { aggregations.includes("day") &&
                <ToggleButton
                    type="radio"
                    id={`${idPrefix}-day`}
                    className="py-0"
                    value="day" 
                    variant="outline-secondary"
                    onChange={() => onSelect("day")}
                >
                    Day
                </ToggleButton>
            }

            { aggregations.includes("week") &&
                <ToggleButton 
                    type="radio"
                    id={`${idPrefix}-week`}
                    className="py-0"
                    value="week"
                    variant="outline-secondary"
                    onChange={() => onSelect("week")}
                >
                    Week
                </ToggleButton>
            }

            { aggregations.includes("month") &&
                <ToggleButton 
                    type="radio"
                    id={`${idPrefix}-month`}
                    className="py-0"
                    value="month" 
                    variant="outline-secondary"
                    onChange={() => onSelect("month")}
                >
                    Month
                </ToggleButton>
            }
            
            { aggregations.some((e) => menuOptions.includes(e)) &&
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle variant="outline-secondary" className="py-0" size="sm" />

                    <Dropdown.Menu>
                        { aggregations.includes("quarter") &&
                            <Dropdown.Item as="button"
                                className={classNames({'fw-bold bg-light border': selected === "quarter"})}
                                onClick={() => onSelect("quarter")}
                            >
                                Quarter
                            </Dropdown.Item>
                        }

                        { aggregations.includes("threeDayMoving") &&
                            <Dropdown.Item as="button"
                                className={classNames({"fw-bold bg-light border": selected === "threeDayMoving"})}
                                onClick={() => onSelect("threeDayMoving")}
                            >
                                3 day moving average
                            </Dropdown.Item>
                        }

                        { aggregations.includes("sevenDayMoving") &&
                            <Dropdown.Item as="button"
                                className={classNames({"fw-bold bg-light border": selected === "sevenDayMoving"})}
                                onClick={() => onSelect("sevenDayMoving")}
                            >
                                7 day moving average
                            </Dropdown.Item>
                        }

                        { aggregations.includes("fourteenDayMoving") &&
                            <Dropdown.Item as="button"
                                className={classNames({"fw-bold bg-light border": selected === "fourteenDayMoving"})}
                                onClick={() => onSelect("fourteenDayMoving")}
                            >
                                14 day moving average
                            </Dropdown.Item> 
                        }   
                    </Dropdown.Menu>
                                                               
                </Dropdown>
            }                                                                                      
        </ToggleButtonGroup>
    );
}