import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartDeferred from 'chartjs-plugin-deferred';

import ISleepLogView from "../../../../types/ISleepLogView";
import DateTimeUtils from "../../../../utils/DateTimeUtils";
import { ChartStyles } from "../constants/chart-styles";

interface Props {
  sleepLogs: ISleepLogView[][];
}

export default function SleepDurationBreakdownCharts(props: Props) {
  const {sleepLogs} = props;

  const [durationBucketSize, setDurationBucketSize] = useState(60);
  const [granularSleepHoursBreakdown, setGranularSleepHoursBreakdown] = useState<ChartData<"bar", number[], string>>({
    labels: [],
    datasets: []
  });

  function calculateSleepBreakdownGranular(
    sleepLogsDatasets: ISleepLogView[][],
    labels: string[], 
    bgColors: string[], 
    borderColors: string[],
    bucketSize: number) {

    let datasets = sleepLogsDatasets.map((e, i) => {
      return {
        label: labels[i],
        data: [] as number[],
        fill: false,
        backgroundColor: bgColors[i],
        borderColor: borderColors[i],
        borderWidth: 0,
        maxBarThickness: 100
      }      
    });

    let sleepBreakdownGranular = {
      labels: [] as string[],
      datasets: datasets,
    };

    let maxSleepDuration = 0;
    let minSleep = Infinity;

    sleepLogsDatasets.forEach((sleepLogs) => {
      sleepLogs.forEach(log => {
        if (log.minutesAsleep !== undefined) {
          maxSleepDuration = Math.max(maxSleepDuration, log.minutesAsleep);
          minSleep = Math.min(minSleep, log.minutesAsleep);
        }
      });
    });

    if (minSleep > maxSleepDuration) {
      return sleepBreakdownGranular;
    }

    let granularBucketCount = Math.floor(maxSleepDuration / bucketSize) + 1;
    const minBucket = Math.floor(minSleep / bucketSize);

    sleepBreakdownGranular.datasets.forEach((e: any, i: number) => {
      sleepBreakdownGranular.datasets[i].data = new Array<number>(granularBucketCount - minBucket).fill(0);
    });

    for (let i = minBucket; i < granularBucketCount; ++i) {
      const [startHours, startMins] = DateTimeUtils.getHoursMins(i * bucketSize);
      const [endHours, endMins] = DateTimeUtils.getHoursMins(((i + 1) * bucketSize));  
      
      if (bucketSize === 30) {
        const start = startMins !== 0 ? `${startHours}.5` : `${startHours}`;
        const end = endMins !== 0 ? `${endHours}.5` : `${endHours}`

        sleepBreakdownGranular.labels.push(`${start}-${end} hrs`);
      }
      else if (bucketSize === 60) {
        //const start = startMins !== 0 ? `${startHours}:${startMins}` : `${startHours}:00`;
        //const end = endMins !== 0 ? `${endHours}:${endMins}` : `${endHours}:00`;

        sleepBreakdownGranular.labels.push(`${startHours}-${endHours} hrs`);
      }
    }

    sleepLogsDatasets.forEach((sleepLogs, i) => {
      let granularBuckets = sleepBreakdownGranular.datasets[i].data;
    
      sleepLogs.forEach(log => {
        if (log.minutesAsleep !== undefined) {
          const bucket = Math.floor(log.minutesAsleep / bucketSize);
          granularBuckets[bucket - minBucket]++;
        }
      });

      for (let i = 0; i < granularBuckets.length; ++i) {
        granularBuckets[i] = (granularBuckets[i] / sleepLogs.length) * 100;
      }
    });
    
    return sleepBreakdownGranular;
  }

  const options: ChartOptions<"bar"> = {
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        color: "grey",
        font: {
            ...ChartStyles.axisFont
        },
        formatter: function(value, context) {
          const percent = value as number;
          return percent >= 5 ? `${value.toFixed(0)}%` : "";
        }
      },      
      legend: {
        display: false
      },      
      title: {
        display: false
      },      
      tooltip: {
        callbacks: {
          label: function(context) {
            const percent = (context.raw ?? 0) as number;
            const numDays = Math.round((percent / 100) * sleepLogs[0].length).toFixed(0);
            return `${numDays} days (${percent.toFixed(0)}%)`;            
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
            color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          font: {
            ...ChartStyles.axisFont
          }
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value, index) {
            let numDays = value as number;
            return `${numDays}%`;
          },   
          stepSize: 1,
          font: {
            ...ChartStyles.axisFont
          },
          maxTicksLimit: 5
        },
        grid: {
            ...ChartStyles.gridYAxis
        },
      }      
    },  
    aspectRatio: 2
  };

  const bgPurple = 'rgba(79, 70, 229, .5)';
  const borderPurple = bgPurple;

  useEffect(() => {
      const sleepData = calculateSleepBreakdownGranular(
        sleepLogs,
        ["Number of days"],
        [bgPurple],
        [borderPurple],
        durationBucketSize);

      setGranularSleepHoursBreakdown(sleepData);     

  }, [sleepLogs, durationBucketSize]);

  return (
    <div>
      <Row className="g-0"> 
        <Col>
          <Bar 
            data={granularSleepHoursBreakdown} 
            options={options} 
            plugins={[ChartDataLabels, ChartDeferred]}
            width={null as any}
            height={null as any} 
          />
        </Col>
      </Row>
      <Row className="g-0 mt-4">
        <Col xs="auto" className="p-0">
          <select className="form-select form-select-sm py-1" value={durationBucketSize} onChange={(e: any) => setDurationBucketSize(Number(e.target.value))}>
            <option value="30">30 mins</option>
            <option value="60">1 hour</option>                                                                   
          </select>
        </Col>       
      </Row>      
    </div>
  );
}