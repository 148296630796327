import { useState, useEffect, useMemo } from "react";

import { ChartArea, ChartData, ChartDataset, ChartOptions} from "chart.js";
import 'chartjs-adapter-luxon';
import { Line } from "react-chartjs-2";
import ChartDeferred from 'chartjs-plugin-deferred';

import ISleepLogView from "../../../../types/ISleepLogView";
import { prettyFormatTimeDurationInHours } from "../../../../utils/FormatUtility";
import { Aggregation, AggregationSummary } from "../../../../types/types";
import AnalyticsUtils from "../../../../utils/analytics-utils";
import _ from "lodash";
import { ChartStyles } from "../constants/chart-styles";

interface Props {
    sleepLogGroups: ISleepLogView[][];
    aggregation: Aggregation;
    goalSleepInMins?: number;
    showLines?: boolean;
    showLegend?: boolean;
    useColorCoding: boolean;
    enableZoom?: boolean;
    alwaysShowPoints?: boolean;
}

const aggregationPeriodMap: Record<Aggregation, number> = {
    'day': 1,
    'week': 7,
    'month': 30, // Approximate
    'quarter': 91, // Approximate
    'threeDayMoving': 3,
    'sevenDayMoving': 7,
    'fourteenDayMoving': 14
}

const getPrimaryColor = (opacity: number) => `rgba(139, 92, 246, ${opacity})`;

export default function SleepPerDayLineChart({
    sleepLogGroups,
    aggregation,
    goalSleepInMins,
    showLines,
    showLegend,
    useColorCoding, 
    enableZoom,
    alwaysShowPoints
}: Props) {
    const [data, setData] = useState<ChartData<"line", number[], string>>({
        labels: [],
        datasets: []
    });

    const getBorderColor = (opacity: number) => {
        const scriptableBorderColor = (context: any) => {
            if (!useColorCoding) {
                return getPrimaryColor(opacity);
            }

            const chart = context.chart;

            const yMax = chart.scales.y.max;
            const {ctx, chartArea} = chart;
    
            if (!chartArea) {
                // This case happens on initial chart load
                return undefined;
            }
            return getGradient(ctx, chartArea, yMax, opacity);
        }

        return scriptableBorderColor;
    }    

    function getAggregationAverage(
        sleepLogGroups: ISleepLogView[][],
        aggregation: AggregationSummary,
    ): ChartData<"line", number[], string>
    {
        const hasFilter = sleepLogGroups.length > 1;
        const pointCount = sleepLogGroups.map(group => group.length).reduce((prev, cur) => prev + cur, 0);

        const dataset: ChartDataset<"line", number[]> = {
            label: '',
            data: [],
            borderColor: getBorderColor(sleepLogGroups.length <= 1 ? .75 : filteredOpacity),
            borderJoinStyle: "bevel",
            pointRadius: (pointCount / aggregationPeriodMap[aggregation]) > 45 && !alwaysShowPoints  ? 0 : 2.5,
            pointBorderWidth: 1,
            pointHoverBorderWidth: 2,
            pointBackgroundColor: pointCount <= 1 && !hasFilter ? getBorderColor(0) : getBorderColor(1),
            pointBorderColor: pointCount <= 1 && !hasFilter ? getBorderColor(0) : getBorderColor(1),
            pointHoverBorderColor: getBorderColor(1),

            borderWidth: 2.5,
            backgroundColor: (context) => {
                const { ctx, chartArea } = context.chart;
                var gradient = ctx.createLinearGradient(0, 0, 0, chartArea.height);

                if (useColorCoding) {
                    gradient.addColorStop(0, 'rgba(255, 214, 102, .2)');
                    gradient.addColorStop(.33, 'rgba(255, 214, 102, .1)');
                    gradient.addColorStop(.66, 'rgba(255, 214, 102, .05)');
                    gradient.addColorStop(1, 'rgba(255, 255, 255,0)');
                    
                }
                else {
                    gradient.addColorStop(0, getPrimaryColor(.25));
                    gradient.addColorStop(.33, getPrimaryColor(.2));
                    gradient.addColorStop(.66, getPrimaryColor(.05));
                    gradient.addColorStop(1, 'rgba(255, 255, 255,0)');
                }

                return gradient;
            },
            fill: true,
            tension: .2
        };  

        const data: ChartData<"line", number[], string> & { labels: string[] } = {
            labels: [],
            datasets: [dataset]
        };

        if (sleepLogGroups.length === 0) {
            return data;
        }

        let totalLogs = sleepLogGroups.map(logs => logs.length).reduce((prev, cur) => prev + cur);

        if (totalLogs === 0) {
            return data;
        }

        let start = "9999-12-31";
        let end = "";

        for (const sleepLogs of sleepLogGroups) {
            if (sleepLogs.length > 0) {
                const firstDate = sleepLogs[0].date.asString;
                const lastDate = sleepLogs[sleepLogs.length - 1].date.asString;
    
                start = firstDate < start ? firstDate : start;
                end = lastDate > end ? lastDate : end;
            }
        };

        const dateToSleepMap = Object.fromEntries(sleepLogGroups[0].map(sleepLog => 
            [sleepLog.date.asString, sleepLog.minutesAsleep !== undefined ? sleepLog.minutesAsleep / 60 : undefined ]
        ));

        const dateRange = AnalyticsUtils.fillDateRange(start, end);
        const values: (number | undefined)[] = dateRange.map(date => dateToSleepMap[date]);
    
        const { dates, values: aggregatedValues } = AnalyticsUtils.aggregation(
            dateRange,
            values,
            aggregation,
            1
        );        

        for (const [date, value] of _.zip(dates, aggregatedValues)) {
            data.labels.push(date!);
            dataset.data.push(value as number);
        }    

        return data;
    }

    function getDayData(sleepLogGroups: ISleepLogView[][], labels: string[]): ChartData<"line", number[], string> {    
        const hasFilter = sleepLogGroups.length > 1;
        const pointCount = sleepLogGroups.map(group => group.length).reduce((prev, cur) => prev + cur, 0);
        const isMovingAverage = aggregation === 'day' || aggregation === 'threeDayMoving' || aggregation === 'sevenDayMoving' || aggregation === 'fourteenDayMoving';

        const dataset: ChartDataset<"line", number[]> = {
            label: '',
            data: [],
            borderColor: getBorderColor(sleepLogGroups.length <= 1 ? .7 : filteredOpacity),
            borderJoinStyle: "bevel",
            pointRadius: [],
            pointBorderWidth: 1,
            pointHoverBorderWidth: 2,
            pointBackgroundColor: (pointCount > 45 && !hasFilter && !alwaysShowPoints) ? getBorderColor(0) : getBorderColor(1),
            pointBorderColor: (pointCount > 45 && !hasFilter && !alwaysShowPoints) ? getBorderColor(0) : getBorderColor(1),
            pointHoverBorderColor: getBorderColor(1),
            borderWidth: pointCount > 180 && isMovingAverage ? 2 : 2.5,
            backgroundColor: (context) => {
                const { ctx, chartArea } = context.chart;
                var gradient = ctx.createLinearGradient(0, 0, 0, chartArea.height * 1.25);

                if (useColorCoding) {
                    gradient.addColorStop(0, 'rgba(255, 214, 102, .2)');
                    gradient.addColorStop(.33, 'rgba(255, 214, 102, .1)');
                    gradient.addColorStop(.66, 'rgba(255, 214, 102, .05)');
                    gradient.addColorStop(1, 'rgba(255, 255, 255,0)');
                    
                }
                else {
                    gradient.addColorStop(0, getPrimaryColor(.25));
                    gradient.addColorStop(.33, getPrimaryColor(.2));
                    gradient.addColorStop(.66, getPrimaryColor(.05));
                    gradient.addColorStop(1, 'rgba(255, 255, 255,0)');
                }

                return gradient;
            },
            fill: true,
            tension: .2
        };

        const data: ChartData<"line", number[], string> = {
            datasets: [dataset]
        };

        let datesMap: { [key: string]: {sleepDuration: number, dataSetIndex: number, pointRadius: number} } = {};

        sleepLogGroups.forEach((sleepLogs, i) => {
            sleepLogs.forEach((log: ISleepLogView) => {
                if (log.minutesAsleep === undefined) {
                    return;
                }

                const sleepDurationInMins = log.minutesAsleep;
                const sleepDurationInHours = Number((sleepDurationInMins / 60).toFixed(3));

                const goal = goalSleepInMins ?? 7 * 60;

                const date = log.date.asString;

                datesMap[date] = {
                    sleepDuration: sleepDurationInHours,
                    dataSetIndex: i, 
                    pointRadius: i === 0 ? 2.5 : 0   
                };
            });
        });

        const dates = Object.keys(datesMap).sort();
        data.labels = dates;

        if (dates.length === 0) {
            return data;
        }

        const values = dates.map(date => datesMap[date].sleepDuration);
        const windowSize = aggregationPeriodMap[aggregation];
        const movingAverages = AnalyticsUtils.movingAverage(dates, values, windowSize, 1);

        for (const [date, value] of _.zip(dates, movingAverages)) {
            dataset.data.push(value as number);
            let dayData = datesMap[date!];

            (dataset.pointRadius as number[]).push(dayData.pointRadius);
        }

        return data;
    }

    function mapColor(color: 'red' | 'darkOrange' | 'orange' | 'yellow' | 'lightYellow' | 'green' | 'darkGreen', opacity: number) {
        const colors = {
            red: 'rgba(245, 34, 45, X)',
            darkOrange: 'rgba(250, 140, 22, X)',
            orange: 'rgba(255, 192, 105, X)',
            yellow: 'rgba(250, 219, 20, X)',
            lightYellow: 'rgba(250, 219, 20, X)',
            green: 'rgba(149, 222, 100, X)',
            darkGreen: 'rgba(56, 158, 13, X)',
        };

        return colors[color].replace('X', opacity.toString());
    }

    function getGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea, yMax: number, opacity: number) {
        let width, height, gradient;

        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (gradient === undefined || gradient === null || width !== chartWidth || height !== chartHeight) {
            // Create the gradient because this is either the first render
            // or the size of the chart has changed
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            
            gradient.addColorStop(0, mapColor('red', opacity));
            if (yMax > 4.5) gradient.addColorStop(Math.min(1, 4.5 / yMax), mapColor('red', opacity));

            if (yMax > 5) gradient.addColorStop(Math.min(1, 5 / yMax), mapColor('darkOrange', opacity));
            if (yMax > 5.5) gradient.addColorStop(Math.min(1, 5.5 / yMax), mapColor('darkOrange', opacity));

            if (yMax > 6) gradient.addColorStop(Math.min(1, 6 / yMax), mapColor('orange', opacity));
            if (yMax > 6.25) gradient.addColorStop(Math.min(1, 6.25 / yMax), mapColor('orange', opacity));

            if (yMax > 6.5) gradient.addColorStop(Math.min(1, 6.4 / yMax), mapColor('yellow', opacity));
            if (yMax > 6.9) gradient.addColorStop(Math.min(1, 6.8 / yMax), mapColor('lightYellow', opacity));
            
            if (yMax > 7.2) gradient.addColorStop(Math.min(1, 7.1 / yMax), mapColor('green', opacity));
            
            if (yMax > 8) gradient.addColorStop(Math.min(1, 8 / yMax), mapColor('darkGreen', opacity)); 
        }
        
      return gradient;
    }    

    const options: ChartOptions<"line"> = useMemo(() => {
        const unit = (() => {
            switch (aggregation) {
                case 'day':
                    return 'day'
                case 'week':
                    return 'week';
                case 'month':
                    return 'month';
                case 'quarter':
                    return "quarter";
                default: 
                    return 'day'
            }
        })();  

        return {
            plugins: {   
                annotation: {
                    annotations: {
                        sleepGoal: {
                          type: 'line',
                          yMin: (goalSleepInMins ?? 0) / 60,
                          yMax: (goalSleepInMins ?? 0) / 60,
                          borderColor: "rgba(192, 38, 211, .5)",
                          borderWidth: 2,
                          borderDash: [8, 4]
                        }
                    }                
                },                     
                legend: {
                    display: showLegend ?? true
                },
                deferred: {
                },
                title: {
                    display: false
                },

                tooltip: {
                    callbacks: {
                        label: function(context) {
                            return context.raw !== undefined ? prettyFormatTimeDurationInHours(context.raw as number) : "";
                        }
                    }
                },
                zoom: {
                    pan: {
                        enabled: enableZoom,
                        mode: 'x',
                    },                
                    zoom: {
                        wheel: {
                            enabled: enableZoom,
                        },
                        pinch: {
                            enabled: enableZoom
                        },
                        mode: 'x',
                    },
                    limits: {
                        y: {min: 0, max: 48},
                    }
                },    
            },        
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: unit,
                        displayFormats: {
                            day: 'M/d',
                            week: "M/d'-'",
                        },
                        tooltipFormat: "MM/dd/y"
                    },   
                    adapters: {
                        date: {
                            zone: "UTC"
                        }
                    },             
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            ...ChartStyles.axisFont
                        }
                    }
                },
                y: {
                    beginAtZero: true,                
                    ticks: {
                            font: {
                                ...ChartStyles.axisFont
                            }
                    },
                    grid: {
                        ...ChartStyles.gridYAxis,
                        display: showLines ?? true,
                    },
                    position: 'right'
                }         
            },
        };
    }, [enableZoom, showLines, showLegend, aggregation]);

    useEffect(() => {
        const data = aggregation !== 'day' && aggregation !== 'threeDayMoving' && aggregation !== 'sevenDayMoving' && aggregation !== 'fourteenDayMoving' ? 
            getAggregationAverage(sleepLogGroups, aggregation) : 
            getDayData(sleepLogGroups, ["", ", filtered"]);

        setData(data);

    }, [useColorCoding, alwaysShowPoints, aggregation, sleepLogGroups[0], sleepLogGroups[1]]);

    return (
        <div>        
            <Line
                data={data}
                options={options}
                plugins={[ChartDeferred]}
            />     
        </div>
    ); 
}

const lineOpacity = .5
const opacity = .6;
const filteredOpacity = .3;

const colors = [
    'rgba(255, 99, 132, X)',
    'rgba(250, 140, 22, X)',
    'rgba(255, 197, 61, X)',
    'rgba(250, 219, 20, X)',
    'rgba(115, 209, 61, X)',
];

function computeColor(sleep: number, opacity: number) {
    if (sleep >= 7 * 60) {
        return colors[4].replace('X', opacity.toString());
    }
    else if (sleep >= 6.5 * 60) {
        return colors[3].replace('X', opacity.toString());
    }
    else if (sleep >= 6 * 60) {
        return colors[2].replace('X', opacity.toString());
    }
    else if (sleep >= 5 * 60) {
        return colors[1].replace('X', opacity.toString());
    }
    else {
        return colors[0].replace('X', opacity.toString());
    }
}

const primaryColor = (opacity: number) => `rgba(153, 102, 255, ${opacity})`;

const getColor = (sleep: number) => {
    let opacity: number;

    if (sleep >= 7 * 60) {
       opacity = 0.9;
    }
    else if (sleep >= 6.5 * 60) {
        opacity = .8;
    }
    else if (sleep >= 6 * 60) {
        opacity = .7;
    }
    else if (sleep >= 5 * 60) {
        opacity = .5
    }
    else {
        opacity = .4;
    }

    return primaryColor(opacity);
}
