import produce from "immer";
import _ from "lodash";
import { DateTime } from "luxon";
import { useState, useRef, useEffect, useLayoutEffect, useContext } from "react";
import { StatusMessage, StatusMessageType } from "../lib/common/StatusMessages";
import { SleepLogHttpServiceContext } from "../lib/http/sleep-log-http-service.provider";
import SleepLogHttpService from "../lib/http/SleepLogHttpService";
import { HttpException } from "../types/Exceptions";
import ISleepLogView from "../types/ISleepLogView";
import SleepLog, { SleepSummary, SleepStage, SleepStageType, SleepEpisode } from "../types/SleepLog";
import DateTimeUtils from "../utils/DateTimeUtils";
import { ISleepLogUpdateFlusher } from "../utils/SleepLogUpdateFlusher";
import SleepStagesUtils from "../utils/SleepStagesUtils";
import SleepEpisodeEditor from "./SleepEpisodeEditor";

export interface State {
    date: string;
    sleepSummary: SleepSummary;
    selectedIndex?: number
    editingTimeRange?: { start: string, end: string };
}

export function useSleepStagesEditorService(
    username: string | undefined,
    sleepLog: ISleepLogView,
    onSave: (sleepSummary: SleepSummary | undefined) => void,
    addStatus: (msg: StatusMessage) => void,
    sleepLogUpdateFlusher: ISleepLogUpdateFlusher
): [State, SleepStagesEditorService] {

    const [state, setState] = useState<State>(() => {
        const summary = sleepLog.sleepSummary ?? { episodes: [] };

        return {
            date: sleepLog.date.asString,
            sleepSummary: summary
        };
    });

    const sleepLogHttpService = useContext(SleepLogHttpServiceContext)!;

    const service = useRef<SleepStagesEditorService | null>(null);

    if (!service.current) {

        service.current = new SleepStagesEditorService(
            username,
            sleepLog.id,
            state,
            onSave,
            addStatus,
            sleepLogUpdateFlusher,
            sleepLogHttpService,
            (state: State) => setState(state));    
    }

    useLayoutEffect(() => {
        const summary = sleepLog.sleepSummary ?? { episodes: [] };
        service.current!.reset(summary);
        service.current!.setSleepLogId(sleepLog.id);
    }, [sleepLog.id, sleepLog.sleepSummary]);
 
    return [state, service.current];
}

export class SleepStagesEditorService {

    private initialSummary: SleepSummary;

    constructor(
        private username: string | undefined,
        private sleepLogId: string,
        private state: State,
        private onSave: (sleepSummary: SleepSummary | undefined) => void,
        private addStatus: (msg: StatusMessage) => void,
        private sleepLogUpdateFlusher: ISleepLogUpdateFlusher,
        private sleepLogHttpService: SleepLogHttpService,
        private emit: (state: State) => void
    ) {
            this.initialSummary = state.sleepSummary;
    }

    public setSleepLogId(sleepLogId: string) {
        this.sleepLogId = sleepLogId;
    }

    public reset(newSleepSummary?: SleepSummary) {
        this.state = produce(this.state, draftState => {
            draftState.sleepSummary = newSleepSummary ?? this.initialSummary;

            if (draftState.selectedIndex && draftState.selectedIndex >= draftState.sleepSummary.episodes.length) {
                draftState.selectedIndex = undefined;
            }

            draftState.editingTimeRange = undefined;
        });

        if (newSleepSummary) {
            this.initialSummary = newSleepSummary;
        }

        this.emit(this.state);
    }

    public selectIndex(i: number | undefined) {
        if (i !== undefined && this.state.sleepSummary.episodes.length <= i) {
            return;
        }

        this.state = produce(this.state, draftState => {
            draftState.selectedIndex = i;
            
            if (i !== undefined) {
                const episode = draftState.sleepSummary.episodes[i];
                draftState.editingTimeRange = { start: episode.start, end: episode.end };
            }
        });

        this.emit(this.state);
    }

    public async save(sleepSummary?: SleepSummary | null): Promise<boolean> {
        let sleepSummaryToSave = sleepSummary !== undefined ? sleepSummary : this.state.sleepSummary;
        sleepSummary = null; // sleepSummaryToSave should be used

        // Sort episodes by start time and compress them before saving.
        if (sleepSummaryToSave) {
            sleepSummaryToSave = produce(sleepSummaryToSave, draftSummary => {
                    draftSummary.episodes = draftSummary.episodes.sort((lhs, rhs) => +DateTime.fromISO(lhs.start) - +DateTime.fromISO(rhs.start))
                    draftSummary.episodes = draftSummary.episodes.map(episode => ({...episode, sleepStages: SleepStagesUtils.compress(episode.sleepStages)}))
            });
        }

        // Validate
        if (sleepSummaryToSave && sleepSummaryToSave.episodes.length > 0) {
            const hasMainSleep = sleepSummaryToSave.episodes.some(episode => episode.isMainSleep);
            if (!hasMainSleep) {
                this.addStatus({
                msg: `Saving was unsuccessful. There must be at least one main sleep episode.`,
                    type: StatusMessageType.Fail
                });
        
                return false;                        
            }

            for (let i = 1; i < sleepSummaryToSave.episodes.length; i++) {
                if (DateTime.fromISO(sleepSummaryToSave.episodes[i].start) < DateTime.fromISO(sleepSummaryToSave.episodes[i - 1].end)) {
                    this.addStatus({
                        msg: `Saving was unsuccessful. Sleep episodes should not intersect.`,
                        type: StatusMessageType.Fail
                    });
            
                    return false;  
                }
            }
        }
        
        let updateMetadata: { lastModifiedTime: string };

        const body = {
            sleepSummary: sleepSummaryToSave
        };

        try {
            updateMetadata = await this.sleepLogHttpService.updateSleepLog(this.sleepLogId, body);
        }
        catch(e: any) {
            if (e instanceof HttpException) {
                this.addStatus({
                    msg: `Saving sleep stages failed unexpectedly. Reason: ${e.message}`,
                    type: StatusMessageType.Fail
                });                
            }
            else {
                this.addStatus({
                    msg: `Saving sleep stages failed unexpectedly. Please try again`,
                    type: StatusMessageType.Fail
                });
            }

            return false;
        }

        this.sleepLogUpdateFlusher.setLastModifiedTime(this.sleepLogId, updateMetadata.lastModifiedTime as string);

        if (sleepSummaryToSave !== null) {
            this.initialSummary = this.state.sleepSummary;
            this.state = produce(this.state, draftState => {
                draftState.sleepSummary = sleepSummaryToSave!;
                draftState.selectedIndex = undefined;
            });
        }
        else {
            this.initialSummary = {episodes: []};
            this.state = produce(this.state, draftState => {
                draftState.selectedIndex = undefined;
                draftState.sleepSummary = { episodes: [] };
                draftState.editingTimeRange = undefined;
            });
        }

        this.emit(this.state);
        this.onSave(sleepSummaryToSave ?? undefined);

        return true;
    }

    public add() {
        this.state = produce(this.state, draftState => {
            let inheritedTimezone: string | undefined;

            const timezones = _.uniq(this.state.sleepSummary.episodes.map(episode => episode.timezone ?? "utc"));

            // If all existing episodes have the same timezone use that.
            if (timezones.length === 1) {
                inheritedTimezone = timezones[0];
            }

            let _start = DateTime.fromISO(this.state.date, { zone: inheritedTimezone ? inheritedTimezone : undefined });
            let start = _start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toUTC();
            const end = start.plus({ hours: 8 });

            const stages: SleepStage[] = [
                {
                    start: DateTimeUtils.format(start, "YYYY-MM-DDTHH:MM:SSZ"),
                    seconds: end.diff(start).as("seconds"),
                    type: "asleep"
                }
            ];

            const hasMainSleep = draftState.sleepSummary.episodes.some(e => e.isMainSleep);

            const length = draftState.sleepSummary.episodes.push({
                start: DateTimeUtils.format(start, "YYYY-MM-DDTHH:MM:SSZ"),
                end: DateTimeUtils.format(end, "YYYY-MM-DDTHH:MM:SSZ"),
                timezone: _start.zone.name, 
                minutesAsleep: stages[0].seconds / 60,
                minutesAwake: 0,
                isMainSleep: !hasMainSleep,
                sleepStages: stages
            });

            draftState.editingTimeRange = { start: DateTimeUtils.format(start, "YYYY-MM-DDTHH:MM:SSZ"), end: DateTimeUtils.format(end, "YYYY-MM-DDTHH:MM:SSZ") };
            draftState.selectedIndex = length - 1;
        });

        this.emit(this.state);
    }

    public async delete(index: number) {
        const state = produce(this.state, draftState => {
            draftState.sleepSummary.episodes.splice(index, 1);
        });

        await this.save(state.sleepSummary);
    }

    public async deleteAll() {
        await this.save(null);        
    }

    public updateTimeRange(index: number, start: DateTime | undefined, end: DateTime | undefined) {
        this.state = produce(this.state, draftState => {
            if (draftState.editingTimeRange) {
                if (start) {
                    draftState.editingTimeRange.start = DateTimeUtils.format(start.setZone("utc"), "YYYY-MM-DDTHH:MM:SSZ");
                }

                if (end) {
                    draftState.editingTimeRange.end = DateTimeUtils.format(end.setZone("utc"), "YYYY-MM-DDTHH:MM:SSZ");
                }
            }
        });

        this.emit(this.state);
    }

    public commitTimeRange(index: number) {
        if (!this.state.editingTimeRange) {
            return;
        }

        const newStart = DateTime.fromISO(this.state.editingTimeRange.start);
        const newEnd = DateTime.fromISO(this.state.editingTimeRange.end);

        if (newStart >= newEnd) {
            return;
        }

        this.state = produce(this.state, draftState => {
            const episode = draftState.sleepSummary.episodes[index];
            episode.start = DateTimeUtils.format(newStart, "YYYY-MM-DDTHH:MM:SSZ");
            episode.end = DateTimeUtils.format(newEnd, "YYYY-MM-DDTHH:MM:SSZ");

            const sleepStages = episode.sleepStages.map(stage => {
                return {
                    start: DateTime.fromISO(stage.start),
                    end: DateTime.fromISO(stage.start).plus({ seconds: stage.seconds }),
                    seconds: stage.seconds,
                    type: stage.type
                };
            });

            if (sleepStages.length === 0 || newStart >= sleepStages[sleepStages.length - 1].end || newEnd <= sleepStages[0].start) {
                episode.sleepStages = [];
                episode.sleepStages.push({
                    start: DateTimeUtils.format(newStart, "YYYY-MM-DDTHH:MM:SSZ"),
                    seconds: newEnd.diff(newStart).as("seconds"),
                    type: 'asleep'
                });

                episode.minutesAsleep = this.calculateMinutesAsleep(episode.sleepStages);
                episode.minutesAwake = this.calculateMinutesAwake(episode.sleepStages);

                return;
            }

            let i = 0;
            let addNewStart = false;

            if (newStart < sleepStages[i].start) {
                addNewStart = true;
            }
            else {
                while (!(sleepStages[i].start <= newStart && newStart < sleepStages[i].end)) {
                    ++i;
                }
            }

            let j = sleepStages.length - 1;
            let addNewEnd = false;

            if (newEnd > sleepStages[j].end) {
                addNewEnd = true;
            }
            else {
                while (!(sleepStages[j].start < newEnd && newEnd <= sleepStages[j].end)) {
                    j--;
                }
            }

            if (i === j && !addNewStart && !addNewEnd) {
                episode.sleepStages[i].start = DateTimeUtils.format(newStart, "YYYY-MM-DDTHH:MM:SSZ");
                episode.sleepStages[i].seconds = newEnd.diff(newStart).as("seconds");
            }
            else {
                if (!addNewStart) {
                    episode.sleepStages[i].start = DateTimeUtils.format(newStart, "YYYY-MM-DDTHH:MM:SSZ");
                    episode.sleepStages[i].seconds = sleepStages[i].end.diff(newStart).as("seconds");
                }

                if (!addNewEnd) {
                    episode.sleepStages[j].seconds = newEnd.diff(sleepStages[j].start).as("seconds");
                }
            }

            episode.sleepStages = episode.sleepStages.slice(i, j + 1);

            if (addNewStart) {
                episode.sleepStages.unshift({
                    start: DateTimeUtils.format(newStart, "YYYY-MM-DDTHH:MM:SSZ"),
                    seconds: sleepStages[0].start.diff(newStart).as("seconds"),
                    type: "asleep"
                });
            }

            if (addNewEnd) {
                episode.sleepStages.push({
                    start: DateTimeUtils.format(sleepStages[j].end, "YYYY-MM-DDTHH:MM:SSZ"),
                    seconds: newEnd.diff(sleepStages[j].end).as("seconds"),
                    type: "asleep"
                });
            }

            episode.minutesAsleep = this.calculateMinutesAsleep(episode.sleepStages); 
            episode.minutesAwake = this.calculateMinutesAwake(episode.sleepStages);
            
            episode.sleepStages = SleepStagesUtils.compress(episode.sleepStages);
        });

        this.emit(this.state);
    }
    
    public addStage(index: number, start: DateTime, end: DateTime, type: SleepStageType) {
        const newStart = start.toUTC();
        const newEnd = end.toUTC();

        if (newStart >= newEnd) {
            return;
        }

        this.state = produce(this.state, (draftState: State) => {
            const episode: SleepEpisode = draftState.sleepSummary.episodes[index];

            const newStage = {
                start: DateTimeUtils.format(newStart, "YYYY-MM-DDTHH:MM:SSZ"),
                seconds: newEnd.diff(newStart).as("seconds"),
                type: type
            };

            const episodeEditor = new SleepEpisodeEditor(episode);
            episodeEditor.add(newStage);

            draftState.editingTimeRange = { start: episode.start, end: episode.end };
        });

        this.emit(this.state);
    }

    public removeAwakenings(index: number, thresholdInSeconds: number) {
        this.state = produce(this.state, draftState => {
            const episode = draftState.sleepSummary.episodes[index];
            const episodeEditor = new SleepEpisodeEditor(episode);
            episodeEditor.removeAwakenings(thresholdInSeconds);
        });

        this.emit(this.state);
    }

    public setIsMainSleep(index: number, isMainSleep: boolean) {
        this.state = produce(this.state, draftState => {
                const episode = draftState.sleepSummary.episodes[index];
                episode.isMainSleep = isMainSleep;
        });

        this.emit(this.state);
    }

    private calculateMinutesAsleep(sleepStages: SleepStage[]) {
        return sleepStages
            .filter(episode => ['asleep', 'light', 'deep', 'rem'].includes(episode.type))
            .map(episode => episode.seconds / 60)
            .reduce((sum, duration) => sum + duration, 0);        
    }

    private calculateMinutesAwake(sleepStages: SleepStage[]) {
        return sleepStages
            .filter(episode => ['awake'].includes(episode.type))
            .map(episode => episode.seconds / 60)
            .reduce((sum, duration) => sum + duration, 0);
    }
}