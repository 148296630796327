import { useEffect, useRef } from "react";
import { SleepLogFilterService, State } from "./sleep-log-filter.service";
import useStore from "../../../shared/store/useStoreService.hook";
import SleepLogSettings from "../../../types/SleepLogSettings";
import SleepLogSettingsService from "../../sleep-log-settings/services/SleepLogSettingsService";
import { SleepLogSettingsServiceContext } from "../../sleep-log-settings/services/sleep-log-settings-service.provider";

export const useSleepLogFilterServiceProvider = (limit?: number) => {
    const serviceRef = useRef<SleepLogFilterService | undefined>(undefined);

    if (serviceRef.current === undefined) {
        serviceRef.current = new SleepLogFilterService(limit);
    }

    const service = serviceRef.current;

    const [, settings] = useStore<SleepLogSettingsService | undefined, SleepLogSettings | undefined>(SleepLogSettingsServiceContext);
    const customMetrics = settings?.customMetrics;

    useEffect(() => {
        service.updateCustomMetricFields(customMetrics ?? []);
    }, [customMetrics]);
    
    return service;
}